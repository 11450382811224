<template>
  <div>
    <tutor-staff
      :can-edit-staff="canViewAndEditStaff"
      :can-view-staff="canViewStaff"
      :can-edit-training="canViewAndEditTraining"
      :can-view-training="canViewTraining"
      :load-staff-params="loadStaffParams"
      show-edit-details-actions
      show-add-training-record
      show-delete-training-record
      click-to="CohortStaffListPage"
      show-training
      show-staff-details-and-roles
    />
  </div>
</template>

<script setup lang="ts">
import TutorStaff from './TutorStaff.vue';
import { mapState } from '@/store/map-store';
import { hasPermissionForSelectedCohort } from '@/composables/permission';
import { computed } from 'vue';

const { selectedCohort } = mapState();
const loadStaffParams = `?restrict_roles_to_cohort_id=${selectedCohort.value?.id}`;
const canViewAndEditTraining = computed(() => hasPermissionForSelectedCohort('staff.training.edit').value);
const canViewAndEditStaff = computed(() => hasPermissionForSelectedCohort('staff.edit').value);
const canViewStaff = computed(() => canViewAndEditStaff.value || hasPermissionForSelectedCohort('staff.view').value);
const canViewTraining = computed(
  () => canViewAndEditTraining.value || hasPermissionForSelectedCohort('staff.training.view').value
);
</script>
