<template>
  <div>
    <v-card v-if="stateError && !stateProcessing">
      <v-card-text>
        <div class="pa-4">Sorry, cannot load your school at the moment</div>
      </v-card-text>
    </v-card>
    <div v-if="!stateError && !stateProcessing">
      <v-card class="mb-4">
        <v-card-text>
          <mosaic-card-title
            >{{ traineeNounCapitalisedAndPluralised }}
            <template #actions>
              <v-btn
                v-if="userStaffHasPermissionForSelectedSchool('schools.registerEct')"
                ripple
                class="ml-2"
                @click.prevent="registerNewEct()"
              >
                <div class="d-flex align-center">
                  <v-icon>mdi-plus</v-icon>
                  <span>New {{ traineeNounCapitalised() }}</span>
                </div>
              </v-btn>
            </template>
          </mosaic-card-title>
          <div class="d-flex align-center">
            <div>
              <v-text-field
                v-model="nameEmailFilter"
                density="compact"
                hide-details
                style="width: 300px"
                :label="`Filter ${traineeNounCapitalisedAndPluralised} by name or email`"
                prepend-icon="mdi-magnify"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-text>
          <div class="text-h6">Active ECTs</div>
          <mosaic-list
            :items="filteredAndSortedEcts"
            :empty-text="`There are no ${traineeNounCapitalisedAndPluralised} associated with this School`"
          >
            <template #item="{ item: e }">
              <mosaic-list-item
                :to="canClickStudent(e) ? ectClickTo(e) : null"
                icon="mdi-account"
                :title="e.name || e.email"
                :subtitle="e.name ? e.email : ''"
              >
                <template #information v-if="e.futureLeavingDate">
                  <mosaic-icon-badge
                    color="secondary"
                    icon="mdi-exit-run"
                    :tooltip="`This ${traineeNoun()} is marked as leaving on ${formatDate(e.futureLeavingDate)}`"
                  />
                </template>

                <template #actions v-if="userStaffHasPermission('Admin')">
                  <ndt-icon-button icon="delete" tooltip="Remove from School" @click.prevent="markAsLeaving(e)" />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-text>
          <div class="text-h6">Pending ECTs</div>
          <mosaic-list
            :items="filteredAndSortedPendingEcts"
            :empty-text="`There are no pending ${traineeNounCapitalisedAndPluralised} associated with this School`"
          >
            <template #item="{ item: e }">
              <mosaic-list-item
                :to="canClickStudent(e) ? ectClickTo(e) : null"
                icon="mdi-account"
                :title="e.name || e.email"
                :subtitle="e.name ? e.email : ''"
              >
                <template #information>
                  <v-chip
                    v-if="
                      e.status === 'awaiting_approval' ||
                      !(
                        userStaffHasPermission('Admin') ||
                        userStaffHasPermissionForSelectedSchool('schools.registerEct')
                      ) ||
                      (e.status === 'awaiting_qts' && !userStaffHasPermission('Admin'))
                    "
                    :color="userStaffHasPermission('Admin') ? 'accent' : 'secondary'"
                    >Awaiting Approval</v-chip
                  >
                  <v-chip v-else-if="e.status === 'awaiting_qts'" color="secondary">Awaiting QTS Check</v-chip>
                  <v-chip v-else-if="e.status === 'does_not_have_qts'" color="red">QTS Check Failed</v-chip>
                  <v-chip v-else-if="e.status === 'pending_registration'" color="accent"
                    >Incomplete Registration</v-chip
                  >
                  <v-chip v-else-if="e.status === 'teacher_not_found'" color="red"
                    >{{ traineeNoun() }} Not Found</v-chip
                  >
                  <v-chip v-else-if="e.status === 'rejected'" color="red">Rejected</v-chip>

                  <v-chip v-if="e.joinSchoolDate" color="accent" class="ml-2"
                    >Start Date: {{ formatDate(e.joinSchoolDate) }}
                  </v-chip>
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </v-card-text>
      </v-card>
    </div>

    <ndt-dialog
      v-model:active="markAsLeavingDialog.active"
      :title="`Mark ${traineeNounCapitalised()} as leaving School`"
      :error-message="markAsLeavingDialog.error"
    >
      <div class="pb-2">Are you sure you want to mark "{{ markAsLeavingDialog.name }}" as leaving this school?</div>
      <mosaic-date-picker
        v-model:date="markAsLeavingDialog.leaveDate"
        label="Date to leave school"
        :min="minLeaveDate"
        :exact-width="false"
      ></mosaic-date-picker>
      <v-alert type="info" variant="outlined"
        >Please note: this will mark the {{ traineeNounCapitalised() }} as leaving the AB. If the ECT is changing
        school, you can manage this via the {{ traineeNounCapitalised() }}'s "Manage Induction" Page.</v-alert
      >
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="markAsLeavingDialog.processing"
          @click.prevent="submitMarkAsLeaving()"
        >
          Mark as Leaving
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapStateError, mapStateProcessing } from '@/store/map-store';
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import { findStudentLevelHomePage } from '@/utils/navigation';
import moment from 'moment';

export default {
  name: 'TutorSchoolStudentsPage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [syncQueryParamsMixin({ nameEmailFilter: { query: 'name' } })],
  components: { NdtIconButton, NdtDialog },
  data: () => ({
    processing: false,
    schoolId: '',
    ects: [],
    pendingEcts: [],
    school: null,
    nameEmailFilter: '',
    addStudentDialog: {
      active: false,
      processing: false,
      error: null,
      showStudentsAlreadyInSchools: false,
      studentIds: [],
    },
    markAsLeavingDialog: {
      active: false,
      processing: false,
      error: null,
      name: null,
      id: null,
      note: '',
      leaveDate: moment.utc().format('YYYY-MM-DD'),
    },
  }),
  computed: {
    ...mapState(['selectedInstitution', 'selectedSchool', 'userStaff']),
    ...mapStateProcessing(['loadSchool']),
    ...mapStateError(['loadSchool']),
    breadcrumbs() {
      return [
        {
          text: this.traineeNounCapitalisedAndPluralised,
        },
      ];
    },
    minLeaveDate() {
      return moment().format('YYYY-MM-DD');
    },
    filteredAndSortedEcts() {
      return this.filterEcts(this.ects);
    },
    filteredAndSortedPendingEcts() {
      const sortedStatuses = [
        'rejected',
        'teacher_not_found',
        'does_not_have_qts',
        'pending_registration',
        'awaiting_qts',
      ];
      // sort by status according to statuses array order
      const sortedEcts = this.pendingEcts.sortBy([x => sortedStatuses.indexOf(x), x => x.name]);
      return this.filterEcts(sortedEcts);
    },
    canSubmitAddStudent() {
      return !this.addStudentDialog.processing && this.addStudentDialog.studentIds.length > 0;
    },
  },
  watch: {
    selectedSchool(school) {
      if (school) {
        this.updateSchoolDetails(school);
      }
    },
  },
  created() {
    this.schoolId = this.$route.params.schoolId;
    if (this.selectedSchool) {
      this.updateSchoolDetails(this.selectedSchool);
    }
  },
  methods: {
    filterEcts(ects) {
      return ects.filter(
        x =>
          x.name?.toLowerCase().includes(this.nameEmailFilter.toLowerCase()) ||
          x.email.toLowerCase().includes(this.nameEmailFilter.toLowerCase())
      );
    },
    startsInFuture(ect) {
      return moment(ect.startDate).isAfter(moment(), 'day');
    },
    canClickStudent(ect) {
      if (this.pendingApproval(ect)) {
        return this.userStaffHasPermission('ects.approveRegistration');
      } else if (this.pendingRegistration(ect)) {
        return this.userStaffHasPermissionForSelectedSchool('schools.registerEct');
      }
      return (
        // Check both inst and cohort for 'Admin' permissions
        // Ect needs to be added to the student object for school permission checking.
        // In other places, only passing in selectedStudent, where Ect is property
        this.hasPermissionForStudent(this.userStaff, 'Admin', ect.student) ||
        (this.hasPermissionForStudent(this.userStaff, 'students.view', { ...ect.student, ect: ect }) &&
          !this.startsInFuture(ect))
      );
    },
    updateSchoolDetails(school) {
      this.school = school;
      this.ects = school.ects;
      this.pendingEcts = school.pendingEcts;
    },
    ectClickTo(e) {
      if (this.pendingApproval(e)) {
        return {
          name: 'TutorEctApproveRegistrationPage',
          params: { institutionId: this.selectedInstitution.id, ectId: e.id },
        };
      } else if (this.pendingRegistration(e)) {
        return {
          name: 'TutorEctReviewRegistrationPage',
          params: { schoolId: this.school.id, ectId: e.id },
        };
      } else {
        // Ect needs to be added to the student object for school permission checking.
        // In other places, only passing in selectedStudent, where Ect is property
        return findStudentLevelHomePage(this.userStaff, { ...e.student, ect: e });
      }
    },
    markAsLeaving(e) {
      this.markAsLeavingDialog.name = e.name || e.email;
      this.markAsLeavingDialog.id = e.id;
      this.markAsLeavingDialog.active = true;
    },
    async submitMarkAsLeaving() {
      this.markAsLeavingDialog.processing = true;
      this.markAsLeavingDialog.error = '';
      try {
        api.post(`/ects/${this.markAsLeavingDialog.id}/mark-as-leaving`, {
          date: this.markAsLeavingDialog.leaveDate,
          note: this.markAsLeavingDialog.note,
        });
        this.$store.dispatch('loadSchool', { schoolId: this.schoolId, force: true });
        this.markAsLeavingDialog.active = false;
      } catch (e) {
        console.log(e);
        this.markAsLeavingDialog.error = `Sorry, cannot mark this ${this.traineeNoun()} as leaving at the moment`;
      }
      this.markAsLeavingDialog.processing = false;
    },
    registerNewEct() {
      this.$router.push({
        name: 'TutorEctRegistrationPage',
        params: { schoolId: this.school.id },
      });
    },
    // Negative seems odd here, but it more helpfully covers when a student is not an ECT (as well as when the ECT is not active)
    pendingApproval(ect) {
      return (
        ect &&
        ect.status !== 'active' &&
        ect.status !== 'pending_registration' &&
        ect.status !== 'rejected' &&
        ect.status !== 'teacher_not_found'
      );
    },
    pendingRegistration(ect) {
      return (
        ect && (ect.status == 'pending_registration' || ect.status == 'rejected' || ect.status == 'teacher_not_found')
      );
    },
  },
};
</script>
