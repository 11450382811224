<template>
  <mosaic-loading-and-error-cards object-type="Instructors" :load="load" loading-type="inline-list">
    <mosaic-action-assignment
      :selected-ids="selectedStaffIds"
      @update:selected-ids="emit('update:selectedStaffIds', $event)"
      :assignees="staff"
      :initially-selected-ids="initiallySelectedStaffIds"
      :is-editing="isEditing"
      :scheduled-date="scheduledDate"
      :action-set-in-the-future="actionSetInTheFuture"
      :action-originally-set-in-the-future="actionOriginallySetInTheFuture"
      assignee-noun="Instructor"
      assignee-noun-pluralised="Instructors"
      :action-noun="objectType"
      :filter="s => filter(s) && filteredCohortStaff(s)"
      :hide-title="hideTitle"
      :empty-text="`There are no Instructors for this Cohort. Instructors can be added by assigning them a role to ${traineeNounPluralised} in the Cohort.`"
      :pending-assignment-label="pendingAssignmentLabel"
      :pending-assignment-tooltip-action="pendingAssignmentTooltipAction"
      :pending-assignment-tooltip="pendingAssignmentTooltip"
    >
      <template #filters>
        <role-filter :roles="studentScopedRoles.concat(cohortAdminRoles)" @update:role-id="selectedRoleId = $event" />
        <div style="min-width: max-content">
          <v-select
            v-model="assignedToTraineeFilter"
            hide-details
            :items="assignedToTraineeOptions"
            :label="`Assigned to ${traineeNoun}?`"
          ></v-select>
        </div>
        <slot name="filters"></slot>
      </template>

      <template v-if="$slots['selected-text']" #selected-text>
        <slot name="selected-text"></slot>
      </template>

      <template #status-badge="{ assignee }">
        <slot name="status-badge" :staff="assignee"></slot>
      </template>

      <template #details-header>
        <slot name="details-header"></slot>
      </template>

      <template #details="{ assignee }">
        <slot name="details" :staff="assignee"></slot>
      </template>
    </mosaic-action-assignment>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { useCohortStore, type CohortStaff } from '@/stores/cohort';
import { computed, ref } from 'vue';
import MosaicActionAssignment from './MosaicActionAssignment.vue';
import { useStudentStore } from '@/stores/student';
import RoleFilter from './RoleFilter.vue';
import { useRoleStore } from '@/stores/role';
import { syncQueryParam } from '@/composables/query';

const props = withDefaults(
  defineProps<{
    cohortId: number;
    selectedStaffIds: number[];
    initiallySelectedStaffIds: number[];
    disabledStaff?: { id: number; tooltip: string }[];
    // extraStaff can contain staff already in the Cohort, in which case they will be filtered out
    extraStaff?: Staff[];
    isEditing: boolean;
    objectType: string;
    filter?: (s: Staff) => boolean;
    scheduledDate?: string;
    actionSetInTheFuture?: boolean;
    actionOriginallySetInTheFuture?: boolean;
    hideTitle?: boolean;
    pendingAssignmentLabel?: string;
    pendingAssignmentTooltipAction?: string;
    pendingAssignmentTooltip?: string;
  }>(),
  {
    filter: (_: Staff) => true,
    disabledStaff: () => [],
    extraStaff: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:selectedStaffIds', ids: number[]): void;
}>();

type Staff = Omit<CohortStaff, 'user'>;

const {
  cohortStaffCache,
  actions: { loadCohortStaff },
} = useCohortStore();
const { traineeNoun, traineeNounPluralised } = useStudentStore();
const cohortStaff = computed<Staff[]>(() => cohortStaffCache.value.getEvenIfExpired(props.cohortId) || []);
const staff = computed(() =>
  cohortStaff.value.concat(props.extraStaff.filter(s => !cohortStaff.value.some(st => st.id == s.id))).map(s => {
    const disabled = props.disabledStaff.find(ds => ds.id == s.id);
    return {
      ...s,
      name: s.name,
      email: s.email,
      displayName: s.name || s.email,
      disabledTooltip: disabled?.tooltip,
    };
  })
);

const {
  studentScopedRoles,
  cohortAdminRoles,
  actions: { loadRoles },
} = useRoleStore();
const selectedRoleId = ref<number>(-1);

const assignedToTraineeFilter = ref<'all' | 'assigned' | 'noStudentYet'>('all');
syncQueryParam(assignedToTraineeFilter, 'assigned', 'string');
const assignedToTraineeOptions = [
  { title: 'All', value: 'all' },
  { title: 'Assigned', value: 'assigned' },
  { title: `No ${traineeNoun.value} yet`, value: 'noStudentYet' },
];

function filteredCohortStaff(s: Staff) {
  let staffRoles: Staff['staffRoles'] = [];
  if (selectedRoleId.value !== -1) {
    staffRoles = s.staffRoles.filter(sr => sr.role.id === selectedRoleId.value);
    if (staffRoles.length === 0) return false;
  }

  return (
    assignedToTraineeFilter.value === 'all' ||
    (assignedToTraineeFilter.value === 'assigned' && staffRoles.some(sr => !!sr.studentId)) ||
    (assignedToTraineeFilter.value === 'noStudentYet' && staffRoles.every(sr => !sr.studentId))
  );
}

async function load() {
  await Promise.all([loadCohortStaff(props.cohortId), loadRoles()]);
}
</script>
