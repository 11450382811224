import { defineStore } from 'pinia';
import { mapActions, mapGetters } from '@/store/map-store';
import { useStore } from './common';

export const useSubjectsStore = useStore(
  defineStore('subjects', () => {
    const { loadSubjects: loadSubjectsAction } = mapActions();
    const { subjectsWithNullOptionAndPrimarySeparation } = mapGetters();

    function reset() {}

    async function loadSubjects() {
      await loadSubjectsAction();
    }

    return { reset, loadSubjects, subjectsWithNullOptionAndPrimarySeparation };
  })
);
