<template>
  <div>
    <mosaic-create-edit-card-page
      object-type="Training Record"
      :load="load"
      :save="save"
      :can-save="canSave"
      :is-creating="isCreating"
      :readonly="!editable"
      :return-to="getReturnTo(breadcrumbs)"
      :dirty="dirty"
      :subtitle="record ? { action: 'Created', name: record.creator.name, actionAt: record.createdAt } : undefined"
    >
      <mosaic-text-field name="name" label="Name" v-model="name" prepend-icon="pencil" required :readonly="!editable" />
      <mosaic-radio-buttons v-model="type" :radios="typeOptions" inline :readonly="!editable" />
      <div v-if="type == 'training_session'" class="d-flex">
        <div class="mr-4">
          <mosaic-date-picker v-model:date="date" label="Date" name="date" :readonly="!editable" />
        </div>
        <mosaic-time-picker v-model="time" label="Duration" mode="duration" :readonly="!editable" allow-zero-duration />
      </div>
      <div v-else>
        <mosaic-date-picker v-model:date="date" label="Date" name="date" :readonly="!editable" />
      </div>
      <staff-training-competency-multi-select
        v-if="institutionStaffTrainingFrameworks"
        v-model="competencyIds"
        :frameworks="institutionStaffTrainingFrameworks"
        :readonly="!editable"
        :assigned-framework-ids="assignedStaffTrainingFrameworks.map(f => f.id)"
        :staff-training-competency-to="staffTrainingCompetencyTo"
        :is-me="isMyStaffTraining"
      />
      <mosaic-quill-field v-model="note" name="notes" label="Notes" :readonly="!editable" />
      <mosaic-file-upload-field
        name="evidence"
        label="Evidence"
        v-model="files"
        :min-files="0"
        :max-files="1"
        :presign-url="`/presign/staff/${staff?.id}/staff-training-evidence`"
        :get-file-url-prefix="`/staff-training-records/${record?.id}/evidence`"
        @uploading="filesUploading = $event"
        :readonly="!editable"
      />
    </mosaic-create-edit-card-page>
  </div>
</template>

<script setup lang="ts">
import { setBreadcrumbs, getReturnTo } from '@/utils/breadcrumbs';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { myStaffTrainingBreadcrumbs, selectedStaffTrainingBreadcrumbs, useStaffTraining } from './staff-training';
import { useInstitutionStore } from '@/stores/institution';
import { ref } from 'vue';
import { DateTime } from 'luxon';
import StaffTrainingCompetencyMultiSelect from './StaffTrainingCompetencyMultiSelect.vue';
import { useApi } from '@/composables/api';
import type { FileDetails } from '@/components/mosaic-content-layout/FileUpload.vue';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';
import { formatTime, splitTimeIntoHoursAndMinutes } from '@/utils/time';
import { mapState } from '@/store/map-store';
// #region setup and stores
const api = useApi();

const route = useRoute();
const isCreating = computed(
  () =>
    route.name == 'CohortStaffTrainingRecordCreatePage' ||
    route.name == 'InstitutionStaffTrainingRecordCreatePage' ||
    route.name == 'MyStaffTrainingRecordCreatePage'
);

const editable = hasPermissionForSelectedInstitution('staff.training.edit');

const {
  assignedStaffTrainingFrameworks,
  actions: { loadStaffTraining },
} = useStaffTrainingStore();

const { selectedCohort } = mapState();

const {
  institutionStaffTrainingFrameworks,
  actions: { loadInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();
const { selectedInstitution } = useInstitutionStore();

const { staff, staffId, isMyStaffTraining, loadStaff } = useStaffTraining();

function staffTrainingCompetencyTo(themeId: number) {
  return isMyStaffTraining.value
    ? { name: 'MyStaffTrainingCompetencyThemePage', params: { themeId: themeId.toString() } }
    : {
        name: selectedCohort.value?.id
          ? 'CohortTutorStaffTrainingCompetencyThemePage'
          : 'InstitutionTutorStaffTrainingCompetencyThemePage',
        params: {
          staffId: staffId.value || '',
          themeId: themeId.toString(),
          institutionId: selectedInstitution.value?.id || selectedCohort.value?.institution_id,
          cohortId: selectedCohort.value?.id,
        },
      };
}
// #endregion

// #region data
const isPriorExperienceAudit = route.query.type === 'prior_experience_audit';
const defaultName = isPriorExperienceAudit ? 'Prior Experience Audit' : '';
const name = ref(defaultName);

const type = ref<'training_session' | 'prior_experience_audit'>(
  isPriorExperienceAudit ? 'prior_experience_audit' : 'training_session'
);
const typeOptions = [
  { label: 'Training Session', value: 'training_session' },
  { label: 'Prior Experience Audit', value: 'prior_experience_audit' },
];

const defaultDate = DateTime.now().toISO();
const date = ref(defaultDate);
const defaultTime = '1:00';
const time = ref(defaultTime);

const note = ref<string>();

const files = ref<FileDetails[]>([]);
const filesUploading = ref(false);

const competencyIds = ref<number[]>([]);

interface StaffTrainingRecordResponse {
  id: number;
  name: string;
  recordType: 'training_session' | 'prior_experience_audit';
  date: string;
  durationHours: number;
  durationMinutes: number;
  note: string;
  staffTrainingRecordEvidence: { fileId: string; title: string }[];
  staffTrainingRecordCompetencies: {
    staffTrainingCompetencyId: number;
  }[];
  createdAt: string;
  creator: {
    id: number;
    name: string;
  };
}
const record = ref<StaffTrainingRecordResponse>();
// #endregion

// #region dirty checking
const isCreatingAndReturning = ref(false);
const dirty = computed(() => {
  if (isCreating.value) {
    if (isCreatingAndReturning.value) return false;
    return (
      name.value != defaultName ||
      date.value != defaultDate ||
      time.value != defaultTime ||
      !!note.value ||
      files.value.length > 0 ||
      competencyIds.value.length > 0
    );
  } else {
    const r = record.value;
    if (!r) return false;
    const fileIds = r.staffTrainingRecordEvidence.map(e => e.fileId);
    const competencies = r.staffTrainingRecordCompetencies.map(c => c.staffTrainingCompetencyId);
    return (
      name.value != r.name ||
      type.value != r.recordType ||
      date.value != r.date ||
      (type.value == 'training_session' && time.value != formatTime(r.durationHours, r.durationMinutes)) ||
      note.value != r.note ||
      files.value.length != r.staffTrainingRecordEvidence.length ||
      files.value.some(f => !fileIds.includes(f.fileId)) ||
      competencyIds.value.length != r.staffTrainingRecordCompetencies.length ||
      competencyIds.value.some(c => !competencies.includes(c))
    );
  }
});
// #endregion

// #region breadcrumbs
const breadcrumbs = computed(() => {
  return [
    ...(isMyStaffTraining.value
      ? myStaffTrainingBreadcrumbs('manual-training-records')
      : selectedStaffTrainingBreadcrumbs(
          'manual-training-records',
          selectedInstitution.value.id || selectedCohort.value?.institution_id,
          staffId.value,
          route,
          staff.value?.user.name,
          selectedCohort.value?.id
        )),
    {
      text: isCreating.value ? 'New Training Record' : record.value?.name || '',
    },
  ];
});
setBreadcrumbs(breadcrumbs);
// #endregion

// #region load
async function load() {
  const apiCalls = [];
  if (!isCreating.value) {
    apiCalls.push(loadRecord());
  }

  apiCalls.push(loadStaff());
  apiCalls.push(loadStaffTraining(staffId.value, undefined, selectedCohort.value?.id));
  apiCalls.push(loadInstitutionStaffTrainingFrameworks());

  await Promise.all(apiCalls);
}

async function loadRecord() {
  const r = await api.get<StaffTrainingRecordResponse>(`/staff-training-records/${route.params.recordId}`);
  record.value = r.data;
  name.value = r.data.name;
  type.value = r.data.recordType;
  date.value = r.data.date;
  note.value = r.data.note;
  if (type.value == 'training_session') {
    time.value = formatTime(r.data.durationHours, r.data.durationMinutes);
  }
  files.value = r.data.staffTrainingRecordEvidence.map(e => ({ ...e, unsaved: false }));
  competencyIds.value = r.data.staffTrainingRecordCompetencies.map(c => c.staffTrainingCompetencyId);
}
// #endregion

// #region save
const canSave = computed(() => !filesUploading.value && !!name.value && dirty.value);

async function save() {
  const { hours, minutes } = splitTimeIntoHoursAndMinutes(time.value);
  const body = {
    name: name.value,
    recordType: type.value,
    date: date.value,
    durationHours: type.value == 'training_session' ? hours : null,
    durationMinutes: type.value == 'training_session' ? minutes : null,
    note: note.value,
    evidence: files.value,
    competencies: competencyIds.value.map(id => ({ staffTrainingCompetencyId: id })),
  };

  if (isCreating.value) {
    await api.post(`/staff/${staffId.value}/staff-training-records`, body);
    isCreatingAndReturning.value = true;
  } else {
    const r = await api.put<typeof body, StaffTrainingRecordResponse>(
      `/staff-training-records/${record.value!.id}`,
      body
    );
    record.value = r.data;
    files.value = files.value.map(f => ({ ...f, unsaved: false }));
  }

  loadStaffTraining(staffId.value, true, selectedCohort.value?.id);
}
// #endregion
</script>
