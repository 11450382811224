<template>
  <div v-if="item.itemType === 'text' && item.value" class="content-layout-text">
    <mosaic-quill :contents="item.value" :read-only="true" />
  </div>

  <div v-else-if="item.itemType === 'resource'">
    <mosaic-list-item
      icon="mdi-file"
      @click.prevent="clickResource(item.resourceId)"
      :title="item.title"
      :subtitle="item.description"
    >
      <template #actions>
        <ndt-icon-button
          icon="download"
          tooltip="Download resource"
          @click.prevent="downloadResource(item.resourceId)"
        />
      </template>
    </mosaic-list-item>
    <mosaic-error-snackbar v-model="openResourceError" action="open this Resource" />
  </div>

  <div v-else-if="item.itemType === 'fileUpload'">
    <mosaic-completion-label v-if="!readonly" :can-edit="true" :completed="hasMinFiles(item)" class="mb-2">
      <div class="text-subtitle-2 my-2">{{ item.title }}</div>
    </mosaic-completion-label>
    <div v-else class="text-subtitle-2 my-2">{{ item.title }}</div>
    <mosaic-content-layout-file-upload
      :files="(files || []).filter(f => f.templateId === item.id)"
      :readonly="readonly"
      :max-files="item.maxFiles"
      :min-files="item.minFiles"
      :presign-url="uploadFilePresignUrl || ''"
      :get-file-url-prefix="getFileUrlPrefix || ''"
      :template-id="item.id"
      @file-added="emit('fileAdded', $event)"
      @file-removed="emit('fileRemoved', $event)"
      @uploading="emit('fileUploading', $event)"
    />
  </div>

  <div v-else-if="item.itemType === 'video'">
    <s3-video-embed
      v-if="dontPadVideos"
      :src="videoSrcs[item.resourceId]"
      @reset-src="emit('resetVideoSrc', item)"
      :get-video-src="getVideoSrc"
    />
    <div v-else class="py-2 d-flex justify-center" :style="{ padding: smallScreen ? '' : '0 5vw' }">
      <s3-video-embed
        :src="videoSrcs[item.resourceId]"
        @reset-src="emit('resetVideoSrc', item)"
        :get-video-src="getVideoSrc"
      />
    </div>
  </div>

  <div v-else-if="item.itemType === 'youtube'">
    <you-tube-video-embed v-if="dontPadVideos" :url="item.url" />
    <div v-else class="py-2 d-flex justify-center" :style="{ padding: smallScreen ? '' : '0 5vw' }">
      <you-tube-video-embed :url="item.url" />
    </div>
  </div>

  <div v-else-if="item.itemType === 'image'">
    <image-embed :item="item" :get-resource-url-prefix="getResourceUrlPrefix" />
  </div>

  <div v-else-if="item.itemType === 'link'">
    <div v-if="item.openType === 'newTab'">
      <mosaic-list-item
        icon="mdi-link"
        :href="item.url"
        target="_blank"
        :title="item.title"
        :subtitle="item.description || undefined"
      >
        <template #prepend
          ><v-avatar><v-icon>mdi-link</v-icon></v-avatar></template
        >
        <template #actions><mosaic-icon icon="launch" /></template>
      </mosaic-list-item>
    </div>
    <div v-else-if="item.openType === 'inline'">
      <div class="d-flex align-center">
        <div class="text-h6 pr-2">{{ item.title }}</div>
        <mosaic-icon-btn icon="mdi-link" tooltip="Open in new tab" @click="openInNewTab(item)" />
      </div>

      <div v-if="item.description" class="content-layout-text pb-2">
        {{ item.description }}
      </div>

      <div class="mt-1">
        <iframe :src="item.url" :style="{ width: '100%', aspectRatio: windowWidth / windowHeight }" />
      </div>
    </div>
  </div>

  <div
    v-else-if="
      item.itemType === 'curriculumLink' && visibleForStudent(safelyGetCurriculumStatement(item, curriculumStatements))
    "
  >
    <mosaic-list-item
      v-if="!loadCurriculumError"
      icon="mdi-hexagon"
      :title="`${safelyGetCurriculumStatement(item, curriculumStatements).code} - ${
        safelyGetCurriculumStatement(item, curriculumStatements).statement
      }`"
      @click.prevent="emit('clickCurriculumStatement', safelyGetCurriculumStatement(item, curriculumStatements))"
    />
    <mosaic-list-item
      v-else
      icon="mdi-hexagon"
      :title="`Sorry, cannot load this ${curriculumNoun} link at the moment. If this persists, then please get in touch with support.`"
    />
  </div>

  <div v-else-if="item.itemType === 'comment' && item.title">
    <mosaic-completion-label
      v-if="!readonly"
      :can-edit="true"
      :completed="hasComment(item, contentLayout, internalComments)"
      class="mb-2"
    >
      <div class="text-subtitle-2">{{ item.title }}</div>
    </mosaic-completion-label>
    <div v-else class="text-subtitle-2 my-2">{{ item.title }}</div>
    <mosaic-quill
      v-if="!readonly || getComment(item)"
      :contents="getComment(item)"
      :read-only="readonly"
      :read-only-border="readonly"
      @update:contents-by-user="emit('commentUpdated', { commentId: item.id, comment: $event })"
    />
    <div v-else-if="readonly" class="text-body-2 pa-3" style="border: 1px solid #ccc; font-size: 13px !important">
      To be completed
    </div>
  </div>

  <div v-else-if="item.itemType === 'taskList' && item.tasks.length > 0">
    <mosaic-completion-label v-if="!readonly" :can-edit="true" :completed="taskListCompleted(item)">
      <div v-if="item.title" class="text-subtitle-2">{{ item.title }}</div>
    </mosaic-completion-label>
    <div v-else-if="item.title" class="text-subtitle-2 mt-2">{{ item.title }}</div>
    <div class="pb-0 pt-2">
      <div v-for="task in item.tasks" :key="task.id" class="d-flex align-start">
        <mosaic-checkbox
          name="task-check"
          :model-value="getTaskCompleted(task)"
          :disabled="readonly"
          :label="task.title"
          density="compact"
          no-icon
          @update:model-value="emit('taskCompletedUpdated', { task, completed: $event || false })"
        />
      </div>
    </div>
  </div>

  <div v-else-if="item.itemType === 'nasbttMentorModule' || item.itemType === 'nasbttTraineeModule'">
    <!-- <div class="d-flex align-center" v-if="item.publicationCode">
      <mosaic-icon icon="mosaic-nasbtt" />
      <div class="px-4 flex-grow-1 align-self-start">
        <div class="text-h7 text-link" @click="openNasbttModule(item.publicationCode)">
          {{ getNasbttModuleTitle(item.publicationCode) }}
        </div>
        <div class="pb-4">{{ getNasbttModuleDescription(item.publicationCode) }}</div>
        <div v-if="item.itemType === 'nasbttTraineeModule'">NASBTT Learn Module</div>
        <div v-else>NASBTT Mentor Development Module</div>

        <div v-if="nasbttModuleProgress" class="pt-4">
          <v-chip color="accent">{{
            `Completed: ${nasbttModuleProgress.completed}  / Progress: ${nasbttModuleProgress.progress}%`
          }}</v-chip>
        </div>
      </div>
      <img
        :src="getNasbttModuleImageUrl(item.publicationCode)"
        :alt="getNasbttModuleTitle(item.publicationCode)"
        height="125"
      />
    </div> -->
    <mosaic-list-item
      v-if="item.publicationCode"
      icon="mosaic-nasbtt"
      icon-size="large"
      :title="
        nasbttModulesLoadingError
          ? isString(nasbttModulesLoadingError)
            ? nasbttModulesLoadingError
            : 'Sorry, cannot load your NASBTT Modules at the moment'
          : getNasbttModuleTitle(item.publicationCode)
      "
      :subtitle="getNasbttModuleDescription(item.publicationCode)"
      @click="readonly ? () => {} : openNasbttModule(item.publicationCode)"
    >
      <template #information>
        <mosaic-error-snackbar v-model="openNasbttModuleError" action="open this NASBTT module" contained />
        <mosaic-tooltip-chip
          :color="
            nasbttModuleProgressError
              ? 'error'
              : !nasbttModuleProgress || !nasbttModuleProgress.completed
              ? 'accent'
              : 'primary'
          "
          @click.stop="
            readonly || isNasbttAccountLinkedForUser
              ? emit('update:refreshNasbttModulesProgress', true)
              : openNasbttModule(item.publicationCode)
          "
        >
          <template #text>
            <span v-if="!isNasbttAccountLinkedForStaffOrStudent">
              <div v-if="!withinColumn">NASBTT modules account not linked</div>
              <mosaic-icon v-else icon="account-off"></mosaic-icon>
            </span>
            <span v-else-if="nasbttModuleProgressError">Can't load progress</span>
            <span v-else>
              {{
                !nasbttModuleProgress || !nasbttModuleProgress.progress
                  ? 'Not Started'
                  : !nasbttModuleProgress.completed
                  ? `Started (${Math.round(nasbttModuleProgress.progress)}%)`
                  : 'Completed'
              }}
            </span>
            <mosaic-icon
              v-if="isNasbttAccountLinkedForStaffOrStudent"
              :icon="nasbttModuleProgressProcessing ? 'loading' : 'refresh'"
              class="ml-2 cursor-pointer"
              :spin="nasbttModuleProgressProcessing"
            />
          </template>
          <template #tooltip>
            <span v-if="!isNasbttAccountLinkedForStaffOrStudent">
              <span v-if="!readonly"
                >Your NASBTT Modules account is not linked. Click to link account<span v-if="preview">
                  to preview opening NASBTT Modules</span
                >.</span
              >
              <span v-else>
                This {{ studentOrStaff?.type == 'staff' ? 'Instructor' : traineeNoun }} has not linked their NASBTT
                modules account.</span
              >
            </span>
            <span v-else>
              Last checked:
              {{ nasbttModuleProgress ? fromNowOrNow(nasbttModuleProgress.lastCheckedAt) : 'still loading'
              }}<span v-if="preview"
                >. For preview purposes your NASBTT modules account is being used to show progress.</span
              >
            </span>
          </template>
        </mosaic-tooltip-chip>
      </template>
      <template #actions v-if="!readonly"><mosaic-icon icon="launch" /></template>
    </mosaic-list-item>
  </div>

  <div v-else-if="item.itemType === 'scormPackage' && scormPackage">
    <mosaic-list-item
      icon="package-variant"
      :title="scormPackage.name"
      @click="launchScormPackage(scormPackage)"
      :chip="
        scormPackageProgressError
          ? { color: 'error', text: `Can't load progress ` }
          : scormPackageProgress
          ? {
              color: scormPackageProgress.completionStatus == 'completed' ? 'primary' : 'accent',
              text:
                scormPackageProgress.completionStatus == 'completed'
                  ? 'Completed'
                  : scormPackageProgress.completionStatus == 'incomplete'
                  ? 'Started'
                  : 'Not Started',
            }
          : undefined
      "
    >
      <template #actions><mosaic-icon icon="launch" /></template>
    </mosaic-list-item>
  </div>

  <div v-else-if="item.itemType === 'goReactAssignment'">
    <mosaic-success-snackbar
      contained
      v-model="goReactPreviewSnackbar"
      message="The GoReact Assignment would be launched if you were not previewing"
    />
    <mosaic-list-item
      icon="mosaic-go-react"
      :title="item.title"
      :error-snackbar="{
        active: !!launchGoReactAssignmentError,
        message: 'Sorry, cannot launch your GoReact Assignment at the moment',
      }"
      v-on="canLaunchGoReactAssignment ?  { click: () => launchGoReactAssignment(item as ContentLayoutGoReactAssignment) } : {}"
    >
      <template #actions><mosaic-icon icon="launch" v-if="canLaunchGoReactAssignment" /></template>
    </mosaic-list-item>
  </div>

  <div
    v-else-if="item.itemType === 'columns'"
    class="d-flex"
    :class="{
      'align-start': !smallScreen && item.alignment === 'top',
      'align-center': !smallScreen && item.alignment === 'center',
      'align-end': !smallScreen && item.alignment === 'bottom',
      'flex-column': smallScreen,
      'vertical-flex-spacing-small': smallScreen,
    }"
  >
    <div :style="{ width: smallScreen ? '100%' : '50%' }" :class="{ 'pr-4': !smallScreen }">
      <mosaic-content-layout-item
        v-if="item.left"
        within-column
        :item="item.left"
        :section="section"
        :content-layout="contentLayout"
        :readonly="readonly"
        :get-resource-url-prefix="getResourceUrlPrefix"
        :get-file-url-prefix="getFileUrlPrefix"
        :upload-file-presign-url="uploadFilePresignUrl"
        :internal-comments="internalComments"
        :tasks="tasks"
        :files="files"
        :video-srcs="videoSrcs"
        :dont-pad-videos="true"
        :get-video-src="getVideoSrc"
        :student-or-staff="studentOrStaff"
        @comment-updated="emit('commentUpdated', $event)"
        @task-completed-updated="emit('taskCompletedUpdated', $event)"
        @file-added="emit('fileAdded', $event)"
        @file-removed="emit('fileRemoved', $event)"
        @file-uploading="emit('fileUploading', $event)"
        @click-curriculum-statement="emit('clickCurriculumStatement', $event)"
        @reset-video-src="emit('resetVideoSrc', $event)"
        :refresh-nasbtt-modules-progress="refreshNasbttModulesProgress"
        @update:refresh-nasbtt-modules-progress="emit('update:refreshNasbttModulesProgress', $event)"
      />
    </div>

    <div :style="{ width: smallScreen ? '100%' : '50%' }" :class="{ 'pl-4': !smallScreen }">
      <mosaic-content-layout-item
        v-if="item.right"
        within-column
        :item="item.right"
        :section="section"
        :content-layout="contentLayout"
        :readonly="readonly"
        :get-resource-url-prefix="getResourceUrlPrefix"
        :get-file-url-prefix="getFileUrlPrefix"
        :upload-file-presign-url="uploadFilePresignUrl"
        :internal-comments="internalComments"
        :tasks="tasks"
        :files="files"
        :video-srcs="videoSrcs"
        :dont-pad-videos="true"
        :get-video-src="getVideoSrc"
        :student-or-staff="studentOrStaff"
        @comment-updated="emit('commentUpdated', $event)"
        @task-completed-updated="emit('taskCompletedUpdated', $event)"
        @file-added="emit('fileAdded', $event)"
        @file-removed="emit('fileRemoved', $event)"
        @file-uploading="emit('fileUploading', $event)"
        @click-curriculum-statement="emit('clickCurriculumStatement', $event)"
        @reset-video-src="emit('resetVideoSrc', $event)"
        :refresh-nasbtt-modules-progress="refreshNasbttModulesProgress"
        @update:refresh-nasbtt-modules-progress="emit('update:refreshNasbttModulesProgress', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
// Appears to be required to make recursive components works
export default {
  name: 'MosaicContentLayoutItem',
};
</script>

<script setup lang="ts">
import type {
  ContentLayout,
  ContentLayoutComment,
  ContentLayoutCompletionComment,
  ContentLayoutCompletionFile,
  ContentLayoutItem,
  ContentLayoutLink,
  ContentLayoutSection,
  ContentLayoutVideo,
  ContentLayoutTaskList,
  ContentLayoutTask,
  ContentLayoutCompletionTask,
  ContentLayoutFileUpload,
  ContentLayoutGoReactAssignment,
} from '@/utils/content-layout';
import { safelyGetCurriculumStatement, hasComment } from '@/utils/content-layout';
import MosaicQuill from '../quill/MosaicQuill.vue';
import MosaicCompletionLabel from '../MosaicCompletionLabel.vue';
import NdtIconButton from '../NdtIconButton.vue';
import S3VideoEmbed from './S3VideoEmbed.vue';
import YouTubeVideoEmbed from './YouTubeVideoEmbed.vue';
import MosaicContentLayoutFileUpload from './MosaicContentLayoutFileUpload.vue';
import ImageEmbed from './ImageEmbed.vue';
import { useWindowSize } from '@vueuse/core';
import { useOpenS3Files } from '@/utils/files';
import type { CurriculumStatement, SelectedStudent, UserStaff, UserStudent } from '@/store/map-store';
import { mapState } from '@/store/map-store';
import { mapStateProcessingAndError } from '@/store/map-state-error-and-processing';
import { useApi } from '@/composables/api';
import { useNasbttModulesStore } from '@/stores/nasbtt-modules';
import { computed, ref, watch } from 'vue';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { fromNowOrNow } from '@/utils/date';
import { waitForAtLeast } from '@/utils/async';
import { isString } from 'lodash';
import { useScormPackagesStore, type ScormPackage } from '@/stores/scorm-packages';
import { useRouter } from 'vue-router';
import type { ScormPackageProgressResponse } from '@/utils/scorm';
import { useStaffStore, type SelectedStaff } from '@/stores/staff';
import { useStudentStore } from '@/stores/student';
import { ltiLaunch } from '@/utils/lti';

const api = useApi();
const router = useRouter();

const props = defineProps<{
  item: ContentLayoutItem;
  section: ContentLayoutSection;
  contentLayout: ContentLayout;
  getResourceUrlPrefix: string;
  getFileUrlPrefix?: string;
  uploadFilePresignUrl?: string;
  readonly?: boolean;
  internalComments: ContentLayoutCompletionComment[];
  tasks: ContentLayoutCompletionTask[];
  files?: ContentLayoutCompletionFile[];
  goReactAssignments?: { id: number; contentLayoutId: string }[];
  videoSrcs: Record<string, string>;
  dontPadVideos?: boolean;
  studentOrStaff?:
    | { type: 'student'; student: UserStudent | SelectedStudent }
    | { type: 'staff'; staff: UserStaff | SelectedStaff }
    | { type: 'preview' };
  getVideoSrc: () => Promise<string | undefined>;
  preview?: boolean;
  refreshNasbttModulesProgress: boolean;
  withinColumn?: boolean;
}>();

const emit = defineEmits<{
  (e: 'commentUpdated', x: { commentId: string; comment: string }): void;
  (e: 'taskCompletedUpdated', x: { task: ContentLayoutTask; completed: boolean }): void;
  (e: 'fileAdded', file: ContentLayoutCompletionFile): void;
  (e: 'fileRemoved', file: ContentLayoutCompletionFile): void;
  (e: 'fileUploading', uploading: boolean): void;
  (e: 'clickCurriculumStatement', cs: CurriculumStatement): void;
  (e: 'resetVideoSrc', video: ContentLayoutVideo): void;
  (e: 'update:refreshNasbttModulesProgress', value: boolean): void;
}>();

const { user, selectedStudent, curriculumStatements } = mapState();
const { traineeNoun, userStudent } = useStudentStore();
const { userStaff } = useStaffStore();

const { width: windowWidth, height: windowHeight } = useWindowSize();

// Resources
const {
  open: clickResource,
  download: downloadResource,
  error: openResourceError,
} = useOpenS3Files(props.getResourceUrlPrefix, api);

// File uploads
function hasMinFiles(item: ContentLayoutFileUpload) {
  return (props.files || []).some(x => x.templateId === item.id);
}

// Curriculum
// Load happends in MosaicContentLayout
const { loadCurriculumError } = mapStateProcessingAndError('loadCurriculum');

function visibleForStudent(s: CurriculumStatement) {
  const student = user.value.student || selectedStudent.value;
  if (!student) return true;
  return s.subject_id === null || s.subject_id == student.subject?.id || s.subject_id == student.additional_subject?.id;
}

// Comments
function getComment(item: ContentLayoutComment) {
  const comment = props.internalComments.find(c => c.templateId === item.id);
  return comment!.comment;
}

// Tasks
function taskListCompleted(item: ContentLayoutTaskList) {
  return item.tasks.every(t => getTaskCompleted(t));
}
function getTaskCompleted(task: ContentLayoutTask) {
  return props.tasks.find(t => t.templateId === task.id)!.completed;
}

// Links
function openInNewTab(item: ContentLayoutLink) {
  window.open(item.url, '_blank');
}

// NASBTT modules
const {
  isNasbttAccountLinkedForUser,
  loadingError: nasbttModulesLoadingError,
  actions: {
    loadNasbttMentorModules,
    getNasbttModuleTitle,
    getNasbttModuleDescription,
    openStudentNasbttModule,
    openStaffNasbttModule,
    getStudentNasbttModuleProgress,
    getStaffNasbttModuleProgress,
    isNasbttAccountLinked,
    // getNasbttModuleImageUrl,
    // getNasbttModuleDescription,
  },
} = useNasbttModulesStore();
if (props.item.itemType === 'nasbttMentorModule') {
  loadNasbttMentorModules();
}

const isNasbttAccountLinkedForStaffOrStudent = computed(() => {
  if (props.studentOrStaff?.type == 'staff') return isNasbttAccountLinked(props.studentOrStaff.staff);
  if (props.studentOrStaff?.type == 'student') return isNasbttAccountLinked(props.studentOrStaff.student);
  return false;
});

const { action: openNasbttModule, error: openNasbttModuleError } = withProcessingAndError((publicationCode: string) => {
  if (!props.studentOrStaff || props.studentOrStaff.type == 'preview') return Promise.resolve();
  if (props.studentOrStaff.type == 'staff')
    return openStaffNasbttModule(props.studentOrStaff.staff.id, publicationCode);
  // if (props.studentOrStaff.type == 'student')
  return openStudentNasbttModule(props.studentOrStaff.student.id, publicationCode);
});

type Progress = Awaited<ReturnType<typeof getStaffNasbttModuleProgress | typeof getStudentNasbttModuleProgress>>;
const nasbttModuleProgress = ref<Progress>();

const {
  action: getNasbttModuleProgess,
  processing: nasbttModuleProgressProcessing,
  error: nasbttModuleProgressError,
} = withProcessingAndError((publicationCode: string, force: boolean, waitTime: number) =>
  waitForAtLeast(async (publicationCode: string, force: boolean) => {
    if (!props.studentOrStaff) return;
    if (props.studentOrStaff.type == 'staff')
      nasbttModuleProgress.value = await getStaffNasbttModuleProgress(
        props.studentOrStaff.staff.id,
        publicationCode,
        force
      );
    if (props.studentOrStaff.type == 'student')
      nasbttModuleProgress.value = await getStudentNasbttModuleProgress(
        props.studentOrStaff.student.id,
        publicationCode,
        force
      );
    emit('update:refreshNasbttModulesProgress', false);
  }, waitTime)(publicationCode, force)
);

const getNasbttModuleProgessNoDelay = (publicationCode: string, force: boolean) =>
  getNasbttModuleProgess(publicationCode, force, 0);

const getNasbttModuleProgessWithDelay = (publicationCode: string, force: boolean) =>
  getNasbttModuleProgess(publicationCode, force, 1000);

if (props.item.itemType === 'nasbttMentorModule') {
  const publicationCode = props.item.publicationCode;
  getNasbttModuleProgessNoDelay(publicationCode, false);

  watch(isNasbttAccountLinkedForUser, x => {
    if (x) getNasbttModuleProgessNoDelay(publicationCode, true);
  });

  watch(
    () => props.refreshNasbttModulesProgress,
    x => {
      if (x) getNasbttModuleProgessWithDelay(publicationCode, true);
    }
  );
}

// SCORM Packages
const {
  institutionScormPackages,
  actions: { loadScormPackages },
} = useScormPackagesStore();

const scormPackage = computed(() =>
  institutionScormPackages.value?.find(
    p => p.id == (props.item.itemType == 'scormPackage' ? props.item.scormPackageId : -1)
  )
);

function launchScormPackage(p: ScormPackage) {
  const routeData = router.resolve({ name: 'ScormPlayerPage', params: { id: p.id } });
  window.open(routeData.href, '_blank');
}

const scormPackageProgress = ref<ScormPackageProgressResponse>();
const { action: getScormPackageProgress, error: scormPackageProgressError } = withProcessingAndError(
  async (scormPackageId: number) => {
    const r = await api.get<ScormPackageProgressResponse>(`/scorm-packages/${scormPackageId}/progress`);
    scormPackageProgress.value = r.data;
  }
);

if (props.item.itemType == 'scormPackage') {
  loadScormPackages();
  getScormPackageProgress(props.item.scormPackageId);
}

// GoReact assignments
const goReactPreviewSnackbar = ref(false);
const { action: launchGoReactAssignment, error: launchGoReactAssignmentError } = withProcessingAndError(
  async (item: ContentLayoutGoReactAssignment) => {
    if (props.preview) {
      goReactPreviewSnackbar.value = true;
      return;
    }
    // GoReact assignments should currently only be assigned to students
    if (props.studentOrStaff?.type != 'student') return;

    const goReactAssignment = props.goReactAssignments!.find(a => a.contentLayoutId == item.id);
    if (!goReactAssignment) throw `Can't find goReactAssignment for item with id ${item.id}`;

    await ltiLaunch(
      api,
      `/lti/go-react-assignments/${goReactAssignment.id}/students/${props.studentOrStaff.student.id}?resource_link_title=${item.title}`
    );
  }
);

const canLaunchGoReactAssignment = computed(() => {
  if (props.preview) return true;
  const item = props.item;
  if (item.itemType !== 'goReactAssignment') return false;
  if (props.studentOrStaff?.type != 'student') return false;

  if (userStudent.value) return true;
  if (!userStaff.value) return false;

  // Assume that if they can view the content layout they view the go react assignment
  return true;
});
</script>

<style>
.content-layout-text .ql-editor {
  padding: 0 !important;
}
</style>
