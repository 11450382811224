import { defineStore } from 'pinia';
import { mapActions, mapState } from '@/store/map-store';
import { computed } from 'vue';
import { useStore } from './common';

export const useRoleStore = useStore(
  defineStore('role', () => {
    const { roles } = mapState();
    const { loadRoles } = mapActions();

    function reset() {}

    const studentScopedRoles = computed(() => roles.value.filter(r => r.student_scope));
    const cohortAdminRoles = computed(() =>
      roles.value.filter(r => r.cohort_scope && r.permissions.some(p => p.name == 'Admin'))
    );

    return { reset, roles, studentScopedRoles, cohortAdminRoles, loadRoles };
  })
);
