<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <template v-if="!error && !busy">
      <v-card class="mb-4">
        <v-card-text>
          <div class="text-h6">{{ term.name }}</div>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text class="pa-5">
          <div class="text-h6 mt-2">{{ cohortCourseWeekNounCapitalisedAndPluralised }}</div>
          <mosaic-list
            :items="term.cohortCourseWeeks"
            :empty-text="`There are no ${cohortCourseWeekNounCapitalisedAndPluralised} for this ${cohortCourseTermNounCapitalised}. Please add some below.`"
          >
            <template #item="{ item: week }">
              <mosaic-list-item
                :to="clickWeekTo(week)"
                icon="mdi-hexagon-slice-1"
                :title="week.name"
                :subtitle="`${formatDate(week.startDate)} - ${formatDate(week.endDate)}`"
                :empty-state="`This ${cohortCourseTermNounCapitalised} has no ${cohortCourseWeekNounCapitalisedAndPluralised}`"
              >
                <template #information>
                  <div>
                    <mosaic-published-draft-chip
                      :published="week.status === 'published'"
                      :object-type="cohortCourseWeekNounCapitalised"
                    />
                  </div>
                </template>

                <template #actions>
                  <mosaic-error-icon
                    v-if="isExistingConflictingWeek(week)"
                    tooltip="The dates for this week overlap with another week"
                  />
                  <v-progress-circular
                    v-else-if="publishWeekDialog.processing && publishWeekDialog.id === week.id"
                    indeterminate
                    color="primary"
                    size="24"
                    class="mr-2"
                  />
                  <ndt-icon-button
                    v-else-if="week.status !== 'published'"
                    icon="publish"
                    :tooltip="
                      canPublish(week)
                        ? `Publish ${cohortCourseWeekNounCapitalised}`
                        : publishWeekDialog.processing
                        ? `Cannot publish week whilst another week is still publishing`
                        : `Cannot publish ${cohortCourseWeekNounCapitalisedAndPluralised} with hidden ${curriculumNoun} links`
                    "
                    :disabled="!canPublish(week)"
                    @click.prevent="publishWeek(week)"
                  />
                  <ndt-icon-button
                    v-else
                    icon="publish-off"
                    :tooltip="`Unpublish ${cohortCourseWeekNounCapitalised}`"
                    @click.prevent="unpublishWeek(week)"
                  />
                  <ndt-icon-button
                    icon="content-copy"
                    :tooltip="`Copy ${cohortCourseWeekNounCapitalised} to another ${cohortCourseTermNounCapitalised}`"
                    @click.prevent="copyWeek(week)"
                  />
                  <ndt-icon-button
                    icon="pencil"
                    :tooltip="`Edit ${cohortCourseWeekNounCapitalised}`"
                    @click.prevent="editWeek(week)"
                  />
                  <ndt-icon-button
                    icon="delete"
                    :tooltip="
                      week.status !== 'published'
                        ? `Delete ${cohortCourseWeekNounCapitalised}`
                        : `Cannot delete published ${cohortCourseWeekNounCapitalisedAndPluralised}`
                    "
                    :disabled="week.status === 'published'"
                    @click.prevent="deleteWeek(week)"
                  />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>

          <div class="d-flex align-center mt-2">
            <div class="text-h6 flex-grow-1">Add {{ cohortCourseWeekNounCapitalisedAndPluralised }}</div>
            <mosaic-router-link
              class="mr-2"
              :to="{
                name: 'TutorAdminCohortCourseWeekTemplatePage',
              }"
              >Go to Training Plan {{ cohortCourseWeekNounCapitalised }} Template</mosaic-router-link
            >
          </div>
          <div class="d-flex align-center">
            <div class="flex-grow-1">
              <mosaic-text-field v-model="weekName" name="week-name" label="Name" prepend-icon="mdi-pencil" />
            </div>
            <div class="ml-4">
              <mosaic-date-picker v-model:date="weekStartDate" name="week-start-date" label="Start date" />
            </div>
            <div class="ml-4">
              <mosaic-date-picker v-model:date="weekEndDate" class="ml-2" name="week-end-date" label="End date" />
            </div>
            <mosaic-error-icon
              v-if="weekStartDate > weekEndDate || isConflictingNewWeek"
              :tooltip="
                isConflictingNewWeek
                  ? `These dates overlap with an existing ${cohortCourseWeekNounCapitalised}`
                  : 'The selected end date is before the start date'
              "
            />
            <div class="flex-grow-1 text-right">
              <v-btn :disabled="!canAddWeek" :loading="addWeekProcessing" @click.prevent="addWeek()"
                >Add {{ cohortCourseWeekNounCapitalised }}</v-btn
              >
            </div>
          </div>

          <mosaic-error-alert :override-error-message="addWeekError" />
        </v-card-text>
      </v-card>
    </template>
    <mosaic-save-dialog
      :active="publishWeekDialog.active"
      :title="`Publish ${cohortCourseWeekNounCapitalised}`"
      :object-type="cohortCourseWeekNounCapitalised"
      action="publish"
      :save="submitPublishWeek"
      :save-errors="{
        week_already_published: `Sorry, this ${cohortCourseWeekNounCapitalised} has already been published.`,
      }"
      @update:active="publishWeekDialog.active = $event"
    >
      <div>
        Are you sure you want to publish "{{ publishWeekDialog.weekName }}"? This will make this
        {{ cohortCourseWeekNounCapitalised }} visible to {{ traineeNounPluralised() }}.
      </div>
      <mosaic-alert v-if="disablePublishReason" class="mt-2" type="error">
        This {{ cohortCourseWeekNounCapitalised }} cannot be published as it has
        {{ disablePublishReason }}.</mosaic-alert
      >
      <mosaic-alert v-else-if="publishWeekDialog.emptySections" class="mt-2" type="warning">
        This {{ cohortCourseWeekNounCapitalised }} may not be finished as at least one Page contains empty Sections.
      </mosaic-alert>
    </mosaic-save-dialog>

    <mosaic-save-dialog
      :active="unpublishWeekDialog.active"
      :title="`Unpublish ${cohortCourseWeekNounCapitalised}`"
      :object-type="cohortCourseWeekNounCapitalised"
      action="unpublish"
      :save="submitUnpublishWeek"
      @update:active="unpublishWeekDialog.active = $event"
    >
      <template v-if="unpublishWeekDialog.inUseWeeks">
        <div>
          Are you sure you want to unpublish "{{ unpublishWeekDialog.weekName }}"? This will delete any
          {{ traineeNoun() }} data associated with this {{ cohortCourseWeekNounCapitalised }}.
        </div>
        <div v-if="unpublishWeekDialog.inUseWeeks.length === 0" class="mt-2">
          No {{ traineeNounPluralised() }} have completed this {{ cohortCourseWeekNounCapitalised }} therefore it is
          safe to unpublish.
        </div>
        <v-alert v-else class="mt-2" color="red-lighten-2">
          {{ unpublishWeekDialog.inUseWeeks.length }}
          {{ unpublishWeekDialog.inUseWeeks.length === 1 ? `${traineeNoun()} has` : `${traineeNounPluralised()} have` }}
          already started or completed this {{ cohortCourseWeekNounCapitalised }}:
          <ul>
            <li v-for="week in unpublishWeekDialog.inUseWeeks" :key="week.id">
              {{ week.student.name || week.student.email }}
              <span v-if="week.status === 'completed'">(marked as complete)</span>
            </li>
          </ul>
        </v-alert>
      </template>
    </mosaic-save-dialog>

    <mosaic-delete-dialog
      v-model:active="deleteWeekDialog.active"
      :url="deleteWeekDialog.url"
      :object-type="cohortCourseWeekNoun"
      :object-name="deleteWeekDialog.name"
      @delete="loadCourseWithErrorHandling"
    />

    <ndt-dialog
      v-model:active="copyWeekDialog.active"
      :title="`Copy ${cohortCourseWeekNounCapitalised}`"
      :error-message="copyWeekDialog.error"
    >
      <div class="mb-2">Copy "{{ copyWeekDialog.weekName }}" to</div>
      <mosaic-select
        v-if="cohortsToCopyWeeksTo.length > 1"
        v-model="copyWeekDialog.targetCohortId"
        name="cohort"
        label="Cohort"
        :items="cohortsToCopyWeeksTo"
        item-value="id"
        item-title="name"
        prepend-icon="mdi-google-classroom"
      />
      <mosaic-select
        v-if="cohortCopyTargetTerms.length > 0"
        v-model="copyWeekDialog.targetTermId"
        name="term"
        :label="cohortCourseTermNounCapitalised"
        :items="cohortCopyTargetTerms"
        item-value="id"
        item-title="name"
        prepend-icon="mdi-hexagon-slice-4"
      />
      <v-alert v-if="copyWeekDialog.targetCohortId && cohortCopyTargetTerms.length === 0" type="warning">
        This Cohort's Training Plan has no {{ cohortCourseTermNounCapitalisedAndPluralised }} to copy this
        {{ cohortCourseWeekNounCapitalised }} to
      </v-alert>
      <v-alert v-if="showDifferentCurriculumInfo" type="info">
        This Cohort uses a different Curriculum. The Curriculum links from this
        {{ cohortCourseWeekNounCapitalised }} will not be copied across.
      </v-alert>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="copyWeekDialog.processing || !copyWeekDialog.targetTermId"
          @click.prevent="submitCopyWeek()"
          >Copy</v-btn
        >
      </template>
    </ndt-dialog>
    <cohort-course-week-edit-dialog v-model:active="editWeekDialog.active" :week="editWeekDialog.week" :term="term" />
  </div>
</template>

<script>
import moment from 'moment';
import NdtDialog from '@/components/NdtDialog.vue';
import NdtIconButton from '@/components/NdtIconButton.vue';
import { mapState } from 'vuex';
import { conflictingWeeks, useEditWeekDetails, useCohortCourseStore } from '@/stores/cohort-course';
import { useCohortStore } from '@/stores/cohort';
import CohortCourseWeekEditDialog from './CohortCourseWeekEditDialog.vue';

const defaultPublishWeekDialog = {
  active: false,
  processing: false,
  error: '',
  weekName: '',
  id: null,
  emptyPages: false,
  noPages: false,
  noSubjectPages: false,
  emptySections: false,
  invalidItems: false,
};

const defaultUnpublishWeekDialog = {
  active: false,
  processing: false,
  error: '',
  inUseWeeks: null,
  weekName: '',
  id: null,
};

const defaultCopyWeekDialog = {
  active: false,
  processing: false,
  error: '',
  id: null,
  weekName: '',
  targetCohortId: null,
  targetTermId: null,
};

export default {
  name: 'TutorAdminCohortCourseTermPage',
  components: {
    NdtIconButton,
    NdtDialog,
    CohortCourseWeekEditDialog,
  },

  setup() {
    const { selectedCohortStudents } = useCohortStore();
    const { editWeekDialog, editWeek } = useEditWeekDetails();
    const {
      selectedCohortCourse: course,
      cohortCourseWeekNounCapitalised,
      cohortCourseTermNounCapitalised,
      cohortCourseTermNounCapitalisedAndPluralised,
      cohortCourseWeekNounCapitalisedAndPluralised,
      actions: { loadCohortCourse },
    } = useCohortCourseStore();
    return {
      editWeekDialog,
      editWeek,
      course,
      cohortCourseTermNounCapitalised,
      loadCourse: loadCohortCourse,
      cohortCourseWeekNounCapitalised,
      cohortCourseTermNounCapitalisedAndPluralised,
      cohortCourseWeekNounCapitalisedAndPluralised,
      selectedCohortStudents,
    };
  },
  data: function () {
    return {
      busy: true,
      error: null,
      weekName: '',
      weekStartDate: null,
      weekEndDate: null,
      addWeekProcessing: false,
      addWeekError: '',
      cohortsToCopyWeeksTo: [],
      deleteWeekDialog: {
        active: false,
        url: '',
        name: '',
      },
      publishWeekDialog: {
        ...defaultPublishWeekDialog,
      },
      unpublishWeekDialog: {
        ...defaultUnpublishWeekDialog,
      },
      copyWeekDialog: {
        ...defaultCopyWeekDialog,
      },
    };
  },

  computed: {
    ...mapState(['selectedCohort', 'selectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: 'Training Plan',
          to: {
            name: 'TutorAdminCohortCoursePage',
          },
        },
        {
          text: this.term?.name,
        },
      ];
    },
    term() {
      if (!this.course) return null;
      return this.course.cohortCourseTerms.find(x => x.id == this.$route.params.termId);
    },
    disablePublishReason() {
      if (this.publishWeekDialog.noPages) return 'no Pages';
      if (this.publishWeekDialog.noSubjectPages)
        return `all subject-specific Pages so that at least one ${this.traineeNounCapitalised()} would not see any pages`;
      if (this.publishWeekDialog.emptyPages) return 'empty Pages';
      if (this.publishWeekDialog.invalidItems) return 'at least one Page with invalid Items';
      return '';
    },
    canAddWeek() {
      return (
        this.weekName &&
        this.weekStartDate &&
        this.weekEndDate &&
        this.weekStartDate < this.weekEndDate &&
        !this.addWeekProcessing &&
        !this.isConflictingNewWeek
      );
    },
    isConflictingNewWeek() {
      return this.conflictingWeeks(this.term, this.weekStartDate, this.weekEndDate).length > 0;
    },
    cohortCopyTargetTerms() {
      if (!this.copyWeekDialog.targetCohortId) return [];
      return this.cohortsToCopyWeeksTo.find(c => c.id === this.copyWeekDialog.targetCohortId).cohortCourse
        .cohortCourseTerms;
    },
    showDifferentCurriculumInfo() {
      if (!this.copyWeekDialog.targetCohortId || !this.copyWeekDialog.id) return false;
      const week = this.term.cohortCourseWeeks.find(x => x.id === this.copyWeekDialog.id);
      if (!week.hasSectionCurriculumStatements) return;
      const cohort = this.cohortsToCopyWeeksTo.find(x => x.id === this.copyWeekDialog.targetCohortId);
      return cohort.curriculumId !== this.selectedCohort.curriculum_id;
    },
  },
  watch: {
    weekStartDate() {
      if (this.weekStartDate && (!this.weekEndDate || this.weekStartDate > this.weekEndDate)) {
        this.weekEndDate = moment(this.weekStartDate).add(6, 'days').format('YYYY-MM-DD');
      }
    },
    'copyWeekDialog.targetCohortId'(x) {
      const cohort = this.cohortsToCopyWeeksTo.find(c => c.id === x);
      if (cohort && cohort.cohortCourse.cohortCourseTerms.length === 1) {
        this.copyWeekDialog.targetTermId = cohort.cohortCourse.cohortCourseTerms[0].id;
      } else {
        this.copyWeekDialog.targetTermId = null;
      }
    },
  },
  async created() {
    this.loadWeekCopyTargets();
    await this.loadCourseWithErrorHandling(false, false);
  },
  methods: {
    async loadCourseWithErrorHandling(force = true, background = true) {
      if (!background) this.busy = true;
      try {
        await this.loadCourse(force);
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot load this term at the moment';
      }
      this.busy = false;
    },
    async loadWeekCopyTargets() {
      try {
        const r = await this.$api.get(`/cohorts/${this.selectedCohort.id}/course/week-copy-targets`);
        this.cohortsToCopyWeeksTo = r.data;
      } catch (e) {
        console.log(e);
      }
    },
    isExistingConflictingWeek(week) {
      return this.conflictingWeeks(this.term, week.startDate, week.endDate).filter(w => w.id != week.id).length > 0;
    },
    conflictingWeeks,
    async addWeek() {
      this.addWeekProcessing = true;
      this.addWeekError = '';
      try {
        await this.$api.post(`/cohorts/${this.selectedCohort.id}/course/terms/${this.term.id}/weeks`, {
          name: this.weekName,
          startDate: this.weekStartDate,
          endDate: this.weekEndDate,
        });

        this.weekName = '';
        this.weekStartDate = null;
        this.weekEndDate = null;
      } catch (e) {
        console.log(e);
        this.addWeekError = 'Sorry, cannot add a week at the moment';
      }
      this.addWeekProcessing = false;
      this.loadCourseWithErrorHandling();
    },
    deleteWeek(week) {
      this.deleteWeekDialog = {
        active: true,
        url: `/cohorts/${this.selectedCohort.id}/course/weeks/${week.id}`,
        name: week.name,
      };
    },
    clickWeekTo(week) {
      return {
        name: 'TutorAdminCohortCourseWeekPage',
        params: { cohortId: this.selectedCohort.id, termId: this.term.id, weekId: week.id },
      };
    },
    canPublish(week) {
      return !this.hasHiddenSectionCurriculumStatements(week) && !this.publishWeekDialog.processing;
    },
    hasHiddenSectionCurriculumStatements(week) {
      return (
        week.hasHiddenSectionCurriculumStatements ||
        (week.hasSectionCurriculumStatements && !this.selectedCohort.show_curriculum_for_students)
      );
    },
    weekHasNoPagesForSubjectStudent(sections) {
      const studentSubjectIds = this.selectedCohortStudents.map(s => [
        s.subject?.id || null,
        s.additional_subject?.id || null,
      ]);
      const sectionSubjectIds = sections.map(s => s.subjectId);
      if (sectionSubjectIds.some(s => s === null)) return false;
      return studentSubjectIds.some(s => sectionSubjectIds.every(ss => !s.includes(ss)));
    },
    async publishWeek(week) {
      this.publishWeekDialog.active = true;
      this.publishWeekDialog.processing = true;
      this.publishWeekDialog.error = '';
      this.publishWeekDialog.weekName = week.name;
      this.publishWeekDialog.id = week.id;

      try {
        const r = await this.$api.get(`/cohorts/${this.selectedCohort.id}/course/weeks/${week.id}`);
        this.publishWeekDialog.noPages = r.data.cohortCourseWeekSections.length === 0;
        this.publishWeekDialog.noSubjectPages = this.weekHasNoPagesForSubjectStudent(r.data.cohortCourseWeekSections);
        this.publishWeekDialog.emptyPages = r.data.cohortCourseWeekSections.some(
          p => p.contentLayout.sections.length === 0
        );
        this.publishWeekDialog.emptySections = r.data.cohortCourseWeekSections.some(p =>
          p.contentLayout.sections.some(s => s.items.length === 0)
        );
        this.publishWeekDialog.invalidItems = r.data.cohortCourseWeekSections.some(p =>
          p.contentLayout.sections.some(s =>
            s.items.some(i => (i.itemType === 'text' && !i.value) || (i.itemType === 'comment' && !i.title))
          )
        );
      } catch (e) {
        console.log(e);
        this.publishWeekDialog.error = `Sorry, cannot check whether this ${this.cohortCourseWeekNounCapitalised} has any content. Publish this ${this.cohortCourseWeekNounCapitalised} at your own risk (or get in touch with support).`;
      }
      this.publishWeekDialog.processing = false;
    },
    async submitPublishWeek() {
      await this.$api.post(`/cohorts/${this.selectedCohort.id}/course/weeks/${this.publishWeekDialog.id}/publish`);
      if (!this.selectedCohort.has_course) {
        this.$store.commit('updateSelectedCohortWithChanges', {
          has_course: true,
        });
      }
      this.loadCourseWithErrorHandling();
    },
    async unpublishWeek(week) {
      this.unpublishWeekDialog.active = true;
      this.unpublishWeekDialog.processing = true;
      this.unpublishWeekDialog.error = '';
      this.unpublishWeekDialog.weekName = week.name;
      this.unpublishWeekDialog.id = week.id;

      try {
        const r = await this.$api.get(`/cohorts/${this.selectedCohort.id}/course/weeks/${week.id}/student-weeks`);
        this.unpublishWeekDialog.inUseWeeks = r.data.filter(
          x => x.status === 'completed' || x.hasCompletedSomeComments || x.hasCompletedSomeTasks
        );
      } catch (e) {
        console.log(e);
        this.unpublishWeekDialog.error = `Sorry, cannot check whether any ${this.traineeNounPluralised()} have already started this ${
          this.cohortCourseWeekNounCapitalised
        }. Unpublish this ${this.cohortCourseWeekNounCapitalised} at your own risk (or get in touch with support).`;
      }
      this.unpublishWeekDialog.processing = false;
    },
    async submitUnpublishWeek() {
      this.unpublishWeekDialog.processing = true;
      this.unpublishWeekDialog.error = '';

      try {
        await this.$api.post(
          `/cohorts/${this.selectedCohort.id}/course/weeks/${this.unpublishWeekDialog.id}/unpublish`
        );
        this.unpublishWeekDialog = {
          ...defaultUnpublishWeekDialog,
        };
        if (this.course.cohortCourseTerms.every(t => t.cohortCourseWeeks.every(w => w.status === 'unpublished'))) {
          this.$store.commit('updateSelectedCohortWithChanges', {
            has_course: false,
          });
        }
      } catch (e) {
        this.unpublishWeekDialog.error = `Sorry, cannot unpublish this ${this.cohortCourseWeekNounCapitalised} at the moment`;
        console.log(e);
      }
      this.unpublishWeekDialog.processing = false;
      this.loadCourseWithErrorHandling();
    },
    copyWeek(week) {
      this.copyWeekDialog.active = true;
      this.copyWeekDialog.id = week.id;
      this.copyWeekDialog.weekName = week.name;

      if (this.cohortsToCopyWeeksTo.length === 1) {
        this.copyWeekDialog.targetCohortId = this.cohortsToCopyWeeksTo[0].id;
      }
    },
    async submitCopyWeek() {
      this.copyWeekDialog.processing = true;
      this.copyWeekDialog.error = '';

      try {
        await this.$api.post(`/cohorts/${this.selectedCohort.id}/course/weeks/${this.copyWeekDialog.id}/copy`, {
          targetTermId: this.copyWeekDialog.targetTermId,
        });
        if (this.copyWeekDialog.targetCohortId == this.selectedCohort.id) {
          await this.loadCourse(true);
        }
        this.copyWeekDialog = {
          ...defaultCopyWeekDialog,
        };
      } catch (e) {
        console.log(e);
        this.copyWeekDialog.error = `Sorry, cannot copy this ${this.cohortCourseWeekNounCapitalised} at the moment`;
      }
      this.copyWeekDialog.processing = false;
    },
  },
};
</script>
