<template>
  <v-card elevation="4">
    <v-toolbar color="white" elevation="6">
      <v-toolbar-title>Fill in your details</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-0">
      <mosaic-error-alert :override-error-message="error" />
      <v-text-field v-model="name" prepend-icon="mdi-account" name="name" label="Name" type="text" />
      <template v-if="!user.forceMicrosoftSso">
        <mosaic-password-help />
        <mosaic-password-text-field
          v-model="password"
          :validate-password="true"
          @password-valid="passwordValid = $event"
        />
        <mosaic-password-text-field
          v-model="passwordConfirmation"
          name="passwordConfirmation"
          label="Password Confirmation"
          :rules="passwordConfirmationRules"
          @keyup.enter="submit"
        />
        <div class="d-flex justify-end">
          <mosaic-password-security-link />
        </div>
      </template>
      <template v-if="showPPCheck">
        <mosaic-checkbox v-model="ppAccepted" no-icon>
          <template #label>
            <div>
              I accept the cookies used as outlined in the
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <mosaic-router-link to="privacy-policy" target="_blank" v-bind="props"
                    >Privacy Policy</mosaic-router-link
                  >
                </template>
                Opens in new window
              </v-tooltip>
            </div>
          </template>
        </mosaic-checkbox>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="mx-2" :disabled="!canSubmit || processing" @click.prevent="submit">Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { validationsPass } from '../utils/validations';
import { routeToNextStep } from '../utils/auth';

export default {
  name: 'UserSetupPage',
  data: function () {
    return {
      name: '',
      password: '',
      passwordConfirmation: '',
      ppAccepted: false,
      processing: false,
      passwordValid: false,
      passwordConfirmationRules: [v => (v && v === this.password) || 'Password confirmation should match password'],
      error: '',
    };
  },
  computed: {
    ...mapState({
      showPPCheck: state => !state.user.hasAcceptedPrivacyPolicy,
      user: state => state.user,
    }),
    canSubmit() {
      return (
        this.name &&
        (this.user.forceMicrosoftSso ||
          (this.password &&
            this.passwordConfirmation &&
            validationsPass(this.passwordConfirmationRules, this.passwordConfirmation) &&
            this.passwordValid)) &&
        (!this.showPPCheck || this.ppAccepted)
      );
    },
  },
  created: function () {
    this.$store.dispatch('refreshUser');
    this.name = this.user.name;
  },
  methods: {
    async submit() {
      if (!this.canSubmit) return;
      this.processing = true;
      this.error = '';
      try {
        const r = await this.$api.post('users/current/update-details', {
          old_password: sessionStorage.getItem('password'),
          name: this.name,
          password: this.password,
        });

        await this.$store.dispatch('refreshUser');
        await routeToNextStep(r.data.next_step, this.$router, this.$route);
      } catch (e) {
        if (e.response?.status === 422 && e.response.data.error_code === 'invalid_password') {
          this.error = e.response.data.message;
        } else {
          console.log(e);
          this.error = `Sorry, cannot update your details at the moment. Please check your internet and, if you're still having issues, send an email to support@penrose.education.`;
        }
      }
      this.processing = false;
    },
  },
};
</script>
