<template>
  <div>
    <home-page-filter-list-card
      v-if="institutions.length > 1"
      mosaic-key="institutions"
      title="Institutions"
      :search="institutionFilter"
      :items="institutions"
      item-name-pluralised="Institutions"
      filter-label="Filter by name"
      :sort="'name'"
    >
      <template #item="{ item: institution }">
        <mosaic-list-item
          :key="institution.id"
          icon="mdi-school"
          :to="institutionClickTo(institution.id)"
          :title="institution.name"
          :subtitle="institution.role"
        ></mosaic-list-item>
      </template>
    </home-page-filter-list-card>

    <!-- professionalResources from API will be empty if no institutions grant access -->
    <div id="professional-resources-card">
      <mosaic-card v-if="myProfessionalResources.length" class="mb-4">
        <mosaic-card-title>Professional Resources</mosaic-card-title>
        <div class="d-flex">
          <mosaic-card-subtitle
            >Curated resources and opportunities to support your professional development</mosaic-card-subtitle
          ><mosaic-help
            >An organisation you work with has consented to Mosaic sharing these resource with you.</mosaic-help
          >
        </div>
        <v-row class="my-2">
          <v-col :cols="smallScreen ? 12 : 6" v-for="resource in myProfessionalResources" :key="resource.id">
            <professional-resource-card :resource="resource" />
          </v-col>
        </v-row>
        <em>
          At Mosaic, we believe working in partnership is vital to the success of teacher professional development. As
          we establish more and more relationships with organisations we think you might find useful, we'll be sure to
          signpost these here.
        </em>
      </mosaic-card>
    </div>
    <div id="notifications-card">
      <mosaic-loading-and-error-cards :load="loadNotifications" loading-type="small-list" object-type="Notification">
        <v-card class="mb-4">
          <v-card-text>
            <div class="text-h6">
              Notifications <span v-if="notificationsUnreadCount > 0">({{ notificationsUnreadCount }} Unread)</span>
            </div>
            <div v-if="notifications.length === 0" class="py-2">You have no Notifications</div>
            <div v-else>
              <mosaic-list two-line :items="paginatedNotifications">
                <template #item="{ item: n }">
                  <notification-list-item :key="n.id" :notification="n" />
                </template>
              </mosaic-list>
              <mosaic-pagination
                v-model="currentPage"
                v-model:page-size="pageSize"
                mosaic-key="notifications"
                :total="notificationsTotal"
                :include-top-margin="false"
              />
            </div>
          </v-card-text>
        </v-card>
      </mosaic-loading-and-error-cards>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { traineeNounCapitalised } from '@/utils/institution';
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import HomePageFilterListCard from '@/components/home-pages/HomePageFilterListCard.vue';
import { findInstitutionLevelHomePage } from '@/router/guard-methods';
import { setNoBreadcrumbs } from '@/utils/breadcrumbs';
import { ref, onMounted } from 'vue';
import type { Notification, NotificationResponse } from '@/components/notifications/notifications';
import { mapNotifications } from '@/components/notifications/notifications';
import NotificationListItem from '@/components/notifications/NotificationListItem.vue';
import { paginateList } from '@/components/library/pagination/pagination';
import { mapMutations } from '@/store/map-store';
import { useProfessionalResourcesStore } from '@/stores/professional-resources';
import { useApi } from '@/composables/api';
import ProfessionalResourceCard from '@/pages/professional-resources/ProfessionalResourceCard.vue';
import { useRoute } from 'vue-router';

const api = useApi();

const { myProfessionalResources } = useProfessionalResourcesStore();

setNoBreadcrumbs('Home');

const { user } = useUserStore();

// Institutions
type Institution = {
  id: number;
  name: string;
  role: string;
  to: RouteLocationRaw;
  status: undefined;
};

const institutions = computed<Institution[]>(() => {
  if (user.value.student) {
    const institution = user.value.student.institution;
    const i: Institution = {
      id: institution.id,
      name: institution.name,
      role: traineeNounCapitalised(institution.config.early_careers ? 'Early Careers' : 'ITT'),
      to: {
        name: 'StudentHomePage',
        params: { studentId: user.value.student.id },
      },
      status: undefined,
    };
    return [i];
  } else if (user.value.staff) {
    return user.value.staff.map(s => {
      const institution = s.institution;
      const roles = s.staff_roles.map(sr => sr.role.name);

      return {
        id: institution.id,
        name: institution.name,
        role: roles
          .unique()
          .sortBy(x => x)
          .join(', '),
        to: {
          name: 'TutorHomePage',
          params: { staffId: s.id.toString() },
        },
        status: undefined,
      };
    });
  } else {
    return [];
  }
});

function institutionFilter(searchTerm: string, i: Institution) {
  return i.name.toLowerCase().includes(searchTerm.toLowerCase());
}

function institutionClickTo(institutionId: number) {
  if (user.value.student) {
    return { name: 'StudentHomePage', params: { studentId: user.value.student.id } };
  } else if (user.value.staff) {
    const staff = user.value.staff.find(s => s.institution.id === institutionId);
    return findInstitutionLevelHomePage(staff);
  }
}

// Notifications

const route = useRoute();

onMounted(async () => {
  if (route.query.card === 'notifications') {
    const element = document.getElementById(`notifications-card`);

    if (element) {
      // Scroll to the notifications card after a delay to allow the page to render. This is a bit hacky, but nextTick doesn't seem to be enough here.
      setTimeout(() => element.scrollIntoView(true), 500);
    }
  }
  if (route.query.card === 'professional-resources') {
    const element = document.getElementById(`professional-resources-card`);

    if (element) {
      // Scroll to the professional resources card after a delay to allow the page to render. This is a bit hacky, but nextTick doesn't seem to be enough here.
      setTimeout(() => element.scrollIntoView(true), 500);
    }
  }
});

const notifications = ref<Notification[]>([]);
const notificationsUnreadCount = ref(0);

const {
  paginatedList: paginatedNotifications,
  currentPage,
  pageSize,
  paginationTotal: notificationsTotal,
} = paginateList(notifications, 5);

const { updateNotifications } = mapMutations();
async function loadNotifications() {
  const r = await api.get<{ notifications: NotificationResponse[]; unreadCount: number }>('notifications');
  notifications.value = mapNotifications(r.data.notifications, user.value.notificationsLastSeenAt);
  notificationsUnreadCount.value = r.data.unreadCount;

  updateNotifications(r.data);
}
</script>
