import type { Api } from '@/composables/api';
import { setAuthTokenCookie } from './external-auth';

export async function ltiLaunch(api: Api, url: string) {
  const r = await api.get<{ login_url: string }>(url);
  // Done after the API call so the auth_token is refreshed if required
  // This is not bullet proof (as it could be very close to expiring) but should be pretty good
  setAuthTokenCookie();
  window.open(r.data.login_url, '_blank');
}
