<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-center" style="position: relative">
        <img src="@/assets/mosaic_icon.png" style="width: 30vw" />
        <div style="position: absolute; bottom: 15vw">
          <v-icon style="font-size: 8vw">mdi-alert</v-icon>
        </div>
      </div>
      <div class="d-flex flex-column align-center mx-4">
        <div class="text-h4 text-center">
          <span>Sorry, you do not have access to {{ objectTypeIsPlural ? 'these' : 'this' }} {{ objectType }}</span>
        </div>
        <div class="my-4">
          <ul>
            <li>
              The {{ objectType }} you were trying to access may no longer exist or you may no longer have permissions
              to see it
            </li>
            <li>
              If you arrived here by mistake, then
              <a @click.prevent="goHome()" class="text-link">go to your Home page</a>
            </li>
            <li>
              If you keep seeing this page and you're not sure why, then
              <a @click="contactSupport()" class="text-link">contact support</a>
            </li>
          </ul>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DoNotHaveAccessErrorPage',
  inject: ['layoutContainerContactSupport'],
  computed: {
    objectType() {
      return this.$route.query.objectType || 'page';
    },
    objectTypeIsPlural() {
      return this.$route.query.objectTypeIsPlural == 'true';
    },
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'Home' });
    },
    contactSupport() {
      this.layoutContainerContactSupport();
    },
  },
};
</script>
