<template>
  <mosaic-dialog
    :active="active"
    title="Contact Support"
    :error-message="errorMessage"
    @update:active="emit('update:active', $event)"
  >
    <div v-if="appUpdateAvailable">
      <h4 class="mb-2">
        <span class="text-primary">New version of Mosaic available </span>
      </h4>
      <p>
        Updates are being made to Mosaic all the time. This sometimes includes bug fixes or usability changes, which may
        resolve the issue you are facing.
      </p>
      <p>
        Before submitting a support ticket, try updating Mosaic and retrying your issue to see if it has been fixed.
      </p>
      <p>
        The update will refresh the browser. Please take a copy of any data you have entered, as unsaved changes will be
        lost.
      </p>
      <div class="d-flex justify-center">
        <v-btn variant="outlined" color="info" @click="refreshPage()">
          <div class="d-flex align-center">
            <v-icon>mdi-update</v-icon>
            <span class="ml-1">Update Mosaic</span>
          </div>
        </v-btn>
      </div>
    </div>
    <div v-else>
      <div v-if="currentSupportStep === 'selectIssue'">
        <div>Please first select one of the following options to help us direct your query.</div>
        <div>My query is related to:</div>
        <mosaic-radio-buttons
          class="mt-2"
          color="primary"
          v-model="selectedQueryTypeValue"
          :radios="filteredQueryTypes"
        ></mosaic-radio-buttons>
      </div>

      <template v-if="selectedQueryTypeValue && currentSupportStep === 'supportTicket'">
        <div v-if="selectedQueryTypeValue === 'other'">
          <div class="mb-2 font-weight-medium">
            Many questions about Mosaic can often be answered by your {{ institutionNoun
            }}{{ institutionName ? `, ${institutionName}` : '' }}, as they are responsible for configuring many aspects
            of the platform.
          </div>
          <div class="font-weight-medium">
            If your query is related to Mosaic configuration rather than a technical issue, you may wish to reach out to
            them first{{ contactEmail ? ` on ` : ''
            }}<a v-if="contactEmail" :href="`mailto:${contactEmail}`"> {{ contactEmail }}</a
            >.
          </div>
          <mosaic-checkbox
            v-model="technicalQuery"
            name="technical-query"
            no-icon
            label="I've encountered a technical issue and would like help from the Mosaic technical support team"
          />
          <template v-if="technicalQuery">
            <v-divider class="pa-2"></v-divider>
            <p>Please describe your query with as much detail as possible.</p>
            <p class="mb-0">Please include:</p>
            <ul class="pb-2">
              <li>what you were trying to do</li>
              <li>what you expected to happen</li>
              <li>the problem you experienced</li>
            </ul>
            <p>
              If your query refers to another person's account, please include their email in the query to enable a
              faster lookup by our support team.
            </p>
            <p>This will greatly help us diagnose the problem.</p>

            <v-textarea
              v-model="queryText"
              name="Support Query"
              label="Your query"
              variant="filled"
              auto-grow
              type="text"
              rows="3"
            />
            <div class="d-flex align-center">
              <div :style="{ 'flex-basis': smallScreen ? '100%' : '48%' }">
                <mosaic-text-field
                  v-model="preferredEmail"
                  class="pr-2"
                  name="Preferred contact email"
                  label="Preferred contact email"
                  prepend-icon="mdi-email"
                />
              </div>
              <mosaic-help>We'll use this email to contact you</mosaic-help>
            </div>
          </template>
        </div>
        <template v-else>
          <div>
            <p class="font-weight-medium" v-if="selectedQueryType && 'explanationCopy' in selectedQueryType">
              {{ selectedQueryType.explanationCopy }}
            </p>
          </div>

          <div v-if="contactEmail">
            You can contact them on <a :href="`mailto:${contactEmail}`">{{ contactEmail }}</a>
            using the template below.
          </div>
          <div v-else-if="institutionName">
            Please contact {{ institutionName }} with your query. You can use the template below to compose your email.
          </div>
          <div v-else>
            Please contact your Training Provider/Appropriate Body with your query. You can use the template below to
            compose your email.
          </div>
          <div class="mt-4">
            <mosaic-text-area
              v-model="emailSubject"
              name="subject"
              prepend-icon="mdi-email"
              rows="1"
              label="Email subject"
            />
            <mosaic-text-area
              v-model="emailBody"
              filled
              name="body"
              prepend-icon="mdi-email-newsletter"
              label="Email body"
            />
          </div>
        </template>
      </template>
    </div>

    <template #buttons>
      <div v-if="!appUpdateAvailable">
        <template v-if="currentSupportStep === 'selectIssue'">
          <mosaic-btn
            :disabled="!selectedQueryType"
            class="ml-2"
            color="primary"
            variant="text"
            @click="toggleSupportStep"
            >Next</mosaic-btn
          >
        </template>
        <template v-if="currentSupportStep === 'supportTicket'">
          <mosaic-btn :disabled="!selectedQueryType" color="primary" variant="text" @click="toggleSupportStep">
            Back</mosaic-btn
          >
          <mosaic-btn
            v-if="selectedQueryTypeValue === 'other' && technicalQuery"
            color="primary"
            variant="text"
            class="mx-2"
            ripple
            :disabled="!canSubmitSupportTicket"
            @click="submitSupportTicket()"
            >Submit</mosaic-btn
          >
          <mosaic-btn
            v-if="selectedQueryTypeValue !== 'other'"
            variant="text"
            color="primary"
            ripple
            @click="composeEmail()"
            >Compose Email</mosaic-btn
          >
        </template>
      </div>
    </template>
  </mosaic-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { mapGetters, mapState } from '../../../store/map-store';
import { useRoute } from 'vue-router';
import { useApi } from '@/composables/api';
const api = useApi();

defineProps<{
  active: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:active', active: boolean): void;
}>();

type QueryType =
  | {
      value: string;
      label: string;
      explanationCopy: string;
      emailSubjectTemplate: string;
      emailBodyTemplate: string;
      studentOnly?: boolean;
      staffOnly?: boolean;
      hideForEarlyCareers?: boolean;
      hideForMentorTrainingOnly?: boolean;
    }
  | {
      value: 'other';
      label: string;
    };

const {
  traineeNounCapitalisedAndPluralised,
  institutionNoun,
  reviewNounCapitalised,
  reviewNounCapitalisedAndPluralised,
  appUpdateAvailable,
  traineeNounCapitalised,
} = mapGetters();

const { selectedInstitution, user } = mapState();

const currentSupportStep = ref<'selectIssue' | 'supportTicket'>('selectIssue');
const toggleSupportStep = () =>
  (currentSupportStep.value = currentSupportStep.value === 'selectIssue' ? 'supportTicket' : 'selectIssue');

//Query types
const queryTypes = computed<QueryType[]>(() => {
  return [
    {
      value: 'missing-training-requirements',
      label: `There appears to be something missing from my Training Requirements`,
      explanationCopy: `Your ${institutionNoun.value} is responsible for administering the Training Requirements.`,
      emailSubjectTemplate: `Missing Training Requirements`,
      emailBodyTemplate: `I expected to see [Event/Module] under "My Training" but can't find it. Could you let me know where to look?`,
      staffOnly: true,
      hideForEarlyCareers: true,
    },
    {
      value: 'missing-students',
      label: `I need access to another ${traineeNounCapitalised.value()} who is not appearing in my account`,
      explanationCopy: `Your ${institutionNoun.value} is responsible for administering account access on Mosaic.`,
      emailSubjectTemplate: `Missing ${traineeNounCapitalised.value()} in Mosaic`,
      emailBodyTemplate: `I need access to XXX via Mosaic. Please could you add them to my account?`,
      staffOnly: true,
      hideForMentorTrainingOnly: true,
    },
    {
      value: 'missing-roles',
      label: `I need additional permissions for one of my ${traineeNounCapitalisedAndPluralised.value}, so that I can complete a ${reviewNounCapitalised.value}`,
      explanationCopy: `Your ${institutionNoun.value} is responsible for assigning roles and permissions on Mosaic.`,
      emailSubjectTemplate: `Missing permissions in Mosaic`,
      emailBodyTemplate: `I need additional permissions to complete a ${reviewNounCapitalised.value} for XXX. Please could you add them to my account?`,
      staffOnly: true,
      hideForMentorTrainingOnly: true,
    },
    {
      value: 'account-details-update',
      label: `I need to update my, or someone else's, account details (name or email)`,
      explanationCopy: `Your ${institutionNoun.value} is responsible for updating account details on Mosaic.`,
      emailSubjectTemplate: `Update my account details in Mosaic`,
      emailBodyTemplate: `Please could you update my/XXX's account details in Mosaic to the following: XXX`,
      staffOnly: false,
    },
    {
      value: 'missing-reviews-staff',
      label: `I need to complete a ${reviewNounCapitalised.value} for ${traineeNounCapitalised.value(
        true
      )}, but I can't see it in their account`,
      explanationCopy: `Your ${institutionNoun.value} is responsible for administering ${reviewNounCapitalisedAndPluralised.value} on Mosaic.`,
      emailSubjectTemplate: `Missing ${reviewNounCapitalised.value} in Mosaic`,
      emailBodyTemplate: `I need to complete a ${reviewNounCapitalised.value} for XXX on Mosaic. Please could you add it to their account?`,
      staffOnly: true,
      hideForMentorTrainingOnly: true,
    },
    {
      value: 'missing-reviews-student',
      label: `I need to complete a ${reviewNounCapitalised.value}, but I can't see it in my account`,
      explanationCopy: `Your ${institutionNoun.value} is responsible for administering ${reviewNounCapitalisedAndPluralised.value} on Mosaic.`,
      emailSubjectTemplate: `Missing ${reviewNounCapitalised.value} in Mosaic`,
      emailBodyTemplate: `I need to complete a ${reviewNounCapitalised.value} on Mosaic. Please could you add it to my account?`,
      studentOnly: true,
      hideForMentorTrainingOnly: true,
    },
    {
      value: 'other',
      label: 'None of the above',
    },
  ];
});

const filteredQueryTypes = computed(() => {
  return queryTypes.value.filter(qt => {
    const hideForEarlyCareers = 'hideForEarlyCareers' in qt && selectedInstitution.value?.config?.early_careers;
    const hideForMentorTrainingOnly =
      'hideForMentorTrainingOnly' in qt && selectedInstitution.value?.config?.mentor_training_only;

    return (
      qt &&
      !hideForEarlyCareers &&
      !hideForMentorTrainingOnly &&
      !('staffOnly' in qt && !user.value.staff) &&
      !('studentOnly' in qt && !user.value.student)
    );
  });
});

const technicalQuery = ref(false);

watch(selectedInstitution, () => {
  selectedQueryTypeValue.value = null;
});

const selectedQueryTypeValue = ref<string | null>(null);
const selectedQueryType = computed(() => {
  return queryTypes.value.find(qt => qt?.value === selectedQueryTypeValue.value) ?? null;
});

watch(selectedQueryType, newQT => {
  if (!newQT) currentSupportStep.value = 'selectIssue';
});

//Email Composition
const contactEmail = computed(() => {
  return selectedInstitution.value?.contact_email || user.value.singleInstitution?.contact_email;
});
const institutionName = computed(() => {
  return selectedInstitution.value?.name || user?.value.singleInstitution?.name;
});
const emailSubject = ref('');
const emailBody = ref('');
watch(selectedQueryTypeValue, () => {
  technicalQuery.value = false;
  if (
    selectedQueryType.value &&
    'emailSubjectTemplate' in selectedQueryType.value &&
    'emailBodyTemplate' in selectedQueryType.value
  ) {
    emailSubject.value = `Mosaic Support: ${selectedQueryType.value.emailSubjectTemplate}`;
    emailBody.value = `Dear ${selectedInstitution?.value?.name || 'XXX'},

${selectedQueryType.value.emailBodyTemplate}

Regards,

${user.value.name}`;
  }
});

function composeEmail() {
  window.open(
    `mailto:${contactEmail.value}?subject=${encodeURIComponent(emailSubject.value)}&body=${encodeURIComponent(
      emailBody.value
    )}`
  );
}

const queryText = ref(`Dear Mosaic,

I was trying to...
I expected...
But instead...

Please could you help me with this?
`);
const preferredEmail = ref(user.value?.email);

const canSubmitSupportTicket = computed(() => {
  return !!queryText.value && !!preferredEmail.value;
});

//Support Ticket Submission
const processing = ref(false);
const errorMessage = ref('');
const { clientInfo } = mapGetters();
const route = useRoute();

async function submitSupportTicket() {
  processing.value = true;
  try {
    await api.post('support', {
      browser: clientInfo.value.getClientBrowser(),
      os: clientInfo.value.getClientOS(),
      query: queryText.value,
      preferred_email: preferredEmail.value,
      path: route.fullPath,
    });
    emit('update:active', false);
    processing.value = false;
  } catch (e) {
    console.log(e);
    errorMessage.value = `Sorry, cannot submit your issue at the moment, please try again or email support@penrose.education`;
    processing.value = false;
  }
}

function refreshPage() {
  window.location.reload();
}
</script>
