import { defineStore } from 'pinia';
import { mapState } from '@/store/map-store';
import { useStore } from './common';
import { useApi } from '@/composables/api';
import { computed, ref } from 'vue';
import { CacheMap } from './cache-map';
import type { Permission, School } from '@/store/map-store';
import { hasPermissionForStudent } from '@/mixins/global-mixins';

export type SelectedStaff = {
  id: number;
  hide_profile_picture: boolean;
  user: {
    id: number;
    email: string;
    name: string;
    display_name: string;
    is_demo: boolean;
    force_microsoft_sso: boolean;
    activated: boolean;
    account_locked: boolean;
    email_verified: boolean;
    email_bounced: boolean;
    opted_out_of_emails: boolean;
    in_multiple_institutions: boolean;
    profile_picture_updated_at: string | null;
  };
  nasbtt_modules_user_id: string;
  staff_roles: StaffRole[];
};

export type StaffRole = {
  created_at: string;
  id: number;
  no_student_yet_cohort: StaffRoleCohort | null;
  role: Role;
  student: {
    name: string;
    id: number;
    email: string;
  } | null;
  school: School | null;
  cohort: StaffRoleCohort | null;
};

export type Role = {
  name: string;
  id: number | null;
  permissions: RolePermission[];
};

export type RolePermission = {
  name: string;
};

export interface StaffRoleCohort {
  id: number;
  name: string;
  show_curriculum_for_students: boolean;
  status: 'active' | 'closed';
  curriculum_id: number | null;
  institution_id: number;
}

export const useStaffStore = useStore(
  defineStore('staff', () => {
    const api = useApi();

    const { userStaff: stateUserStaff, user, selectedStudent, selectedCohort } = mapState();
    const userStaff = computed(() => ({ ...stateUserStaff.value, user: user.value }));

    const staffCache = ref<CacheMap<SelectedStaff>>(new CacheMap());
    const selectedStaffId = ref<number>();

    function reset() {
      staffCache.value.clear();
      selectedStaffId.value = undefined;
    }

    const selectedStaff = computed(() =>
      selectedStaffId.value ? staffCache.value.getEvenIfExpired(selectedStaffId.value) : undefined
    );

    const userStaffHasPermissionForSelectedStudent = (...permissions: Permission[]) => {
      return permissions.every(permission =>
        hasPermissionForStudent(userStaff.value, permission, selectedStudent.value)
      );
    };
    const userStaffHasRoleIdForSelectedStudent = (roleId: number) => {
      return userStaff.value.staff_roles.some(
        sr =>
          sr.role.id === roleId &&
          (sr.student_id === selectedStudent.value.id ||
            sr.cohort_id === selectedStudent.value.cohort.id ||
            sr.institution_id === selectedStudent.value.institution_id)
      );
    };

    // selectedStaff should probably be automatically selected by the router and not rely on indivdual pages to call loadStaff
    async function loadStaff(staffId: number, force = false) {
      selectedStaffId.value = staffId;
      if (force || !staffCache.value.get(staffId)) {
        const params = selectedCohort.value?.id ? `?restrict_roles_to_cohort_id=${selectedCohort.value?.id}` : '';
        const r = await api.get<SelectedStaff>(`/staff/${staffId}${params}`);
        staffCache.value.set(staffId, r.data);
      }
    }

    return {
      reset,
      userStaff,
      loadStaff,
      staffCache,
      selectedStaffId,
      selectedStaff,
      userStaffHasPermissionForSelectedStudent,
      userStaffHasRoleIdForSelectedStudent,
    };
  })
);
