<template>
  <mosaic-loading-and-error-cards
    object-type="Forum"
    :load="load"
    v-model:trigger-background-load="triggerBackgroundLoad"
  >
    <div v-if="forum">
      <mosaic-card class="mb-4">
        <mosaic-card-title>
          {{ forum.name }}

          <template #subtitle>
            {{ forum.cohort ? `Cohort: ${forum.cohort.name}` : 'All Cohorts' }}
          </template>
        </mosaic-card-title>
        <v-divider class="mt-4" />

        <mosaic-tabs :headers="tabs">
          <template #discussions>
            <div class="d-flex pt-2 pb-1 pr-1">
              <div
                class="d-flex"
                :class="{
                  'flex-column': smallScreen,
                  'align-center': !smallScreen,
                  'vertical-flex-spacing': smallScreen,
                  'horizontal-flex-spacing': !smallScreen,
                }"
              >
                <mosaic-icon icon="filter" class="pt-1" />
                <mosaic-text-field
                  dense
                  no-icon
                  hide-details
                  name="discussions-name-filter"
                  label="Filter by name"
                  v-model="discussionsNameFilter"
                  style="width: 200px"
                />
              </div>
              <div class="flex-grow-1"></div>
              <mosaic-btn
                icon="plus"
                :to="{ name: 'StaffForumDiscussionCreatePage', params: { forumId: id } }"
                :disabled="!forum.canCreateDiscussions"
                disabled-tooltip="Only Facilitators and Moderators can create Discussions in this Forum."
              >
                Discussion
              </mosaic-btn>
            </div>

            <mosaic-list
              :items="paginatedDiscussions"
              :empty-text="`There are no Discussions${
                forum.forumDiscussions.length !== fiteredDiscussions.length ? ' for these filters' : ''
              } in this Forum.${forum.canCreateDiscussions ? ' Add one by clicking the button above.' : ''}`"
            >
              <template #item="{ item }">
                <mosaic-list-item
                  :title="item.title"
                  :icon="icons.forumDiscussion"
                  :subtitle="`Created by ${item.createdBy.displayName} - ${enumerateItems(
                    item.commentAndReplyCount,
                    'Comment'
                  )} - ${item.commentAndReplyCount == 0 ? `Created` : `Last activity`} at ${formatDateTime(
                    item.lastActivityAt
                  )}`"
                  :to="{ name: 'StaffForumDiscussionViewPage', params: { forumId: id, id: item.id } }"
                >
                  <template #actions>
                    <mosaic-icon-btn
                      icon="pencil"
                      tooltip="Edit Discussions"
                      :to="{ name: 'StaffForumDiscussionEditPage', params: { forumId: id, id: item.id } }"
                      :disabled="!item.canEdit.permitted"
                      :disabled-tooltip="deleteOrEditDiscussionsErrorCodeMap('edit')[item.canEdit.reason]"
                    />
                    <mosaic-icon-btn
                      icon="delete"
                      tooltip="Delete Discussion"
                      @click.prevent="deleteDiscusssion(item)"
                      :disabled="!item.canDelete.permitted"
                      :disabled-tooltip="deleteOrEditDiscussionsErrorCodeMap('delete')[item.canEdit.reason]"
                    />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
            <mosaic-pagination
              v-model="discussionCurrentPage"
              v-model:page-size="discussionPageSize"
              :total="discussionPaginationTotal"
            />
          </template>

          <template #members>
            <div class="d-flex pt-2 pb-1 pr-1">
              <div
                class="d-flex"
                :class="{
                  'flex-column': smallScreen,
                  'align-center': !smallScreen,
                  'vertical-flex-spacing': smallScreen,
                  'horizontal-flex-spacing': !smallScreen,
                }"
              >
                <mosaic-icon icon="filter" class="pt-1" />
                <mosaic-text-field
                  dense
                  no-icon
                  hide-details
                  name="members-name-filter"
                  label="Filter by name"
                  v-model="membersNameFilter"
                  style="width: 200px"
                />
                <mosaic-select
                  label="Filter by type"
                  :items="memberTypeFilterItems"
                  v-model="memberTypeFilter"
                  hide-details
                  dense
                  no-icon
                  style="width: 150px"
                />
              </div>
              <div class="flex-grow-1"></div>
              <mosaic-btn v-if="forum.canEditMembers" icon="pencil" :to="{ name: 'StaffForumEditMembersPage' }">
                Members
              </mosaic-btn>
            </div>
            <mosaic-list
              :items="paginatedMembers"
              :empty-text="`There are no Members${
                forum.forumMembers.length !== filteredMembers.length ? ' for these filters' : ''
              } in this Forum.${forum.canEditMembers ? ` Add one by clicking the Edit Members button above.` : ''}`"
            >
              <template #item="{ item }">
                <mosaic-list-item
                  :title="item.displayName"
                  :subtitle="capitaliseFirstLetter(item.memberType)"
                  :user-avatar="{
                    displayName: item.displayName,
                    id: item.userId,
                    profilePictureUpdatedAt: item.profilePictureUpdatedAt,
                    hideProfilePicture: item.hideProfilePicture,
                  }"
                  :error-snackbar="{
                    active: !!removeMemberError && item.id == removeMemberId,
                    message: `Sorry, cannot remove this Member at the moment.`,
                  }"
                >
                  <template #information v-if="forum.canEditMembers && item.notInCohort">
                    <forum-member-not-in-cohort-warning-icon />
                  </template>

                  <template #actions v-if="hasInstitutionLevelStaffForumsEdit">
                    <mosaic-icon-btn
                      v-if="forum.canEditMembers"
                      icon="pencil"
                      tooltip="Edit Member"
                      @click="editMember(item)"
                    />
                    <mosaic-icon-btn
                      v-if="forum.canEditMembers"
                      icon="delete"
                      tooltip="Remove Member"
                      @click="removeMember(item.id)"
                      :disabled="removeMemberProcessing"
                      :loading="removeMemberProcessing && item.id == removeMemberId"
                    />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
            <mosaic-pagination
              v-model="memberCurrentPage"
              v-model:page-size="memberPageSize"
              :total="memberPaginationTotal"
            />
          </template>

          <template #notifications>
            <div class="pb-1 pr-1" v-if="forum.notifications">
              <div>In this Forum, notify me when:</div>
              <mosaic-checkbox label="A new Discussion is created" v-model="newDiscussionNotification" no-icon dense />
              <mosaic-checkbox
                label="There is a new Comment on my Discussions"
                v-model="newCommentNotification"
                no-icon
                dense
              />
              <mosaic-checkbox label="Someone replies to one my Comments" v-model="replyNotification" no-icon dense />
              <mosaic-save-buttons
                :save="saveNotifications"
                :can-save="notificationsDirty"
                object-type="Forum Notification settings"
                :object-type-is-plural="true"
                :hide-return="true"
              />
            </div>
            <div v-else>You are not a Member of this Forum, so won't receive any notifications.</div>
          </template>
        </mosaic-tabs>
      </mosaic-card>
    </div>
  </mosaic-loading-and-error-cards>

  <mosaic-save-dialog
    v-model:active="editMemberDialog.active"
    title="Edit Member"
    object-type="Member"
    :save="submitEditMember"
    :can-save="editMemberDialog.type != editMemberDialog.originalType"
  >
    <mosaic-select
      name="member-type"
      dense
      hide-details
      :items="memberTypeItems"
      v-model="editMemberDialog.type"
      :prepend-icon="icons.role"
    />
  </mosaic-save-dialog>

  <mosaic-delete-dialog
    v-model:active="deleteDiscussionDialog.active"
    object-type="Discussion"
    :object-name="deleteDiscussionDialog.name"
    :url="`/staff/${userStaff.id}/forum-discussions/${deleteDiscussionDialog.discussionId}`"
    @delete="triggerBackgroundLoad = true"
    :error-code-map="deleteOrEditDiscussionsErrorCodeMap('delete')"
  >
  </mosaic-delete-dialog>
</template>

<script setup lang="ts">
import { paginateList } from '@/components/library/pagination/pagination';
import { useApi } from '@/composables/api';
import { parseRouteId } from '@/composables/vue-router';
import { useStaffStore } from '@/stores/staff';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { enumerateItems } from '@/utils/text';
import { formatDateTime } from '@/utils/date';
import { filterByString } from '@/components/library/filters/filters';
import type { ForumResponse } from './forums';
import { deleteOrEditDiscussionsErrorCodeMap, memberTypeItems } from './forums';
import { icons } from '@/utils/icons';
import { syncQueryParam } from '@/composables/query';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import ForumMemberNotInCohortWarningIcon from './ForumMemberNotInCohortWarningIcon.vue';

const api = useApi();
const id = parseRouteId('id');
const { userStaff } = useStaffStore();

const forum = ref<ForumResponse>();

const breadcrumbs = computed(() => [
  { text: 'Forums', to: { name: 'StaffForumsListPage' } },
  { text: forum.value?.name || '' },
]);
setBreadcrumbs(breadcrumbs);

const tabs = computed(() => [
  { key: 'discussions', text: 'Discussions' },
  { key: 'members', text: 'Members' },
  { key: 'notifications', text: 'Notifications', dirty: notificationsDirty.value },
]);

const hasInstitutionLevelStaffForumsEdit = hasPermissionForSelectedInstitution('staff.forums.edit');

const triggerBackgroundLoad = ref(false);
async function load() {
  const r = await api.get<ForumResponse>(`/staff/${userStaff.value.id}/forums/${id.value}`);
  forum.value = r.data;
  if (forum.value.notifications) {
    newDiscussionNotification.value = forum.value.notifications.newDiscussion;
    newCommentNotification.value = forum.value.notifications.newCommentInMyDiscussion;
    replyNotification.value = forum.value.notifications.newReplyToMyComment;
  }
}

// #region discussions
const discussionsNameFilter = ref('');
syncQueryParam(discussionsNameFilter, 'discussionName', 'string');
const fiteredDiscussions = computed(() =>
  (forum.value?.forumDiscussions || []).filter(d => filterByString(d.title, discussionsNameFilter.value))
);
const {
  paginatedList: paginatedDiscussions,
  currentPage: discussionCurrentPage,
  pageSize: discussionPageSize,
  paginationTotal: discussionPaginationTotal,
} = paginateList(fiteredDiscussions);

const deleteDiscussionDialog = ref({ active: false, discussionId: -1, name: '' });
function deleteDiscusssion(discussion: ForumResponse['forumDiscussions'][0]) {
  deleteDiscussionDialog.value = {
    active: true,
    discussionId: discussion.id,
    name: discussion.title,
  };
}
// #endregion

// #region members
const membersNameFilter = ref('');
syncQueryParam(membersNameFilter, 'memberName', 'string');
const memberTypeFilterItems = ['All', 'Participant', 'Facilitator', 'Moderator'];
const memberTypeFilter = ref('All');
syncQueryParam(memberTypeFilter, 'memberType', 'string');
const filteredMembers = computed(
  () =>
    forum.value?.forumMembers
      .filter(
        m =>
          filterByString(m.displayName, membersNameFilter.value) &&
          (memberTypeFilter.value == 'All' || memberTypeFilter.value.toLowerCase() == m.memberType)
      )
      .sortBy(m => m.displayName) || []
);
const {
  paginatedList: paginatedMembers,
  currentPage: memberCurrentPage,
  pageSize: memberPageSize,
  paginationTotal: memberPaginationTotal,
} = paginateList(filteredMembers);

const editMemberDialog = ref({
  active: false,
  memberId: -1,
  originalType: '',
  type: '',
});

function editMember(member: ForumResponse['forumMembers'][0]) {
  editMemberDialog.value = {
    active: true,
    memberId: member.id,
    type: member.memberType,
    originalType: member.memberType,
  };
}

async function submitEditMember() {
  await api.put(`/staff/${userStaff.value.id}/forum-members/${editMemberDialog.value.memberId}`, {
    memberType: editMemberDialog.value.type,
  });
  triggerBackgroundLoad.value = true;
  editMemberDialog.value.active = false;
}

const removeMemberId = ref(-1);
const {
  action: removeMember,
  processing: removeMemberProcessing,
  error: removeMemberError,
} = withProcessingAndError(async (memberId: number) => {
  removeMemberId.value = memberId;
  await api.delete(`/staff/${userStaff.value.id}/forum-members/${memberId}`);
  triggerBackgroundLoad.value = true;
});
// #endregion

// #region notifications
const newDiscussionNotification = ref(true);
const newCommentNotification = ref(true);
const replyNotification = ref(true);

const notificationsDirty = computed(() => {
  const n = forum.value?.notifications;
  if (!n) return false;
  return (
    newDiscussionNotification.value != n.newDiscussion ||
    newCommentNotification.value != n.newCommentInMyDiscussion ||
    replyNotification.value != n.newReplyToMyComment
  );
});

async function saveNotifications() {
  await api.put(`/staff/${userStaff.value.id}/forums/${id.value}/notifications`, {
    newDiscussion: newDiscussionNotification.value,
    newCommentInMyDiscussion: newCommentNotification.value,
    newReplyToMyComment: replyNotification.value,
  });
  triggerBackgroundLoad.value = true;
}
// #endregion
</script>
