<template>
  <div class="d-flex align-start">
    <div style="height: 56px" class="d-flex align-center mt-2">
      <mosaic-checkbox
        class="mb-1"
        prepend-icon="mdi-calendar-question"
        name="schedule"
        label="Schedule in the future?"
        :model-value="scheduleInTheFuture"
        density="compact"
        @update:model-value="emit('update:scheduleInTheFuture', $event)"
      />
    </div>
    <div v-if="scheduleInTheFuture" class="ml-4">
      <mosaic-date-picker
        label="Scheduled date"
        :date="scheduledDate"
        hide-details
        exact-width="175px"
        :min="tomorrow"
        hide-clear
        @update:date="emit('update:scheduledDate', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { mapState } from '@/store/map-store';
import { institutionTimeTomorrowAsDate } from '@/utils/time';

defineProps<{
  scheduleInTheFuture: boolean;
  scheduledDate: string;
}>();

const emit = defineEmits<{
  (e: 'update:scheduleInTheFuture', value: boolean): void;
  (e: 'update:scheduledDate', value: string): void;
}>();

const { selectedInstitution } = mapState();
const tomorrow = institutionTimeTomorrowAsDate(selectedInstitution.value.config.region);
</script>
