<template>
  <div>
    <mosaic-tab-card-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      v-model:selected-tab="selectedTab"
      v-model:selected-tab-query-key="selectedTabQueryKey"
      object-type="Institution's Instructor Training"
      title="Instructor Training"
      :load="load"
      :headers="tabHeaders"
      type="list"
    >
      <template #frameworks-tab-item>
        <legacy-mosaic-inline-creation-list
          v-model:trigger-save="triggerFrameworkSave"
          v-model:trigger-background-load="triggerBackgroundLoad"
          object-type="Competency Framework"
          :items="frameworkItems"
          :icon="icons.instructorTrainingFramework"
          :hide-add="false"
          :can-add-item="canAddFramework"
          :add-item="addFramework"
          :delete-url-stem="`/staff-training/frameworks`"
          :can-retitle-item="canRenameFramework"
          :retitle-item-dialog-opened="renameFramework"
          :retitle-item="saveRenameFramework"
          @after-update="afterUpdate()"
        >
          <template #information="{ item: framework }">
            <mosaic-published-draft-chip
              :published="framework.status === 'published'"
              object-type="Competency Framework"
              published-tooltip="This Competency Framework is live with Instructors"
            />
          </template>
          <template #actions="{ item: framework }">
            <mosaic-icon-btn
              v-if="framework.status !== 'published'"
              icon="mdi-publish"
              tooltip="Publish Competency Framework to Roles and Cohorts"
              @click.prevent="publishFramework(framework)"
            />
            <mosaic-icon-btn
              v-else
              icon="mosaic-change-publish-icon"
              tooltip="Update published-to Roles and Cohorts"
              @click.prevent="changeFramework(framework)"
            />
            <mosaic-icon-btn
              icon="mdi-publish-off"
              :tooltip="`${
                framework.status === 'published'
                  ? !framework.someCompetenciesHaveBeenMet
                    ? 'Unpublish Competency Framework'
                    : 'Cannot unpublish as some Competencies have already been marked as met'
                  : 'Framework is not published'
              }`"
              :disabled="framework.status != 'published' || framework.someCompetenciesHaveBeenMet"
              @click.prevent="unpublishFramework(framework)"
            />
          </template>
          <template #add-item-fields>
            <mosaic-text-field
              v-model="addFrameworkName"
              name="add-framework-name"
              label="Name"
              prepend-icon="mdi-pencil"
              class="flex-grow-1"
            />
          </template>
          <template #retitle-item-fields="{ onKeyupEnter }">
            <mosaic-text-field
              v-model="renameFrameworkDialog.name"
              name="rename-framework"
              label="Name"
              prepend-icon="mdi-pencil"
              class="flex-grow-1"
              @keyup.enter="onKeyupEnter"
            />
          </template>
        </legacy-mosaic-inline-creation-list>
      </template>

      <template #modules-tab-item>
        <mosaic-inline-creation-list
          v-model:trigger-save="triggerModuleSave"
          v-model:trigger-background-load="triggerBackgroundLoad"
          object-type="Training Module"
          :items="moduleItems"
          :icon="icons.instructorTrainingModule"
          :hide-add="false"
          :can-add-item="canAddModule"
          :add-item="addModule"
          :delete-url-stem="`/staff-training/modules`"
          :edit-item-clicked="editModule"
          @after-update="afterUpdate()"
        >
          <template #information="{ item: module }">
            <mosaic-published-draft-chip :published="module.status === 'published'" object-type="Training Module" />
          </template>
          <template #actions="{ item: module }">
            <publish-unpublish-module-buttons
              @handle-save-publish-module="savePublishModule"
              @handle-save-unpublish-module="saveUnpublishModule"
              :module="module"
            />
            <mosaic-icon-btn
              icon="content-copy"
              tooltip="Copy Training Module"
              @click.prevent="copyModule(module)"
            ></mosaic-icon-btn>
          </template>

          <template #add-item-fields>
            <mosaic-text-field
              v-model="addModuleName"
              name="add-module-name"
              label="Name"
              prepend-icon="mdi-pencil"
              class="flex-grow-1"
            />
            <mosaic-time-picker
              v-model="addModuleDuration"
              label="Expected duration"
              mode="duration"
              allow-zero-duration
              style="width: 150px"
            />
          </template>
        </mosaic-inline-creation-list>
      </template>

      <template #certificates-tab-item>
        <institution-staff-training-page-accepted-certificates-tab
          :certificates="certificates"
          v-model:trigger-background-load="triggerBackgroundLoad"
        />
      </template>

      <template #events-tab-item>
        <institution-staff-training-page-events-tab
          :events="events"
          v-model:trigger-background-load="triggerBackgroundLoad"
        />
      </template>
    </mosaic-tab-card-page>

    <institution-staff-training-module-edit-dialog
      v-model:active="editModuleDialog.active"
      :module="editModuleDialog.module"
      @save="
        triggerBackgroundLoad = true;
        afterUpdate();
      "
    />

    <mosaic-save-dialog
      v-model:active="publishFrameworkDialog.active"
      title="Publish Framework"
      object-type="Framework"
      action="publish"
      :save="savePublishFramework"
      :can-save="canPublishFramework"
      @save="triggerBackgroundLoad = true"
    >
      <template v-if="publishFrameworkDialog.detailsLoadError">
        <mosaic-alert type="error">Sorry cannot load Framework details required to publish right now.</mosaic-alert>
      </template>
      <template v-else>
        <mosaic-alert v-if="!publishFrameworkDialog.hasThemes" type="warning" class="mb-2">
          This Framework does not contain any Themes. Are you sure you want to publish?
        </mosaic-alert>
        <mosaic-alert v-else-if="!publishFrameworkDialog.allThemesHaveCompetencies" type="warning" class="mb-2">
          Some of the Themes in this Framework do not have any Competencies. Are you sure you want to publish?
        </mosaic-alert>
        <mosaic-alert v-else-if="!publishFrameworkDialog.allCompetenciesHaveRequirements" type="warning" class="mb-2">
          Some of the Competencies in this Framework do not have any Requirements. Are you sure you want to publish?
        </mosaic-alert>
        <div>
          <div>
            By publishing this framework, it will become available for Instructors in the following Cohorts, who have at
            least one of the following Roles. All requirements linked to Competencies in this Framework will show as
            requirements for these Instructors.
          </div>
          <div class="pt-2">
            When unpublishing a framework, any module requirements that have already been started by Instructors will
            remain in their Training Record.
          </div>
          <mosaic-select
            v-model="publishFrameworkDialog.cohorts"
            multiple
            name="publish-framework-cohorts"
            label="Cohorts"
            prepend-icon="mdi-google-classroom"
            :items="activeCohorts"
            item-title="name"
            item-value="id"
          />
          <mosaic-select
            v-model="publishFrameworkDialog.roles"
            multiple
            name="publish-framework-roles"
            label="Roles"
            prepend-icon="mdi-account-multiple-check"
            :items="studentScopedRoles"
            item-title="name"
            item-value="id"
          />
        </div>
      </template>
    </mosaic-save-dialog>

    <mosaic-dialog
      v-model:active="unpublishFrameworkDialog.active"
      title="Unpublish Framework"
      :error-message="unpublishFrameworkDialog.errorMessage"
    >
      <mosaic-alert type="warning" class="mb-2">
        Unpublishing this Framework will remove it from all Instructors' Training Requirements page. Any modules that
        have been started by Instructors will remain in their Training Record.
      </mosaic-alert>
      <div>Are you sure you want to unpublish this Framework?</div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="unpublishFrameworkDialog.processing"
          :loading="unpublishFrameworkDialog.processing"
          @click.prevent="submitUnpublishFramework()"
          >Confirm</v-btn
        >
      </template>
    </mosaic-dialog>

    <mosaic-save-dialog
      v-model:active="copyModuleDialog.active"
      title="Copy Training Module"
      object-type="Training Module"
      action="copy"
      :save="saveCopyModule"
      :can-save="!!copyModuleDialog.name"
      @save="triggerBackgroundLoad = true"
    >
      <mosaic-text-field
        v-model="copyModuleDialog.name"
        name="copy-module-name"
        label="Name"
        prepend-icon="mdi-pencil"
        class="flex-grow-1"
      />
    </mosaic-save-dialog>
  </div>
</template>

<script>
// Module (and probably framework) lists should use dialog creation lists to allow for pagination

import { mapGetters, mapState } from 'vuex';
import MosaicTabCardPage from '@/components/library/pages/MosaicTabCardPage.vue';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useRetitleListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { enumerateItems } from '@/utils/text';
import { icons } from '@/utils/icons';
import InstitutionStaffTrainingPageAcceptedCertificatesTab from './InstitutionStaffTrainingPageAcceptedCertificatesTab.vue';
import InstitutionStaffTrainingPageEventsTab from './InstitutionStaffTrainingPageEventsTab.vue';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';
import InstitutionStaffTrainingModuleEditDialog from './InstitutionStaffTrainingModuleEditDialog.vue';
import { useQueryStore } from '@/stores/query';
import { splitTimeIntoHoursAndMinutes } from '@/utils/time';
import { staffTrainingModuleExpectedDuration } from '@/pages/staff-training/staff-training';
import PublishUnpublishModuleButtons from '@/components/PublishUnpublishModuleButtons.vue';

const defaultPublishFrameworkDialog = {
  active: false,
  id: null,
  cohorts: [],
  roles: [],
  hasThemes: false,
  allThemesHaveCompetencies: false,
  allCompetenciesHaveRequirements: false,
  detailsLoadError: false,
};

export default {
  name: 'InstitutionStaffTrainingPage',
  components: {
    MosaicTabCardPage,
    InstitutionStaffTrainingPageAcceptedCertificatesTab,
    InstitutionStaffTrainingPageEventsTab,
    InstitutionStaffTrainingModuleEditDialog,
    PublishUnpublishModuleButtons,
  },
  mixins: [
    syncQueryParamsMixin({
      //Would be nice to be able to use an object here to define the query params. Logic currently contained within the tab component
      selectedTabQueryKey: { query: 'tab', type: 'string' },
    }),
  ],
  setup() {
    const staffTrainingStore = useStaffTrainingStore();
    const institutionStaffTrainingStore = useInstitutionStaffTrainingStore();
    const queryStore = useQueryStore();
    return {
      staffTrainingStore,
      institutionStaffTrainingStore,
      queryStore,
    };
  },
  data: () => ({
    certificates: [],
    frameworks: [],
    events: [],
    addModuleName: '',
    addModuleDuration: '1:00',
    addFrameworkName: '',
    editModuleDialog: {
      active: false,
      module: {},
    },
    renameFrameworkDialog: {
      name: '',
      originalName: '',
    },
    publishFrameworkDialog: {
      ...defaultPublishFrameworkDialog,
    },
    unpublishFrameworkDialog: {
      active: false,
      id: null,
      name: null,
      processing: false,
      errorMessage: null,
    },
    copyModuleDialog: {
      active: false,
      id: -1,
      name: '',
    },
    triggerModuleSave: false,
    triggerFrameworkSave: false,
    triggerBackgroundLoad: false,
    tabHeaders: [
      {
        text: 'Frameworks',
        key: 'frameworks',
      },
      {
        text: 'Modules',
        key: 'modules',
      },
      {
        text: 'Accepted Certificates',
        key: 'certificates',
      },
      {
        text: 'Events',
        key: 'events',
      },
    ],
    selectedTab: {
      text: 'Frameworks',
      key: 'frameworks',
    },
    selectedTabQueryKey: 'frameworks',
    icons,
  }),
  computed: {
    ...mapState(['selectedInstitution', 'staffTrainingModules', 'userStaff']),
    ...mapGetters(['activeCohorts', 'studentScopedRoles']),
    breadcrumbs() {
      return [
        {
          text: 'Instructor Training',
        },
      ];
    },
    moduleItems() {
      if (!this.staffTrainingModules) return [];
      return this.staffTrainingModules.map(m => {
        const uniqueFrameworksCount = this.countUnique(m.staffTrainingCompetencies.map(c => c.frameworkId));
        const acrossOrIn = uniqueFrameworksCount > 1 ? 'across' : 'in';
        return {
          ...m,
          title: m.name,
          subtitle:
            `${staffTrainingModuleExpectedDuration(m)} - ` +
            (m.staffTrainingCompetencies.length
              ? `${m.status !== 'published' ? 'Unpublished r' : 'R'}equirement for ${this.enumerateItems(
                  m.staffTrainingCompetencies.length,
                  'Competency'
                )} ${acrossOrIn} ${this.enumerateItems(uniqueFrameworksCount, 'Framework')}`
              : 'Not linked as a requirement for any Competencies yet'),
          hideMove: true,
          disableDelete: m.status === 'published',
          deleteDisabledTooltip: m.status === 'published' ? 'Published Modules cannot be deleted' : null,
          clickTo: this.clickModuleTo(m),
        };
      });
    },
    canAddModule() {
      return !!this.addModuleName;
    },
    canPublishFramework() {
      return (
        this.publishFrameworkDialog.cohorts.length > 0 &&
        this.publishFrameworkDialog.roles.length > 0 &&
        !this.publishFrameworkDialog.detailsLoadError
      );
    },
    allModulesPublished() {
      return this.staffTrainingModules.every(m => m.status === 'published');
    },
    canAddFramework() {
      return !!this.addFrameworkName;
    },
    frameworkItems() {
      if (!this.frameworks) return [];
      return this.frameworks
        .map(f => ({
          ...f,
          title: f.name,
          hideMove: true,
          subtitle: enumerateItems(f.themeCount, 'Competency Theme'),
          warningMessage: this.getFrameworkWarningMessage(f),
          disableDelete: f.themeCount > 0,
          deleteDisabledTooltip: f.themeCount > 0 ? 'Cannot delete Frameworks with Competency Themes' : null,
          clickTo: this.clickFrameworkTo(f),
        }))
        .sortBy('status');
    },
    canRenameFramework() {
      return (
        !!this.renameFrameworkDialog.name && this.renameFrameworkDialog.name !== this.renameFrameworkDialog.originalName
      );
    },
  },
  async created() {
    this.$store.dispatch('loadCohorts');
    this.$store.dispatch('loadRoles');
  },
  methods: {
    countUnique(iterable) {
      return new Set(iterable).size;
    },
    getFrameworkWarningMessage(f) {
      if (f.status === 'unpublished') return null;
      const reason =
        f.themeCount === 0
          ? 'This Framework has no Competency Themes. '
          : !f.allThemesHaveCompetencies
          ? 'Some of the Competency Themes in this Framework do not have any Competencies. '
          : !f.allCompetenciesHaveRequirements
          ? 'Some of the Competencies in this Framework do not have any Training Module, Certificate or published Event requirements. '
          : null;
      const publishModifier = f.status == 'published' ? 'This' : 'Publishing this Framework ';
      const result = ' may cause confusion for Instructors.';
      return reason ? reason + publishModifier + result : undefined;
    },
    async afterUpdate() {
      await this.staffTrainingStore.actions.loadStaffTraining(this.userStaff.id, true);
    },
    async load() {
      await Promise.all([this.loadModules(), this.loadFrameworks(), this.loadCertificates(), this.loadEvents()]);
    },
    async loadCertificates() {
      const r = await this.$api.get(
        `/institutions/${this.selectedInstitution.id}/staff-training/accepted-certificates`
      );
      this.certificates = r.data;
    },
    async loadEvents() {
      const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/staff-training/events`);
      this.events = r.data;
    },
    async loadModules() {
      await this.$store.dispatch('loadStaffTrainingModules', { force: true });
    },
    async addModule() {
      const { hours, minutes } = splitTimeIntoHoursAndMinutes(this.addModuleDuration);
      await this.$api.post(`/institutions/${this.selectedInstitution.id}/staff-training/modules`, {
        name: this.addModuleName,
        durationHours: hours,
        durationMinutes: minutes,
      });
    },
    async addFramework() {
      await this.$api.post(`/institutions/${this.selectedInstitution.id}/staff-training/frameworks`, {
        name: this.addFrameworkName,
      });
    },
    editModule(moduleItem) {
      const module = this.staffTrainingModules.find(m => m.id === moduleItem.id);
      this.editModuleDialog = {
        active: true,
        module,
      };
    },
    copyModule(module) {
      this.copyModuleDialog = {
        active: true,
        id: module.id,
        name: `${module.name} (copy)`,
      };
    },
    async saveCopyModule() {
      await this.$api.post(`/staff-training/modules/${this.copyModuleDialog.id}/copy`, {
        name: this.copyModuleDialog.name,
      });
    },
    publishFramework(framework) {
      this.publishFrameworkDialog = {
        ...(framework.id !== this.publishFrameworkDialog.id
          ? defaultPublishFrameworkDialog
          : this.publishFrameworkDialog),
        active: true,
        id: framework.id,
        detailsLoadError: false,
      };
      // Avoids loading all associations for all frameworks
      this.getFrameworkPublishDetailsForDialog(framework);
    },
    changeFramework(framework) {
      this.publishFrameworkDialog = {
        ...(framework.id !== this.publishFrameworkDialog.id
          ? defaultPublishFrameworkDialog
          : this.publishFrameworkDialog),
        active: true,
        id: framework.id,
        detailsLoadError: false,
        cohorts: framework.publishedToCohorts.map(c => c.id),
        roles: framework.publishedToRoles.map(r => r.id),
      };
      // Avoids loading all associations for all frameworks
      this.getFrameworkPublishDetailsForDialog(framework);
    },

    getFrameworkPublishDetailsForDialog(framework) {
      this.publishFrameworkDialog = {
        ...this.publishFrameworkDialog,
        hasThemes: framework.themeCount > 0,
        allThemesHaveCompetencies: framework.allThemesHaveCompetencies,
        allCompetenciesHaveRequirements: framework.allCompetenciesHaveRequirements,
      };
    },
    unpublishFramework(framework) {
      this.unpublishFrameworkDialog = {
        active: true,
        id: framework.id,
        name: framework.name,
      };
    },
    async submitUnpublishFramework() {
      this.unpublishFrameworkDialog.processing = true;
      try {
        await this.$api.put(`/staff-training/frameworks/${this.unpublishFrameworkDialog.id}/unpublish`);
        this.unpublishFrameworkDialog = {
          active: false,
          id: null,
          name: null,
        };
      } catch (e) {
        console.error(e);
        this.unpublishFrameworkDialog.errorMessage = 'Sorry, cannot delete this Framework right now.';
      }
      this.unpublishFrameworkDialog.processing = false;
      // Deliberately not awaited, so it happens in the background
      this.loadFrameworks();
      this.institutionStaffTrainingStore.actions.clearInstitutionStaffTrainingFrameworks();
      this.afterUpdate();
    },
    async savePublishFramework() {
      await this.$api.post(`/staff-training/frameworks/${this.publishFrameworkDialog.id}/publish`, {
        cohortIds: this.publishFrameworkDialog.cohorts,
        roleIds: this.publishFrameworkDialog.roles,
      });

      // Deliberately not awaited, so it happens in the background
      this.afterUpdate();

      this.institutionStaffTrainingStore.actions.clearInstitutionStaffTrainingFrameworks();
      this.staffTrainingStore.actions.clearAllStaffTraining();
    },
    savePublishModule() {
      this.loadModules();
      this.afterUpdate();
    },
    saveUnpublishModule() {
      this.loadModules();
      this.afterUpdate();
    },
    clickModuleTo(module) {
      return { name: 'InstitutionStaffTrainingModulePage', params: { id: module.id } };
    },
    async loadFrameworks() {
      const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/staff-training/frameworks`);
      this.frameworks = r.data.sortBy('name');
    },
    renameFramework(frameworkItem) {
      const framework = this.frameworks.find(f => f.id === frameworkItem.id);
      this.renameFrameworkDialog = {
        name: framework.name,
        originalName: framework.name,
      };
    },
    async saveRenameFramework(id) {
      await useRetitleListItem(
        '/staff-training/frameworks/',
        {
          value: {
            name: this.renameFrameworkDialog.name,
          },
        },
        this.$api
      ).retitleListItem(id);

      this.institutionStaffTrainingStore.actions.clearInstitutionStaffTrainingFrameworks();
    },
    clickFrameworkTo(framework) {
      return { name: 'InstitutionStaffTrainingFrameworkPage', params: { id: framework.id } };
    },
  },
};
</script>
