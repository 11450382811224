<template>
  <mosaic-create-edit-card-page
    object-type="GoReact Template"
    :is-creating="isCreating"
    :load="load"
    :dirty="dirty"
    :save="save"
    :can-save="canSave"
    :return-to="getReturnTo(breadcrumbs)"
  >
    <mosaic-text-field label="Name" v-model="name" prepend-icon="pencil" />
    <mosaic-card-heading>Configure</mosaic-card-heading>
    <div class="mb-2">
      Some copy explaining that you need to configure and Mosaic won't be able to tell that you've configured it
    </div>
    <mosaic-error-alert :error="configureGoReactError" action="configure one of your GoReact Assignments" />

    <div class="d-flex align-center">
      <mosaic-btn
        class="mr-2"
        @click="configureGoReactAssignment()"
        :disabled="configureGoReactProcessing || !name"
        :loading="configureGoReactProcessing"
      >
        Configure
      </mosaic-btn>
      <mosaic-icon v-if="!isConfigured" icon="progress-question" color="accent" />
      <mosaic-icon v-else icon="progress-check" color="success" />
    </div>
  </mosaic-create-edit-card-page>
</template>

<script setup lang="ts">
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { setBreadcrumbs, getReturnTo } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { v4 as uuid } from 'uuid';
import { ltiLaunch } from '@/utils/lti';
import { useInstitutionStore } from '@/stores/institution';
import { useApi } from '@/composables/api';
import { parseRouteId } from '@/composables/vue-router';
import type { GoReactTemplateResponse } from './go-react-template';
import { useGoReactTemplatesStore } from '@/stores/go-react-templates';

// #region setup
const api = useApi();
const route = useRoute();
const templateId = parseRouteId('id');
const isCreating = computed(() => (route.name || '').toString().includes('Create'));
const { selectedInstitution } = useInstitutionStore();
const {
  actions: { clearGoReactTemplates },
} = useGoReactTemplatesStore();
// #endregion

// #region data
const template = ref<GoReactTemplateResponse>();

const name = ref('');
// Current isConfigured just means there has been an Admin LTI launch
const isConfigured = ref(false);
const ltiResourceLinkId = ref(uuid());

const isCreatingAndReturning = ref(false);
const dirty = computed(() => {
  if (isCreatingAndReturning.value) return false;
  if (isCreating.value) {
    return !!name.value || isConfigured.value;
  } else {
    const t = template.value;
    if (!t) return false;
    return name.value != t.name;
  }
});
// #endregion

// #region breadcrumbs
const breadcrumbs = computed(() => [
  {
    text: 'GoReact Templates',
    to: {
      name: 'InstitutionGoReactTemplatesListPage',
    },
  },
  {
    text: isCreating.value ? 'New GoReact Template' : template.value?.name || '',
  },
]);
setBreadcrumbs(breadcrumbs);
// #endregion

// #region setup
async function load() {
  if (!isCreating.value) {
    const r = await api.get<GoReactTemplateResponse>(`/go-react-templates/${templateId.value}`);
    template.value = r.data;
    name.value = r.data.name;
    ltiResourceLinkId.value = r.data.ltiResourceLinkId;
    isConfigured.value = true;
  }
}
// #endregion

// #region save
const canSave = computed(() => {
  if (isCreating.value) {
    return !!name.value && isConfigured.value;
  } else {
    return dirty.value && !!name.value;
  }
});

async function save() {
  const body = {
    name: name.value,
    ltiResourceLinkId: ltiResourceLinkId.value,
  };
  if (isCreating.value) {
    await api.post(`/institutions/${selectedInstitution.value.id}/go-react-templates`, body);
    isCreatingAndReturning.value = true;
  } else {
    const r = await api.put<typeof body, GoReactTemplateResponse>(`/go-react-templates/${templateId.value}`, body);
    template.value = r.data;
  }
  clearGoReactTemplates();
}
// #endregion

// #region configure
const {
  action: configureGoReactAssignment,
  error: configureGoReactError,
  processing: configureGoReactProcessing,
} = withProcessingAndError(async () => {
  await ltiLaunch(
    api,
    `/lti/institutions/${selectedInstitution.value.id}/login-url/${ltiResourceLinkId.value}?resource_link_title=${name.value}`
  );

  isConfigured.value = true;
});
// #endregion
</script>
