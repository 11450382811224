<template>
  <mosaic-loading-and-error-cards
    object-type="Discussion"
    :load="load"
    v-model:trigger-background-load="triggerBackgroundLoad"
  >
    <template v-if="discussion">
      <mosaic-card>
        <mosaic-card-title>
          {{ discussion.title }}
          <template #actions v-if="discussion.canEdit">
            <mosaic-card-action-icon-btn
              icon="pencil"
              tooltip="Edit Discussion"
              :to="{ name: 'StaffForumDiscussionEditPage', params: { id } }"
            />
          </template>
        </mosaic-card-title>
        <mosaic-card-subtitle class="mb-2">
          Created by {{ discussion.createdBy.displayName }} on {{ formatDate(discussion.createdAt) }}
        </mosaic-card-subtitle>
        <mosaic-quill v-if="discussion.content" :read-only="true" :contents="discussion.content" />

        <v-divider />
        <div class="py-4">
          <mosaic-btn @click="addingComment = true" v-if="!addingComment" icon="plus">Comment</mosaic-btn>
          <div v-else>
            <mosaic-quill v-model:contents="newComment" :read-only="false" />
            <mosaic-error-alert :error="addCommentError" action="add this Comment" />
            <div class="d-flex justify-end pt-2">
              <mosaic-btn
                class="mr-2"
                @click="
                  addingComment = false;
                  addCommentError = false;
                "
                :disabled="addCommentProcessing"
              >
                Cancel
              </mosaic-btn>
              <mosaic-btn
                color="primary"
                @click="addComment"
                :disabled="addCommentProcessing || !newComment"
                :loading="addCommentProcessing"
                >Comment</mosaic-btn
              >
            </div>
          </div>
        </div>
        <v-divider />

        <mosaic-list
          class="mt-2"
          empty-text="There are no Comments in this Discussion."
          :items="forumDiscussionComments"
        >
          <template #item="{ item }">
            <div class="d-flex align-center" :id="`comment-${item.id}`">
              <mosaic-avatar
                :user="{
                  id: item.createdBy.userId,
                  displayName: item.createdBy.displayName,
                  profilePictureUpdatedAt: item.createdBy.profilePictureUpdatedAt,
                }"
                :hide-profile-picture="item.createdBy.hideProfilePicture"
              />
              <div class="ml-2">{{ item.createdBy.displayName }} said at {{ formatDateTime(item.createdAt) }}:</div>
              <mosaic-icon-btn
                v-if="item.createdBy.id == userStaff.id"
                icon="pencil"
                size="small"
                tooltip="Edit Comment"
                :disabled="!item.canEdit.permitted || DateTime.fromISO(item.createdAt) < now.minus({ minutes: 10 })"
                :disabled-tooltip="
                  editOrDeleteCommentErrorCodeMap('edit')[item.canEdit.reason] ||
                  editOrDeleteCommentErrorCodeMap('edit')['older_than_10_minutes']
                "
                @click="startEditingComment(item)"
              />
              <mosaic-icon-btn
                v-if="item.createdBy.id == userStaff.id || item.canDelete.permitted"
                icon="delete"
                size="small"
                tooltip="Delete Comment"
                :loading="item.deleteProcessing"
                :disabled="
                  item.deleteProcessing ||
                  !item.canDelete.permitted ||
                  (!!item.canDelete.permittedUntil && DateTime.fromISO(item.canDelete.permittedUntil) < now)
                "
                :disabled-tooltip="
                  editOrDeleteCommentErrorCodeMap('delete')[item.canDelete.reason] ||
                  (item.deleteProcessing
                    ? undefined
                    : editOrDeleteCommentErrorCodeMap('delete')['older_than_10_minutes'])
                "
                @click="deleteComment(item.id)"
              />
              <mosaic-error-snackbar v-model="item.deleteError" action="delete this Comment" contained />
            </div>
            <mosaic-quill
              :read-only="!item.editing"
              :contents="item.content"
              @update:contents="updateCommentEdit(item.id, $event)"
              :class="{ 'my-2': item.editing }"
            />
            <mosaic-error-alert :error="item.editError" action="edit this Comment" />
            <div class="d-flex justify-end mb-2" v-if="item.editing">
              <mosaic-btn class="mr-2" @click="stopEditingComment(item.id)" :disabled="item.editProcessing">
                Cancel
              </mosaic-btn>
              <mosaic-btn
                color="primary"
                @click="editComment(item.id)"
                :disabled="item.editProcessing || !item.editContent || item.editContent == item.content"
                :loading="item.editProcessing"
                >Save</mosaic-btn
              >
            </div>
            <div v-for="c of item.childComments" :key="c.id" class="pl-8">
              <div class="d-flex align-center" :id="`reply-${c.id}`">
                <mosaic-avatar
                  :user="{
                    id: c.createdBy.userId,
                    displayName: c.createdBy.displayName,
                    profilePictureUpdatedAt: c.createdBy.profilePictureUpdatedAt,
                  }"
                  :hide-profile-picture="c.createdBy.hideProfilePicture"
                />
                <div class="mx-2">{{ c.createdBy.displayName }} said at {{ formatDateTime(c.createdAt) }}:</div>
                <mosaic-icon-btn
                  v-if="c.createdBy.id == userStaff.id"
                  icon="pencil"
                  size="small"
                  tooltip="Edit Comment"
                  :disabled="!c.canEdit.permitted || DateTime.fromISO(c.createdAt) < now.minus({ minutes: 10 })"
                  :disabled-tooltip="
                    editOrDeleteCommentErrorCodeMap('edit')[c.canEdit.reason] ||
                    editOrDeleteCommentErrorCodeMap('edit')['older_than_10_minutes']
                  "
                  @click="startEditingComment(c)"
                />
                <mosaic-icon-btn
                  v-if="c.createdBy.id == userStaff.id || c.canDelete.permitted"
                  icon="delete"
                  size="small"
                  tooltip="Delete Comment"
                  :loading="c.deleteProcessing"
                  :disabled="
                    c.deleteProcessing ||
                    !c.canDelete.permitted ||
                    (!!c.canDelete.permittedUntil && DateTime.fromISO(c.canDelete.permittedUntil) < now)
                  "
                  :disabled-tooltip="
                    editOrDeleteCommentErrorCodeMap('delete')[c.canDelete.reason] ||
                    (c.deleteProcessing
                      ? undefined
                      : editOrDeleteCommentErrorCodeMap('delete')['older_than_10_minutes'])
                  "
                  @click="deleteComment(c.id)"
                />
                <mosaic-error-snackbar v-model="item.deleteError" action="delete this Comment" contained />
              </div>
              <mosaic-quill
                :read-only="!c.editing"
                :contents="c.content"
                @update:contents="updateCommentEdit(c.id, $event)"
                :class="{ 'my-2': c.editing }"
              />
              <mosaic-error-alert :error="c.editError" action="edit this Comment" />
              <div class="d-flex justify-end mb-2" v-if="c.editing">
                <mosaic-btn class="mr-2" @click="stopEditingComment(c.id)" :disabled="c.editProcessing">
                  Cancel
                </mosaic-btn>
                <mosaic-btn
                  color="primary"
                  @click="editComment(c.id, item.id)"
                  :disabled="c.editProcessing || !c.editContent || c.editContent == c.content"
                  :loading="c.editProcessing"
                  >Save</mosaic-btn
                >
              </div>
            </div>
            <div class="pa-1">
              <mosaic-btn v-if="!item.replying" @click="startReplying(item.id)" icon="plus">Reply</mosaic-btn>
              <div v-else>
                <mosaic-quill
                  :contents="item.reply"
                  @update:contents="updateReply(item.id, $event)"
                  :read-only="false"
                />
                <mosaic-error-alert :error="item.replyError" action="reply to this Comment" />
                <div class="d-flex justify-end pt-2">
                  <mosaic-btn class="mr-2" @click="stopReplying(item.id)" :disabled="item.replyProcessing">
                    Cancel
                  </mosaic-btn>
                  <mosaic-btn
                    color="primary"
                    @click="addReply(item.id)"
                    :disabled="item.replyProcessing || !item.reply"
                    :loading="item.replyProcessing"
                    >Reply</mosaic-btn
                  >
                </div>
              </div>
            </div>
            <v-divider class="my-4" />
          </template>
        </mosaic-list>
      </mosaic-card>
    </template>
  </mosaic-loading-and-error-cards>

  <unsaved-changes-dialog :unsaved-changes-dialog="dialog" object-type="Discussion" />
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { parseRouteId } from '@/composables/vue-router';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref, watch } from 'vue';
import { formatDateTime } from '@/utils/date';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import { handleErrorCodes, withProcessingAndError } from '@/composables/processing-and-errors';
import { useStaffStore } from '@/stores/staff';
import { useRoute } from 'vue-router';
import { isString } from 'lodash';
import { useCurrentTime } from '@/utils/time';
import { DateTime } from 'luxon';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { useUnsavedChanges } from '@/composables/unsaved-changes';

const api = useApi();
const id = parseRouteId('id');
const { userStaff } = useStaffStore();
const route = useRoute();
const now = useCurrentTime();

interface ForumDiscussionResponse {
  id: number;
  title: string;
  content: string;
  createdAt: string;
  canEdit: boolean;
  forumDiscussionComments: (ForumDiscussionComment & {
    childComments: ForumDiscussionComment[];
  })[];
  createdBy: {
    id: number;
    displayName: string;
    profilePictureUpdatedAt: string | null;
  };
  forum: {
    id: number;
    name: string;
  };
}

interface ForumDiscussionComment {
  id: number;
  content: string;
  createdAt: string;
  createdBy: {
    id: number;
    displayName: string;
    userId: number;
    profilePictureUpdatedAt: string | null;
    hideProfilePicture: boolean;
  };
  canEdit: {
    permitted: boolean;
    reason: string;
    permittedUntil?: string;
  };
  canDelete: {
    permitted: boolean;
    reason: string;
    permittedUntil?: string;
  };
}

const discussion = ref<ForumDiscussionResponse>();

const breadcrumbs = computed(() => [
  { text: 'Forums', to: { name: 'StaffForumsListPage' } },
  {
    text: discussion.value?.forum.name || '',
    to: discussion.value ? { name: 'StaffForumPage', params: { id: discussion.value.forum.id } } : undefined,
  },
  { text: discussion.value?.title || '' },
]);
setBreadcrumbs(breadcrumbs);

// #region load
const triggerBackgroundLoad = ref(false);
async function load() {
  const r = await api.get<ForumDiscussionResponse>(`/staff/${userStaff.value.id}/forum-discussions/${id.value}`);
  discussion.value = r.data;

  if (route.query.commentId) {
    setTimeout(() => {
      const element = document.getElementById(`comment-${route.query.commentId}`);
      if (element) {
        element.scrollIntoView({ block: 'center' });
      }
    }, 100);
  }
  if (route.query.replyId) {
    setTimeout(() => {
      const element = document.getElementById(`reply-${route.query.replyId}`);
      if (element) {
        element.scrollIntoView({ block: 'center' });
      }
    }, 100);
  }
}

watch(
  () => route.query.commentId,
  commentId => {
    if (commentId) {
      triggerBackgroundLoad.value = true;
    }
  }
);

watch(
  () => route.query.replyId,
  commentId => {
    if (commentId) {
      triggerBackgroundLoad.value = true;
    }
  }
);
// #endregion

// #region comments
const addingComment = ref(false);
const newComment = ref('');

const {
  action: addComment,
  processing: addCommentProcessing,
  error: addCommentError,
} = withProcessingAndError(async () => {
  await api.post(`/staff/${userStaff.value.id}/forum-discussions/${id.value}/forum-discussion-comments`, {
    content: newComment.value,
    staffId: userStaff.value.id,
  });
  triggerBackgroundLoad.value = true;
  addingComment.value = false;
  newComment.value = '';
});

// #endregion

// #region replies
const addReplyStatus = ref<{
  [commentId: string]: { replying: boolean; reply: string; replyProcessing: boolean; replyError: boolean };
}>({});
const defaultAddReplyStatus = { replying: false, reply: '', replyProcessing: false, replyError: false };

const forumDiscussionComments = computed(() =>
  (discussion.value?.forumDiscussionComments || []).map(c => ({
    ...c,
    ...(addReplyStatus.value[c.id] || { ...defaultAddReplyStatus }),
    ...(editCommentStatus.value[c.id] || { ...defaultEditCommentStatus }),
    ...(deleteCommentStatus.value[c.id] || { ...defaultDeleteCommentStatus }),
    childComments: c.childComments.map(cc => ({
      ...cc,
      ...(editCommentStatus.value[cc.id] || { ...defaultEditCommentStatus }),
      ...(deleteCommentStatus.value[cc.id] || { ...defaultDeleteCommentStatus }),
    })),
  }))
);

function startReplying(commentId: number) {
  getAddReplyStatus(commentId).replying = true;
}

function stopReplying(commentId: number) {
  getAddReplyStatus(commentId).replying = false;
  getAddReplyStatus(commentId).replyError = false;
}

function updateReply(commentId: number, content: string) {
  getAddReplyStatus(commentId).reply = content;
}

function getAddReplyStatus(commentId: number) {
  if (!addReplyStatus.value[commentId]) {
    addReplyStatus.value[commentId] = { ...defaultAddReplyStatus };
  }

  return addReplyStatus.value[commentId];
}

async function addReply(commentId: number) {
  getAddReplyStatus(commentId).replyProcessing = true;
  getAddReplyStatus(commentId).replyError = false;
  try {
    await api.post(`/staff/${userStaff.value.id}/forum-discussion-comments/${commentId}/reply`, {
      content: getAddReplyStatus(commentId).reply,
      staffId: userStaff.value.id,
    });
    triggerBackgroundLoad.value = true;
    getAddReplyStatus(commentId).replying = false;
    getAddReplyStatus(commentId).reply = '';
  } catch (e) {
    console.log(e);
    getAddReplyStatus(commentId).replyError = true;
  }
  getAddReplyStatus(commentId).replyProcessing = false;
}

const editCommentStatus = ref<{
  [commentId: string]: { editing: boolean; editProcessing: boolean; editError: boolean | string; editContent: string };
}>({});
const defaultEditCommentStatus = { editing: false, editProcessing: false, editError: false, editContent: '' };

function getEditCommentStatus(commentId: number) {
  if (!editCommentStatus.value[commentId]) {
    editCommentStatus.value[commentId] = { ...defaultEditCommentStatus };
  }

  return editCommentStatus.value[commentId];
}
function startEditingComment(comment: ForumDiscussionComment) {
  getEditCommentStatus(comment.id).editing = true;
  getEditCommentStatus(comment.id).editContent = comment.content;
}
function stopEditingComment(commentId: number) {
  getEditCommentStatus(commentId).editing = false;
  getEditCommentStatus(commentId).editError = false;
}
function updateCommentEdit(commentId: number, content: string) {
  if (getEditCommentStatus(commentId).editing) {
    getEditCommentStatus(commentId).editContent = content;
  }
}

function editOrDeleteCommentErrorCodeMap(action: 'edit' | 'delete'): { [errorCode: string]: string } {
  return {
    older_than_10_minutes: `Cannot ${action} Comments older than 10 minutes`,
    has_replies: `Cannot ${action} Comments with replies`,
  };
}
async function editComment(commentId: number, parentCommentId?: number) {
  getEditCommentStatus(commentId).editProcessing = true;
  getEditCommentStatus(commentId).editError = false;
  try {
    const content = getEditCommentStatus(commentId).editContent;
    const error = await handleErrorCodes(
      () =>
        api.put<unknown, undefined>(`/staff/${userStaff.value.id}/forum-discussion-comments/${commentId}`, {
          content,
          staffId: userStaff.value.id,
        }),
      editOrDeleteCommentErrorCodeMap('edit')
    );

    if (isString(error)) {
      getEditCommentStatus(commentId).editError = error;
    } else {
      if (parentCommentId) {
        discussion
          .value!.forumDiscussionComments.find(c => c.id == parentCommentId)!
          .childComments.find(cc => cc.id == commentId)!.content = content;
      } else {
        discussion.value!.forumDiscussionComments.find(c => c.id == commentId)!.content = content;
      }
      getEditCommentStatus(commentId).editing = false;
      getEditCommentStatus(commentId).editContent = '';
    }
  } catch (e) {
    console.log(e);
    getEditCommentStatus(commentId).editError = true;
  }
  getEditCommentStatus(commentId).editProcessing = false;
}

const deleteCommentStatus = ref<{
  [commentId: string]: { deleteProcessing: boolean; deleteError: boolean | string };
}>({});
const defaultDeleteCommentStatus = { deleteProcessing: false, deleteError: false };
function getDeleteCommentStatus(commentId: number) {
  if (!deleteCommentStatus.value[commentId]) {
    deleteCommentStatus.value[commentId] = { ...defaultDeleteCommentStatus };
  }

  return deleteCommentStatus.value[commentId];
}
async function deleteComment(commentId: number) {
  getDeleteCommentStatus(commentId).deleteProcessing = true;
  try {
    const error = await handleErrorCodes(
      () => api.delete(`/staff/${userStaff.value.id}/forum-discussion-comments/${commentId}`),
      editOrDeleteCommentErrorCodeMap('delete')
    );
    if (isString(error)) {
      getDeleteCommentStatus(commentId).deleteError = error;
    } else {
      triggerBackgroundLoad.value = true;
    }
  } catch (e) {
    console.log(e);
    getDeleteCommentStatus(commentId).deleteError = true;
  }
  getDeleteCommentStatus(commentId).deleteProcessing = false;
}
// #endregion

const dirty = computed(() => {
  return (
    (!!newComment.value && addingComment.value) ||
    Object.values(addReplyStatus.value).some(x => !!x.reply) ||
    Object.values(editCommentStatus.value).some(x => !!x.editContent)
  );
});
const { dialog } = useUnsavedChanges(dirty);
</script>
