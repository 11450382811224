import { defineStore } from 'pinia';
import { useStore } from './common';
import { computed, ref } from 'vue';
import { useInstitutionStore } from './institution';
import { useApi } from '@/composables/api';
import { CacheMap } from './cache-map';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

export interface InstitutionStaff {
  id: number;
  user: {
    id: number;
    name: string;
    email: string;
  };
  staffRoles: {
    role: { id: number; name: string };
    student?: { cohortId: number };
    noStudentYetCohort?: { id: number };
    cohort?: { id: number };
  }[];
}

export const useInstitutionStaffStore = useStore(
  defineStore('institution-staff', () => {
    const { selectedInstitution } = useInstitutionStore();
    const api = useApi();

    const institutionStaffCache = ref<CacheMap<InstitutionStaff[]>>(new CacheMap());

    function reset() {
      institutionStaffCache.value.clear();
    }

    const institutionStaff = computed(
      () => institutionStaffCache.value.getEvenIfExpired(selectedInstitution.value.id) || []
    );

    async function loadInstitutionStaff(force?: boolean) {
      const institutionId = selectedInstitution.value.id;
      if (!force && institutionStaffCache.value.get(institutionId)) return;
      let staff: InstitutionStaff[] = [];
      while (true) {
        const r = await api.get<InstitutionStaff[]>(`/institutions/${institutionId}/staff/full?offset=${staff.length}`);
        staff = staff.concat(r.data.map(x => fromSnakeCaseToCamelCase(x)));
        if (r.data.length === 0) break;
      }
      institutionStaffCache.value.set(institutionId, staff);
    }

    function clearInstitutionStaff() {
      institutionStaffCache.value.remove(selectedInstitution.value.id);
    }

    return { reset, institutionStaff, institutionStaffCache, loadInstitutionStaff, clearInstitutionStaff };
  })
);
