<template>
  <mosaic-filter-list-page
    object-type="GoReact Template"
    short-object-type="Template"
    :load="load"
    :items="templates"
    @add="add"
  >
    <template #list-item="{ item: t }">
      <mosaic-list-item
        :key="t.id"
        :title="t.name"
        icon="mosaic-go-react"
        :to="{ name: 'InstitutionGoReactTemplateEditPage', params: { id: t.id } }"
      />
    </template>
  </mosaic-filter-list-page>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import type { GoReactTemplateResponse } from './go-react-template';
import { useApi } from '@/composables/api';
import { useInstitutionStore } from '@/stores/institution';
import { setBreadcrumbs } from '@/utils/breadcrumbs';

const api = useApi();
const { selectedInstitution } = useInstitutionStore();

const templates = ref<GoReactTemplateResponse[]>([]);
async function load() {
  const r = await api.get<GoReactTemplateResponse[]>(
    `/institutions/${selectedInstitution.value.id}/go-react-templates`
  );
  templates.value = r.data;
}

const router = useRouter();
function add() {
  router.push({
    name: 'InstitutionGoReactTemplateCreatePage',
  });
}

// #region breadcrumbs
const breadcrumbs = [
  {
    text: 'GoReact Templates',
  },
];
setBreadcrumbs(breadcrumbs);
// #endregion
</script>
