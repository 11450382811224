import { defineStore } from 'pinia';
import { mapState } from '@/store/map-store';
import { useStore } from './common';

export const useInstitutionStore = useStore(
  defineStore('institution', () => {
    const { selectedInstitution } = mapState();

    function reset() {}

    return { reset, selectedInstitution };
  })
);
