import type { AxiosResponse, CancelToken } from 'axios';
import axios, { isAxiosError } from 'axios';
import { getAuthHeader, refreshAuthToken } from '@/utils/auth';
import type { App } from 'vue';
import router from '@/router/index';
import type { Api } from '@/composables/api';
import store from '@/store/index';
import { delay } from '@/utils/async';

async function makeRequest<T>(apiCall: () => Promise<AxiosResponse<T>>) {
  try {
    return await apiCall();
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status == 401) {
      await startOrWaitForRefresh();
      return await apiCall();
    } else {
      throw error;
    }
  }
}

let refreshPromise: Promise<void> | null = null;
async function startOrWaitForRefresh() {
  if (refreshPromise) {
    await refreshPromise;
  } else {
    try {
      refreshPromise = refreshAuthToken(store);
      await refreshPromise;
    } catch (e) {
      console.log('Refreshing auth token failed', e);
      await router.push({ name: 'LogoutPage' });
    } finally {
      refreshPromise = null;
    }
  }
}

const defaultPollGetOptions = {
  maxAttempts: 30,
  waitInMs: 1000,
  retryOnErrorStatuses: [404],
  retryOnSuccess: () => false,
};

const api: Api = {
  get<T>(url: string, cancelToken?: CancelToken) {
    return makeRequest(() => axios.get<T>(url, { headers: getAuthHeader(), cancelToken }));
  },
  async getAllPages<T extends { id: number }>(url: string, pageSize?: number, cancelToken?: CancelToken): Promise<T[]> {
    let offset = 0;
    let firstId = -1;
    let data: T[] = [];

    while (true) {
      const r = await api.get<T[]>(`${url}?offset=${offset}${pageSize ? `&limit=${pageSize}` : ''}`, cancelToken);
      if (r.data.length === 0) break;
      // Hopefully redundant check to avoid infinite loops
      if (firstId === r.data[0].id) break;
      firstId = r.data[0].id;

      offset += r.data.length;
      data = [...data, ...r.data];

      if (pageSize && r.data.length < pageSize) break;
    }

    return data;
  },
  getWithoutAuth<T>(url: string, cancelToken?: CancelToken) {
    return axios.get<T>(url, { cancelToken });
  },
  async pollGet<T>(
    url: string,
    options: {
      cancelToken?: CancelToken;
      maxAttempts?: number;
      waitInMs?: number;
      retryOnErrorStatuses?: number[];
      retryOnSuccess?: (response: AxiosResponse<T, unknown>) => boolean;
    } = {}
  ) {
    const maxAttempts = options.maxAttempts || defaultPollGetOptions.maxAttempts;
    const waitInMs = options.waitInMs || defaultPollGetOptions.waitInMs;
    const retryOnErrorStatuses = options.retryOnErrorStatuses || defaultPollGetOptions.retryOnErrorStatuses;
    const retryOnSuccess = options.retryOnSuccess || defaultPollGetOptions.retryOnSuccess;
    let attempts = 0;

    while (attempts < maxAttempts) {
      try {
        const r = await this.get<T>(url, options.cancelToken);
        if (retryOnSuccess(r)) {
          await delay(waitInMs);
        } else {
          return r;
        }
      } catch (e) {
        if (isAxiosError(e)) {
          if (e.response?.status && retryOnErrorStatuses.includes(e.response?.status)) {
            await delay(waitInMs);
          } else {
            throw e;
          }
        }
      }

      attempts += 1;
    }

    throw 'Polling timed out';
  },
  post<Request, Response>(url: string, body: Request, cancelToken?: CancelToken) {
    return makeRequest(() => axios.post<Response>(url, body, { headers: getAuthHeader(), cancelToken }));
  },
  put<Request, Response>(url: string, body: Request, contentType?: string) {
    const headers: Record<string, string> = getAuthHeader();
    if (contentType) {
      headers['Content-Type'] = contentType;
    }
    return makeRequest(() => axios.put<Response>(url, body, { headers }));
  },
  putFile<T>(url: string, file: File, contentType: string) {
    return makeRequest(() => axios.put<T>(url, file, { headers: { 'Content-Type': contentType } }));
  },
  delete<T>(url: string) {
    return makeRequest(() => axios.delete<T>(url, { headers: getAuthHeader() }));
  },
  async downloadAndOpen(url: string) {
    const response = await makeRequest(() => axios.get(url, { headers: getAuthHeader(), responseType: 'blob' }));
    window.open(URL.createObjectURL(response.data));
  },
};

export function installApiPlugin(app: App) {
  app.config.globalProperties.$api = api;
}

export default api;
