import moment from 'moment';
import { mapGetters } from 'vuex';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';
import {
  enumerateItems,
  lowercaseFirstLetter,
  initialiseText,
  capitaliseFirstLetters,
  capitaliseFirstLetter,
  pluralise,
  pluraliseItemsName,
} from '@/utils/text';
import {
  fromNowOrNow,
  isSameOrAfter,
  dateIsInclusiveBetween,
  todayFallsWithin,
  isOverdue,
  formatDateTime,
} from '@/utils/date';
import { formatTime, formatDuration } from '@/utils/time';
import { DateTime } from 'luxon';

export function hasPermission(staff, permission) {
  if (!staff) return false;
  return staff.staff_roles.some(
    x =>
      x.role.permissions.map(x => x.name).includes(permission) &&
      x.institution_id &&
      x.institution_id === staff.institution.id // Don't think this check is strictly necessary
  );
}

export { fromNowOrNow, isSameOrAfter, dateIsInclusiveBetween, todayFallsWithin, isOverdue };

export function hasPermissionAcrossAllInstitutions(user, permission) {
  return user.staff.some(s => hasPermission(s, permission));
}

export function hasPermissionForCohort(staff, permission, cohort) {
  if (!staff || !cohort) return false;
  return staff.staff_roles.some(
    x =>
      x.role.permissions.map(x => x.name).includes(permission) &&
      // Checking staff_role, staff and cohort are in the same inst isn't strictly necessary, but safer
      ((x.institution_id &&
        x.institution_id === staff.institution.id &&
        x.institution_id === (cohort.institution_id || cohort.institution?.id)) ||
        (x.cohort_id && x.cohort_id === cohort.id))
  );
}

export function hasPermissionForSchool(staff, permission, school) {
  if (!staff || !school) return false;
  return staff.staff_roles.some(
    x =>
      x.role.permissions.map(x => x.name).includes(permission) &&
      // Checking staff_role, staff and school are in the same inst isn't strictly necessary, but safer
      ((x.institution_id &&
        x.institution_id === staff.institution.id &&
        x.institution_id === (school.institution_id || school.institution?.id || school.institutionId)) ||
        (x.school_id && x.school_id === school.id))
  );
}

export function hasPermissionForStudent(staff, permission, student) {
  if (!staff || !student) return false;
  const camelStaff = fromSnakeCaseToCamelCase(staff);
  const camelStudent = fromSnakeCaseToCamelCase(student);
  return camelStaff.staffRoles.some(x => {
    const camelStaffRoleSchoolId = x.schoolId || x.school_id;
    return (
      x.role.permissions.map(x => x.name).includes(permission) &&
      (x.studentId === camelStudent.id ||
        // Checking staff_role, staff and student are in the same inst isn't strictly necessary, but safer
        (x.institutionId &&
          x.institutionId === staff.institution.id &&
          x.institutionId === (camelStudent.institutionId || camelStudent.institution?.id)) ||
        (x.cohortId && x.cohortId === (camelStudent.cohort?.id || camelStudent.cohortId)) ||
        (camelStudent.ect && camelStaffRoleSchoolId && camelStaffRoleSchoolId === camelStudent.ect.currentSchoolId))
    );
  });
}

export function formatDate(date) {
  if (!date) return '';
  return DateTime.fromISO(date).toFormat('dd/MM/y');
}

export function formatDateFromNow(date) {
  return moment.utc(date).fromNow();
}

export function todaysDate() {
  return moment().format('DD/MM/YYYY');
}

export function joinWithAnd(list) {
  if (list.length === 0) return '';
  if (list.length === 1) return list[0];
  const last = list[list.length - 1];
  const rest = list.slice(0, list.length - 1);
  return `${rest.join(', ')} and ${last}`;
}

const pagesThatManageOwnBreadcrumbs = [
  'LegacyStudentReviewOverallReviewPage',
  'LegacyStudentReviewRequirementsPage',
  'LegacyStudentReviewStandardPage',
  'LegacyTutorReviewOverallReviewPage',
  'LegacyTutorReviewRequirementsPage',
  'LegacyTutorReviewStandardPage',
];

export const globalMixin = {
  watch: {
    breadcrumbs: {
      handler() {
        if (
          this.$options.name?.endsWith('Page') &&
          this.breadcrumbs &&
          !pagesThatManageOwnBreadcrumbs.includes(this.$route.name)
        ) {
          this.$store.commit('setPageInformation', { breadcrumbs: this._createBreadcrumbs() });
        }
      },
      deep: true,
    },
  },
  data: () => ({
    originalRouteParams: {},
  }),
  mounted() {
    // A fix for a "Missing required param" error - details https://github.com/vuetifyjs/vuetify/issues/17176#issuecomment-1517843304
    // If that issue is fixed then this can probably be removed

    // This no longer works with the composition API
    // Not sure how much I like this, but will hopefully reduce mistakes/the need for pages to declare they don't use breadcrumbs
    if (this.$options.name?.endsWith('Page')) {
      this.originalRouteParams = { ...this.$route.params };

      if (this.breadcrumbs && !pagesThatManageOwnBreadcrumbs.includes(this.$options.name)) {
        this.$store.commit('setPageInformation', {
          breadcrumbs: this._createBreadcrumbs(),
        });
        // document.title set by MosaicBreadcrumbs
      } else if (this.breadcrumbs === undefined) {
        this.$store.commit('setPageInformation', { breadcrumbs: [] });
        try {
          const pageName = this.$route.name.replace('Page', '').replace('Student', '').replace('Tutor', '');
          const pageNameSplitOnCaps = pageName.split(/(?=[A-Z])/).join(' ');
          document.title = `Mosaic - ${pageNameSplitOnCaps}`;
        } catch (e) {
          document.title = 'Mosaic';
          throw e;
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'traineeNoun',
      'traineeNounCapitalisedAndPluralised',
      'traineeNounArticle',
      'traineeNounArticleCapitalised',
      'traineeNounCapitalised',
      'traineeNounPluralised',
      'reviewNoun',
      'reviewNounPluralised',
      'reviewNounCapitalised',
      'reviewNounCapitalisedAndPluralised',
      'standardNoun',
      'standardNounPluralised',
      'standardNounCapitalised',
      'standardNounCapitalisedAndPluralised',
      'cohortCourseTermNoun',
      'cohortCourseTermNounPluralised',
      'cohortCourseTermNounCapitalised',
      'cohortCourseTermNounCapitalisedAndPluralised',
      'cohortCourseWeekNoun',
      'cohortCourseWeekNounPluralised',
      'cohortCourseWeekNounCapitalised',
      'cohortCourseWeekNounCapitalisedAndPluralised',
      'curriculumNoun',
    ]),
    smallScreen() {
      return this.$vuetify.display.smAndDown;
    },
  },
  methods: {
    _createBreadcrumbs() {
      return this.breadcrumbs.map(b => ({
        ...b,
        to: b.to ? { ...b.to, params: { ...this.originalRouteParams, ...b.to.params } } : undefined,
      }));
    },
    hasPermission,
    userStaffHasPermission(...permissions) {
      return permissions.every(permission => this.hasPermission(this.$store.state.userStaff, permission));
    },
    userStaffHasAnyPermission(...permissions) {
      return permissions.some(permission => this.hasPermission(this.$store.state.userStaff, permission));
    },
    hasPermissionForStudent,
    userStaffHasPermissionForSelectedStudent(...permissions) {
      return permissions.every(permission =>
        this.hasPermissionForStudent(this.$store.state.userStaff, permission, this.$store.state.selectedStudent)
      );
    },
    userStaffHasAnyPermissionForSelectedStudent(...permissions) {
      return permissions.some(permission =>
        this.hasPermissionForStudent(this.$store.state.userStaff, permission, this.$store.state.selectedStudent)
      );
    },
    hasPermissionForCohort,
    userStaffHasPermissionForSelectedCohort(...permissions) {
      return permissions.every(permission =>
        this.hasPermissionForCohort(this.$store.state.userStaff, permission, this.$store.state.selectedCohort)
      );
    },
    userStaffHasAnyPermissionForSelectedCohort(...permissions) {
      return permissions.some(permission =>
        this.hasPermissionForCohort(this.$store.state.userStaff, permission, this.$store.state.selectedCohort)
      );
    },
    hasPermissionForSchool,
    userStaffHasPermissionForSelectedSchool(...permissions) {
      return permissions.every(permission =>
        this.hasPermissionForSchool(this.$store.state.userStaff, permission, this.$store.state.selectedSchool)
      );
    },
    userStaffHasAnyPermissionForSelectedSchool(...permissions) {
      return permissions.some(permission =>
        this.hasPermissionForSchool(this.$store.state.userStaff, permission, this.$store.state.selectedSchool)
      );
    },
    hasPermissionAcrossAllInstitutions,
    formatDate,
    formatDateFromNow,
    todaysDate,
    todayFallsWithin,
    dateIsInclusiveBetween,
    fromNowOrNow,
    pluraliseItemsName,
    enumerateItems,
    joinWithAnd,
    initialiseText,
    isSameOrAfter,
    isOverdue,
    capitaliseFirstLetter,
    capitaliseFirstLetters,
    lowercaseFirstLetter,
    pluralise,
    formatDateTime,
    formatTime,
    formatDuration,
  },
};
