<template>
  <div>
    <mosaic-multi-section-card
      :title="`${studentAssignment.assignment.name} ${versionNumber ? `(v${versionNumber})` : ''}`"
      :readonly="isReviewPage ? reviewPageReadonly : sectionPagesReadonly"
      :sections="sectionsWithCompletion"
      :section-id="sectionId"
      :can-save="canSave"
      :save="save"
      :used-with-configure-preview="preview"
      object-type="Course Activity"
      @update:section-id="emit('update:sectionId', $event)"
      @click-section="emit('click-section', $event)"
    >
      <template #title-chip>
        <mosaic-role-completed-chip
          class="pr-2"
          :completed="studentAssignment.status === 'completed' || studentAssignment.status === 'reviewed'"
          :role-id="studentAssignment.assignment.roleId || 'student'"
          :role-scoped-tooltip="(role: RoleWithStudent) => `This Activity is for ${role.pluralisedName}`"
        />
        <assignment-status-chip class="mr-2" :status="studentAssignment.status" />
        <assignment-grade-chip
          :status="studentAssignment.status"
          :grade="studentAssignment.reviewPageGradeJudgementDescriptor"
          class="mr-4"
        />
        <bud-submission-chip
          v-if="linkedBudActivity"
          class="mr-2"
          :bud-activity="linkedBudActivity"
          mosaic-object="Course Activity"
        />
      </template>
      <template #subtitle
        ><span v-if="studentAssignment.assignment.dueDate" class="mr-2"
          >Due {{ formatDate(studentAssignment.assignment.dueDate) }}</span
        ></template
      >
      <template #info>
        <div v-if="showRequiresChangesMessage" class="mt-4">
          <mosaic-info-alert>
            <mosaic-collapsable-text>
              <template #top-line>
                <div>{{ requiresChangesMessage }}</div>
              </template>
              <template #body>
                <div class="pt-2" style="white-space: pre-wrap">
                  <em>"{{ studentAssignment.previousVersionRequiredChanges }}"</em>
                </div>
              </template>
            </mosaic-collapsable-text>
          </mosaic-info-alert>
        </div>
      </template>

      <template #title-actions>
        <student-assignment-version-navigation
          v-if="versionNumber"
          :version-number="versionNumber"
          :is-latest-version="isLatestVersion"
          :previous-version-route="previousVersionRoute"
          :next-version-route="nextVersionRoute"
          :latest-version-route="latestVersionRoute"
        />
        <div v-if="showCompletionButton">
          <mosaic-disabled-tooltip
            :disabled="markAsCompleteDisabled"
            :loading="markAsCompleteProcessing"
            :tooltip="markAsCompleteDisabledTooltip"
          >
            <template #default="{ disabled }">
              <mosaic-btn :disabled="disabled" :loading="markAsCompleteProcessing" @click="markAsComplete()">
                {{ markAsCompleteDisplayText }}
              </mosaic-btn>
            </template>
          </mosaic-disabled-tooltip>

          <mosaic-error-snackbar v-model="markAsCompleteError" :action="markAsCompleteErrorAction" />
        </div>
        <div v-if="showRequiresChangesButton">
          <mosaic-disabled-tooltip
            :disabled="markAsRequiresChangesDisabled"
            :tooltip="markAsRequiresChangesDisabledTooltip"
          >
            <template #default="{ disabled }">
              <mosaic-btn :disabled="disabled" @click="requiresChanges()">
                {{ markAsRequiresChangesDisplayText }}
              </mosaic-btn>
            </template>
          </mosaic-disabled-tooltip>
        </div>
        <div v-if="showReviewButton">
          <mosaic-disabled-tooltip
            :disabled="markAsReviewedDisabled"
            :loading="markAsReviewedProcessing"
            :tooltip="markAsReviewedDisabledTooltip"
          >
            <template #default="{ disabled }">
              <mosaic-btn :disabled="disabled" @click="setReviewedStatus()" :loading="markAsReviewedProcessing">
                {{ markAsReviewedDisplayText }}
              </mosaic-btn>
            </template>
          </mosaic-disabled-tooltip>
          <mosaic-error-snackbar v-model="markAsReviewedError" :action="markAsReviewedErrorAction" />
        </div>
        <div v-if="showBudButton">
          <mosaic-disabled-tooltip :disabled="sendToBudDisabled" :tooltip="sendToBudDisabledTooltip">
            <template #default="{ disabled }">
              <mosaic-btn :disabled="disabled" @click="sendToBud()" :loading="budLearningPlanProcessing">
                <mosaic-icon icon="mosaic-bud" />
                <v-icon v-if="budLearningPlanLoadError" color="error" class="pt-1">mdi-close</v-icon>
                <span v-else class="pl-2">Send to Bud</span>
                <template #loader>
                  <span class="pr-2">Bud - Loading</span><v-progress-circular indeterminate color="white" />
                </template>
              </mosaic-btn>
            </template>
          </mosaic-disabled-tooltip>
        </div>
      </template>
      <div v-if="isReviewPage">
        <div v-if="studentAssignment.status === 'not_started' || studentAssignment.status === 'started'" class="pb-2">
          The "Review" Page is used to review the Course Activity.
          {{ canReview ? `It will be editable once this Course Activity is marked as complete.` : '' }}
        </div>
        <mosaic-alert v-if="studentAssignment.status === 'reviewed_requires_changes'" type="info" class="mt-2 mb-4">
          <div>This Activity has been marked as requiring changes with the following comment:</div>
          <div class="pt-2" style="white-space: pre-wrap">
            <em>"{{ studentAssignment.requiredChanges }}"</em>
          </div>
        </mosaic-alert>
        <div v-if="studentAssignment.assignment.assignmentTemplate.reviewPageGradeJudgementSet" class="d-flex pt-2">
          <mosaic-completion-label
            :can-edit="!reviewPageReadonly"
            :completed="!!reviewPageGradeJudgementDescriptorId"
            :show-when-readonly="false"
          >
            <mosaic-judgement-select
              v-model="reviewPageGradeJudgementDescriptorId"
              label="Grade"
              :always-show-label="true"
              :readonly="reviewPageReadonly"
              :items="
                studentAssignment.assignment.assignmentTemplate.reviewPageGradeJudgementSet
                  .institutionJudgementDescriptors
              "
            />
          </mosaic-completion-label>
        </div>
        <mosaic-content-layout
          v-model:tasks="reviewPageTasks"
          v-model:comments="reviewPageComments"
          v-model:files="reviewPageFiles"
          :readonly="reviewPageReadonly"
          :content-layout="studentAssignment.assignment.assignmentTemplate.reviewPageContentLayout"
          :get-resource-url-prefix="`/assignment-templates/review-page/resources`"
          :get-file-url-prefix="`/student-assignments/${studentAssignment.id}/review-page-files`"
          :upload-file-presign-url="fileUploadPresignUrl"
          :preview="preview"
          @files-uploading="reviewPageFilesUploading = $event"
        />
      </div>
      <div v-else-if="isCurriculumStatementsPage">
        <curriculum-links
          class="my-4"
          artefact-type="Course Activity"
          :selected-curriculum-statements="studentAssignment.curriculumStatements"
          :can-edit="false"
        >
        </curriculum-links>
      </div>

      <div v-else>
        <mosaic-content-layout
          v-if="section"
          v-model:tasks="tasks"
          v-model:comments="comments"
          v-model:files="files"
          :readonly="sectionPagesReadonly"
          :content-layout="section.contentLayout"
          :upload-file-presign-url="fileUploadPresignUrl"
          :get-file-url-prefix="`/student-assignments/sections/${
            studentAssignmentSection && studentAssignmentSection.id
          }/files`"
          :get-resource-url-prefix="`/assignment-templates/sections/resources`"
          :go-react-assignments="studentAssignment.assignment.goReactAssignments"
          :student-or-staff="preview ? { type: 'preview' } : { type: 'student', student: userOrSelectedStudent }"
          :preview="preview"
          @files-uploading="filesUploading = $event"
        />
      </div>

      <template
        v-if="!isReviewPage && !isCurriculumStatementsPage && !sectionContentLayoutIsCompletable"
        #beside-buttons
      >
        <mosaic-checkbox
          v-model="sectionManuallyCompleted"
          name="completed"
          :label="completionConfirmationLabel"
          :disabled="sectionPagesReadonly"
          dense
          no-icon
        />
      </template>
    </mosaic-multi-section-card>

    <mark-as-complete-prompt-dialog
      v-model:active="markAsCompleteDialogActive"
      title="Is This Course Activity Complete?"
      object-type="Course Activity"
      :mark-as-complete-api-call="markAsCompleteApiCall"
    >
      <p>You have filled in all the required elements of this Couse Activity, would you like to mark it as complete?</p>
      <div>This will indicate to your course leads that this Course Activity is ready to be assessed.</div>
    </mark-as-complete-prompt-dialog>
    <mosaic-save-dialog
      v-model:active="requiresChangesDialogActive"
      title="Mark Course Activity as Requires Changes"
      object-type="Course Activity"
      action="mark as requires changes"
      :save="markAsRequiresChangesApiCall"
      :can-save="!!requiredChanges"
    >
      <div>What changes are required for this Course Activity?</div>
      <mosaic-text-area
        v-model="requiredChanges"
        name="required-changes"
        label="Required Changes"
        variant="filled"
        no-icon
        class="pt-2"
      />
      <div>
        Marking this Course Activity as requiring changes will:
        <ul class="pt-2 pl-6">
          <li>create a new version for the {{ traineeNoun }} to update,</li>
          <li>preserve the original version for later review and</li>
          <li>notify the trainee that changes are required.</li>
        </ul>
      </div>
      <mosaic-alert v-if="reviewPageDirty" type="warning" class="mt-2">
        <div>
          You have unsaved Review Page changes. If you mark this Course Activity as requires changes, these changes will
          be lost.
        </div>
        <div class="pt-2">
          If you would like to save these changes, please click 'Cancel' and save the Review Page before marking this
          Course Activity as requiring changes.
        </div>
      </mosaic-alert>
    </mosaic-save-dialog>
    <mosaic-save-dialog
      v-model:active="undoRequiresChangesDialogActive"
      title="Undo Requires Changes?"
      object-type="Course Activity"
      action="undo requires changes"
      :save="() => undoRequiresChangesApiCall()"
    >
      <template v-if="undoRequiresChangesPenultimateOrLatest === 'penultimate'">
        <div>This Course Activity is currently marked as requiring changes. Would you like to undo this?</div>
        <div class="pt-2">
          This action will delete the latest version of the Activity, and remove the 'Requires Changes' notification
          that was created for the {{ traineeNoun }}.
        </div>
      </template>
      <template v-else>
        <div>
          This is the latest Version of a Course Activity that has been marked as requiring changes. As the
          {{ traineeNounCapitalisedAndPluralised }} has not yet started working on this Version, you can undo marking
          this Activity as requiring changes.
        </div>
        <div class="pt-2">
          This action will delete this latest version of the Activity, and remove the 'Requires Changes' notification
          that was created for the {{ traineeNoun }}.
        </div>
      </template>
      <div class="pt-2">Are you sure you would like to undo marking this Course Activity as requiring changes?</div>
    </mosaic-save-dialog>

    <mosaic-save-dialog
      v-model:active="markAsReviewedDialogActive"
      title="Has This Course Activity Been Reviewed?"
      object-type="Course Activity"
      action="mark as reviewed"
      :save="() => markAsReviewedApiCall(true)"
      close-text="Not now"
    >
      <div>
        You have filled in all the required elements of the Review page, would you like to mark this Course Activity as
        reviewed?
      </div>
    </mosaic-save-dialog>
    <mosaic-dialog
      v-model:active="sendToBudDialog.active"
      :error-message="sendToBudDialog.errorMessage"
      title="Send file to Bud Activity"
      :width="950"
    >
      <span class="text-caption">Course Activity File to send:</span>
      <mosaic-list-item :icon="icons.files" :title="fileForBud.title"> </mosaic-list-item>
      <mosaic-info-alert class="ml-6">
        Mosaic will attempt to send this file to the selected Bud Activity. You can only submit files to Bud Activities
        that are "In Progress". This means the Bud Activity must be:
        <div>
          <ul class="pt-2 pl-6">
            <li>be "Set" with a Due Date,</li>
            <li>not yet marked as complete, and</li>
            <li>not yet have any submissions.</li>
          </ul>
        </div>
      </mosaic-info-alert>
      <mosaic-alert v-if="submittableBudActivities.length === 0" type="warning" class="mt-4">
        There are no Bud Activities for this {{ traineeNoun }}, which can accept a file submission.
      </mosaic-alert>

      <mosaic-select
        class="pt-2"
        v-model="sendToBudDialog.selectedActivity"
        name="select-school"
        label="Select Bud Activity"
        :items="budActivityItems"
        item-value="id"
        item-title="name"
        no-data-text="No Bud Activities found"
        :prepend-icon="icons.budActivity"
        return-object
      >
        <template #item="{ item, props: itemProps }">
          <v-divider v-if="item.raw.divider" />

          <v-list-subheader v-else-if="item.raw.header">{{ item.raw.header }}</v-list-subheader>
          <div v-else class="d-flex align-center">
            <v-list-item v-bind="itemProps" title="" :disabled="item.raw.statusId !== 1" class="flex-grow-1">
              <div>
                {{ item.raw.name }}
              </div>
            </v-list-item>
            <mosaic-tooltip-chip :color="item.raw.statusId === 1 ? 'primary' : 'secondary'" class="pr-2">
              <template #text>{{ item.raw.initials }}</template>
              <template #tooltip>{{ item.raw.friendlyDescription }}</template>
            </mosaic-tooltip-chip>
          </div>
        </template>
      </mosaic-select>
      <mosaic-alert
        v-if="
          sendToBudDialog.selectedActivity &&
          studentAssignment.assignment.name !== sendToBudDialog.selectedActivity.name
        "
        type="warning"
        >The name of the Bud Activity you have selected does not match the Course Activity name. This may be expected,
        but please check you are submitting to the correct Bud Activity before clicking Send, as this action is not easy
        to undo.</mosaic-alert
      >
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="primary"
          :disabled="submitSendToBudDisabled"
          :loading="sendToBudDialog.processing"
          @click.prevent="submitSendToBud()"
        >
          Send
        </v-btn>
      </template>
    </mosaic-dialog>
  </div>
</template>

<script setup lang="ts">
import {
  createContentLayoutCompletionComments,
  createContentLayoutCompletionFiles,
  createContentLayoutCompletionTasks,
} from '@/utils/content-layout';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import BudSubmissionChip from '@/components/library/display/BudSubmissionChip.vue';
import type { Section } from '@/components/library/mosaic-card/MosaicMultiSectionCard.vue';
import MosaicMultiSectionCard from '@/components/library/mosaic-card/MosaicMultiSectionCard.vue';
import { computed, ref, watchEffect } from 'vue';
import type { StudentAssignment } from './student-assignments';
import AssignmentStatusChip from './AssignmentStatusChip.vue';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import AssignmentGradeChip from './AssignmentGradeChip.vue';
import { watch, nextTick } from 'vue';
import { until } from '@vueuse/core';
import MosaicCompletionLabel from '@/components/MosaicCompletionLabel.vue';
import StudentAssignmentVersionNavigation from './StudentAssignmentVersionNavigation.vue';
import { useApi } from '@/composables/api';
import { mapGetters, mapState } from '@/store/map-store';
import { useCurriculumStore } from '@/stores/curriculum';
import { useStudentStore } from '@/stores/student';
import MarkAsCompletePromptDialog from '@/components/library/has-status/MarkAsCompletePromptDialog.vue';
import type { RoleWithStudent } from '@/store/map-store';
import { type BudActivity, budActivityStatuses } from '@/utils/bud';
import type { BudLearningPlanResponse } from '@/types/responses';
import { icons } from '@/utils/icons';
import { executeMosaicJob } from '@/utils/mosaic-job';

const { traineeNoun } = useStudentStore();
const { selectedInstitution } = mapState();
const { selectedStudent, userOrSelectedStudent } = useStudentStore();

const api = useApi();

const props = defineProps<{
  studentAssignment: StudentAssignment;
  versionNumber?: number | null;
  latestVersionNumber?: number | null;
  routingData?: { namePrefix: string; params: Record<string, string> };
  sectionId: number;
  canEditAssignment: boolean;
  canReview: boolean;
  canMarkAsReviewed: boolean;
  preview?: boolean;
  fileUploadPresignUrl: string;
  budLearningPlan?: BudLearningPlanResponse | null;
  budLearningPlanProcessing?: boolean;
  budLearningPlanLoadError?: string;
}>();

const emit = defineEmits<{
  (e: 'click-section', sectionId: number): void;
  (e: 'update:sectionId', sectionId: number): void;
  (e: 'update:dirty', dirty: boolean): void;
  (e: 'update:studentAssignment', studentAssignment: StudentAssignment): void;
}>();

const createVersionRoute = (versionNumber: number | null, latestVersionNumber: number | null) => {
  if (versionNumber === null || latestVersionNumber === null || !props.routingData) {
    return null;
  }
  let params = {
    ...props.routingData.params,
  };
  if (versionNumber) {
    params = {
      ...params,
      versionNumber: versionNumber.toString(),
    };
  }
  const route = {
    name: `${props.routingData.namePrefix}Assignment${versionNumber == latestVersionNumber ? 'LatestVersion' : ''}Page`,
    params: params,
  };

  return route;
};

const isLatestVersion = computed(() => !!props.versionNumber && props.versionNumber === props.latestVersionNumber);

const nextVersionRoute = computed(() => {
  if (!props.versionNumber || !props.latestVersionNumber || isLatestVersion.value) {
    return null;
  }
  const nextVersionNumber = isLatestVersion.value ? props.latestVersionNumber : props.versionNumber + 1;
  return createVersionRoute(nextVersionNumber, props.latestVersionNumber);
});

const previousVersionRoute = computed(() => {
  if (!props.versionNumber || !props.latestVersionNumber || props.versionNumber === 1) {
    return null;
  }
  return createVersionRoute(props.versionNumber - 1, props.latestVersionNumber);
});

const latestVersionRoute = computed(() => {
  if (!props.versionNumber || !props.latestVersionNumber || isLatestVersion.value) {
    return null;
  }
  return createVersionRoute(props.latestVersionNumber, props.latestVersionNumber);
});

// creating full routes with query (rather than just progammatically updating the query
// to allow for use with :to in router-link

const isReviewPage = computed(() => props.sectionId === -1);
const isCurriculumStatementsPage = computed(() => props.sectionId === -2);
const sectionPagesReadonly = computed(
  () =>
    !props.canEditAssignment ||
    ['completed', 'reviewed', 'reviewed_requires_changes'].includes(props.studentAssignment.status)
);
const showReviewPage = computed(
  () =>
    props.canReview ||
    props.canMarkAsReviewed ||
    props.studentAssignment.status === 'reviewed' ||
    props.studentAssignment.status === 'reviewed_requires_changes'
);
const reviewPageReadonly = computed(() => !props.canReview || props.studentAssignment.status !== 'completed');

const completable = computed(() => props.studentAssignment.studentAssignmentSections.every(sas => sas.completed));
const { curriculumEnabled } = mapGetters();
const { curriculumStatementNounPluralised } = useCurriculumStore();

const sectionsWithCompletion = computed(() => {
  const sections: Section[] = props.studentAssignment.assignment.assignmentTemplate.assignmentTemplateSections.map(
    s => ({
      ...s,
      completed:
        props.studentAssignment.studentAssignmentSections.find(sas => sas.assignmentTemplateSectionId === s.id)
          ?.completed || false,
      readonly: sectionPagesReadonly.value,
      disabled: false,
    })
  );

  const completed =
    (!props.studentAssignment.assignment.assignmentTemplate.reviewPageGradeJudgementSet ||
      !!reviewPageGradeJudgementDescriptorId.value) &&
    reviewPageComments.value.every(c => c.comment) &&
    reviewPageTasks.value.every(t => t.completed);
  sections.push({
    id: -1,
    name: 'Review',
    order: props.studentAssignment.studentAssignmentSections.length,
    completed,
    readonly: reviewPageReadonly.value,
    disabled: !showReviewPage.value,
    disabledTooltip: 'This Activity has not yet been Reviewed',
  });
  if (!props.preview && curriculumEnabled.value) {
    sections.push({
      id: -2,
      name: curriculumStatementNounPluralised.value,
      order: sections.length,
      isMetaSection: true,
    });
  }

  return sections;
});

const section = computed(() =>
  props.studentAssignment.assignment.assignmentTemplate.assignmentTemplateSections.find(s => s.id === props.sectionId)
);

const studentAssignmentSection = computed(() =>
  props.studentAssignment.studentAssignmentSections.find(s => s.assignmentTemplateSectionId === props.sectionId)
);

const { comments, commentsDirty } = createContentLayoutCompletionComments(
  computed(() => studentAssignmentSection.value?.studentAssignmentSectionComments || []),
  c => c.assignmentTemplateSectionCommentId
);
const { tasks, tasksDirty } = createContentLayoutCompletionTasks(
  computed(() => studentAssignmentSection.value?.studentAssignmentSectionTasks || []),
  t => t.assignmentTemplateSectionTaskId
);
const { files, filesDirty } = createContentLayoutCompletionFiles(
  computed(() => studentAssignmentSection.value?.studentAssignmentSectionFiles || []),
  f => f.assignmentTemplateSectionFileUploadId
);

const sectionContentLayoutIsCompletable = computed(
  () =>
    comments.value.length > 0 ||
    tasks.value.length > 0 ||
    section.value?.contentLayout.sections.some(s => s.items.some(i => i.itemType === 'fileUpload'))
);
const sectionManuallyCompleted = ref(studentAssignmentSection.value?.completed || false);
watch(
  () => studentAssignmentSection.value,
  sas => {
    if (sas) {
      sectionManuallyCompleted.value = sas.completed;
    }
  }
);

const { comments: reviewPageComments, commentsDirty: reviewPageCommentsDirty } = createContentLayoutCompletionComments(
  computed(() => props.studentAssignment.studentAssignmentReviewPageComments),
  c => c.assignmentTemplateReviewPageCommentId
);
const { tasks: reviewPageTasks, tasksDirty: reviewPageTasksDirty } = createContentLayoutCompletionTasks(
  computed(() => props.studentAssignment.studentAssignmentReviewPageTasks),
  c => c.assignmentTemplateReviewPageTaskId
);
const { files: reviewPageFiles, filesDirty: reviewPageFilesDirty } = createContentLayoutCompletionFiles(
  computed(() => props.studentAssignment.studentAssignmentReviewPageFiles),
  c => c.assignmentTemplateReviewPageFileUploadId
);

const reviewPageGradeJudgementDescriptorId = ref<number | null>(null);
watchEffect(
  () =>
    (reviewPageGradeJudgementDescriptorId.value =
      props.studentAssignment.reviewPageGradeJudgementDescriptor?.id || null)
);

const reviewPageFilesUploading = ref(false);
const reviewPageDirty = computed(
  () =>
    reviewPageCommentsDirty.value ||
    reviewPageTasksDirty.value ||
    reviewPageFilesDirty.value ||
    reviewPageFilesUploading.value ||
    reviewPageGradeJudgementDescriptorId.value !==
      (props.studentAssignment.reviewPageGradeJudgementDescriptor?.id || null)
);
const filesUploading = ref(false);
const dirty = computed(() => {
  if (isCurriculumStatementsPage.value) return false;

  if (!isReviewPage.value) {
    return (
      filesUploading.value ||
      commentsDirty.value ||
      tasksDirty.value ||
      filesDirty.value ||
      sectionManuallyCompleted.value !== studentAssignmentSection.value?.completed
    );
  } else {
    return reviewPageDirty.value;
  }
});
watchEffect(() => emit('update:dirty', dirty.value));

const canSave = computed(() => dirty.value && !filesUploading.value && !reviewPageFilesUploading.value);

const completionConfirmationLabel = computed(
  () =>
    `I confirm I have read all of the information on this page${
      props.studentAssignment.assignment.assignmentTemplate.hasGoReactAssignments
        ? ` and have completed all GoReact Assignments`
        : ''
    }`
);

async function save(): Promise<void> {
  if (!isReviewPage.value) {
    const body = sectionContentLayoutIsCompletable.value
      ? {
          comments: comments.value.map(c => ({
            comment: c.comment,
            assignmentTemplateSectionCommentId: c.templateId,
          })),
          tasks: tasks.value.map(t => ({
            completed: t.completed,
            assignmentTemplateSectionTaskId: t.templateId,
          })),
          files: files.value.map(f => ({
            fileId: f.fileId,
            title: f.title,
            assignmentTemplateSectionFileUploadId: f.templateId,
          })),
        }
      : { completed: sectionManuallyCompleted.value };
    const r = await api.put<unknown, StudentAssignment>(
      `/student-assignments/sections/${studentAssignmentSection.value!.id}`,
      body
    );
    emit('update:studentAssignment', r.data);

    await nextTick();
    if (completable.value) {
      markAsCompleteDialogActive.value = true;
    }
    await until(markAsCompleteDialogActive).toBe(false);
  } else {
    const r = await api.put<unknown, StudentAssignment>(
      `/student-assignments/${props.studentAssignment.id}/review-page`,
      {
        comments: reviewPageComments.value.map(c => ({
          comment: c.comment,
          assignmentTemplateReviewPageCommentId: c.templateId,
        })),
        tasks: reviewPageTasks.value.map(t => ({
          completed: t.completed,
          assignmentTemplateReviewPageTaskId: t.templateId,
        })),
        files: reviewPageFiles.value.map(f => ({
          fileId: f.fileId,
          title: f.title,
          assignmentTemplateReviewPageFileUploadId: f.templateId,
        })),
        reviewPageGradeJudgementDescriptorId: reviewPageGradeJudgementDescriptorId.value,
      }
    );
    emit('update:studentAssignment', r.data);

    await nextTick();
    if (props.studentAssignment.reviewPageCompleted && props.canMarkAsReviewed) {
      markAsReviewedDialogActive.value = true;
    }
    await until(markAsReviewedDialogActive).toBe(false);
  }
}

//#region Mark as complete

const {
  action: markAsComplete,
  processing: markAsCompleteProcessing,
  error: markAsCompleteError,
} = withProcessingAndError(markAsCompleteApiCall);

const markAsCompleteDialogActive = ref(false);
const markedAsComplete = computed(() => props.studentAssignment.status === 'completed');
const showCompletionButton = computed(
  () => props.canEditAssignment && ['not_started', 'started', 'completed'].includes(props.studentAssignment.status)
);
const markAsCompleteDisabled = computed(
  () =>
    markAsCompleteProcessing.value ||
    (!markedAsComplete.value && (!completable.value || props.studentAssignment.status === 'not_started'))
);
const markAsCompleteDisplayText = computed(() => {
  if (markedAsComplete.value) return 'Undo Mark as Complete';
  else return 'Mark as Complete';
});

const markAsCompleteDisabledTooltip = computed(() => {
  if (markAsCompleteProcessing.value) {
    return 'This action is currently processing.';
  }
  if (!markedAsComplete.value) {
    return `You must ${
      props.studentAssignment.status === 'not_started' ? 'update this version' : 'complete and save all Pages'
    } before being able to mark this Course Activity as complete.`;
  }
  return '';
});
const markAsCompleteErrorAction = `${
  markedAsComplete.value ? 'undo marking' : 'mark'
} this Course Activity as complete`;

async function markAsCompleteApiCall() {
  const completed = !markedAsComplete.value;
  const r = await api.post<unknown, StudentAssignment>(
    `/student-assignments/${props.studentAssignment.id}/mark-as-completed`,
    {
      completed,
    }
  );
  emit('update:studentAssignment', r.data);
}
//#endregion

//#region Mark as reviewed
async function markAsReviewedApiCall(reviewed: boolean) {
  const r = await api.post<unknown, StudentAssignment>(
    `/student-assignments/${props.studentAssignment.id}/mark-as-reviewed`,
    {
      reviewed,
    }
  );
  emit('update:studentAssignment', r.data);
}
const {
  action: markAsReviewed,
  processing: markAsReviewedProcessing,
  error: markAsReviewedError,
} = withProcessingAndError(markAsReviewedApiCall);

const markAsReviewedDialogActive = ref(false);

const setReviewedStatus = () => {
  markAsReviewed(props.studentAssignment.status === 'reviewed' ? false : true);
};

const showReviewButton = computed(
  () =>
    ['completed', 'reviewed'].includes(props.studentAssignment.status) && (props.canReview || props.canMarkAsReviewed)
);

const markAsReviewedDisabled = computed(
  () =>
    !props.canMarkAsReviewed ||
    markAsReviewedProcessing.value ||
    (!props.studentAssignment.reviewPageCompleted && props.studentAssignment.status !== 'reviewed')
);
const markAsReviewedDisabledTooltip = computed(() => {
  if (!props.canMarkAsReviewed) {
    return `You do not have permission to ${
      props.studentAssignment.status === 'reviewed' ? 'undo marking' : 'mark'
    } this Course Activity as reviewed`;
  }
  if (markAsReviewedProcessing.value) {
    return 'This action is currently processing';
  }
  if (!props.studentAssignment.reviewPageCompleted && props.canReview) {
    return 'You must complete and save the Review Page before being able to mark this Course Activity as reviewed';
  }
  if (!props.studentAssignment.reviewPageCompleted && !props.canReview) {
    return 'The Review Page must be completed before you can mark this Course Activity as reviewed';
  }
  return '';
});

const markAsReviewedDisplayText = computed(() =>
  props.studentAssignment.status === 'reviewed' ? 'Undo Mark as Reviewed' : 'Mark as Reviewed'
);
const markAsReviewedErrorAction = `${
  props.studentAssignment.status === 'reviewed' ? 'undo marking' : 'mark'
} this Course Activity as reviewed`;

//#endregion

//#region Mark as Requires Changes

const requiresChangesDialogActive = ref(false);

const requiredChanges = ref('');
async function markAsRequiresChangesApiCall() {
  const r = await api.post<unknown, StudentAssignment>(
    `/student-assignments/${props.studentAssignment.id}/mark-as-requires-changes`,
    {
      requiredChanges: requiredChanges.value,
    }
  );
  emit('update:studentAssignment', r.data);
}

const undoRequiresChangesDialogActive = ref(false);
const undoRequiresChangesPenultimateOrLatest = ref('');
async function undoRequiresChangesApiCall() {
  const r = await api.post<Record<string, never>, StudentAssignment>(
    `/students/${props.studentAssignment.studentId}/assignments/${props.studentAssignment.assignment.id}/undo-requires-changes`,
    {}
  );
  emit('update:studentAssignment', r.data);
}

const showRequiresChangesButton = computed(
  () =>
    (props.canReview || props.canMarkAsReviewed) &&
    (props.studentAssignment.status === 'completed' || undoMarkedAsRequiresChanges.value)
);

const undoMarkedAsRequiresChanges = computed(
  () =>
    (props.studentAssignment.status === 'reviewed_requires_changes' &&
      props.studentAssignment.nextVersionStatus === 'not_started') ||
    (props.studentAssignment.status === 'not_started' && isLatestVersion.value)
);
const markAsRequiresChangesDisplayText = computed(() =>
  undoMarkedAsRequiresChanges.value ? 'Undo Requires Changes' : 'Requires Changes'
);

const markAsRequiresChangesDisabled = computed(() => !props.canMarkAsReviewed);
const markAsRequiresChangesDisabledTooltip = computed(() => {
  return `You do not have permission to ${
    undoMarkedAsRequiresChanges.value ? 'undo marking' : 'mark'
  } this Course Activity as requires changes`;
});
const requiresChanges = () => {
  if (undoMarkedAsRequiresChanges.value) {
    undoRequiresChangesPenultimateOrLatest.value = isLatestVersion.value ? 'latest' : 'penultimate';
    undoRequiresChangesDialogActive.value = true;
  } else {
    requiresChangesDialogActive.value = true;
  }
};

const showRequiresChangesMessage = computed(
  () => props.versionNumber && previousVersionRoute.value && isLatestVersion.value
);

const requiresChangesMessage = computed(() => {
  if (props.studentAssignment.status === 'not_started' || props.studentAssignment.status === 'started') {
    return 'This Activity requires changes:';
  }
  if (props.studentAssignment.status === 'completed') {
    return `This Activity was marked as requires changes, but has since been marked as complete by the ${traineeNoun.value}. The required changes were:`;
  } else return `This Activity was marked as requires changes, but has since been reviewed. The required changes were:`;
});

//#endregion

//#region Send to Bud

const studentFileUploads = computed(() =>
  props.studentAssignment.studentAssignmentSections.flatMap(sas => sas.studentAssignmentSectionFiles)
);

const hasSingleFile = computed(() => studentFileUploads.value.length === 1);
const fileForBud = computed(() => studentFileUploads.value[0] || undefined);

const showBudButton = computed(() => selectedInstitution.value?.config.use_bud_integration && props.canMarkAsReviewed);

const sendToBudDisabled = computed(
  () =>
    !selectedStudent.value?.bud_reference_number ||
    !selectedStudent.value?.bud_dob ||
    !hasSingleFile.value ||
    !!props.studentAssignment.budSubmission ||
    props.budLearningPlanProcessing ||
    props.budLearningPlanLoadError ||
    !['completed', 'reviewed'].includes(props.studentAssignment.status)
);
const sendToBudDisabledTooltip = computed(() => {
  if (!selectedStudent.value?.bud_reference_number || !selectedStudent.value?.bud_dob) {
    return `You must add a BUD reference number and date of birth to the ${traineeNoun.value} Details before you can send this Course Activity to BUD`;
  }
  if (props.budLearningPlanLoadError) {
    return props.budLearningPlanLoadError;
  }
  if (props.budLearningPlanProcessing) {
    return 'This action is currently processing';
  }
  if (!hasSingleFile.value) {
    return 'Only Activities with a single file upload can be sent to BUD';
  }
  if (props.studentAssignment.budSubmission) {
    return 'A file has already been sent to Bud for this Course Activity';
  }
  if (!['completed', 'reviewed'].includes(props.studentAssignment.status)) {
    return 'This Course Activity must be marked as completed or reviewed before a File submission can be sent to Bud';
  }
  return '';
});

// Nicer to not show a button, but instead show some submitted state (and differentiate for case where not completed)

const sendToBudDialog = ref<{
  active: boolean;
  processing: boolean;
  errorMessage: string;
  selectedActivity: BudActivity | null;
  selectedLearningPlanId: string | null;
}>({
  active: false,
  processing: false,
  errorMessage: '',
  selectedActivity: null,
  selectedLearningPlanId: null,
});

const sendToBud = () => {
  sendToBudDialog.value.errorMessage = '';
  sendToBudDialog.value.active = true;
};

const submitSendToBudDisabled = computed(() => {
  return sendToBudDialog.value.processing || !sendToBudDialog.value.selectedActivity;
});

// map activites

const mappedBudActivities = computed(
  () =>
    props.budLearningPlan?.activities
      .map(activity => {
        return {
          id: activity.id,
          name: activity.name,
          isExempt: activity.isExempt,
          ...(budActivityStatuses.find(status => status.statusId === activity.statusId) || {
            statusId: 'not-found',
            statusName: 'Not Found',
          }),
        };
      })
      .sortBy('sortOrder') || []
);

const linkedBudActivity = computed(() => {
  if (mappedBudActivities.value.length === 0 || !props.studentAssignment.budSubmission) return null;
  return (
    mappedBudActivities.value.find(activity => activity.id === props.studentAssignment.budSubmission?.budActivityId) ||
    null
  );
});

const submittableBudActivities = computed(() => mappedBudActivities.value.filter(activity => activity?.statusId === 1));
const notSubmittableBudActivities = computed(() =>
  mappedBudActivities.value.filter(activity => activity?.statusId !== 1)
);

const budActivityItems = computed(() => {
  return [
    { id: 0, name: 'header', header: 'Available for submission' },
    ...submittableBudActivities.value,
    {
      id: 0,
      name: 'divider',
      divider: true,
    },
    { id: 0, name: 'header', header: 'Not available for submission' },
    ...notSubmittableBudActivities.value,
  ];
});

type PollSuccessData = {
  student_assignment: StudentAssignment;
};

const submitSendToBud = async () => {
  sendToBudDialog.value.processing = true;
  sendToBudDialog.value.errorMessage = '';
  if (!sendToBudDialog.value.selectedActivity || !props.budLearningPlan?.id) return;
  await executeMosaicJob<PollSuccessData>(
    api,
    'submit to file to Bud',
    `/student-assignments/${props.studentAssignment.id}/send-to-bud`,
    {
      budActivityId: sendToBudDialog.value.selectedActivity.id,
      budLearningPlanId: props.budLearningPlan.id,
      fileId: fileForBud.value?.fileId,
      templateSectionFileUploadId: fileForBud.value?.assignmentTemplateSectionFileUploadId,
      fileName: fileForBud.value?.title,
    },
    (resultData: PollSuccessData) => {
      emit('update:studentAssignment', resultData.student_assignment);
      sendToBudDialog.value.active = false;
    },
    (e: { errorCode: string; errorMessage: string }) => {
      console.log(e);
      const error_code = e.errorCode;
      if (error_code === 'bud_file_submission_error') {
        sendToBudDialog.value.errorMessage =
          'Sorry, could not submit file to Bud. Please check that the Bud Activity is still In Progress and try again.';
      } else if (error_code === 'bud_activity_completion_error') {
        sendToBudDialog.value.errorMessage =
          'The file has been submitted to the Bud Activity. However, there was an error marking the Bud Activity as complete. This will need to be manually done via Bud.';
      } else sendToBudDialog.value.errorMessage = e.errorMessage;
    }
  );
  sendToBudDialog.value.processing = false;
};

//#endregion
</script>
