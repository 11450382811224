<template>
  <div>
    <v-card v-if="stateError && !stateProcessing">
      <v-card-text>
        <div class="pa-4">Sorry, cannot load your school at the moment</div>
      </v-card-text>
    </v-card>
    <div v-if="!stateError && !stateProcessing">
      <v-card class="mb-4">
        <v-card-text>
          <mosaic-card-title>
            Instructors
            <template #actions>
              <v-btn v-if="userStaffHasPermission('staff.edit')" ripple @click.prevent="addStaff">
                <div class="d-flex align-center">
                  <v-icon>mdi-plus</v-icon>
                  <span>Instructor</span>
                </div>
              </v-btn>
            </template>
          </mosaic-card-title>
          <div class="d-flex align-center">
            <div>
              <v-text-field
                v-model="nameEmailFilter"
                density="compact"
                hide-details
                style="width: 350px"
                label="Filter instructors by name or email"
                prepend-icon="mdi-magnify"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-text>
          <mosaic-list
            :items="filteredAndSortedStaff"
            empty-text="There are no Instructors associated with this School"
          >
            <template #item="{ item: s }">
              <mosaic-list-item
                :to="userStaffHasPermission('staff.edit') ? staffTo(s) : null"
                icon="mdi-human-male-board"
                :title="s.name || s.email"
                :subtitle="s.roleNames.join(', ')"
              >
                <template #actions v-if="userStaffHasPermission('staff.edit')">
                  <ndt-icon-button
                    icon="delete"
                    tooltip="Remove from School"
                    @click.prevent="removeStaffFromSchool(s)"
                  />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </v-card-text>
      </v-card>
    </div>

    <ndt-dialog
      v-model:active="addStaffDialog.active"
      title="Add Instructors to School"
      :error-message="addStaffDialog.error"
    >
      <v-alert v-if="schoolRoles.length === 0" type="warning" density="compact">
        There are no roles that can be applied at the school level. Please get in touch with Mosaic support if you think
        there should be.
      </v-alert>
      <template v-else>
        <v-autocomplete
          v-model="addStaffDialog.staffIds"
          name="add-staff"
          chips
          multiple
          label="Instructors"
          :items="autocompleteStaff"
          item-title="nameOrEmail"
          item-value="id"
          no-data-text="No Instructors found"
          prepend-inner-icon="mdi-magnify"
          color="black"
          :custom-filter="filterStudentsAndStaff"
        ></v-autocomplete>
        <v-select
          id="role"
          v-model="addStaffDialog.roleId"
          prepend-icon="mdi-account-multiple-check"
          :items="schoolRoles"
          item-title="name"
          item-value="id"
          placeholder="Select Role"
        ></v-select>
      </template>
      <template #buttons>
        <v-btn
          v-if="schoolRoles.length > 0"
          variant="text"
          ripple
          :disabled="!canSubmitAddStaff"
          @click.prevent="submitAddStaff"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="removeStaffDialog.active"
      title="Remove Instructor from School"
      :error-message="removeStaffDialog.error"
    >
      <div>Are you sure you want to remove "{{ removeStaffDialog.name }}" from this school?</div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="removeStaffDialog.processing"
          @click.prevent="submitRemoveStaff()"
        >
          Delete
        </v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapStateError, mapStateProcessing } from '@/store/map-store';
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'TutorSchoolStaffPage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [syncQueryParamsMixin({ nameEmailFilter: { query: 'name' } })],
  components: { NdtIconButton, NdtDialog },
  data: () => ({
    schoolId: '',
    students: [],
    staff: [],
    institutionStaff: [],
    schoolRoles: [],
    school: null,
    nameEmailFilter: '',
    addStaffDialog: {
      active: false,
      processing: false,
      error: null,
      staffIds: [],
      roleId: null,
    },
    removeStaffDialog: {
      active: false,
      processing: false,
      error: null,
      name: null,
      id: null,
    },
  }),
  computed: {
    ...mapState(['selectedInstitution', 'selectedSchool']),
    ...mapStateProcessing(['loadSchool']),
    ...mapStateError(['loadSchool']),
    breadcrumbs() {
      return [
        {
          text: 'Instructors',
        },
      ];
    },
    filteredAndSortedStaff() {
      return this.staff
        .filter(
          x =>
            x.name?.toLowerCase().includes(this.nameEmailFilter.toLowerCase()) ||
            x.email.toLowerCase().includes(this.nameEmailFilter.toLowerCase())
        )
        .sort((a, b) => {
          return (a.name || a.email) > (b.name || b.email) ? 1 : -1;
        });
    },
    canSubmitAddStaff() {
      return !this.addStaffDialog.processing && this.addStaffDialog.staffIds.length > 0 && this.addStaffDialog.roleId;
    },
    autocompleteStaff() {
      if (!this.institutionStaff) return [];
      const schoolStaffIds = this.staff.map(s => s.id);
      return this.institutionStaff
        .filter(x => !schoolStaffIds.includes(x.id))
        .map(x => ({
          ...x,
          nameOrEmail: x.name ? `${x.name} (${x.email})` : x.email,
        }));
    },
  },
  watch: {
    selectedSchool(school) {
      if (school) {
        this.updateSchoolDetails(school);
      }
    },
  },
  created() {
    this.schoolId = this.$route.params.schoolId;
    if (this.selectedSchool) {
      this.updateSchoolDetails(this.selectedSchool);
    }
    if (this.userStaffHasPermission('staff.edit')) {
      this.loadStaff();
      this.loadSchoolRoles();
    }
  },
  methods: {
    updateSchoolDetails(school) {
      this.staff = school.staffRoles.reduce((acc, x) => {
        const staff = acc.find(s => s.id === x.staff.id);
        if (staff) {
          staff.roleNames << x;
        } else {
          acc.push({ ...x.staff, roleNames: [x.role.name] });
        }
        return acc;
      }, []);
    },
    async loadStaff() {
      const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/staff`);
      this.institutionStaff = r.data.map(x => ({ ...x.user, ...x }));
    },
    async loadSchoolRoles() {
      const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/roles/school`);
      this.schoolRoles = r.data;
    },
    staffTo(s) {
      return { name: 'InstitutionTutorStaffPage', params: { id: s.id, institutionId: this.selectedInstitution.id } };
    },
    filterStudentsAndStaff(nameOrEmail, queryText) {
      const searchText = queryText.toLowerCase();
      return nameOrEmail.toLowerCase().includes(searchText);
    },
    addStaff() {
      this.addStaffDialog.active = true;
      if (this.schoolRoles.length === 1) {
        this.addStaffDialog.roleId = this.schoolRoles[0].id;
      }
    },
    async submitAddStaff() {
      this.addStaffDialog.processing = true;
      this.addStaffDialog.error = '';
      try {
        await this.$api.post(`/schools/${this.schoolId}/staff`, {
          staffIds: this.addStaffDialog.staffIds,
          roleId: this.addStaffDialog.roleId,
        });
        this.$store.dispatch('loadSchool', { schoolId: this.schoolId, force: true });
        this.addStaffDialog.active = false;
      } catch (e) {
        console.log(e);
        this.addStaffDialog.error = `Sorry, cannot add these instructors at the moment`;
      }
      this.addStaffDialog.processing = false;
    },
    removeStaffFromSchool(s) {
      this.removeStaffDialog.name = s.name || s.email;
      this.removeStaffDialog.id = s.id;
      this.removeStaffDialog.active = true;
    },
    async submitRemoveStaff() {
      this.removeStaffDialog.processing = true;
      this.removeStaffDialog.error = '';
      try {
        await this.$api.delete(`/schools/${this.schoolId}/staff/${this.removeStaffDialog.id}`);
        this.$store.dispatch('loadSchool', { schoolId: this.schoolId, force: true });
        this.removeStaffDialog.active = false;
      } catch (e) {
        console.log(e);
        this.removeStaffDialog.error = `Sorry, cannot remove this instructor at the moment`;
      }
      this.removeStaffDialog.processing = false;
    },
  },
};
</script>
