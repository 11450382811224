import { ref } from 'vue';

import type { ContentLayout } from '@/utils/content-layout';

export type NoPublishReason = 'no_pages' | 'page_has_no_content' | 'review_page_has_no_content' | null;

export function cannotPublishTooltip(noPublishReason: NoPublishReason) {
  const reason = mapNoPublishReasonToMessage(noPublishReason);
  if (!reason) return '';
  return `Cannot publish this Course Activity Template as ${reason}`;
}

export function mapNoPublishReasonToMessage(reason: NoPublishReason) {
  switch (reason) {
    case 'no_pages':
      return 'does not have any Pages';
    case 'page_has_no_content':
      return 'at least one Page does not have any content';
    case 'review_page_has_no_content':
      return 'the Review Page does not have any content';
  }
  return '';
}

type EditTemplateDialog = {
  active: boolean;
  template: AssignmentTemplateSlimResponse | null;
};

export function useEditAssignmentTemplate() {
  const editTemplateDialog = ref<EditTemplateDialog>({
    active: false,
    template: {
      id: 0,
      name: '',
      status: 'draft',
      noPublishReason: null,
      assignmentCount: 0,
      isTraineeContributor: false,
      roleId: null,
      hasGoReactAssignments: false,
    },
  });

  const editTemplate = (template: AssignmentTemplateSlimResponse | null) => {
    editTemplateDialog.value = {
      active: true,
      template: template || {
        id: 0,
        name: '',
        status: 'draft',
        noPublishReason: null,
        assignmentCount: 0,
        isTraineeContributor: false,
        roleId: null,
        hasGoReactAssignments: false,
      },
    };
  };
  return { editTemplateDialog, editTemplate };
}
interface AssignmentTemplateSection {
  id: number;
  name: string;
  order: number;
  contentLayout: ContentLayout;
}
export interface AssignmentTemplate {
  id: number;
  name: string;
  status: 'draft' | 'published';
  noPublishReason: NoPublishReason;
  assignmentCount: number;
  isTraineeContributor: boolean;
  hasGoReactAssignments: boolean;
  roleId: number | null;
  reviewPageContentLayout: ContentLayout;
  reviewPageGradeJudgementSetId: number | null;
  assignmentTemplateSections: AssignmentTemplateSection[];
}

export interface AssignmentTemplateSlimResponse {
  id: number;
  name: string;
  status: 'draft' | 'published';
  noPublishReason: NoPublishReason;
  assignmentCount: number;
  roleId: number | null;
  isTraineeContributor: boolean;
  hasGoReactAssignments: boolean;
}
