<template>
  <div>
    <cohort-action-edit-page
      action-noun="Instructor Announcements"
      :action-set-date="announcement.setDate"
      :action-scheduled-at-date="scheduledDate"
      :action-schedule-in-the-future="scheduleInTheFuture"
      assignee-noun="Instructor"
      assignee-noun-pluralised="Instructors"
      :load="load"
      :can-continue="canContinue"
      :can-save="canSave"
      :save="saveWithDialog"
      :return-to="returnTo"
      :dirty="dirty"
      :is-editing="isEditing"
    >
      <template #details-step>
        <mosaic-text-field v-model="title" prepend-icon="mdi-pencil" name="title" label="Title" />
        <mosaic-quill-field v-model="body" name="body" label="Contents" />
        <mosaic-text-field prepend-icon="mdi-account" name="from" label="From" :model-value="createdByName" readonly />
        <schedule-in-the-future-picker
          v-if="!isEditing || announcementStatus == 'scheduled'"
          v-model:schedule-in-the-future="scheduleInTheFuture"
          v-model:scheduled-date="scheduledDate"
        />
        <div v-if="isEmailVerificationOnForSelectedInstitution" class="d-flex align-center">
          <mosaic-checkbox
            v-model="sendEmail"
            prepend-icon="mdi-email"
            name="send-email"
            label="Notify by email"
            :disabled="announcementStatus === 'live'"
            density="compact"
          />
          <div class="ml-1">
            <mosaic-help>
              <span v-if="scheduleInTheFuture && announcementStatus != 'live'"
                >Emails will be sent at approximately 8am on the scheduled date</span
              >
              <span v-else>Emails will be sent within 10 minutes of Announcement creation</span>
            </mosaic-help>
          </div>
          <mosaic-count-chip
            v-if="sendEmail && newlySelectedCantReceiveEmailCount > 0"
            class="ml-2"
            color="accent"
            :count="newlySelectedCantReceiveEmailCount"
            :tooltip="`${enumerateItems(newlySelectedCantReceiveEmailCount, 'Instructor')} won't receive the email`"
          />
        </div>
      </template>

      <template #assignment-step>
        <cohort-staff-assignment
          :cohort-id="selectedCohort.id"
          v-model:selected-staff-ids="selectedStaffIds"
          :initially-selected-staff-ids="announcement.adminStaffAnnouncementStaff.map(s => s.staffId)"
          :is-editing="isEditing"
          :scheduled-date="scheduledDate"
          :action-set-in-the-future="isEditing ? !announcement.setDate && scheduleInTheFuture : scheduleInTheFuture"
          :action-originally-set-in-the-future="isEditing && !announcement.setDate"
          object-type="Instructor Announcement"
        >
          <template v-if="sendEmail && newlySelectedCantReceiveEmailCount > 0" #selected-text>
            <span>
              - {{ enumerateItems(newlySelectedCantReceiveEmailCount, 'Instructor') }} won't receive the email</span
            >
          </template>

          <template #status-badge="{ staff }">
            <staff-announcement-status-chip :read="hasRead(staff)" />
          </template>

          <template v-if="sendEmail" #details-header>
            <div class="d-flex justify-center" style="width: 90px">Email Status</div>
          </template>

          <template v-if="sendEmail" #details="{ staff }">
            <div class="d-flex justify-center" style="width: 90px">
              <email-status-chip v-bind="getUserEmailStatus(staff)" />
            </div>
          </template>
        </cohort-staff-assignment>
      </template>
    </cohort-action-edit-page>

    <mosaic-confirm-cohort-action-update-dialog
      v-model:active="confirmDialogActive"
      action-noun="Announcement"
      :action-status-items="announcementStatusItems"
      student-or-staff-noun="Instructor"
      student-or-staff-noun-pluralised="Instructors"
      :get-action-status="getStaffAnnouncementStatus"
      :show-delete-check="false"
      @confirm="confirmed = true"
      @cancel="cancelled = true"
    >
      <template v-if="isEditing && detailsDirty && hasStaffWithUpdatedStatus">
        <mosaic-card-subheading>Updated Details</mosaic-card-subheading>
        <div>
          You have edited some of the details of this Announcement and some Instructors have already marked it as read.
        </div>
        <div class="pt-2">Would you like to reset the Status of these Instructor announcements to unread?</div>
        <mosaic-checkbox
          no-icon
          v-model="resetStaffAnnouncementStatus"
          :label="`Reset Instructor announcements' status to unread`"
      /></template>
    </mosaic-confirm-cohort-action-update-dialog>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCohortStore } from '@/stores/cohort';
import { parseRouteId } from '@/composables/vue-router';
import { institutionTimeTomorrowAsDate } from '@/utils/time';
import { mapActions, mapGetters, mapState } from '@/store/map-store';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import { enumerateItems } from '@/utils/text';
import StaffAnnouncementStatusChip from './StaffAnnouncementStatusChip.vue';
import CohortActionEditPage from '@/components/cohort-actions/CohortActionEditPage.vue';
import ScheduleInTheFuturePicker from '@/components/cohort-actions/ScheduleInTheFuturePicker.vue';
import { createReturnTo } from '@/components/cohort-actions/cohort-actions';
import { useApi } from '@/composables/api';
import CohortStaffAssignment from '@/components/assignment/CohortStaffAssignment.vue';

import { until } from '@vueuse/core';

const api = useApi();

// Setup
const route = useRoute();
const isEditing = computed(() => route.name !== 'CohortAdminStaffAnnouncementCreatePage');
const announcementId = parseRouteId('id');

const { user } = useUserStore();
const { selectedInstitution } = mapState();
const { isEmailVerificationOnForSelectedInstitution } = mapGetters();
const { loadCurriculum } = mapActions();
loadCurriculum();

type AdminStaffAnnouncement = {
  id: number;
  title: string;
  body?: string;
  setDate: string;
  scheduledAtDate: string;
  sendEmail: boolean;
  createdBy: { id: number; name: string };
  adminStaffAnnouncementStaff: { id: number; staffId: number; staffAnnouncement?: { read: boolean } }[];
};

const announcement = ref<AdminStaffAnnouncement>({
  id: -1,
  title: '',
  body: undefined,
  setDate: '',
  scheduledAtDate: '',
  sendEmail: false,
  createdBy: { id: -1, name: ' ' },
  adminStaffAnnouncementStaff: [],
});
const announcementStatus = computed<'live' | 'scheduled'>(() => (announcement.value.setDate ? 'live' : 'scheduled'));

// Fields
const title = ref('');
const body = ref<string>();

const createdByName = ref(user.value.name);

const scheduleInTheFuture = ref(false);

const scheduledDate = ref<string>(institutionTimeTomorrowAsDate(selectedInstitution.value.config.region));

const sendEmail = ref(false);

const canContinue = computed(
  () => !!title.value && !!body.value && (!scheduleInTheFuture.value || !!scheduledDate.value)
);

const returnTo = createReturnTo('CohortAdminStaffAnnouncementsPage', scheduleInTheFuture, announcementStatus);

setBreadcrumbs(
  computed(() => [
    {
      text: `Instructor Announcements`,
      to: returnTo.value,
    },
    {
      text: isEditing.value ? announcement.value.title : `New Instructor Announcement`,
    },
  ])
);

// Selected staff
const selectedStaffIds = ref<number[]>([]);

function hasRead({ id }: { id: number }) {
  const adminStaffAnnouncementStaff = announcement.value.adminStaffAnnouncementStaff.find(s => s.staffId === id);
  return adminStaffAnnouncementStaff?.staffAnnouncement?.read;
}

function getUserEmailStatus({ id }: { id: number }) {
  return (
    selectedCohortStaff.value.find(s => s.id === id)?.user || {
      optedOutOfEmails: false,
      emailBounced: false,
      emailVerified: false,
    }
  );
}

const newlySelectedCantReceiveEmailCount = computed(() => {
  const initiallyUserStaffds = announcement.value.adminStaffAnnouncementStaff.map(s => s.staffId);
  const newlyselectedStaffIds = selectedStaffIds.value.filter(id => !initiallyUserStaffds.includes(id));

  return selectedCohortStaff.value.filter(s => {
    return (
      newlyselectedStaffIds.includes(s.id) &&
      (s.user.emailBounced || !s.user.emailVerified || s.user.optedOutOfEmails || s.user.isDemo)
    );
  }).length;
});

// Dirty
const isCreatingAndReturning = ref(false);
const dirty = computed(() => {
  const a = announcement.value;
  if (isEditing.value) {
    const scheduledDirty = a.scheduledAtDate
      ? !scheduleInTheFuture.value || a.scheduledAtDate !== scheduledDate.value
      : scheduleInTheFuture.value;
    const userStaffDirty =
      selectedStaffIds.value.length !== a.adminStaffAnnouncementStaff.length ||
      a.adminStaffAnnouncementStaff.map(s => s.staffId).some(id => !selectedStaffIds.value.includes(id));
    return detailsDirty.value || scheduledDirty || sendEmail.value !== a.sendEmail || userStaffDirty;
  } else {
    if (isCreatingAndReturning.value) return false;
    return detailsDirty.value || scheduleInTheFuture.value || sendEmail.value || selectedStaffIds.value.length > 0;
  }
});

const detailsDirty = computed(() => {
  if (isEditing.value) {
    return title.value !== announcement.value.title || body.value !== announcement.value.body;
  } else {
    return !!title.value || !!body.value;
  }
});

// Load
async function load() {
  await loadAnnouncement();
}

const { selectedCohort, selectedCohortStaff } = useCohortStore();

async function loadAnnouncement() {
  if (!isEditing.value) return;
  const r = await api.get<AdminStaffAnnouncement>(`/admin-staff-announcements/${announcementId.value}`);
  announcement.value = r.data;
  title.value = r.data.title;
  body.value = r.data.body;
  createdByName.value = r.data.createdBy.name;
  scheduleInTheFuture.value = !!r.data.scheduledAtDate;
  scheduledDate.value = r.data.scheduledAtDate;
  sendEmail.value = r.data.sendEmail;
  selectedStaffIds.value = r.data.adminStaffAnnouncementStaff.map(s => s.staffId);
}

// Save
const canSave = computed(() => canContinue.value && selectedStaffIds.value.length > 0 && dirty.value);
const resetStaffAnnouncementStatus = ref(false);

async function save() {
  const requestBody = {
    title: title.value,
    body: body.value,
    scheduledAtDate:
      (!isEditing.value || announcementStatus.value == 'scheduled') && scheduleInTheFuture.value
        ? scheduledDate.value
        : null,
    sendEmail: sendEmail.value,
    selectedAll: selectedStaffIds.value.length === selectedCohortStaff.value.length,
    staffIds: selectedStaffIds.value,
    resetStaffAnnouncementStatus: resetStaffAnnouncementStatus.value,
  };

  if (isEditing.value) {
    const r = await api.put<unknown, AdminStaffAnnouncement>(
      `/admin-staff-announcements/${announcementId.value}`,
      requestBody
    );
    announcement.value = r.data;
  } else {
    await api.post(`/cohorts/${selectedCohort.value.id}/admin-staff-announcements`, requestBody);
    isCreatingAndReturning.value = true;
  }
}

const confirmed = ref(false);
const cancelled = ref(false);

const hasStaffWithUpdatedStatus = computed(() =>
  announcement.value.adminStaffAnnouncementStaff.some(s => s.staffAnnouncement?.read)
);

const confirmDialogActive = ref(false);

const announcementStatusItems = [
  { title: 'Read', value: 'read' },
  { title: 'Unread', value: 'unread' },
];
const getStaffAnnouncementStatus = (staffId: number) => {
  const aas = announcement.value.adminStaffAnnouncementStaff.find(asas => asas.staffId === staffId);
  return aas?.staffAnnouncement?.read ? 'read' : 'unread';
};

async function saveWithDialog() {
  if (detailsDirty.value && hasStaffWithUpdatedStatus.value) {
    confirmed.value = false;
    cancelled.value = false;
    confirmDialogActive.value = true;
    await until(computed(() => confirmed.value || cancelled.value)).toBe(true);
    if (cancelled.value) {
      return 'no-op';
    }
  }

  await save();
}
</script>
