<template>
  <div class="d-flex align-center" v-if="selectedInstitution.config.allow_forcing_microsoft_sso">
    <div>
      <mosaic-checkbox
        name="demo-account-check"
        class="mr-2 ml-0"
        density="compact"
        label="Force signing in with Microsoft"
        no-icon
        :model-value="modelValue"
        :disabled="disabled"
        :readonly="readonly"
        :positive-help-text="`${capitaliseFirstLetter(
          noun
        )} will only be able to sign in with Microsoft and not using a password`"
        :negative-help-text="`${capitaliseFirstLetter(noun)} can sign in with Microsoft or using a password`"
        :help-text="infoMessage"
        @update:model-value="emit('update:model-value', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useInstitutionStore } from '@/stores/institution';
import { useStudentStore } from '@/stores/student';
import { capitaliseFirstLetter } from '@/utils/text';
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean;
  disabled?: boolean;
  readonly?: boolean;
  isBulk?: boolean;
  type: 'staff' | 'student';
}>();

const emit = defineEmits<{
  'update:model-value': [v: boolean];
}>();

const noun = computed(() => {
  if (props.type == 'staff') {
    if (props.isBulk) return 'these Instructors';
    return 'this Instructor';
  }

  if (props.isBulk) return `these ${traineeNounPluralised.value}`;
  return `this ${traineeNoun.value}`;
});

const infoMessage = computed(() => {
  if (!props.disabled)
    return `If enabled, ${noun.value} will only be able to sign in with Microsoft and not using a password`;

  // isBulk not currently applicable for this use case. This is only used when updating individual Instructor state
  return `Cannot disable this as ${noun.value} is in multiple Institutions. Please contact support if this is a problem.`;
});

const { selectedInstitution } = useInstitutionStore();
const { traineeNoun, traineeNounPluralised } = useStudentStore();
</script>
