<template>
  <mosaic-filter-list-page object-type="Announcement" :load="load" :items="announcements" :hide-add="true">
    <template #list-item="{ item: a }">
      <mosaic-list-item
        :key="a.id"
        :title="a.title"
        :subtitle="
          formatDateFromNow(a.announcedAt) + ' - from ' + a.createdBy.name + (a.cohortName ? ` -  ${a.cohortName}` : '')
        "
        :icon="icons.announcement"
        :to="{
          name: 'StaffAnnouncementPage',
          params: { id: a.id },
        }"
      >
        <template #information>
          <staff-announcement-status-chip :read="a.read" />
        </template>

        <template #actions>
          <mosaic-icon-btn
            v-if="a.read"
            icon="email-mark-as-unread"
            tooltip="Mark as unread"
            :disabled="unreadProcessing"
            @click.prevent="markAsUnread(a)"
          />
          <div v-else-if="anyAnnouncementsAreRead" style="width: 36px"></div>
        </template>
      </mosaic-list-item>
    </template>

    <template #list-snackbar>
      <mosaic-error-snackbar v-model="unreadError" action="mark this Announcement as unread" />
    </template>
  </mosaic-filter-list-page>
</template>

<script setup lang="ts">
import { useStaffStore } from '@/stores/staff';
import { ref } from 'vue';
import { icons } from '@/utils/icons';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import StaffAnnouncementStatusChip from './StaffAnnouncementStatusChip.vue';
import { computed } from 'vue';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { useApi } from '@/composables/api';
import moment from 'moment';

const api = useApi();

setBreadcrumbs([
  {
    text: 'Announcements',
  },
]);

interface StaffAnnouncement {
  id: number;
  title: string;
  announcedAt: string;
  read: boolean;
  cohortName?: string;
  createdBy: { name: string };
}

const announcements = ref<StaffAnnouncement[]>([]);
const anyAnnouncementsAreRead = computed(() => announcements.value.some(a => a.read));

const { userStaff } = useStaffStore();

async function load() {
  const r = await api.get<StaffAnnouncement[]>(`staff/${userStaff.value.id}/staff-announcements`);
  announcements.value = r.data.sortBy([x => moment(x.announcedAt).startOf('day'), 'title'], ['desc', 'asc']);
}

const {
  action: markAsUnread,
  processing: unreadProcessing,
  error: unreadError,
} = withProcessingAndError(async (a: StaffAnnouncement) => {
  await api.post(`staff-announcements/${a.id}/unread`, {});
  announcements.value = announcements.value.map(announcement => {
    if (announcement.id === a.id) return { ...announcement, read: false };
    return announcement;
  });
});
</script>
