<template>
  <div>
    <cohort-student-action-edit-page
      v-model:selected-student-ids="selectedStudentIds"
      action-noun="Course Target"
      :action-set-date="target.set_date"
      :action-scheduled-at-date="scheduledDate"
      :action-schedule-in-the-future="status === 'scheduled'"
      :load="load"
      :can-continue="canContinue"
      :save="save"
      :return-to="returnTo"
      :details-dirty="detailsDirty"
      :has-students-with-updated-status="hasStudentsWithUpdatedStatus"
      :dirty="dirty"
      :is-editing="isEditing"
      :students="selectedCohortStudents"
      :initially-selected-student-ids="target.admin_target_students.map(s => s.student_id)"
      :can-delete-student-without-confirmation="canDeleteStudentWithoutConfirmation"
      :action-status-items="targetStatusItems"
      :get-action-status="targetStatus"
      :draft="status === 'draft'"
    >
      <template #details-step>
        <v-textarea
          v-model="goal"
          class="mb-4"
          :placeholder="`Target - what should ${traineeNounCapitalisedAndPluralised} be aiming to achieve?`"
          autofocus
          auto-grow
          type="text"
          rows="1"
          hide-details
        />
        <v-textarea
          v-model="description"
          variant="filled"
          label="Description - please add any additional detail relevant to this target"
          auto-grow
          type="text"
          rows="2"
          hide-details
        />
        <mosaic-card-heading class="mt-4">Actions</mosaic-card-heading>
        <div v-if="scheduleEditable" class="subtitle">
          What steps should {{ traineeNounCapitalisedAndPluralised }} take to achieve this target?
        </div>
        <div v-if="scheduleEditable" class="py-2">
          <div v-for="action in actions" :key="action.id" class="d-flex align-end ml-1 mb-1">
            <mosaic-checkbox name="action-check" disabled class="shrink mr-2" no-icon dense />
            <v-text-field
              :ref="'action' + action.id"
              v-model="action.text"
              placeholder="Add new action"
              class="mr-4 pt-0"
              hide-details
            />
            <mosaic-icon-btn icon="delete" tooltip="Delete" @click.prevent="deleteAction(action.id)" class="pt-6" />
          </div>
          <div class="d-flex align-center pt-4">
            <v-btn ripple @click.prevent="addAction()">
              <v-icon>mdi-plus</v-icon>
              <span>Add new action</span>
            </v-btn>
          </div>
        </div>
        <div v-else class="py-2">
          <div v-if="!actions || actions.length === 0" class="ml-1">No actions</div>
          <div v-for="action in actions" :key="action.id" class="d-flex align-center pa-1">
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
            <span class="pl-2">{{ action.text }}</span>
          </div>
        </div>
        <curriculum-links
          v-if="!!selectedInstitution.curriculum_id || !!selectedCohort.curriculum_id"
          class="mt-4"
          force-display
          :selected-curriculum-statements="curriculumStatements"
          :can-edit="scheduleEditable"
          artefact-type="Course Target"
          @update:link-added="addCurriculumLink"
          @update:link-removed="removeCurriculumLink"
        >
        </curriculum-links>
        <div v-if="(!!selectedInstitution.curriculum_id || !!selectedCohort.curriculum_id) && !curriculumEnabled">
          <mosaic-info-alert class="mt-4">
            The Curriculum visibility for this Cohort is currently disabled.
            {{ traineeNounCapitalisedAndPluralised }} are unable to view the Curriculum Statements linked to the Course
            Targets. To enable Curriculum visibility,
            <mosaic-router-link
              :to="{
                name: 'TutorAdminCohortCurriculumPage',
              }"
              color="text-info"
              >click here</mosaic-router-link
            >.
          </mosaic-info-alert>
        </div>

        <template v-if="scheduleEditable">
          <mosaic-card-heading class="mt-4">Create as</mosaic-card-heading>
          <v-row class="ml-0 mt-0">
            <mosaic-radio-buttons
              class="mt-2 mr-4"
              :readonly="false"
              color="primary"
              :radios="[
                {
                  label: 'Schedule in the future',
                  value: 'scheduled',
                },
                { label: 'Create as live target', value: 'live' },
                {
                  label: 'Create as draft',
                  value: 'draft',
                },
              ]"
              v-model="status"
            />

            <mosaic-date-picker
              v-if="status === 'scheduled'"
              label="Scheduled date"
              v-model:date="scheduledDate"
              hide-details
              exact-width="175px"
              :min="tomorrow"
              hide-clear
            />
          </v-row>
        </template>
      </template>
      <template #action-assignment-status-badge="{ assignee }">
        <target-status-chip :status="targetStatus(assignee.id)" />
      </template>
      <template #reset-student-action-status-prompt>
        <template v-if="isEditing && detailsDirty && hasStudentsWithUpdatedStatus">
          <mosaic-card-subheading>Updated Details</mosaic-card-subheading>
          <div class="pt-2">
            You have edited some of the details of this Course Target and some {{ traineeNoun }} Targets have already
            been marked as completed/approved.
          </div>
          <div class="pt-2">Would you like to reset the Status of these {{ traineeNoun }} Targets to active?</div>
          <mosaic-checkbox
            v-model="resetStudentActionStatus"
            :label="`Reset ${traineeNoun} Targets' status to active`"
            no-icon
        /></template>
      </template>
    </cohort-student-action-edit-page>
  </div>
</template>

<script setup lang="ts">
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import CohortStudentActionEditPage from '@/components/cohort-actions/CohortStudentActionEditPage.vue';
import { useRoute } from 'vue-router';
import { computed, getCurrentInstance, nextTick } from 'vue';
import { ref } from 'vue';
import { institutionTimeTomorrowAsDate } from '@/utils/time';
import { mapGetters, mapState } from '@/store/map-store';
import type { CurriculumStatementSlimLegacy } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { createReturnTo } from '@/components/cohort-actions/cohort-actions';
import { useCohortStore } from '@/stores/cohort';
import { parseRouteId } from '@/composables/vue-router';
import TargetStatusChip from './TargetStatusChip.vue';
import { targetStatusItems } from './targets';
import { VTextField } from 'vuetify/components';
import { useApi } from '@/composables/api';
import { useCurriculumLinks } from '@/frameworks/curriculum/curriculum-links';
import type { AdminTargetStatus } from '@/components/cohort-actions/cohort-actions';
import { useStudentStore } from '@/stores/student';

const api = useApi();
const { traineeNoun } = useStudentStore();

// Setup
const route = useRoute();
const isEditing = computed(() => route.name !== 'CohortCourseTargetCreatePage');
const targetId = parseRouteId('id');

export interface CourseTarget {
  admin_target_actions: (CourseTargetAction & {
    admin_target_id: number;
    created_at: string;
    updated_at: string;
  })[];
  admin_target_students: {
    id: number;
    target_id: number;
    student_id: number;
    status: 'active' | 'completed' | 'approved';
    student: { id: number; email: string; name: string };
  }[];
  created_at: string;
  curriculum_statements: CurriculumStatementSlimLegacy[];
  goal: string;
  id: number;
  impact_goal: string;
  schedule_created_date?: string;
  scheduled_at_date: string;
  scheduled_date?: string;
  draft: boolean;
  selected_all: boolean;
  set_date?: string;
}
interface CourseTargetAction {
  id: number;
  text: string;
}

const target = ref<CourseTarget>({
  id: -1,
  admin_target_actions: [],
  admin_target_students: [],
  created_at: '',
  curriculum_statements: [],
  goal: '',
  impact_goal: '',
  schedule_created_date: '',
  scheduled_at_date: '',
  selected_all: false,
  set_date: '',
  draft: false,
});

const status = ref<AdminTargetStatus>(
  route?.query?.fromTab === 'scheduled' || route?.query?.fromTab === 'live' ? route?.query?.fromTab : 'draft'
);

const { selectedInstitution } = mapState();
const { curriculumEnabled } = mapGetters();
const { selectedCohortStudents, selectedCohort } = useCohortStore();

// Fields
const goal = ref('');
const description = ref('');

const scheduledDate = ref<string>(institutionTimeTomorrowAsDate(selectedInstitution.value.config.region));

const scheduleEditable = computed(() => !target.value.set_date);

const canContinue = computed(() => !!goal.value && (status.value !== 'scheduled' || !!scheduledDate.value));

const selectedStudentIds = ref<number[]>([]);

function targetStatus(studentId: number) {
  const adminTargetStudent = target.value.admin_target_students.find(s => s.student_id === studentId);
  return adminTargetStudent?.status;
}

const returnTo = createReturnTo(
  'CohortCourseTargetsPage',
  computed(() => status.value === 'scheduled'),
  status
);

setBreadcrumbs(
  computed(() => [
    {
      text: `Course Targets`,
      to: returnTo.value,
    },
    {
      text: isEditing.value ? target.value.goal : `New Course Target`,
    },
  ])
);

// Actions
const actions = ref<CourseTargetAction[]>([]);

function deleteAction(id: number) {
  actions.value = actions.value.filter(x => x.id != id);
}

const currentInstance = getCurrentInstance();
async function addAction() {
  // These ids are not used for creating the ids serverside - just to have unique keys in the vue list
  const id = actions.value.length ? Math.max(...actions.value.map(x => x.id)) + 1 : 0;
  actions.value.push({ id, text: '' });
  const ref = 'action' + id;
  await nextTick();
  if (currentInstance && currentInstance.proxy) {
    const textFields = currentInstance.proxy.$refs[ref] as VTextField[];
    textFields[0].focus();
  }
}

const tomorrow = institutionTimeTomorrowAsDate(selectedInstitution.value.config.region);

// Load
async function load() {
  if (!isEditing.value) return;
  const r = await api.get<CourseTarget>(`admin-targets/${targetId.value}`);
  target.value = r.data;
  goal.value = r.data.goal;
  description.value = r.data.impact_goal;
  actions.value = r.data.admin_target_actions.map(a => ({ ...a }));
  selectedStudentIds.value = r.data.admin_target_students.map(s => s.student_id);
  if (r.data.scheduled_at_date) scheduledDate.value = r.data.scheduled_at_date;
  status.value = r.data.draft ? 'draft' : r.data.scheduled_at_date ? 'scheduled' : 'live';
}

const { curriculumStatements, addCurriculumLink, removeCurriculumLink } = useCurriculumLinks(target);

// Dirty
const isCreatingAndReturning = ref(false);
const detailsDirty = computed(() => {
  const t = target.value;
  if (isEditing.value) {
    const actionsDirty =
      actions.value.length !== t.admin_target_actions.length ||
      t.admin_target_actions.some((a, i) => actions.value[i].text !== a.text);
    const curriculumStatementIds = curriculumStatements.value.map(cs => cs.id);
    const curriculumStatementsDirty =
      curriculumStatements.value.length !== t.curriculum_statements.length ||
      t.curriculum_statements.some(cs => !curriculumStatementIds.includes(cs.id));

    const isDraft = status.value === 'draft';
    return (
      goal.value !== t.goal ||
      description.value !== t.impact_goal ||
      isDraft !== !!t.draft ||
      actionsDirty ||
      curriculumStatementsDirty
    );
  } else {
    if (isCreatingAndReturning.value) return false;
    return !!goal.value || !!description.value || actions.value.length > 0 || curriculumStatements.value.length > 0;
  }
});

const dirty = computed(() => {
  const t = target.value;
  const scheduledDirty =
    isEditing.value &&
    (t.scheduled_at_date
      ? status.value !== 'scheduled' || t.scheduled_at_date !== scheduledDate.value
      : status.value === 'scheduled');
  const selectedStudentsDirty =
    isEditing.value &&
    (selectedStudentIds.value.length !== t.admin_target_students.length ||
      t.admin_target_students.map(s => s.student_id).some(id => !selectedStudentIds.value.includes(id)));
  return detailsDirty.value || scheduledDirty || selectedStudentsDirty;
});

// Save

const resetStudentActionStatus = ref(false);
async function save() {
  const requestBody = {
    goal: goal.value,
    impactGoal: description.value,
    studentIds: selectedStudentIds.value,
    targetActions: actions.value,
    curriculumStatementIds: curriculumStatements.value.map(s => s.id),
    scheduledDate: status.value === 'scheduled' && scheduleEditable.value ? scheduledDate.value : null,
    selectedAll: selectedStudentIds.value.length === selectedCohortStudents.value.length,
    draft: status.value === 'draft',
    resetStudentActionStatus: resetStudentActionStatus.value,
  };
  if (isEditing.value) {
    const r = await api.put<unknown, CourseTarget>(`/admin-targets/${targetId.value}`, requestBody);
    target.value = r.data;
  } else {
    await api.post(`/cohorts/${selectedCohort.value.id}/admin-targets`, requestBody);
    isCreatingAndReturning.value = true;
  }
}

function canDeleteStudentWithoutConfirmation(_id: number) {
  // Ideally this should check if the target has been started, but we don't have that concept at the moment
  return status.value === 'draft' || target.value.draft;
}

//  Ignore students about to be removed in this check
const hasStudentsWithUpdatedStatus = computed(() =>
  target.value.admin_target_students.some(s => s.status !== 'active' && selectedStudentIds.value.includes(s.student_id))
);
</script>
