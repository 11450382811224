<template>
  <div>
    <tutor-staff
      click-to="InstitutionStaffListPage"
      :can-edit-staff="canViewAndEditStaff"
      :can-view-staff="canViewStaff"
      :can-edit-training="canViewAndEditTraining"
      :can-view-training="canViewTraining"
      show-edit-details-actions
      show-edit-roles-actions
      show-add-training-record
      show-delete-training-record
      show-training
      show-staff-details-and-roles
    />
  </div>
</template>

<script setup lang="ts">
import TutorStaff from './TutorStaff.vue';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import { computed } from 'vue';

const canViewAndEditTraining = computed(() => hasPermissionForSelectedInstitution('staff.training.edit').value);
const canViewAndEditStaff = computed(() => hasPermissionForSelectedInstitution('staff.edit').value);
const canViewStaff = computed(
  () => canViewAndEditStaff.value || hasPermissionForSelectedInstitution('staff.view').value
);
const canViewTraining = computed(
  () => canViewAndEditTraining.value || hasPermissionForSelectedInstitution('staff.training.view').value
);
</script>
