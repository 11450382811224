<template>
  <div>
    <div v-if="loadError" class="mt-2">
      <span v-if="isString(loadError)">{{ loadError }}</span>
      <span v-else>
        Sorry, cannot load {{ isMe ? 'your' : "this Instructor's" }} Manual Training Records right now. If this problem
        persists, please contact support.
      </span>
    </div>
    <mosaic-tab-item-list
      v-else
      :items="sortedAndFilteredItems"
      :actions="addTrainingRecordActions"
      :empty-text="emptyText"
      mosaic-key="records"
    >
      <template #filters>
        <mosaic-text-field
          dense
          no-icon
          hide-details
          name="name-filter"
          label="Filter by name"
          v-model="nameFilter"
          style="width: 300px"
        />
        <mosaic-select
          name="type-filter"
          label="Filter by type"
          :items="typeFilterItems"
          v-model="typeFilter"
          hide-details
          dense
          no-icon
          style="width: 210px"
        />
      </template>

      <template #item="{ item }">
        <mosaic-list-item
          :key="item.key"
          :icon="item.icon"
          :title="item.title"
          :subtitle="item.subtitle"
          :to="item.to"
          :chip="item.chip"
        >
          <template #actions v-if="props.canDeleteRecord && props.showDeleteTrainingRecord">
            <mosaic-delete-icon-btn object-type="Manual Training Record" @click.prevent="deleteTrainingRecord(item)" />
          </template>
        </mosaic-list-item>
      </template>
    </mosaic-tab-item-list>

    <mosaic-delete-dialog
      v-model:active="deleteDialog.active"
      :object-name="deleteDialog.name"
      object-type="Manual Training Record"
      :url="`/staff-training-records/${deleteDialog.id}`"
      @delete="reload()"
    />
  </div>
</template>

<script setup lang="ts">
import { icons } from '@/utils/icons';
import type { StaffTraining } from '@/stores/staff-training';
import { computed, ref } from 'vue';
import { useRouter, type RouteLocationNamedRaw } from 'vue-router';
import { staffTrainingRecordSubtitle } from './staff-training';
import { isString } from 'lodash';
import { syncQueryParam } from '@/composables/query';

type StaffTrainingRecord = StaffTraining['staffTrainingRecords'][number];
const props = withDefaults(
  defineProps<{
    trainingRecords: StaffTraining['staffTrainingRecords'];
    loadError?: boolean | string;
    clickRecordTo: (record: StaffTrainingRecord) => RouteLocationNamedRaw;
    createTrainingRecordTo: RouteLocationNamedRaw;
    isMe?: boolean;
    reload: () => Promise<unknown>;
    showAddTrainingRecord?: boolean;
    showDeleteTrainingRecord?: boolean;
    canDeleteRecord?: boolean;
    canAddRecord?: boolean;
  }>(),
  {
    loadError: false,
    isMe: false,
    showAddTrainingRecord: false,
    showDeleteTrainingRecord: false,
    canDeleteRecord: false,
    canAddRecord: false,
  }
);

// #region items and filters
const items = computed(() =>
  props.trainingRecords.map(t => {
    return {
      id: t.id,
      key: `record-${t.id}`,
      type: t.recordType,
      icon:
        t.recordType == 'training_session'
          ? icons.instructorTrainingRecordSession
          : icons.instructorTrainingRecordAudit,
      title: t.name,
      subtitle: staffTrainingRecordSubtitle(t),
      to: props.clickRecordTo(t),
      chip: undefined,
      date: t.date,
    };
  })
);

const nameFilter = ref('');
syncQueryParam(nameFilter, 'records-name', 'string');

const typeFilterItems = [
  { text: 'All', value: null },
  { text: 'Training Session', value: 'training_session' },
  { text: 'Prior Experience Audit', value: 'prior_experience_audit' },
];
const typeFilter = ref<'training_session' | 'prior_experience_audit' | null>(null);
syncQueryParam(typeFilter, 'records-type', 'string | null');

const sortedAndFilteredItems = computed(() =>
  items.value
    .filter(
      i =>
        i.title.toLowerCase().includes(nameFilter.value.toLowerCase()) &&
        (typeFilter.value === null || i.type == typeFilter.value)
    )
    .sortBy('date', 'desc')
);
// #endregion

// #region delete
const deleteDialog = ref({
  active: false,
  id: -1,
  name: '',
});

function deleteTrainingRecord(item: (typeof items)['value'][number]) {
  deleteDialog.value = {
    active: true,
    id: item.id,
    name: item.title,
  };
}
// #endregion

const emptyText = computed(() => {
  if (items.value.length == 0) {
    return props.isMe ? 'You have no Manual Training Records' : `This Instructor has no Manual Training Records`;
  } else {
    return props.isMe
      ? 'You have no Manual Training Records for these filters'
      : 'This Instructor has no Manual Training Records for these filters';
  }
});

const router = useRouter();
const addTrainingRecordActions = computed(() =>
  props.canAddRecord && props.showAddTrainingRecord
    ? {
        icon: 'plus',
        text: 'Training Record',
        items: [
          {
            icon: icons.instructorTrainingRecordSession,
            text: 'Training Session',
            click: () => {
              router.push({
                ...props.createTrainingRecordTo,
                query: { ...props.createTrainingRecordTo.query, type: 'training_session' },
              });
            },
          },
          {
            icon: icons.instructorTrainingRecordAudit,
            text: 'Prior Experience Audit',
            click: () =>
              router.push({
                ...props.createTrainingRecordTo,
                query: { ...props.createTrainingRecordTo.query, type: 'prior_experience_audit' },
              }),
          },
        ],
      }
    : undefined
);
</script>
