import api from '../entrypoints/application/api';

const defaultState = {
  reviewTemplatePage: {
    saving: false,
    saveError: false,
    previewToggle: 0,
    creating: false,
    error: null,
    previewRoleId: 'trainee',
  },
  reviewTemplate: null,
};

const mutations = {
  updateReviewTemplate(state, reviewTemplateUpdates) {
    state.reviewTemplate = { ...state.reviewTemplate, ...reviewTemplateUpdates };
  },
  removeReviewTemplateStandard(state, reviewTemplateStandardId) {
    const review_template_standards = state.reviewTemplate.review_template_standards.filter(
      x => x.id !== reviewTemplateStandardId
    );
    state.reviewTemplate = { ...state.reviewTemplate, review_template_standards };
  },
  updateReviewTemplatePage(state, reviewTemplatePageUpdates) {
    state.reviewTemplatePage = { ...state.reviewTemplatePage, ...reviewTemplatePageUpdates };
  },
  resetReviewTemplatePage(state) {
    state.reviewTemplatePage = { ...defaultState.reviewTemplatePage };
    state.reviewTemplate = null;
  },
};

const actions = {
  loadReviewTemplate({ commit, state, dispatch }, { id, force }) {
    if (state.reviewTemplatePage.error) {
      commit('updateReviewTemplatePage', { error: null });
    }
    Promise.all([
      dispatch('loadRoles'),
      dispatch('loadSubjects'),
      dispatch('loadJudgementSets'),
      dispatch('loadCohorts'),
    ]).then(() => {
      if (force || !state.reviewTemplate || state.reviewTemplate.id !== parseInt(id)) {
        api
          .get(`/review-templates/${id}`)
          .then(r => {
            commit('updateReviewTemplate', r.data);
            commit('updateSelectedStandardSet', r.data.standard_set);
          })
          .catch(e => {
            console.log(e);
            commit('updateReviewTemplatePage', { error: 'Sorry, cannot load your review template at the moment' });
          });
      }
    });
  },
  updateReviewTemplateOverallComment({ commit, dispatch, state }, { id, updates }) {
    const review_template_overall_comments = state.reviewTemplate.review_template_overall_comments.map(x => {
      if (x.id === id) {
        return { ...x, ...updates };
      }
      return x;
    });
    commit('updateReviewTemplate', { review_template_overall_comments });
    dispatch('saveReviewTemplate');
  },
  addReviewTemplateOverallComment({ commit, dispatch, state }) {
    const comments = state.reviewTemplate.review_template_overall_comments;
    const comment = {
      label: 'New Comment',
      placeholder: '',
      id: getMaxId(comments) + 1,
      order: comments.length,
      review_template_permissions: [],
      can_trainee_edit: true,
    };
    commit('updateReviewTemplate', { review_template_overall_comments: [...comments, comment] });
    dispatch('saveReviewTemplate');
  },
  deleteReviewTemplateOverallComment({ commit, dispatch, state }, id) {
    commit('updateReviewTemplate', {
      review_template_overall_comments: state.reviewTemplate.review_template_overall_comments.filter(x => x.id !== id),
    });
    dispatch('saveReviewTemplate');
  },
  updateReviewTemplateStandardComment({ commit, dispatch, state }, { id, updates }) {
    const review_template_standard_comments = state.reviewTemplate.review_template_standard_comments.map(x => {
      if (x.id === id) {
        return { ...x, ...updates };
      }
      return x;
    });
    commit('updateReviewTemplate', { review_template_standard_comments });
    dispatch('saveReviewTemplate');
  },
  updateReviewTemplateStandardCommentEditPermissions({ dispatch, state }, { commentId, roleId, edit }) {
    const comment = state.reviewTemplate.review_template_standard_comments.find(x => x.id === commentId);
    const review_template_permissions = comment.review_template_permissions
      .filter(x => x.role_id !== roleId)
      .concat([{ role_id: roleId, edit }]);
    dispatch('updateReviewTemplateStandardComment', { id: commentId, updates: { review_template_permissions } });
  },
  addReviewTemplateStandardComment({ commit, dispatch, state }) {
    const comments = state.reviewTemplate.review_template_standard_comments;
    const comment = {
      label: 'New Comment',
      placeholder: '',
      id: getMaxId(comments) + 1,
      order: comments.length,
      review_template_permissions: [],
      can_trainee_edit: true,
    };
    commit('updateReviewTemplate', { review_template_standard_comments: [...comments, comment] });
    dispatch('saveReviewTemplate');
  },
  deleteReviewTemplateStandardComment({ commit, dispatch, state }, id) {
    commit('updateReviewTemplate', {
      review_template_standard_comments: state.reviewTemplate.review_template_standard_comments.filter(
        x => x.id !== id
      ),
    });
    dispatch('saveReviewTemplate');
  },
  resetReviewTemplateStandardJudgements({ commit, dispatch }) {
    commit('updateReviewTemplate', { review_standard_institution_judgement_set_id: null });
    dispatch('saveReviewTemplate');
  },
  resetReviewTemplateOverallJudgement({ commit, dispatch }) {
    commit('updateReviewTemplate', { review_overall_institution_judgement_set_id: null });
    dispatch('saveReviewTemplate');
  },
  resetReviewTemplateStandardStatements({ commit, dispatch }) {
    commit('updateReviewTemplate', { review_standard_statement_institution_judgement_set_id: null });
    dispatch('saveReviewTemplate');
  },
  resetReviewTemplateOverallStatements({ commit, dispatch }) {
    commit('updateReviewTemplate', { review_overall_statement_institution_judgement_set_id: null });
    dispatch('saveReviewTemplate');
  },
  updateReviewTemplateStandard({ commit, dispatch, state }, { id, updates }) {
    const review_template_standards = state.reviewTemplate.review_template_standards.map(x => {
      if (x.id === id) {
        return { ...x, ...updates };
      }
      return x;
    });
    commit('updateReviewTemplate', { review_template_standards });
    dispatch('saveReviewTemplate');
  },
  addReviewTemplatePart2Statement({ commit, dispatch, state }, { statement }) {
    const part2Statements = state.reviewTemplate.review_template_part2_statements;
    const newStatement = {
      statement,
      id: getMaxId(part2Statements) + 1,
      order: part2Statements.length,
    };
    commit('updateReviewTemplate', { review_template_part2_statements: [...part2Statements, newStatement] });
    dispatch('saveReviewTemplate');
  },
  deleteReviewTemplatePart2Statement({ commit, dispatch, state }, { id }) {
    commit('updateReviewTemplate', {
      review_template_part2_statements: state.reviewTemplate.review_template_part2_statements.filter(x => x.id !== id),
    });
    dispatch('saveReviewTemplate');
  },
  updateReviewTemplatePart2Statement({ commit, dispatch, state }, { id, statement }) {
    const review_template_part2_statements = state.reviewTemplate.review_template_part2_statements.map(x => {
      if (x.id === id) {
        return { ...x, statement };
      }
      return x;
    });
    commit('updateReviewTemplate', { review_template_part2_statements });
    dispatch('saveReviewTemplate');
  },
  updateReviewTemplatePermissions({ dispatch, state }, { roles, traineePermissionUpdates }) {
    const review_template_permissions = state.reviewTemplate.review_template_permissions
      .filter(p => !roles.some(r => r.roleId === p.role_id && r.permission === p.permission_type))
      .concat(roles.map(r => ({ role_id: r.roleId, edit: r.edit, permission_type: r.permission })));
    dispatch('updateReviewTemplate', { review_template_permissions, ...(traineePermissionUpdates || {}) });
  },
  updateReviewTemplate({ commit, dispatch, state }, updates) {
    commit('updateReviewTemplate', { ...state.reviewTemplate, ...updates });
    dispatch('saveReviewTemplate');
  },
  saveReviewTemplate({ commit, state }) {
    saveWithErrorHandling(commit, api.put(`/review-templates/${state.reviewTemplate.id}`, state.reviewTemplate));
  },
  addReviewTemplateStatementGroup({ commit, state }, { reviewTemplateStandardId, name }) {
    const reviewTemplateStandard = state.reviewTemplate.review_template_standards.find(
      x => x.id === reviewTemplateStandardId
    );
    saveWithErrorHandling(
      commit,
      api.post(
        `/review-template-statement-sets/${state.reviewTemplate.review_template_statement_set.id}/review-template-statement-groups`,
        {
          name,
          standard_id: reviewTemplateStandard.standard_id,
          substandard_id: reviewTemplateStandard.substandard_id,
        }
      ),
      x => {
        const body = x.data;
        const statementSet = state.reviewTemplate.review_template_statement_set;
        const review_template_statement_groups = statementSet.review_template_statement_groups.concat([
          {
            ...body,
            review_template_statements: [],
          },
        ]);
        commit('updateReviewTemplate', {
          review_template_statement_set: { ...statementSet, review_template_statement_groups },
        });
      }
    );
  },
  removeReviewTemplateStatementGroup({ commit, state }, { id }) {
    saveWithErrorHandling(commit, api.delete(`/review-template-statement-groups/${id}`), () => {
      const statementSet = state.reviewTemplate.review_template_statement_set;
      const review_template_statement_groups = statementSet.review_template_statement_groups.filter(x => x.id !== id);
      commit('updateReviewTemplate', {
        review_template_statement_set: { ...statementSet, review_template_statement_groups },
      });
    });
  },
  updateReviewTemplateStatementGroup({ commit, state }, { id, name }) {
    saveWithErrorHandling(commit, api.put(`/review-template-statement-groups/${id}`, { name }), () => {
      const statementSet = state.reviewTemplate.review_template_statement_set;
      const review_template_statement_groups = statementSet.review_template_statement_groups.map(x => {
        if (x.id === id) {
          return { ...x, name };
        }
        return x;
      });
      commit('updateReviewTemplate', {
        review_template_statement_set: { ...statementSet, review_template_statement_groups },
      });
    });
  },
  addReviewTemplateStatement({ commit, state }, { groupId, statement }) {
    saveWithErrorHandling(
      commit,
      api.post(`/review-template-statement-groups/${groupId}/review-template-statements`, {
        statement,
      }),
      r => {
        const body = r.data;
        const statementSet = state.reviewTemplate.review_template_statement_set;
        const review_template_statement_groups = statementSet.review_template_statement_groups.map(x => {
          if (x.id === groupId) {
            const review_template_statements = x.review_template_statements.concat([body]);
            return { ...x, review_template_statements };
          }
          return x;
        });
        commit('updateReviewTemplate', {
          review_template_statement_set: { ...statementSet, review_template_statement_groups },
        });
      }
    );
  },
  removeReviewTemplateStatement({ commit, state }, { id }) {
    saveWithErrorHandling(commit, api.delete(`/review-template-statements/${id}`), () => {
      const statementSet = state.reviewTemplate.review_template_statement_set;
      const review_template_statement_groups = statementSet.review_template_statement_groups.map(x => ({
        ...x,
        review_template_statements: x.review_template_statements.filter(y => y.id !== id),
      }));
      commit('updateReviewTemplate', {
        review_template_statement_set: { ...statementSet, review_template_statement_groups },
      });
    });
  },
  updateReviewTemplateStatement({ commit, state }, { id, statement, guidance }) {
    saveWithErrorHandling(commit, api.put(`/review-template-statements/${id}`, { statement, guidance }), () => {
      const statementSet = state.reviewTemplate.review_template_statement_set;
      const review_template_statement_groups = statementSet.review_template_statement_groups.map(x => ({
        ...x,
        review_template_statements: x.review_template_statements.map(y => {
          if (y.id === id) {
            return { ...y, statement, guidance };
          }
          return y;
        }),
      }));
      commit('updateReviewTemplate', {
        review_template_statement_set: { ...statementSet, review_template_statement_groups },
      });
    });
  },
  addReviewTemplateOverallStatementGroup({ commit, state, dispatch }, { name }) {
    const groups = state.reviewTemplate.review_template_overall_statement_groups;
    const newGroup = {
      name,
      id: getMaxId(groups) + 1,
      order: groups.length,
      review_template_overall_statements: [],
    };

    commit('updateReviewTemplate', { review_template_overall_statement_groups: [...groups, newGroup] });
    dispatch('saveReviewTemplate');
  },
  removeReviewTemplateOverallStatementGroup({ commit, state, dispatch }, { id }) {
    commit('updateReviewTemplate', {
      review_template_overall_statement_groups: state.reviewTemplate.review_template_overall_statement_groups.filter(
        x => x.id !== id
      ),
    });
    dispatch('saveReviewTemplate');
  },
  updateReviewTemplateOverallStatementGroup({ commit, dispatch, state }, { id, name }) {
    const review_template_overall_statement_groups = state.reviewTemplate.review_template_overall_statement_groups.map(
      x => {
        if (x.id === id) {
          return { ...x, name };
        }
        return x;
      }
    );
    commit('updateReviewTemplate', { review_template_overall_statement_groups });
    dispatch('saveReviewTemplate');
  },
  addReviewTemplateOverallStatement({ commit, state, dispatch }, { statement, groupId }) {
    const groups = state.reviewTemplate.review_template_overall_statement_groups;
    const group = groups.find(x => x.id === groupId);
    const statements = group.review_template_overall_statements;
    const previousStatementInGroup = statements.length > 0 ? statements[statements.length - 1] : null;
    const newStatement = {
      statement,
      id: getMaxId(statements) + 1,
      order: statements.length,
      can_trainee_edit: previousStatementInGroup ? previousStatementInGroup.can_trainee_edit : true,
      can_trainee_view: previousStatementInGroup ? previousStatementInGroup.can_trainee_view : true,
      review_template_permissions: state.roles.map(r => {
        const previousPermission = previousStatementInGroup?.review_template_permissions.find(x => x.role_id === r.id);
        return {
          role_id: r.id,
          edit: previousPermission ? previousPermission.edit : false,
          view: previousPermission ? previousPermission.view : true,
        };
      }),
    };
    const updatedStatements = [
      ...groups.filter(x => x.id !== group.id),
      { ...group, review_template_overall_statements: [...statements, newStatement] },
    ];
    commit('updateReviewTemplate', {
      review_template_overall_statement_groups: updatedStatements,
    });

    dispatch('saveReviewTemplate');
  },
  removeReviewTemplateOverallStatement({ commit, state, dispatch }, { id, groupId }) {
    const review_template_overall_statement_groups = state.reviewTemplate.review_template_overall_statement_groups.map(
      g => {
        if (g.id === groupId) {
          g.review_template_overall_statements = g.review_template_overall_statements.filter(x => x.id !== id);
        }
        return g;
      }
    );
    commit('updateReviewTemplate', { review_template_overall_statement_groups });
    dispatch('saveReviewTemplate');
  },
  updateReviewTemplateOverallStatement({ commit, dispatch, state }, { id, updates, groupId }) {
    const review_template_overall_statement_groups = state.reviewTemplate.review_template_overall_statement_groups.map(
      g => {
        if (g.id === groupId) {
          g.review_template_overall_statements = g.review_template_overall_statements.map(s => {
            if (s.id === id) {
              s = { ...s, ...updates };
            }
            return s;
          });
        }
        return g;
      }
    );
    commit('updateReviewTemplate', { review_template_overall_statement_groups });
    dispatch('saveReviewTemplate');
  },
  updateReviewTemplateOverallStatementPermissions(
    { dispatch, state, commit },
    { id, groupId, roles, traineePermissionUpdates }
  ) {
    const review_template_overall_statement_groups = state.reviewTemplate.review_template_overall_statement_groups.map(
      g => {
        if (g.id === groupId) {
          g.review_template_overall_statements = g.review_template_overall_statements.map(s => {
            if (s.id === id) {
              s.review_template_permissions = roles.map(r => {
                const permission = s.review_template_permissions.find(p => r.roleId === p.role_id) || {};
                return { ...permission, ...r.updates };
              });
              return { ...s, ...traineePermissionUpdates };
            }
            return s;
          });
        }
        return g;
      }
    );
    commit('updateReviewTemplate', { review_template_overall_statement_groups });
    dispatch('saveReviewTemplate');
  },
};

function saveWithErrorHandling(commit, apiCall, then) {
  commit('updateReviewTemplatePage', { saving: true, saveError: false });
  apiCall
    .then(x => {
      if (then) then(x);
      commit('updateReviewTemplatePage', { saving: false });
    })
    .catch(e => {
      console.log(e);
      commit('updateReviewTemplatePage', { saving: false, saveError: true });
    });
}

const getters = {
  reviewTemplateRoles(state, getters) {
    const viewerPermissions = getters.createPermissionsObject('Viewer');
    return state.roles.map(r => ({
      ...r,
      canView: viewerPermissions[`${r.id}_edit`],
    }));
  },
  createPermissionsObject(state) {
    return function (permission) {
      const permissions = state.reviewTemplate.review_template_permissions.filter(
        x => x.permission_type === permission
      );
      const permissionsObject = {};
      for (const r of state.roles) {
        permissionsObject[`${r.id}_edit`] = permissions.find(x => x.role_id === r.id)?.edit || false;
      }
      return permissionsObject;
    };
  },
  createPermissionsArray(state, getters) {
    return function (permission) {
      const permissions = state.reviewTemplate.review_template_permissions.filter(
        x => x.permission_type === permission
      );
      return getters.reviewTemplateRoles.map(r => ({
        roleId: r.id,
        selected: permissions.find(x => x.role_id === r.id)?.edit || false,
        canView: r.canView,
      }));
    };
  },
  reviewTemplateReviews(state) {
    return state.reviewTemplate.reviews;
  },
  reviewTemplatesThatShareStatementSet(state) {
    return state.reviewTemplate.review_template_statement_set.review_templates.filter(
      x => x.id !== state.reviewTemplate.id
    );
  },
  reviewTemplateInUse(state) {
    return state.reviewTemplate.reviews.length > 0;
  },
  reviewTemplateStatementsInUse(state, getters) {
    const otherTemplates = getters.reviewTemplatesThatShareStatementSet;
    return state.reviewTemplate.reviews.length > 0 || otherTemplates.some(x => x.has_reviews);
  },
  reviewTemplateAsStudentReview(state, getters) {
    const reviewTemplate = state.reviewTemplate;
    if (!reviewTemplate) return null;
    const roleIdsThatAreContributors = roleIdsWithPermission(state, 'Contributor');
    const roleIdsThatCanEditOverallJudgement = roleIdsWithPermission(state, 'OverallJudgement');
    const roleIdsThatCanEditJudgements = roleIdsWithPermission(state, 'StandardJudgement');
    const roleIdsThatCanEditStatementJudgements = roleIdsWithPermission(state, 'StandardStatementJudgement');
    const roleIdsThatCanEditPart2 = roleIdsWithPermission(state, 'Part2');

    const overallComments = reviewTemplate.review_template_overall_comments
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map(c => ({
        id: c.id,
        label: c.label,
        placeholder: c.placeholder,
        comment: null,
        optional: c.optional,
        canTraineeEdit: c.can_trainee_edit,
        roleIdsThatCanEdit: state.roles
          .filter(r => c.review_template_permissions.find(x => x.role_id === r.id)?.edit || false)
          .map(r => r.id),
      }));

    const overallStatementGroups = reviewTemplate.review_template_overall_statement_groups
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map(g => ({
        id: g.id,
        name: g.name,
        statements: g.review_template_overall_statements
          .map(s => ({
            id: s.id,
            order: s.order,
            statement: s.statement,
            canTraineeView: s.can_trainee_view,
            canTraineeEdit: s.can_trainee_edit,
            roleIdsThatCanView: state.roles
              .filter(r => s.review_template_permissions.find(x => x.role_id === r.id)?.view || false)
              .map(r => r.id),
            roleIdsThatCanEdit: state.roles
              .filter(r => s.review_template_permissions.find(x => x.role_id === r.id)?.edit || false)
              .map(r => r.id),
            judgementDescriptorId: null,
          }))
          .sort((a, b) => (a.order > b.order ? 1 : -1)),
      }));

    let overallReviewCompleted = [];
    if (
      reviewTemplate.can_trainee_view_review &&
      reviewTemplate.is_trainee_contributor &&
      overallComments.filter(x => x.canTraineeEdit).length > 0
    ) {
      overallReviewCompleted.push({
        completed: false,
        name: getters.traineeNounCapitalised(),
        roleId: 'student',
      });
    }
    overallReviewCompleted = [
      ...overallReviewCompleted,
      ...roleIdsThatAreContributors
        .filter(
          x =>
            overallComments.some(c => c.roleIdsThatCanEdit.includes(x)) ||
            overallStatementGroups.some(g => g.statements.some(s => s.roleIdsThatCanEdit.includes(x)))
        )
        .map(x => ({
          completed: false,
          name: state.roles.find(r => r.id === x).name,
          roleId: state.roles.find(r => r.id === x).id,
        })),
    ];

    const reviewStandards = [
      {
        id: 'overall',
        title: 'General',
        clickRoute: {
          name: 'TutorAdminReviewTemplateOverallPage',
          params: { templateId: reviewTemplate.id },
        },
        completed: overallReviewCompleted,
        judgementDescriptor: null,
        canAddTargets: reviewTemplate.can_add_targets_overall,
        targetCount: 0,
      },
    ].concat(
      reviewTemplate.review_template_standards
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map(x => {
          const rs = {
            id: x.id,
            title: `${x.code} - ${x.name}`,
            clickRoute: {
              name: 'TutorAdminReviewTemplateStandardPage',
              params: { id: x.id, templateId: reviewTemplate.id },
            },
            note: x.note,
            targets: [],
            judgementDescriptor: null,
            canAddTargets: reviewTemplate.can_add_targets_per_standard,
            targetCount: 0,
            statementGroups: reviewTemplate.review_standard_statement_institution_judgement_set_id
              ? reviewTemplate.review_template_statement_set.review_template_statement_groups
                  .filter(
                    g =>
                      (g.standard_id && g.standard_id === x.standard_id) ||
                      (g.substandard_id && g.substandard_id === x.substandard_id)
                  )
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map(g => ({
                    id: g.id,
                    name: g.name,
                    statements: g.review_template_statements
                      .sort((a, b) => (a.order > b.order ? 1 : -1))
                      .map(s => ({
                        id: s.id,
                        statement: s.statement,
                        guidance: s.guidance,
                        judgementDescriptorId: null,
                      })),
                  }))
              : [],
            standardComments: reviewTemplate.review_template_standard_comments
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .map(c => ({
                id: c.id,
                label: c.label,
                placeholder: c.placeholder,
                comment: null,
                optional: c.optional,
                canTraineeEdit: c.can_trainee_edit,
                roleIdsThatCanEdit: state.roles
                  .filter(r => c.review_template_permissions.find(x => x.role_id === r.id)?.edit || false)
                  .map(r => r.id),
              })),
          };

          const completed = [];
          if (
            reviewTemplate.can_trainee_view_review &&
            reviewTemplate.is_trainee_contributor &&
            rs.standardComments.filter(x => x.canTraineeEdit).length > 0
          ) {
            completed.push({
              completed: false,
              trainee: true,
              name: getters.traineeNounCapitalised(),
              roleId: 'student',
            });
          }

          rs.completed = [
            ...completed,
            ...roleIdsThatAreContributors
              .filter(x => {
                return (
                  rs.standardComments.filter(c => c.roleIdsThatCanEdit.includes(x)).length > 0 ||
                  (reviewTemplate.review_standard_institution_judgement_set_id &&
                    roleIdsThatCanEditJudgements.includes(x)) ||
                  (reviewTemplate.review_standard_statement_institution_judgement_set_id &&
                    roleIdsThatCanEditStatementJudgements.includes(x) &&
                    rs.statementGroups.length > 0)
                );
              })
              .map(x => ({
                completed: false,
                name: state.roles.find(r => r.id === x).name,
                roleId: state.roles.find(r => r.id === x).id,
              })),
          ];

          return rs;
        })
    );
    let part2Completed = [];
    if (reviewTemplate.use_part2) {
      part2Completed = roleIdsThatAreContributors
        .filter(x => roleIdsThatCanEditPart2.includes(x))
        .map(x => ({
          completed: false,
          name: state.roles.find(r => r.id === x).name,
          roleId: state.roles.find(r => r.id === x).id,
        }));

      reviewStandards.push({
        id: 'part2',
        title: 'Part 2 - Personal and Professional Conduct',
        clickRoute: {
          name: 'TutorAdminReviewTemplatePart2Page',
          params: { templateId: reviewTemplate.id },
        },
        completed: part2Completed,
      });
    }

    reviewStandards.forEach((rs, i) => {
      if (i !== reviewStandards.length - 1) {
        rs.next = reviewStandards[i + 1].clickRoute;
      }

      if (i !== 0) {
        rs.previous = reviewStandards[i - 1].clickRoute;
      }
    });

    return {
      id: -1,
      approved: false,
      name: reviewTemplate.name,
      type: reviewTemplate.review_type,
      status: reviewTemplate.status,
      targets: [],
      submissions: [],
      judgementDescriptorId: null,
      absenceCount: null,
      review: {
        canAddTargetsOverall: reviewTemplate.can_add_targets_overall,
        canTraineeAddTargetsOverall:
          reviewTemplate.can_trainee_view_review && reviewTemplate.can_trainee_add_targets_overall,
        canAddTargetsPerStandard: reviewTemplate.can_add_targets_per_standard,
        canTraineeAddTargetsPerStandard:
          reviewTemplate.can_trainee_view_review && reviewTemplate.can_trainee_add_targets_per_standard,
        canTraineesEditStandardStatementJudgements:
          reviewTemplate.can_trainee_view_review && reviewTemplate.can_trainees_edit_standard_statement_judgements,
        canTraineesViewJudgements:
          reviewTemplate.can_trainee_view_review && reviewTemplate.can_trainees_view_judgements,
        canTraineesViewOverallJudgement:
          reviewTemplate.can_trainee_view_review && reviewTemplate.can_trainees_view_overall_judgement,
        canTraineeRecordAbsences: reviewTemplate.can_trainee_view_review && reviewTemplate.can_trainee_record_absences,
        canTraineeViewReview: reviewTemplate.can_trainee_view_review,
        isTraineeContributor: reviewTemplate.can_trainee_view_review && reviewTemplate.is_trainee_contributor,
        overallJudgementSet: state.judgementSets.find(
          x => x.id === reviewTemplate.review_overall_institution_judgement_set_id
        ),
        standardJudgementSet: state.judgementSets.find(
          x => x.id === reviewTemplate.review_standard_institution_judgement_set_id
        ),
        standardStatementJudgementSet: state.judgementSets.find(
          x => x.id === reviewTemplate.review_standard_statement_institution_judgement_set_id
        ),
        overallStatementJudgementSet: state.judgementSets.find(
          x => x.id === reviewTemplate.review_overall_statement_institution_judgement_set_id
        ),
        overallNote: reviewTemplate.overall_note,
        overallAssessmentGuidance: reviewTemplate.overall_assessment_guidance,
        standardNoun: reviewTemplate.standard_set.standard_noun,
        recordAbsences: reviewTemplate.record_absences,
        roleIdsThatCanEditOverallJudgement,
        roleIdsThatCanEditJudgements,
        roleIdsThatCanEditStatementJudgements,
        roleIdsThatCanEditPart2,
        roleIdsThatCanAddTargetsOverall: roleIdsWithPermission(state, 'AddTargetsOverall'),
        roleIdsThatCanAddTargetsPerStandard: roleIdsWithPermission(state, 'AddTargetsPerStandard'),
        roleIdsThatAreContributors,
        roleIdsThatCanApprove: roleIdsWithPermission(state, 'Approve'),
        roleIdsThatCanViewReview: roleIdsWithPermission(state, 'Viewer'),
        rolesIdsThatCanRecordAbsences: roleIdsWithPermission(state, 'RecordAbsences'),
        useStPhilipsLegacyPart2JudgementSet: [1183, 1184].includes(reviewTemplate.id), // St. Philip's reivew template created by the legacy review migration
      },
      overallComments,
      overallReviewCompleted,
      overallStatementGroups: reviewTemplate.review_overall_statement_institution_judgement_set_id
        ? overallStatementGroups
        : [],
      part2Statements: reviewTemplate.review_template_part2_statements
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map(s => ({
          id: s.id,
          statement: s.statement,
          judgementDescriptorId: null,
          comment: null,
          concernsRaised: false,
        })),
      part2Completed,
      reviewStandards,
    };
  },
  previewStudent() {
    return {
      id: 123,
    };
  },
  previewUser(state) {
    if (state.reviewTemplatePage.previewRoleId === 'trainee') {
      return {
        student: {
          id: 123,
        },
      };
    }

    return {
      staff: {
        staff_roles: [
          {
            student_id: 123,
            role: {
              id: state.reviewTemplatePage.previewRoleId,
            },
          },
        ],
      },
    };
  },
};

function roleIdsWithPermission(state, permission) {
  const viewerPermissions = state.reviewTemplate.review_template_permissions
    .filter(x => x.permission_type === 'Viewer' && x.edit)
    .map(x => x.role_id);
  return state.roles
    .filter(
      r =>
        state.reviewTemplate.review_template_permissions
          .filter(x => x.permission_type === permission && viewerPermissions.includes(x.role_id))
          .find(x => x.role_id === r.id)?.edit || false
    )
    .map(r => r.id);
}

function getMaxId(arrayWithId) {
  return arrayWithId.length > 0 ? Math.max(...arrayWithId.map(x => x.id)) : 0;
}

export default {
  defaultState,
  mutations,
  actions,
  getters,
};
