<template>
  <mosaic-loading-and-error-cards object-type="Event" :load="load">
    <mosaic-save-card
      v-if="event"
      :readonly="true"
      :save="async () => {}"
      object-type="Event"
      :return-to="getReturnTo(breadcrumbs)"
      :can-save="true"
    >
      <mosaic-card-title :chip="chip">{{ event.staffTrainingEvent.name }}</mosaic-card-title>
      <div class="pt-2">
        <mosaic-text-field label="Name" :model-value="event.staffTrainingEvent.name" prepend-icon="pencil" readonly />
        <div class="d-flex">
          <div class="mr-6">
            <mosaic-date-picker :date="date" label="Date" name="date" :readonly="true" />
          </div>
          <div class="mr-6">
            <mosaic-time-picker :model-value="time" label="Time" mode="time" :readonly="true" />
          </div>
          <mosaic-time-picker :model-value="duration" label="Duration" mode="duration" :readonly="true" />
        </div>
      </div>
      <div v-if="event.staffTrainingEvent.meetingLink" class="d-flex align-center mb-4">
        <mosaic-icon icon="laptop-account" />
        <a class="pl-4 text-link" :href="event.staffTrainingEvent.meetingLink" target="_blank">Online meeting link</a>
      </div>
      <mosaic-quill-field
        v-if="event.staffTrainingEvent.description"
        :model-value="event.staffTrainingEvent.description"
        label="Description"
        name="description"
        :readonly="true"
      />
      <staff-training-competency-multi-select
        v-if="institutionStaffTrainingFrameworks"
        :model-value="event.staffTrainingEvent.staffTrainingEventCompetencies.map(c => c.staffTrainingCompetencyId)"
        :frameworks="institutionStaffTrainingFrameworks"
        :readonly="true"
        label="Competencies this Event meets"
        :assigned-framework-ids="assignedStaffTrainingFrameworks.map(f => f.id)"
        :staff-training-competency-to="staffTrainingCompetencyTo"
      />
    </mosaic-save-card>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { setBreadcrumbs, getReturnTo } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import { myStaffTrainingBreadcrumbs, selectedStaffTrainingBreadcrumbs, useStaffTraining } from './staff-training';
import { useRoute } from 'vue-router';
import { useInstitutionStore } from '@/stores/institution';
import { useApi } from '@/composables/api';
import { parseRouteId } from '@/composables/vue-router';
import { dateTimeIsInThePast, splitIntoLocalDateAndTime } from '@/utils/date';
import { formatTime } from '@/utils/time';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';
import StaffTrainingCompetencyMultiSelect from './StaffTrainingCompetencyMultiSelect.vue';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { mapState } from '@/store/map-store';

const { staff, staffId, isMyStaffTraining, loadStaff, staffTrainingCompetencyTo } = useStaffTraining();
const { selectedInstitution } = useInstitutionStore();
const { selectedCohort } = mapState();

const {
  assignedStaffTrainingFrameworks,
  actions: { loadStaffTraining },
} = useStaffTrainingStore();
const {
  institutionStaffTrainingFrameworks,
  actions: { loadInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();
const route = useRoute();
const eventId = parseRouteId('eventId');
const api = useApi();

// #region data
type StaffTrainingEventResponse = {
  staffTrainingEvent: {
    id: number;
    name: string;
    startsAt: string;
    durationHours: number;
    durationMinutes: number;
    description: string;
    meetingLink: string;
    staffTrainingEventCompetencies: {
      staffTrainingCompetencyId: number;
    }[];
  };
  staffTrainingEventAttendance: {
    id: number;
  } | null;
};

const event = ref<StaffTrainingEventResponse>();
const chip = computed(() => {
  if (!event.value) return undefined;
  if (!dateTimeIsInThePast(event.value.staffTrainingEvent.startsAt)) {
    return {
      text: 'Upcoming',
      color: 'accent',
      tooltip: 'This Event has not happened yet',
    };
  }

  const prefix = isMyStaffTraining ? 'You have' : 'This Instructor has';
  if (event.value.staffTrainingEventAttendance) {
    return {
      text: 'Attended',
      color: 'primary',
      tooltip: `${prefix} been marked as attending this Event`,
    };
  }

  return {
    text: 'Did not attend',
    color: 'secondary',
    tooltip: `${prefix} not been marked as attending this Event`,
  };
});
const duration = computed(() =>
  formatTime(event.value!.staffTrainingEvent.durationHours, event.value!.staffTrainingEvent.durationMinutes)
);
const date = computed(() => splitIntoLocalDateAndTime(event.value!.staffTrainingEvent.startsAt).date);
const time = computed(() => splitIntoLocalDateAndTime(event.value!.staffTrainingEvent.startsAt).time);
// #endregion

// #region breadcrumbs
const breadcrumbs = computed(() => {
  return [
    ...(isMyStaffTraining.value
      ? myStaffTrainingBreadcrumbs('events')
      : selectedStaffTrainingBreadcrumbs(
          'events',
          selectedInstitution.value?.id || selectedCohort.value.institution_id,
          staffId.value,
          route,
          staff.value?.user?.name,
          selectedCohort.value?.id
        )),
    {
      text: event.value?.staffTrainingEvent.name || '',
    },
  ];
});
setBreadcrumbs(breadcrumbs);
// #endregion

// #region load
async function load() {
  await Promise.all([
    loadStaff(),
    loadEvent(),
    loadInstitutionStaffTrainingFrameworks(),
    loadStaffTraining(staffId.value, undefined, selectedCohort.value?.id),
  ]);
}

async function loadEvent() {
  const r = await api.get<StaffTrainingEventResponse>(`/staff/${staffId.value}/staff-training-events/${eventId.value}`);
  event.value = r.data;
}
//
</script>
