<template>
  <student-lesson-observation-edit-page v-if="isStudent" @update:dirty="dirty = $event" ref="page" />
  <tutor-lesson-observation-edit-page v-else @update:dirty="dirty = $event" ref="page" />

  <unsaved-changes-dialog
    v-model:unsaved-changes-dialog="dialog"
    object-type="Lesson Observation"
    :save="isCreating ? undefined : page?.save"
  />
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import StudentLessonObservationEditPage from './StudentLessonObservationEditPage.vue';
import TutorLessonObservationEditPage from './TutorLessonObservationEditPage.vue';
import { ref } from 'vue';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { isCreatingRoute } from '@/composables/vue-router';

const { isStudent } = useUserStore();

const isCreating = isCreatingRoute();

// This only needs to be done if the child pages are options API
const dirty = ref(false);
const { dialog } = useUnsavedChanges(dirty);

const page = ref<(HTMLInputElement & { save: () => Promise<void> }) | null>(null);
</script>
