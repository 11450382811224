import { defineStore } from 'pinia';
import { useStore } from './common';
import { mapState } from '@/store/map-store';
import { computed } from 'vue';

export const useUserStore = useStore(
  defineStore('user', () => {
    // TODO: remove userStaff from staff store?
    const { userStaff: stateUserStaff, userStudent: stateUserStudent, user } = mapState();

    function reset() {}

    const userStaff = computed(() => ({ ...stateUserStaff.value, user: user.value }));

    const userStudent = computed(() => ({ ...stateUserStudent.value, user: user.value }));

    const isStudent = computed(() => !!stateUserStudent.value);

    return { reset, user, isStudent, userStaff, userStudent };
  })
);
