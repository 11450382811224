<template>
  <mosaic-save-dialog
    :active="active"
    @update:active="emit('update:active', $event)"
    title="Edit Standard"
    object-type="Standard"
    :save="saveEditStandard"
    :can-save="canEditStandard"
    @save="emit('save')"
  >
    <template #default="{ onKeyupEnter }">
      <mosaic-text-field
        v-model="internalStandard.code"
        prepend-icon="mdi-pencil"
        name="code"
        label="Code"
        hide-details
      ></mosaic-text-field>
      <mosaic-text-field
        v-model="internalStandard.name"
        name="name"
        label="Name"
        hide-details
        prepend-icon="mdi-pencil"
        @keyup.enter="onKeyupEnter"
      ></mosaic-text-field>
      <mosaic-select
        v-model="internalStandard.subject"
        prepend-icon="mdi-pencil-ruler"
        name="edit-student-subject"
        :items="subjects"
        placeholder="Subject"
        item-title="name"
        item-value="id"
        return-object
        ><template #item="{ item, props: slotProps }">
          <v-divider v-if="item.raw.divider" />
          <v-list-item v-else v-bind="slotProps" :title="item.raw.name" /> </template></mosaic-select
    ></template>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { computed } from 'vue';
import { ref, watchEffect } from 'vue';

import { mapGetters, mapActions } from '@/store/map-store';

const { subjectsWithNullOptionAndPrimarySeparation: subjects } = mapGetters();
const { loadSubjects } = mapActions();

loadSubjects();

// props.contentLayout is the bound contentLayout on contentLayout builder so may include unsaved state
// props.section.contentLayout is the saved state
type StandardOrSubstandard = {
  id: number;
  code: string;
  name: string;
  title: string;
  order: number;
  subject: {
    id: number;
    name: string;
  } | null;
};
const props = withDefaults(
  defineProps<{
    active: boolean;
    standardOrSubstandard: StandardOrSubstandard;
    isSubstandard?: boolean;
  }>(),
  {
    isSubstandard: false,
  }
);

const emit = defineEmits<{
  'update:active': [active: boolean];
  save: [void];
}>();

const api = useApi();

const internalStandard = ref<StandardOrSubstandard>({
  name: '',
  title: '',
  subject: null,
  order: 0,
  id: 0,
  code: '',
});

watchEffect(() => {
  internalStandard.value = {
    name: props.standardOrSubstandard.name,
    subject: props.standardOrSubstandard.subject,
    code: props.standardOrSubstandard.code,
    order: props.standardOrSubstandard.order,
    id: props.standardOrSubstandard.id,
    title: props.standardOrSubstandard.name,
  };
});

const canEditStandard = computed(() => {
  const s = props.standardOrSubstandard;
  return (
    !!internalStandard.value.name &&
    !!internalStandard.value.code &&
    (internalStandard.value.name !== s.name ||
      internalStandard.value.subject?.id !== s.subject?.id ||
      internalStandard.value.code !== s.code)
  );
});

async function saveEditStandard() {
  const url = props.isSubstandard
    ? `admin-standards/substandards/${props.standardOrSubstandard.id}/details`
    : `admin-standard-sets/standards/${props.standardOrSubstandard.id}/details`;
  await api.put(url, {
    name: internalStandard.value.name,
    code: internalStandard.value.code,
    subjectId: internalStandard.value?.subject?.id,
  });

  emit('update:active', false);
}
</script>
