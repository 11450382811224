import type { StoreGeneric } from 'pinia';
import { storeToRefs } from 'pinia';

// This is use to wrap pinia store definition so they return refs rather than reactive
// This is for better consistency with normal code and the fact that Vue themselves seems to have moved
// towards this approach when they abandoned the reactivity transform
export function useStore<T extends StoreGeneric>(storeDefinition: () => T) {
  return () => {
    const store = storeDefinition();

    if (!store.reset) {
      throw `Pinia store '${store.$id}' does not implement 'reset' which is needed to clear state on logout.`;
    }

    // All stores should implement a reset function (that will be called on logout and can be used to reset the store from devtools)
    // https://pinia.vuejs.org/core-concepts/state.html#Resetting-the-state
    store.$reset = store.reset;

    // I want to `return { ...store, ...storeToRefs(store) }`, but this creates an intersection type for getters that I don't understand
    // e.g. the type of curriculumStatements is `CurriculumStatements & ComputedRef<CurriculumStatements>` instead of `ComputedRef<CurriculumStatements>`
    return { ...storeToRefs(store), actions: store };
  };
}
