<template>
  <div>
    <mosaic-tab-card-page object-type="Instructor" :headers="tabHeaders" :load="load">
      <template #title>
        <div class="d-flex">
          <mosaic-card-title class="mr-4">
            <div class="d-flex align-center mb-1">
              <span class="mr-2">{{ title }}</span>
              <demo-account-badge v-if="staff?.user.is_demo" />
            </div>

            <template #avatar v-if="staff">
              <mosaic-avatar
                :user="{
                  ...staff.user,
                  displayName: staff.user.display_name,
                  profilePictureUpdatedAt: staff.user.profile_picture_updated_at,
                }"
                :hide-profile-picture="hideProfilePicture"
                size="x-large"
              />
            </template>

            <template
              #subtitle
              v-if="
                (props.canViewTraining || props.canEditTraining) &&
                staffTraining?.totalDuration &&
                !selectedInstitution.config.early_careers
              "
            >
              <staff-training-subtitle
                :total-duration="staffTraining.totalDuration"
                :final-clause="`across ${
                  selectedInstitution?.name ? selectedInstitution?.name + ' Institution' : 'this Institution'
                }`"
              />
            </template>
          </mosaic-card-title>
        </div>
      </template>

      <template #info
        ><mosaic-info-alert v-if="staff && staffTrainingHasNasbttModules && !staff.nasbtt_modules_user_id" class="mt-2"
          >This Instructor has not yet linked their NASBTT account to Mosaic</mosaic-info-alert
        ></template
      >
      <template #details-tab-item>
        <staff-details-tab
          v-if="staff && props.canViewStaff && props.showStaffDetailsAndRoles"
          :staff="staff"
          @update-staff-data="loadStaff"
          @update:hide-profile-picture="hideProfilePicture = $event"
          :can-edit-details="props.canEditStaff"
          :show-edit-details-actions="props.showEditDetailsActions"
        />
      </template>

      <template #roles-tab-item>
        <staff-roles-tab
          v-if="props.canViewStaff && props.showStaffDetailsAndRoles"
          :staff="staff"
          :staff-roles="staffRoles"
          :can-edit-roles="props.canEditStaff"
          :show-edit-roles-actions="props.showEditRolesActions"
          @update-staff="updateStaff"
          :info-alert-message="
            selectedCohort?.name || (selectedInstitution?.name && staff?.user?.name)
              ? `You are viewing ${isMe ? `your Roles` : `the Roles of Instructor ${staff?.user.name}`} for the ${
                  selectedCohort?.name ? `${selectedCohort.name} Cohort` : `${selectedInstitution?.name} Institution`
                }`
              : undefined
          "
        />
      </template>

      <template #training-requirements-tab-item>
        <staff-training-requirements-tab
          v-if="props.canViewTraining && props.showTraining"
          :frameworks="frameworks"
          :click-theme-to="theme => clickThemeTo(theme)"
          :load-error="staffTrainingLoadError"
          show-cohorts-filter
        />
      </template>

      <template #training-modules-tab-item>
        <staff-training-modules-tab
          v-if="props.canViewTraining && props.showTraining"
          :module-completions="moduleCompletions"
          :click-module-to="module => clickModuleTo(module)"
          :load-error="staffTrainingLoadError"
          :reload="() => loadTraining(true, false)"
        />
      </template>

      <template #manual-training-records-tab-item>
        <staff-training-manual-records-tab
          v-if="props.canViewTraining && props.showTraining"
          :training-records="trainingRecords"
          :click-record-to="record => clickRecordTo(record)"
          :create-training-record-to="{
            name: selectedCohort?.id
              ? 'CohortStaffTrainingRecordCreatePage'
              : 'InstitutionStaffTrainingRecordCreatePage',
            params: redirectionCommonParams,
          }"
          :load-error="staffTrainingLoadError"
          :reload="() => loadTraining(true, false)"
          :show-add-training-record="props.showAddTrainingRecord"
          :show-delete-training-record="props.showDeleteTrainingRecord"
          :can-delete-record="props.canEditTraining"
          :can-add-record="props.canEditTraining"
        />
      </template>

      <template #certificates-tab-item>
        <div v-if="props.canViewTraining && props.showTraining">
          <div v-if="staffTrainingLoadError" class="mt-2">
            Sorry, cannot load this Instructor's Certificates right now. If this problem persists, please contact
            support.
          </div>
          <staff-training-certificates-tab
            v-else-if="staffTraining"
            :staff-training="staffTraining"
            :click-certificate-to="
              c => ({
                name: selectedCohort?.id
                  ? 'CohortStaffTrainingCertificateEditPage'
                  : 'InstitutionStaffTrainingCertificateEditPage',
                params: {
                  certificateId: c.id,
                  ...redirectionCommonParams,
                },
              })
            "
            :create-certificate-to="{
              name: 'InstitutionStaffTrainingCertificateCreatePage',
              params: redirectionCommonParams,
            }"
            :certificate-deleted="() => loadTraining(true, true)"
            :read-only="!props.canEditTraining"
          />
        </div>
      </template>

      <template #events-tab-item>
        <div v-if="props.canViewTraining && props.showTraining">
          <div v-if="staffTrainingLoadError" class="mt-2">
            Sorry, cannot load this Instructor's Events right now. If this problem persists, please contact support.
          </div>
          <staff-training-events-tab
            v-else-if="staffTraining"
            :staff-training="staffTraining"
            :click-event-to="clickEventTo"
          />
        </div>
      </template>
    </mosaic-tab-card-page>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { mapState } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import StaffTrainingRequirementsTab from '@/pages/staff-training/StaffTrainingRequirementsTab.vue';
import StaffTrainingModulesTab from '@/pages/staff-training/StaffTrainingModulesTab.vue';
import StaffTrainingManualRecordsTab from '@/pages/staff-training/StaffTrainingManualRecordsTab.vue';
import StaffTrainingCertificatesTab from '@/pages/staff-training/StaffTrainingCertificatesTab.vue';
import StaffTrainingEventsTab from '@/pages/staff-training/StaffTrainingEventsTab.vue';
import StaffTrainingSubtitle from '@/pages/staff-training/StaffTrainingSubtitle.vue';
import DemoAccountBadge from '@/components/DemoAccountBadge.vue';
import { useApi } from '@/composables/api';
import { useStaffTrainingStore, type StaffTraining } from '@/stores/staff-training';
import { parseRouteId } from '@/composables/vue-router';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { staffTrainingTabHeaders } from '@/pages/staff-training/staff-training';
import StaffDetailsTab from './StaffDetailsTab.vue';
import StaffRolesTab from './StaffRolesTab.vue';
import type { SelectedStaff, StaffRole } from '@/stores/staff';

const { selectedInstitution, user, selectedCohort } = mapState();

const staffId = parseRouteId('id');
const api = useApi();

const props = withDefaults(
  defineProps<{
    clickTo: 'InstitutionStaffListPage' | 'CohortStaffListPage';
    canEditStaff: boolean;
    canViewStaff: boolean;
    canEditTraining: boolean;
    canViewTraining: boolean;
    loadStaffParams?: string;
    showAddTrainingRecord?: boolean;
    showDeleteTrainingRecord?: boolean;
    showEditDetailsActions?: boolean;
    showEditRolesActions?: boolean;
    showTraining?: boolean;
    showStaffDetailsAndRoles?: boolean;
  }>(),
  {
    canEditStaff: false,
    canViewStaff: false,
    canEditTraining: false,
    canViewTraining: false,
    showAddTrainingRecord: false,
    showDeleteTrainingRecord: false,
    showEditDetailsActions: false,
    showEditRolesActions: false,
    showTraining: false,
    showStaffDetailsAndRoles: false,
  }
);

const displayTraining = computed(
  () => !selectedInstitution.value.config.early_careers && props.canViewTraining && props.showTraining
);

async function load() {
  await loadStaff();
  if (displayTraining.value) await loadTraining(false, false);
}

const title = computed(() => (staff.value ? staff.value.user.name : ''));
const staff = ref<SelectedStaff | null>(null);
const hideProfilePicture = ref(false);
const staffRoles = ref<StaffRole[] | []>([]);
const isMe = computed(() => user.value?.staff?.some(s => s.id === staffId.value));

const redirectionCommonParams = computed(() => ({
  staffId: staff.value!.id.toString(),
  institutionId: selectedInstitution.value?.id || selectedCohort.value?.institution_id,
  cohortId: selectedCohort.value?.id,
}));

function updateStaffData(data: SelectedStaff) {
  staff.value = data;
  staffRoles.value = data.staff_roles.sortBy(['created_at'], ['desc']);
}
async function loadStaff() {
  const r = await api.get<SelectedStaff>(
    `/staff/${staffId.value}${props.loadStaffParams ? props.loadStaffParams : ''}`
  );
  updateStaffData(r.data);
}

// Staff Training
const {
  assignedStaffTrainingFrameworks: frameworks,
  staffTrainingModuleCompletions: moduleCompletions,
  staffTrainingRecords: trainingRecords,
  staffTraining,
  staffTrainingHasNasbttModules,
  actions: { loadStaffTraining },
} = useStaffTrainingStore();

const { action: loadTraining, error: staffTrainingLoadError } = withProcessingAndError(
  (force: boolean, forUser: boolean) =>
    forUser
      ? Promise.all(user.value.staff?.map(staff => loadStaffTraining(staff.id, true, selectedCohort?.value?.id)) || [])
      : loadStaffTraining(staffId.value, force || false, selectedCohort?.value?.id)
);

function clickThemeTo(
  theme: StaffTraining['staffTrainingFrameworks'][number]['staffTrainingCompetencyThemes'][number]
) {
  return {
    name: selectedCohort.value?.id
      ? 'CohortTutorStaffTrainingCompetencyThemePage'
      : 'InstitutionTutorStaffTrainingCompetencyThemePage',
    params: {
      themeId: theme.id.toString(),
      ...redirectionCommonParams.value,
    },
    query: { fromTab: 'training-requirements' },
  };
}

function clickEventTo(event: StaffTraining['staffTrainingEvents'][number]) {
  return {
    name: selectedCohort.value?.id ? 'CohortStaffTrainingEventPage' : 'InstitutionStaffTrainingEventPage',
    params: {
      eventId: event.id.toString(),
      ...redirectionCommonParams.value,
    },
    query: { fromTab: 'events' },
  };
}

function clickModuleTo(module: StaffTraining['staffTrainingModuleCompletions'][number]['staffTrainingModule']) {
  return {
    name: selectedCohort.value?.id
      ? 'CohortTutorCohortStaffTrainingModulePage'
      : 'InstitutionTutorStaffTrainingModulePage',
    params: {
      moduleId: module.id.toString(),
      ...redirectionCommonParams.value,
    },
    query: { fromTab: 'training-modules' },
  };
}

function clickRecordTo(record: StaffTraining['staffTrainingRecords'][number]) {
  return {
    name: selectedCohort.value?.id ? 'CohortStaffTrainingRecordEditPage' : 'InstitutionStaffTrainingRecordEditPage',
    params: {
      recordId: record.id.toString(),
      ...redirectionCommonParams.value,
    },
  };
}

// Tab setup
const tabHeaders = computed(() => {
  let headers =
    props.canEditStaff || props.canViewStaff
      ? [
          {
            key: 'details',
            text: `Details`,
          },
          {
            key: 'roles',
            text: `Roles in the ${selectedCohort.value?.id ? 'Cohort' : 'Institution'}`,
          },
        ]
      : [];
  if (displayTraining.value) {
    headers = headers.concat(staffTrainingTabHeaders);
  }
  return headers;
});

async function updateStaff() {
  await loadStaff();
  if (displayTraining.value) await loadStaffTraining(staffId.value, true, selectedCohort?.value?.id);
}

setBreadcrumbs(
  computed(() => [
    {
      text: 'Instructors',
      to: {
        name: props.clickTo,
      },
    },
    {
      text: staff?.value?.user.name || staff?.value?.user.email || '',
    },
  ])
);
</script>
