<template>
  <mosaic-inline-creation-list-page
    object-type="Standard"
    :title="standardSetName"
    list-title="Standards"
    :load="load"
    :items="standardItems"
    :icon="icons.evidenceNotLinked"
    :hide-add="false"
    :can-add-item="canAdd"
    :move-item="moveStandardItem"
    :add-item="() => addStandard()"
    :edit-item-clicked="editStandard"
    edit-action="Edit"
    :delete-url-stem="`admin-standard-sets/standards`"
    v-model:trigger-background-load="triggerBackgroundLoad"
  >
    <template #subtitle>
      <div v-if="institutions.length" class="wrap">
        {{ 'Institutions using this standard set: ' + institutions.map(i => i.name).join(', ') }}
      </div>
      <div v-if="cohort" class="wrap">
        {{ 'Cohort using this standard set: ' + cohort.name }}
      </div>
      <mosaic-alert v-if="cohort || institutions.length" class="mt-2" type="warning">
        CAUTION: Any edits made on this page will be reflected in the UI for users in institutions and cohorts using
        this standard set. This includes ordering.
      </mosaic-alert>
      <div class="pt-4 d-flex align-center">
        <mosaic-text-field
          v-model="standardNoun"
          class="pr-4"
          style="width: 200px"
          name="code"
          label="Standard Noun"
          type="text"
          no-icon
        />
        <mosaic-text-field
          v-model="standardNounPlural"
          name="title"
          style="width: 200px"
          label="Standard Noun Plural"
          type="text"
          no-icon
        />
        <v-btn class="ml-6" @click.prevent="updateStandardNoun()">Update</v-btn>
      </div></template
    >
    <template #information="{ item }">
      <v-chip v-if="item.professional" class="mr-2">Part 2</v-chip>
      <v-chip v-if="item.reviewOnly" class="mr-2">Review Only</v-chip>
      <v-chip v-if="item.subject">{{ item.subject.name }}</v-chip></template
    >
    <template #title-actions><mosaic-btn :disabled="copying" @click.prevent="copy()">Make a copy</mosaic-btn></template>
    <template #add-item-fields>
      <div class="d-flex align-top flex-grow-1">
        <mosaic-text-field
          v-model="newStandard.code"
          name="new-code"
          style="width: 100px"
          label="Code"
          class="mr-4"
          hide-details
          prepend-icon="mdi-pencil"
        ></mosaic-text-field>
        <mosaic-text-field
          v-model="newStandard.name"
          name="new-name"
          class="flex-grow-1 mr-2"
          label="Name"
          hide-details
          prepend-icon="mdi-pencil"
        ></mosaic-text-field>
        <mosaic-select
          v-model="newStandard.subject.id"
          prepend-icon="mdi-pencil-ruler"
          name="edit-student-subject"
          class="flex-srhink-1"
          :items="subjectsWithNullOptionAndPrimarySeparation"
          placeholder="Subject"
          item-title="name"
          item-value="id"
          ><template #item="{ item, props: slotProps }">
            <v-divider v-if="item.raw.divider" />
            <v-list-item v-else v-bind="slotProps" :title="item.raw.name" /> </template
        ></mosaic-select>
      </div>
    </template>
  </mosaic-inline-creation-list-page>
  <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" :message="snackbar.message" />
  <admin-standard-edit-dialog
    v-model:active="editStandardDialog.active"
    :standard-or-substandard="editStandardDialog.standardOrSubstandard"
    @save="triggerBackgroundLoad = true"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { parseRouteId } from '@/composables/vue-router';
import { useApi } from '@/composables/api';
import { useSubjectsStore } from '@/stores/subjects';
import { icons } from '@/utils/icons';
import { useMoveListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { useEditStandardOrSubstandard } from '@/composables/admin-standards';
import AdminStandardEditDialog from './AdminStandardEditDialog.vue';
import { useRouter } from 'vue-router';
import type { SnackbarDetails } from '@/components/library/feedback/MosaicSnackbar.vue';
const {
  actions: { loadSubjects },
  subjectsWithNullOptionAndPrimarySeparation,
} = useSubjectsStore();
import { setBreadcrumbs } from '@/utils/breadcrumbs';

const api = useApi();
const router = useRouter();
const standardSetId = parseRouteId('standardSetId');

const standardSetName = ref('');
const standards = ref<StandardResponse[]>([]);
const institutions = ref<InstitutionResponse[]>([]);
const standardNoun = ref('');
const standardNounPlural = ref('');
const cohort = ref<CohortResponse | null>(null);

interface StandardResponse {
  id: number;
  code: string;
  name: string;
  order: number;
  review_only: boolean;
  professional: boolean;
  subject: {
    id: number;
    name: string;
  };
  standard_set_id: number;
}

interface InstitutionResponse {
  id: number;
  name: string;
}
interface CohortResponse {
  id: number;
  name: string;
}

interface StandardSetResponse {
  id: number;
  name: string;
  standard_noun: string;
  standard_noun_plural: string;
  standards: StandardResponse[];
  institutions: InstitutionResponse[];
  cohort: CohortResponse;
}

const triggerBackgroundLoad = ref(false);
async function getStandards() {
  const r = await api.get<StandardSetResponse>(`/standard-sets/${standardSetId.value}`);
  standardSetName.value = r.data.name;
  standardNoun.value = r.data.standard_noun;
  standardNounPlural.value = r.data.standard_noun_plural;
  standards.value = r.data.standards;
  institutions.value = r.data.institutions;
  cohort.value = r.data.cohort;
}

const load = async () => {
  getStandards();
  loadSubjects();
};

const standardItems = computed(() => {
  return standards.value.map(s => ({
    ...s,
    title: `${s.code}: ${s.name}`,
    hideMove: false,
    disableDelete: institutions.value.length > 0,
    reviewOnly: s.review_only,
    clickTo: {
      name: 'AdminStandardSetSubstandardsPage',
      params: { standardId: s.id },
    },
  }));
});

//#region Add Standard
const newStandard = ref({
  code: '',
  name: '',
  subject: { id: null, name: '' },
});

const canAdd = computed(() => !!(newStandard.value.code && newStandard.value.name));

async function addStandard() {
  await api.post(`/admin-standard-sets/${standardSetId.value}/standards`, { ...newStandard.value });
}

//#endregion

//#region Move
const { moveListItem: moveStandardItem } = useMoveListItem('/admin-standard-sets/standards', api);

const { editStandardOrSubstandard: editStandard, editStandardOrSubstandardDialog: editStandardDialog } =
  useEditStandardOrSubstandard();

//#endregion

const copying = ref(false);
const copyErrorMessage = ref<string>('');

async function copy() {
  copying.value = true;
  copyErrorMessage.value = '';
  try {
    const r = await api.post<unknown, { id: number }>(`/standard-sets/${standardSetId.value}/copy`, {});
    router.push({ name: 'AdminStandardSetStandardsPage', params: { standardSetId: r.data.id } });
  } catch (e) {
    copyErrorMessage.value = 'Error copying this standard set';
    console.log(e);
  }
  copying.value = false;
}

const snackbar = ref<SnackbarDetails>({
  active: false,
  color: 'success',
  message: '',
});

async function updateStandardNoun() {
  try {
    await api.put(`/standard-sets/${standardSetId.value}`, {
      standard_noun: standardNoun.value,
      standard_noun_plural: standardNounPlural.value,
    });
    snackbar.value = {
      active: true,
      color: 'success',
      message: 'Success!',
    };
  } catch (e) {
    console.log(e);
    snackbar.value = {
      active: true,
      color: 'error',
      message: 'Sorry cannot update standard nouns right now',
    };
  }
}

const breadcrumbs = computed(() => {
  return [
    {
      text: 'Standard Sets',
      to: {
        name: 'AdminStandardSetsPage',
      },
    },
    {
      text: standardSetName.value,
    },
  ];
});
setBreadcrumbs(breadcrumbs);
</script>
