<template>
  <div>
    <mosaic-loading-card v-if="busy" type="review-view" />
    <mosaic-load-error-card v-if="error && !busy" :object-type="reviewNounCapitalised" @retry="loadReview" />
    <div v-if="!error && !busy">
      <v-card class="mb-4">
        <v-card-text class="pa-5">
          <div class="d-flex">
            <div class="text-h6">{{ reviewNounCapitalised }}</div>
            <div class="flex-grow-1"></div>
            <v-btn @click.prevent="download()"><span>Download</span></v-btn>
            <v-btn
              v-if="userStaffHasPermissionForSelectedCohort('reviews.sendReminders')"
              class="ml-2"
              @click.prevent="sendReminders()"
              ><v-icon>mdi-email-multiple</v-icon><span class="pl-1">Reminder Emails</span></v-btn
            >
          </div>
          <div class="d-flex align-center">
            <v-icon class="mt-5">mdi-pencil</v-icon>
            <div class="pl-2">
              <div class="text-caption">Name</div>
              <div class="text-body-1">{{ review.name }}</div>
            </div>
          </div>
          <mosaic-text-field
            v-if="review.note"
            v-model="review.note"
            label="Note"
            name="note"
            :readonly="true"
            class="mt-6"
          />
          <div class="d-flex align-center mt-6">
            <v-icon class="mt-5">mdi-calendar</v-icon>
            <div class="pl-2">
              <div class="text-caption">Due date</div>
              <div class="text-body-1">{{ formatDate(review.due_date) }}</div>
            </div>
          </div>
          <div class="mt-6 mb-4 d-flex align-center">
            <v-icon class="mt-5">mdi-scissors-cutting</v-icon>
            <div class="pl-2">
              <div class="text-caption">{{ reviewNounCapitalised }} Template</div>
              <mosaic-router-link class="text-body-1" :to="reviewTemplateTo()">{{
                review.review_template_name
              }}</mosaic-router-link>
            </div>
          </div>
          <div v-if="review.review_type" class="mt-6 mb-4 d-flex align-center">
            <div class="pl-8">
              <div class="text-caption">{{ reviewNounCapitalised }} Type</div>
              <div class="text-body-1">{{ reviewTypeItems.find(x => x.value === review.review_type).title }}</div>
            </div>
          </div>
          <div class="d-flex align-end pb-4">
            <div>
              <v-text-field
                v-model="traineeFilter"
                hide-details
                prepend-icon="mdi-magnify"
                style="width: 250px"
                :label="`Filter by ${traineeNounCapitalised()} name or email`"
              />
            </div>
            <mosaic-cohort-monitoring-filters
              :students="students"
              @update:filtered-student-ids="filteredStudentIds = $event"
              @filter-state-updated="cohortMonitoringFilterState = $event"
            />
          </div>
          <div class="d-flex align-end">
            <div class="d-flex">
              <div>
                <v-select
                  v-model="statusFilter"
                  class="multiple-v-select"
                  style="min-width: 250px"
                  multiple
                  label="Filter by status"
                  :items="statuses"
                  prepend-icon="mdi-progress-check"
                  hide-details
                />
              </div>
            </div>
            <div class="flex-grow-1"></div>
            <v-btn ripple @click.prevent="editReview()">
              <div class="d-flex align-center">
                <v-icon>mdi-pencil</v-icon>
                <span class="pl-1">Edit</span>
              </div>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <template v-if="review">
            <div v-if="filteredStudentReviews.length === 0" class="pa-4 pl-6">
              There are no {{ reviewNounPluralised }} for these filters
            </div>
            <mosaic-list v-else :items="paginatedStudentReviews">
              <template #item="{ item: studentReview }">
                <mosaic-list-item
                  :to="studentClickTo(studentReview)"
                  icon="mdi-check-circle-outline"
                  :title="studentReview.student.name ? studentReview.student.name : studentReview.student.email"
                >
                  <template #information>
                    <div class="d-flex align-center">
                      <template v-if="studentReview.due_date != review.due_date">
                        <mosaic-help large
                          >This due date has been edited for this {{ traineeNounCapitalised() }} since
                          {{ reviewNounCapitalised }} creation</mosaic-help
                        >
                      </template>
                      <div
                        v-if="studentReview.student.ect && studentReview.student.ect.status === 'completed_induction'"
                        class="pr-2"
                      >
                        <v-chip color="secondary">Completed Induction</v-chip>
                      </div>
                      <template v-if="!studentReview.approved_at">
                        <mosaic-role-completed-chip
                          v-if="review.is_trainee_contributor"
                          class="pr-1"
                          role-id="student"
                          :completed="!!studentReview.student_submitted_at"
                          verb="Submitted"
                        />
                        <mosaic-role-completed-chip
                          v-for="role of rolesThatCanComplete"
                          :key="role.id"
                          class="pr-1"
                          :role-id="role.id"
                          :completed="!!submission(studentReview, role)"
                        />
                      </template>
                      <div v-else class="pr-1">
                        <v-chip color="secondary">Approved</v-chip>
                      </div>
                    </div>
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </template>
        </v-card-text>
      </v-card>
      <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="filteredStudentReviews.length" />
    </div>
    <ndt-dialog
      v-model:active="sendReminderDialog.active"
      title="Compose Reminder Emails"
      :error-message="sendReminderDialog.error"
    >
      <div class="pb-2">
        This will compose a reminder email to all the people who have these roles and have not marked their
        {{ reviewNounCapitalised }} as complete (and the {{ reviewNounCapitalised }} is not approved).
      </div>
      <div class="pb-2">
        When you click "Compose" it will open a prefilled email in your normal email program for you to edit and then
        send.
      </div>
      <div
        v-if="selectedInstitution.config.show_review_email_reminder_rules && userStaffHasPermission('reviews.edit')"
        class="pb-2"
      >
        If you would prefer Mosaic to send automated reminder emails for you, then
        <mosaic-router-link
          :to="{
            name: 'TutorAdminReviewTemplatesListPage',
            params: { institutionId: selectedInstitution.id },
            query: { tab: 'rules' },
          }"
          >configure {{ reviewNounCapitalised }} email reminder rules</mosaic-router-link
        >.
      </div>
      <mosaic-select
        v-model="sendReminderDialog.roles"
        :items="contributorRoles"
        item-title="name"
        item-value="id"
        multiple
        name="select-roles"
        label="Select roles"
        prepend-icon="mdi-account-multiple-check"
      />
      <mosaic-text-area
        v-model="sendReminderDialog.subject"
        name="subject"
        prepend-icon="mdi-email"
        rows="1"
        label="Email subject"
      />
      <mosaic-text-area
        v-model="sendReminderDialog.body"
        name="body"
        prepend-icon="mdi-email-newsletter"
        label="Email body"
      />
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSendEmail" @click.prevent="submitSendReminders()">Compose</v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NdtDialog from '@/components/NdtDialog.vue';
import { statuses } from '@/utils/student-review';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
import { formatDateTime } from '@/utils/date';
import { useCohortStore } from '@/stores/cohort';

export default {
  name: 'TutorAdminReviewViewPage',
  setup() {
    const queryStore = useQueryStore();
    const { selectedCohortStudents } = useCohortStore();
    return { queryStore, selectedCohortStudents };
  },
  mixins: [
    syncQueryParamsMixin({
      traineeFilter: { query: 'trainee' },
      statusFilter: { query: 'statusFilter', type: 'array' },
    }),
  ],
  components: { NdtDialog },
  data: () => ({
    busy: true,
    review: null,
    currentPage: 1,
    pageSize: 10,
    error: '',
    statuses: statuses,
    statusFilter: ['notstarted', 'started', 'partiallycompleted', 'completed', 'approved'],
    traineeFilter: '',
    filteredStudentIds: [],
    sendReminderDialog: {
      active: false,
      processing: false,
      subject: '',
      body: '',
      roles: [],
      error: null,
    },
    emails: {},
    cohortMonitoringFilterState: {},
  }),
  computed: {
    ...mapState(['user', 'selectedCohort', 'roles', 'selectedInstitution', 'reviewTypeItems']),
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
          to: {
            name: 'CohortReviewsPage',
          },
        },
        {
          text: this.review?.name,
        },
      ];
    },
    canSendEmail() {
      return !this.sendReminderDialog.processing && this.sendReminderDialog.roles.length > 0;
    },
    contributorRoles() {
      if (!this.review) return this.roles;
      const roles = [];
      if (this.review.is_trainee_contributor) {
        roles.push({
          id: 'trainee',
          name: this.traineeNounCapitalised() + (this.emails['trainee'] ? ` (${this.emails['trainee'].length})` : ''),
          disabled: this.emails.trainee?.length === 0,
        });
      }
      const contributorRoles = this.review.review_permissions
        .filter(x => x.permission_type === 'Contributor')
        .map(x => x.role_id);

      return roles.concat(
        this.roles
          .filter(x => contributorRoles.includes(x.id))
          .map(x => ({
            id: x.id,
            name: x.name + (this.emails[x.id] ? ` (${this.emails[x.id].length})` : ''),
            disabled: this.emails[x.id]?.length === 0,
          }))
      );
    },
    filteredStudentReviews() {
      if (!this.review) return [];
      return this.review.student_reviews
        .filter(
          x =>
            this.statusFilter.includes(x.status) &&
            this.filteredStudentIds.includes(x.student.id) &&
            (x.student.name.toLowerCase().includes(this.traineeFilter.toLowerCase()) ||
              x.student.email.toLowerCase().includes(this.traineeFilter.toLowerCase()))
        )
        .sort((a, b) => {
          const aValue = a.student.name || a.student.email;
          const bValue = b.student.name || b.student.email;
          return aValue > bValue ? 1 : -1;
        });
    },
    paginatedStudentReviews() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.filteredStudentReviews.slice(index, index + this.pageSize);
    },
    rolesThatCanComplete() {
      if (!this.roles) return [];
      const roleIdsThatAreContributors = this.review.review_permissions
        .filter(x => x.permission_type === 'Contributor' && x.edit)
        .map(x => x.role_id);
      return this.roles.filter(x => roleIdsThatAreContributors.includes(x.id));
    },
    students() {
      const selectedCohortStudentIds = this.selectedCohortStudents.map(s => s.id);
      // e.g completed ECTs
      const studentReviewsInReviewButNotCohort = this.review.student_reviews.filter(
        sr => !selectedCohortStudentIds.includes(sr.student_id)
      );
      return this.selectedCohortStudents.concat(
        studentReviewsInReviewButNotCohort.map(sr => ({
          ...sr.student,
          student_groups: [],
        }))
      );
    },
  },
  watch: {
    filteredStudentReviews() {
      this.currentPage = 1;
    },
  },
  async created() {
    await this.$store.dispatch('loadRoles');
    await this.loadReview();
  },
  methods: {
    async loadReview() {
      this.busy = true;
      try {
        // Assumed to be same API call as TutorAdminReviewEditPage
        const r = await this.$api.get(`/reviews/${this.$route.params.reviewId}`);
        this.review = r.data;
      } catch (e) {
        this.error = true;
      }
      this.busy = false;
    },
    studentClickTo(studentReview) {
      if (studentReview.student.ect?.status === 'completed_induction') return null;
      return {
        name: 'TutorReviewPage',
        params: { studentId: studentReview.student.id, id: studentReview.id },
      };
    },
    submission(studentReview, role) {
      return !!studentReview.student_review_submissions.find(x => x.role_id === role.id);
    },
    editReview() {
      this.$router.push({
        name: 'TutorAdminReviewEditPage',
        params: { cohortId: this.selectedCohort.id, id: this.review.id, review: this.review },
      });
    },
    reviewTemplateTo() {
      return {
        name: 'TutorAdminReviewTemplatePage',
        params: { institutionId: this.selectedInstitution.id, templateId: this.review.review_template_id },
      };
    },
    async sendReminders() {
      this.$api
        .post(`/reviews/${this.review.id}/get-reminder-emails`, {
          roles: this.contributorRoles.map(x => x.id),
        })
        .then(r => {
          this.emails = r.data.emails;
          this.sendReminderDialog.error = ``;
        })
        .catch(() => {
          this.sendReminderDialog.error = `Sorry, cannot load the contributors at the moment`;
        });

      this.sendReminderDialog.active = true;
      this.sendReminderDialog.subject =
        this.sendReminderDialog.subject ||
        `Reminder: ${this.review.name} ${this.reviewNoun} is due by ${this.formatDate(
          this.review.due_date
        )} on Mosaic `;
      this.sendReminderDialog.body =
        this.sendReminderDialog.body ||
        `Hello,

Your ${this.review.name} ${this.reviewNoun} is due by ${this.formatDate(this.review.due_date)}.

You can fill in, and mark as complete, this ${
          this.reviewNoun
        } by going to Mosaic (https://app.mosaic.penrose.education).

Thanks`;
    },
    async submitSendReminders() {
      this.sendReminderDialog.processing = true;
      this.sendReminderDialog.error = '';
      try {
        const emails = this.sendReminderDialog.roles
          .map(x => this.emails[x])
          .flat()
          .unique();
        const mailTo = `mailto:${this.user.email}?bcc=${emails.join(',')}&subject=${encodeURIComponent(
          this.sendReminderDialog.subject
        )}&body=${encodeURIComponent(this.sendReminderDialog.body)}`;
        window.open(mailTo, '_blank');
      } catch (e) {
        console.log(e);
        this.sendReminderDialog.error = 'Sorry, cannot send reminder emails at the moment';
      }
      this.sendReminderDialog.processing = false;
    },
    download() {
      const xlxs = XLSX.utils.book_new();

      const headers = ['Name', 'Email', 'Approved at'];
      if (this.review.is_trainee_contributor) {
        headers.push(`${this.traineeNounCapitalised()} submitted at`);
      }
      for (const role of this.rolesThatCanComplete) {
        headers.push(`${role.name} completed at`);
      }

      const data = [
        headers,
        ...this.filteredStudentReviews.map(sr => {
          const d = [sr.student.name, sr.student.email, formatDateTime(sr.approved_at) || 'Not approved'];

          if (this.review.is_trainee_contributor) {
            d.push(formatDateTime(sr.student_submitted_at) || 'Not submitted');
          }

          for (const role of this.rolesThatCanComplete) {
            d.push(
              formatDateTime(sr.student_review_submissions.find(x => x.role_id === role.id)?.submitted_at) ||
                'Not completed'
            );
          }

          return d;
        }),
      ];

      XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(data), `${this.reviewNounCapitalised} Completion`);

      const filters = [
        ['Search term', ...this.cohortMonitoringFilterState.visibleFilters, 'Statuses'],
        [
          this.traineeFilter || 'None',
          ...this.cohortMonitoringFilterState.values,
          this.statusFilter.map(s => statuses.find(st => st.value === s).title).join(', '),
        ],
      ];
      XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(filters), `Applied Filters`);

      XLSX.writeFile(xlxs, `${this.review.name} Completion (${formatDateTime(DateTime.now().toISO())}).xlsx`);
    },
  },
};
</script>

<style>
.multiple-v-select .v-select__selections input {
  display: none;
}
</style>
