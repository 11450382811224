<template>
  <div>
    <div>
      <mosaic-list-filters-card
        :title="title"
        :filters-applied="filtersApplied"
        :extra-filters-applied="extraFiltersApplied"
      >
        <template #switches v-if="staffLoading">
          <v-icon>mdi-loading mdi-spin</v-icon>
        </template>

        <template #filters>
          <div class="d-flex align-center flex-wrap flex-grow-1" style="row-gap: 16px; column-gap: 16px">
            <div style="min-width: 230px">
              <staff-list-name-filter @update:filter="nameEmailFilter = $event" />
            </div>
            <staff-list-role-filter @update:role="selectedRole = $event" />
          </div>
        </template>

        <template #extraFilters>
          <div class="d-flex align-center flex-wrap flex-grow-1" style="row-gap: 16px; column-gap: 16px">
            <staff-list-wont-receive-emails-filter @update:wont-receive-emails="wontReceiveEmails = $event" />
            <staff-list-last-active-filter @update:last-active="selectedLastActive = $event" />
            <staff-list-storage-not-set-up-filter @update:storage-not-set-up="storageNotSetUp = $event" />
          </div>
        </template>

        <template #actions>
          <staff-export-button :staff="filteredStaff" :disable="busy || !!error" />
          <add-staff-button v-if="props.showAddStaff && props.canAddStaff" @update-staff="loadStaff" />
        </template>
      </mosaic-list-filters-card>

      <div>
        <mosaic-loading-card v-if="busy" type="list-no-filters" />
        <mosaic-load-error-card v-else-if="error" object-type="Instructors" :object-type-is-plural="true" />

        <template v-else>
          <mosaic-card>
            <v-snackbar location="top" color="success" v-model="passwordSnackbar" :timeout="2000">
              <span class="snackbar-text">Password updated</span></v-snackbar
            >
            <mosaic-list
              :items="paginatedStaff"
              :empty-text="`You have no Instructors${staff.length > 0 ? ' for these filters' : ''}`"
            >
              <template #item="{ item: s }">
                <mosaic-list-item
                  :class="{ 'instructor-click': !props.staffClickTo(s.id) }"
                  :to="props.staffClickTo(s.id)"
                  :user-avatar="{
                    ...s.user,
                    displayName: s.user.display_name,
                    profilePictureUpdatedAt: s.user.profile_picture_updated_at,
                    hideProfilePicture: s.hide_profile_picture,
                  }"
                  :title="renderStaffTitle(s)"
                  :subtitle="`${renderStaffSubtitle(s)} ${renderStaffSubtitle2(s)} ${renderStaffLastActive(s)}`"
                >
                  <template #information>
                    <demo-account-badge v-if="s.user.is_demo" class="pr-2" />
                    <account-locked-chip v-if="s.user.account_locked" class="pr-2" />
                    <email-status-chip
                      v-if="
                        isEmailVerificationOnForSelectedInstitution && !s.user.is_demo && s.user.wont_receive_emails
                      "
                      class="pr-2"
                      :email-verified="s.user.email_verified"
                      :email-bounced="s.user.email_bounced"
                      :opted-out-of-emails="s.user.opted_out_of_emails"
                    />
                    <div>
                      <mosaic-tooltip-chip v-for="role in staffRoles(s)" :key="role.id" color="primary" class="mr-2">
                        <template #text> {{ role.initialisedText || initialiseText(role.name) }} </template>
                        <template #tooltip>
                          {{ role.name }}
                        </template>
                      </mosaic-tooltip-chip>
                    </div>
                  </template>

                  <template #actions>
                    <ndt-icon-button
                      v-if="props.showArchiveStaff && props.canArchiveStaff"
                      icon="package-down"
                      :tooltip="
                        hasMultipleInstitutions(s)
                          ? 'Cannot archive as this Instructor shares their account with another Institution.'
                          : 'Archive Instructor'
                      "
                      :disabled="hasMultipleInstitutions(s)"
                      @click.prevent="archiveStaff(s)"
                    />
                    <ndt-icon-button
                      v-if="props.showResetPassword && props.canResetPassword"
                      icon="lock-reset"
                      :tooltip="
                        hasMultipleInstitutions(s)
                          ? 'Cannot reset password as this Instructor shares their account with another Institution. Please ask them to try the forgotten password flow or contact support'
                          : s.user.force_microsoft_sso
                          ? 'Cannot reset password as this Instructor must sign in with Microsoft'
                          : 'Reset password'
                      "
                      class="mr-2"
                      :disabled="hasMultipleInstitutions(s) || s.user.force_microsoft_sso || !props.canResetPassword"
                      @click.prevent="resetPassword(s)"
                    />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </mosaic-card>
          <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="paginationTotal" />
        </template>
      </div>
      <mosaic-save-dialog
        v-model:active="archiveDialog.active"
        :title="`Archive Instructor`"
        :save="submitArchiveStaff"
        @save="archiveStaffSuccess"
        action="archive"
        object-type="Instructor"
      >
        <div>
          Are you sure you want to archive {{ archiveDialog.title }}'s account? The User will no longer be able to log
          in and their account will not be visible in the Instructor list.
        </div>
        <div class="pt-2">You will be able to retrieve this account later by contacting support.</div>
      </mosaic-save-dialog>

      <mosaic-save-dialog
        v-model:active="resetPasswordDialog.active"
        title="Reset Password"
        :save="submitResetPassword"
        @save="resetPasswordSuccess"
        :can-save="canSubmitResetPassword"
        action="reset"
        object-type="password"
      >
        <div>
          <v-alert v-if="resetPasswordDialog.accountLocked" type="info" variant="outlined"
            >This account is locked due to too many incorrect login attempts. Resetting the password will unlock the
            account.</v-alert
          >
          <mosaic-password-text-field
            v-model="resetPasswordDialog.newPassword"
            name="newPassword"
            label="Password"
            :validate-password="true"
            :revealed="true"
            @password-valid="passwordValid = $event"
          />
          <mosaic-password-text-field
            v-model="resetPasswordDialog.newPasswordConfirmation"
            name="newPasswordConfirmation"
            label="Password Confirmation"
            :rules="passwordConfirmationRules"
            :revealed="true"
            @keyup.enter="submitResetPassword"
          />
          <div class="d-flex justify-end">
            <mosaic-password-security-link />
          </div>
        </div>
      </mosaic-save-dialog>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { mapState, mapGetters } from '@/store/map-store';
import { useApi } from '@/composables/api';
import { formatDate } from '@/mixins/global-mixins';
import NdtIconButton from '@/components/NdtIconButton.vue';
import DemoAccountBadge from '@/components/DemoAccountBadge.vue';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import AccountLockedChip from '@/components/user/AccountLockedChip.vue';
import { initialiseText } from '@/utils/text';
import { generateStongPassword } from '@/utils/passwords';
import { validationsPass } from '@/utils/validations';
import { useInstitutionStaffStore } from '@/stores/institution-staff';
import type { Rule } from '@/utils/validations';
import StaffListRoleFilter from './StaffListRoleFilter.vue';
import StaffListNameFilter from './StaffListNameFilter.vue';
import StaffListWontReceiveEmailsFilter from './StaffListWontReceiveEmailsFilter.vue';
import StaffListLastActiveFilter from './StaffListLastActiveFilter.vue';
import StaffListStorageNotSetUpFilter from './StaffListStorageNotSetUpFilter.vue';
import AddStaffButton from './AddStaffButton.vue';
import StaffExportButton from './StaffExportButton.vue';
import { syncQueryParam } from '@/composables/query';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { paginateList } from '@/components/library/pagination/pagination';

export interface User {
  id: number;
  name: string;
  email: string;
  display_name: string;
  activated: boolean;
  staff_ids: number[];
  is_demo: boolean;
  wont_receive_emails: boolean;
  email_verified: boolean;
  opted_out_of_emails: boolean;
  email_bounced: boolean;
  last_active_at: null;
  force_microsoft_sso: boolean;
  in_multiple_institutions: boolean;
  account_locked: boolean;
  profile_picture_updated_at: string | null;
}

export type Staff = {
  id: number;
  created_at: string;
  storage_email: null | boolean;
  hide_profile_picture: boolean;
  user: User;
  roles: [
    {
      id: number;
      name: string;
      initialisedText?: string;
    }
  ];
};

const props = withDefaults(
  defineProps<{
    showAddStaff?: boolean;
    canAddStaff?: boolean;
    showArchiveStaff?: boolean;
    canArchiveStaff?: boolean;
    showResetPassword?: boolean;
    canResetPassword?: boolean;
    staffClickTo: (id: number) => { name: string; params: { id: number; institutionId: number } } | undefined;
    url: string;
    title: string;
  }>(),
  {
    showAddStaff: false,
    canArchiveStaff: false,
    showArchiveStaff: false,
    canAddStaff: false,
    showResetPassword: false,
    canResetPassword: false,
  }
);

const api = useApi();

const { user, selectedInstitution } = mapState();
const { isEmailVerificationOnForSelectedInstitution } = mapGetters();

const {
  actions: { clearInstitutionStaff },
} = useInstitutionStaffStore();

const error = ref(false);
const busy = ref(false);
const staff = ref<Staff[]>([]);
const staffLoading = ref(true);
const selectedRoleId = ref(-2);
const filterTerm = ref('');
const wontReceiveEmails = ref(false);
const storageNotSetUp = ref(false);
const selectedLastActive = ref({
  text: 'Show all users',
  value: 'all',
  filter: (user: User) => !!user,
});
const nameEmailFilter = ref('');
const selectedRole = ref(-2);
const passwordSnackbar = ref(false);
const resetPasswordDialog = ref<{
  active: boolean;
  name: string;
  id: null | number;
  accountLocked: boolean;
  newPassword: string;
  newPasswordConfirmation: string;
}>({
  active: false,
  name: '',
  id: null,
  accountLocked: false,
  newPassword: '',
  newPasswordConfirmation: '',
});
const archiveDialog = ref<{
  active: boolean;
  id: null | number;
  title: string;
}>({
  active: false,
  id: null,
  title: '',
});
const passwordValid = ref(false);
const passwordConfirmationRules = ref<Rule[]>([]);

syncQueryParam(filterTerm, 'name', 'string');
syncQueryParam(selectedRoleId, 'selectedRoleId', 'integer');

setBreadcrumbs(
  computed(() => [
    {
      text: 'Instructors',
    },
  ])
);

const canSubmitResetPassword = computed(
  () =>
    !!(
      resetPasswordDialog.value.newPassword &&
      validationsPass(passwordConfirmationRules.value, resetPasswordDialog.value.newPasswordConfirmation) &&
      passwordValid
    )
);

onMounted(async () => {
  await loadStaff();

  passwordConfirmationRules.value = [
    (v: string | number) => {
      if (typeof v === 'string' && v === resetPasswordDialog.value.newPassword) {
        return true;
      }
      return 'Password confirmation should match password';
    },
  ];
});

const filtersApplied = computed(() => {
  return !!(filterTerm.value || selectedRoleId.value !== -2);
});
const extraFiltersApplied = computed(() => {
  return selectedLastActive.value.value !== 'all' || wontReceiveEmails.value || storageNotSetUp.value;
});

const filteredStaff = computed(() => {
  return staff.value.filter(x => {
    const roleFilter =
      !selectedRoleId.value ||
      selectedRoleId.value === -2 ||
      (selectedRoleId.value === -1 && !x.roles.length) ||
      x.roles.map(x => x.id).includes(selectedRoleId.value);

    const lastActiveFilter = selectedLastActive.value.filter(x.user);
    const nameEmailFilter =
      x.user.name.toLowerCase().includes(filterTerm.value.toLowerCase()) ||
      x.user.email.toLowerCase().includes(filterTerm.value.toLowerCase());
    return (
      roleFilter &&
      lastActiveFilter &&
      nameEmailFilter &&
      (!wontReceiveEmails.value || x.user.wont_receive_emails) &&
      (selectedInstitution.value.storage_type === 'Mosaic' || !storageNotSetUp.value || !x.storage_email)
    );
  });
});
const { currentPage, pageSize, paginatedList: paginatedStaff, paginationTotal } = paginateList(filteredStaff);

const loadStaff = async () => {
  busy.value = true;
  staffLoading.value = true;
  try {
    const staffResponse: Staff[] = await api.getAllPages<Staff>(`${props.url}`);
    staff.value = staffResponse;
  } catch (e) {
    console.log(e);
    error.value = true;
  }
  busy.value = false;
  staffLoading.value = false;
};

const hasMultipleInstitutions = (s: Staff) => {
  return s.user.in_multiple_institutions;
};

const staffRoles = (staff: Staff) => {
  const roles = staff.roles.uniqueBy(x => x.id);
  if (roles.length > 4) {
    return roles
      .slice(0, 3)
      .concat([{ id: -1, name: `And ${roles.length - 3} more`, initialisedText: `+${roles.length - 3}` }]);
  }

  return roles;
};

const renderStaffTitle = (staff: Staff) => {
  return staff.user.name ? staff.user.name : staff.user.email;
};

const renderStaffSubtitle = (staff: Staff) => {
  return staff.user.email;
};

const renderStaffSubtitle2 = (staff: Staff) => {
  return !(
    selectedInstitution.value.storage_type === 'Mosaic' ||
    selectedInstitution.value.config.on_demand_sharing ||
    staff.storage_email
  )
    ? '- Storage not set up'
    : '';
};

const renderStaffLastActive = (staff: Staff) => {
  return !staff.user.last_active_at
    ? ' - Has never logged in'
    : ' - Last active on ' + formatDate(staff.user.last_active_at);
};

const resetPassword = async (staff: Staff) => {
  const password = await generateStongPassword(api, user.value);
  resetPasswordDialog.value.active = true;
  resetPasswordDialog.value.name = staff.user.name;
  resetPasswordDialog.value.id = staff.id;
  resetPasswordDialog.value.newPassword = password;
  resetPasswordDialog.value.newPasswordConfirmation = password;
  resetPasswordDialog.value.accountLocked = staff.user.account_locked;
};

const submitResetPassword = async () => {
  if (!canSubmitResetPassword.value) return 'A password and confirmation must be entered';
  if (resetPasswordDialog.value.newPassword !== resetPasswordDialog.value.newPasswordConfirmation) {
    return `Password and confirmation must match`;
  }
  await api.put(`/staff/${resetPasswordDialog.value.id}/reset-password`, {
    password: resetPasswordDialog.value.newPassword,
  });
};

const resetPasswordSuccess = () => {
  loadStaff();
  passwordSnackbar.value = true;
};

const archiveStaff = (staff: Staff) => {
  archiveDialog.value.active = true;
  archiveDialog.value.id = staff.id;
  archiveDialog.value.title = staff.user.name || staff.user.email;
};

const submitArchiveStaff = async () => {
  await api.put(`/staff/${archiveDialog.value.id}/archive`, {});
};

const archiveStaffSuccess = () => {
  loadStaff();
  clearInstitutionStaff();
};
</script>
<style scoped>
.instructor-click {
  cursor: initial;
}
.snackbar-text {
  display: block;
  width: 100%;
  text-align: center;
}
</style>
