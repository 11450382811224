import { defineStore } from 'pinia';
import { mapMutations, mapState, type Cohort, type CohortStudent } from '@/store/map-store';
import { computed, ref, watch } from 'vue';
import { useStore } from './common';
import { useApi } from '@/composables/api';
import type { StudentCohortResponse } from '@/types/responses';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { CacheMap } from './cache-map';
import { hasPermissionForSelectedCohort } from '@/composables/permission';

export interface CohortStaff {
  id: number;
  name: string;
  email: string;
  displayName: string;
  staffRoles: { id: number; studentId: number; noStudentYetCohortId: number; role: { id: number; name: string } }[];
  user: {
    emailVerified: boolean;
    emailBounced: boolean;
    optedOutOfEmails: boolean;
    isDemo: boolean;
  };
}

export const useCohortStore = useStore(
  defineStore('cohort', () => {
    const api = useApi();
    const { selectedCohort } = mapState();
    const { updateSelectedCohortWithChanges } = mapMutations();
    const hasPlacements = computed(
      () => !!selectedCohort.value.placements && selectedCohort.value.placements.length > 0
    );
    const cohortStaffCache = ref<CacheMap<CohortStaff[]>>(new CacheMap());
    const cohortStudents = ref<CacheMap<StudentCohortResponse[]>>(new CacheMap());

    function reset() {
      cohortStudents.value.clear();
      cohortStaffCache.value.clear();
    }

    function loadSelectedCohortStaff() {
      return loadCohortStaff(selectedCohort.value.id);
    }

    // The API is called (and the cache is refreshed) every time - need to ensure the cache is cleared in all the right places when we stop doing this
    async function loadCohortStaff(cohortId: number) {
      const r = await api.get<CohortStaff[]>(`/cohorts/${cohortId}/cohort-staff`);
      cohortStaffCache.value.set(cohortId, r.data);
    }
    const selectedCohortStaff = computed(() => cohortStaffCache.value.getEvenIfExpired(selectedCohort.value.id) || []);

    const {
      action,
      actionInBackground,
      processing: loadCohortStudentsProcessing,
      error: loadCohortStudentsError,
    } = withProcessingAndError(async (force: boolean) => {
      if (!hasPermissionForSelectedCohort('Admin').value) return;

      const cohortId = selectedCohort.value.id;
      if (force || !cohortStudents.value.get(cohortId)) {
        const students = await api.getAllPages<StudentCohortResponse>(`/cohorts/${cohortId}/students`);
        cohortStudents.value.set(cohortId, students);
      }
    });

    function loadCohortStudents(force = false, doInBackground = false) {
      return doInBackground ? actionInBackground(force) : action(force);
    }

    watch(selectedCohort, cohort => {
      if (cohort) {
        loadCohortStudents();
      }
    });

    const selectedCohortStudents = computed<CohortStudent[]>(() => {
      return selectedCohort.value ? cohortStudents.value.getEvenIfExpired(selectedCohort.value.id) || [] : [];
    });

    function updateSelectedCohort(changes: Partial<Cohort>) {
      updateSelectedCohortWithChanges(changes);
    }

    const inUseCohortSubjects = computed(() => {
      type Subject =
        | NonNullable<StudentCohortResponse['subject']>
        | NonNullable<StudentCohortResponse['additional_subject']>;
      return (
        selectedCohortStudents.value
          .map(x => [x.subject, x.additional_subject])
          .flat()
          .filter(x => !!x) as Subject[]
      ).uniqueBy(x => x.id);
    });

    return {
      reset,
      selectedCohort,
      hasPlacements,
      selectedCohortStaff,
      cohortStaffCache,
      loadSelectedCohortStaff,
      loadCohortStaff,
      loadCohortStudents,
      selectedCohortStudents,
      updateSelectedCohort,
      loadCohortStudentsProcessing,
      loadCohortStudentsError,
      inUseCohortSubjects,
    };
  })
);
