<template>
  <div>
    <staff-list
      :staff-click-to="staffClickTo"
      show-add-staff
      show-archive-staff
      show-reset-password
      :can-reset-password="hasStaffViewEditPermission"
      :can-add-staff="hasStaffViewEditPermission"
      :can-archive-staff="hasStaffViewEditPermission"
      :url="url"
      :title="`${selectedInstitution.name} Institution Instructors`"
    />
  </div>
</template>
<script setup lang="ts">
import { mapState } from '@/store/map-store';
import StaffList from './StaffList.vue';
import { hasPermissionForSelectedInstitution } from '@/composables/permission';
import { computed } from 'vue';

const { selectedInstitution } = mapState();
const url = `/institutions/${selectedInstitution.value.id}/staff`;
const hasStaffViewEditPermission = computed(() => hasPermissionForSelectedInstitution('staff.edit').value);

const staffClickTo = (id: number) => {
  if (selectedInstitution.value && hasStaffViewEditPermission.value) {
    return { name: 'InstitutionTutorStaffPage', params: { id, institutionId: selectedInstitution.value.id } };
  }
  return;
};
</script>
