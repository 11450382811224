import { useQueryStore } from '@/stores/query';
import { isArray } from 'lodash';
import { computed, watch, type Ref } from 'vue';

type SyncableValueTypes = string | number | boolean | number[] | string[];
type SyncableTypes = SyncableValueTypes | null;

type ParseAs =
  | 'integer'
  | 'string'
  | 'boolean'
  | 'integer | null'
  | 'string | null'
  | 'boolean | null'
  | 'string[] | null'
  | 'integer[] | null';

export function syncQueryParam<T extends SyncableTypes>(ref: Ref<T>, key: string, parseAs: ParseAs) {
  const { queryParams } = useQueryStore();

  const queryString = computed(() => queryParams.value[key]);
  // Update ref with what's in query (potentially overwriting any default values)
  updateRef(queryString.value);
  // Ensure any default values are set in the query
  updateQuery(ref.value);

  watch(ref, updateQuery);
  watch(queryString, updateRef);

  function updateQuery(refValue: T) {
    queryParams.value = { ...queryParams.value, [key]: convertDataToQueryString(refValue) };
  }

  function updateRef(data: string | undefined) {
    if (data === undefined) return;
    const value = convertQueryStringToData(data, parseAs);
    if (value === undefined) return;
    // @ts-expect-error can't use type level information, e.g. information about T, to determine what type to parse the
    // query string as. Therefore, we rely on the `parseAs` parameter matching the actual type.
    ref.value = value;
  }
}

// Returning undefined means the component's data won't be changed
function convertQueryStringToData(param: string, parseAs: ParseAs) {
  if (param === 'null') {
    if (parseAs.includes('| null')) return null;
    return undefined;
  }

  if (parseAs === 'integer') {
    const value = parseInt(param);
    return isNaN(value) ? undefined : value;
  }

  if (parseAs === 'boolean') {
    return param === 'false' ? false : param === 'true' ? true : undefined;
  }

  if (parseAs.includes('[]')) {
    if (param.trim() === '') return [];
    return param.split(',').map(item => {
      return parseAs.includes('integer') ? Number(item) : item;
    });
  }

  return param;
}

function convertDataToQueryString(data: SyncableTypes): string {
  if (isArray(data)) {
    return data.join(',');
  }
  return data === null ? 'null' : data.toString();
}
