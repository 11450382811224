<template>
  <div>
    <v-card v-if="showInstitutionCard" class="mb-4">
      <v-card-text>
        <div class="text-h6">{{ selectedInstitution.name }}</div>
        <div v-if="selectedInstitution.config.staff_home_page_rubric">
          <mosaic-quill :contents="selectedInstitution.config.staff_home_page_rubric" :read-only="true" />
        </div>
        <mosaic-list v-if="institutionCardItems" :items="institutionCardItems">
          <template #item="{ item }">
            <mosaic-list-item :key="item.icon" :icon="item.icon" :to="item.to" :title="item.title">
              <template #subtitle>
                <div v-if="!!item.subtitle">{{ item.subtitle }}</div>
                <div v-if="item.status?.length" class="my-training-status">
                  <span v-for="st in item.status" :key="st.text">
                    <mosaic-icon :icon="st.icon" />
                    {{ st.text }}
                  </span>
                </div>
              </template>
            </mosaic-list-item></template
          >
        </mosaic-list>
      </v-card-text>
    </v-card>
    <mosaic-card
      v-if="!staffTrainingStore.hasStaffTraining.value && selectedInstitution.config.mentor_training_only"
      class="mb-4"
    >
      <mosaic-card-title><span class="text-h6">My Training</span></mosaic-card-title>
      <div class="mt-2">You have no training assigned at the moment.</div>
    </mosaic-card>

    <v-card v-if="!showInstitutionCard && !hasHomePageContent && userStaffHasPermission('Admin')">
      <v-card-text>
        <v-list lines="two">
          <v-list-item>
            <div>
              There is currently nothing to see here.
              <span v-if="!selectedInstitution.config.mentor_training_only"
                >If you're assigned to any individual {{ traineeNounCapitalisedAndPluralised }} they will appear
                here.</span
              ><span v-if="!selectedInstitution.config.early_careers">
                Shared Files and Instructor Training will
                <span v-if="!selectedInstitution.config.mentor_training_only">also</span> appear here if enabled.</span
              >
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <home-page-simple-list-card v-if="announcements.length > 0" class="mb-4" v-bind="announcementsCard" />

    <home-page-filter-list-card
      v-if="upcomingStaffTrainingEvents.length > 0"
      mosaic-key="events"
      title="Upcoming Events"
      :search="eventsFilter"
      :items="upcomingStaffTrainingEvents"
      item-name-pluralised="Events"
      filter-label="Filter by name"
      :sort="['status', 'name']"
      :institution-name="selectedInstitution.name"
      :institution-contact-email="selectedInstitution.contact_email"
    >
      <template #item="{ item: event }">
        <mosaic-list-item
          :key="event.id"
          :icon="icons.instructorTrainingEvent"
          :to="eventClickTo(event)"
          :title="event.name"
          :subtitle="`${formatDateTime(event.startsAt)} - Duration ${formatDuration(
            event.durationHours,
            event.durationMinutes
          )}`"
        ></mosaic-list-item>
      </template>
    </home-page-filter-list-card>

    <v-card
      v-if="!hasHomePageContent && !userStaffHasPermission('Admin') && !selectedInstitution.config.mentor_training_only"
      class="mb-4"
    >
      <v-card-text>
        <v-list lines="two">
          <v-list-item>
            <div>
              You have not been assigned any {{ traineeNounCapitalisedAndPluralised }}. {{ contactProviderMessage }}
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <home-page-filter-list-card
      v-if="cohorts.length > 0"
      mosaic-key="cohorts"
      title="Cohorts"
      :search="cohortFilter"
      :items="cohorts"
      item-name-pluralised="Cohorts"
      filter-label="Filter by name"
      status-filter-label="Active or Closed"
      :status-filter-statuses="cohortStatuses"
      :initial-filter-status="initialCohortFilterStatus"
      :sort="['status', 'name']"
      :institution-name="selectedInstitution.name"
      :institution-contact-email="selectedInstitution.contact_email"
    >
      <template #item="{ item: cohort }">
        <mosaic-list-item
          :key="cohort.id + cohort.roleName"
          icon="mdi-google-classroom"
          :to="cohortClickTo(cohort)"
          :title="cohort.name"
          :subtitle="`${cohort.roleName} - ${cohort.institution.name}`"
        ></mosaic-list-item>
      </template>
      <template #information>
        <v-chip>{{ renderCohortStatus(cohort.status) }}</v-chip>
      </template>
    </home-page-filter-list-card>

    <home-page-filter-list-card
      v-if="incompleteReviews.length > 0 && !selectedInstitution.config.mentor_training_only"
      mosaic-key="incompleteReviews"
      :title="`Incomplete ${reviewNounCapitalisedAndPluralised}`"
      :search="incompleteReviewFilter"
      :items="mappedIncompleteReviews"
      :item-name-pluralised="reviewNounCapitalisedAndPluralised"
      :filter-label="`Filter by name, email or ${reviewNounCapitalised}`"
      status-filter-label="Status"
      :status-filter-statuses="assignmentNotCompleteStatuses"
      :sort="incompleteReviewsSort"
      :institution-name="selectedInstitution.name"
      :institution-contact-email="selectedInstitution.contact_email"
    >
      <template #item="{ item: { student, id, name, overdue, student_due_date, incomplete_role_ids } }">
        <mosaic-list-item
          icon="mdi-check-circle-outline"
          :key="id"
          :to="studentReviewClickTo(id, student.id)"
          :title="name"
          :subtitle="student.name || student.email"
          ><template #information>
            <v-chip :color="overdue === 1 ? 'error' : 'accent'">Due {{ formatDueDate(student_due_date) }}</v-chip>

            <mosaic-role-completed-chip
              class="pl-1"
              v-for="roleId in incomplete_role_ids"
              :key="roleId"
              :role-id="roleId"
              :completed="false"
              :role-scoped-tooltip="role => `Incomplete for your role as ${role.name}`"
            /> </template
        ></mosaic-list-item>
      </template>
    </home-page-filter-list-card>

    <home-page-filter-list-card
      v-if="incompleteAssignments.length > 0 && !selectedInstitution.config.mentor_training_only"
      mosaic-key="incompleteAssignments"
      title="Incomplete Course Activities"
      :search="incompleteAssignmentFilter"
      :items="mappedIncompleteAssignments"
      status-filter-label="Status"
      :status-filter-statuses="assignmentNotCompleteStatuses"
      item-name-pluralised="Course Activities"
      filter-label="Filter by name"
      :sort="incompleteAssignmentsSort"
      :institution-name="selectedInstitution.name"
      :institution-contact-email="selectedInstitution.contact_email"
    >
      <template #item="{ item: { student, id, overdue, dueDate, assignment, status } }">
        <mosaic-list-item
          :icon="icons.courseActivity"
          :key="id"
          :to="studentAssignmentClickTo(assignment, student.id)"
          :title="assignment.name"
          :subtitle="student.name || student.email"
          ><template #information>
            <v-chip v-if="dueDate" class="mr-2" :color="overdue === 1 ? 'error' : 'accent'"
              >Due {{ formatDueDate(dueDate) }}</v-chip
            >
            <assignment-status-chip :status="status" /> </template
        ></mosaic-list-item>
      </template>
    </home-page-filter-list-card>

    <!-- Defaults to showing completed even though that hides reviews that are overdue, but not completed
           Bright Futures rely on email reminders for that, so it's not the primary workflow here -->
    <home-page-filter-list-card
      v-if="unapprovedReviews.length > 0 && !selectedInstitution.config.mentor_training_only"
      mosaic-key="unapprovedReviews"
      :error-message="unapprovedReviewsErrorMessage"
      :title="`Unapproved ${reviewNounCapitalisedAndPluralised} for your Schools`"
      :search="unapprovedReviewFilter"
      :items="unapprovedReviewsWithOrders"
      :status-filter-statuses="completionStatuses"
      status-filter-label="Completion Status"
      initial-filter-status="completed"
      :item-name-pluralised="reviewNounCapitalisedAndPluralised"
      :filter-label="`Filter by ECT, School or ${reviewNounCapitalised}`"
      :sort="unapprovedReviewsSort"
      :institution-name="selectedInstitution.name"
      :institution-contact-email="selectedInstitution.contact_email"
    >
      <template #item="{ item: { student, id, name, status, overdue, ect_school, student_due_date } }">
        <mosaic-list-item
          :key="id"
          icon="mdi-check-circle-outline"
          :to="studentReviewClickTo(id, student.id)"
          :title="`${student.name || student.email} - ${name}`"
          :subtitle="`Assigned whilst at: ${ect_school.display_name}`"
          ><template #information>
            <v-chip class="mr-1">{{ renderCompletionStatus(status) }}</v-chip>
            <v-chip :color="overdue === 1 ? 'error' : 'accent'">Due {{ formatDueDate(student_due_date) }}</v-chip>
          </template></mosaic-list-item
        >
      </template>
    </home-page-filter-list-card>

    <home-page-filter-list-card
      v-if="ectRegistrationsRequiringAttention.length > 0 && !selectedInstitution.config.mentor_training_only"
      mosaic-key="attentionEcts"
      title="Rejected ECT Registrations"
      :items="ectRegistrationsRequiringAttention"
      item-name-pluralised="ECTs"
      :search="studentFilter"
      filter-label="Filter by name or email"
      :sort="['status', 'name']"
      :institution-name="selectedInstitution.name"
      :institution-contact-email="selectedInstitution.contact_email"
    >
      <template #item="{ item, item: { name, status, id, currentSchool, startSchool } }">
        <mosaic-list-item
          :key="id"
          icon="mdi-account"
          :to="ectRegistrationClickTo(item)"
          :title="name"
          :subtitle="currentSchool ? currentSchool.displayName : startSchool.displayName"
        >
          <template #information>
            <v-chip color="red">{{ ectStatusName(status) }}</v-chip>
          </template>
        </mosaic-list-item>
      </template>
    </home-page-filter-list-card>

    <home-page-filter-list-card
      v-if="schools.length > 0"
      mosaic-key="schools"
      title="Schools"
      :items="schools"
      item-name-pluralised="Schools"
      :search="schoolFilter"
      filter-label="Filter by name or postcode"
      :sort="'name'"
      :institution-name="selectedInstitution.name"
      :institution-contact-email="selectedInstitution.contact_email"
    >
      <template #item="{ item: { display_name, roleName, institution, id } }">
        <mosaic-list-item
          :key="id"
          icon="mdi-domain"
          :to="schoolClickTo(id)"
          :title="display_name"
          :subtitle="`${roleName} -
                                                                                                                                                                                                                                                                                                                                                                                                                                ${institution.name}`"
        ></mosaic-list-item>
      </template>
    </home-page-filter-list-card>

    <home-page-filter-list-card
      v-if="trainees.length > 0 && !selectedInstitution.config.mentor_training_only"
      mosaic-key="trainees"
      title="My Trainees"
      :items="trainees"
      item-name-pluralised="Trainees"
      :search="studentFilter"
      filter-label="Filter by name or email"
      :sort="'name'"
      :institution-name="selectedInstitution.name"
      :institution-contact-email="selectedInstitution.contact_email"
    >
      <template #item="{ item: student }">
        <mosaic-list-item
          :key="student.id"
          icon="mdi-account"
          :to="studentClickTo(student)"
          :title="student.name || student.email"
          :subtitle="`${student.roleName} - ${student.institution.name}`"
        ></mosaic-list-item>
      </template>
    </home-page-filter-list-card>

    <home-page-filter-list-card
      v-if="ects.length > 0 && !selectedInstitution.config.mentor_training_only"
      mosaic-key="ects"
      title="My ECTs"
      :items="ects"
      item-name-pluralised="ECTs"
      :search="studentFilter"
      filter-label="Filter by name or email"
      :sort="'name'"
      :institution-name="selectedInstitution.name"
      :institution-contact-email="selectedInstitution.contact_email"
    >
      <template #item="{ item: student }">
        <mosaic-list-item
          :key="student.id"
          icon="mdi-account"
          :to="studentClickTo(student)"
          :title="student.name || student.email"
          :subtitle="`${student.roleName} - ${student.institution.name}`"
        ></mosaic-list-item>
      </template>
    </home-page-filter-list-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import HomePageFilterListCard from '@/components/home-pages/HomePageFilterListCard.vue';
import HomePageSimpleListCard from '@/components/home-pages/HomePageSimpleListCard.vue';
import { findStudentLevelHomePage } from '@/utils/navigation';
import { statuses } from '@/utils/student-review';
import { ectStatusItems } from '@/utils/ect/ect';
import moment from 'moment';
import { canAuthorInstructorCourseFiles } from '@/utils/staff';
import { icons } from '@/utils/icons';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import { createStaffAnnouncementStatusChip } from '@/pages/announcements/staff-announcements';
import { useStaffTrainingStore } from '@/stores/staff-training';
import AssignmentStatusChip from '@/pages/assignments/AssignmentStatusChip.vue';
import { getMentorTrainingPageRedirection } from '@/router/guard-methods';

export default {
  name: 'TutorHomePage',
  components: { HomePageFilterListCard, MosaicQuill, HomePageSimpleListCard, AssignmentStatusChip },
  setup() {
    const staffTrainingStore = useStaffTrainingStore();
    return {
      staffTrainingStore,
    };
  },
  data: () => ({
    cohortStatuses: [
      { title: 'All', value: 'all' },
      { title: 'Closed', value: 'closed' },
      { title: 'Active', value: 'active' },
    ],
    selectedCohortStatus: 'active',
    traineeSearchTerm: '',
    ectSearchTerm: '',
    schoolSearchTerm: '',
    currentPage: 1,
    pageSize: 10,
    unapprovedReviews: [],
    incompleteReviews: [],
    unapprovedReviewsErrorMessage: '',
    studentReviewSearchTerm: '',
    completionStatuses: [{ title: 'All', value: 'all' }, ...statuses].filter(s => s.value != 'approved'),
    assignmentNotCompleteStatuses: [
      { title: 'All', value: 'all' },
      { title: 'Not Started', value: 'not_started' },
      { title: 'Started', value: 'started' },
    ],
    selectedCompletionStatus: 'all',
    ectRegistrationsRequiringAttention: [],
    ectStatusItems,
    announcements: [],
    upcomingStaffTrainingEvents: [],
    icons,
    incompleteReviewsSort: {
      iteratees: ['overdue', 'student_due_date', 'student.name', 'student.email'],
      orders: ['desc', 'asc', 'asc', 'asc'],
    },
    incompleteAssignments: [],
    incompleteAssignmentsSort: {
      iteratees: ['overdue', 'due_date', 'student.name', 'student.email'],
      orders: ['desc', 'asc', 'asc', 'asc'],
    },
    unapprovedReviewsSort: {
      iteratees: ['overdue', 'completedOrder', 'student_due_date', 'statusOrder', 'student.name', 'student.email'],
      orders: ['desc', 'desc', 'asc', 'desc', 'asc', 'asc'],
    },
  }),
  mounted() {
    this.staffTrainingStore.actions.loadStaffTraining(this.userStaff.id);
  },
  computed: {
    ...mapState(['user', 'userStaff', 'selectedInstitution']),
    ...mapGetters(['contactProviderMessage']),
    canAuthorInstructorCourseFiles() {
      return canAuthorInstructorCourseFiles(this.userStaff);
    },
    trainees() {
      if (this.userStaff.institution.config.early_careers) return [];

      const staffRoles = this.userStaff.staff_roles
        .filter(x => x.student_id)
        .map(x => ({ ...x.student, institution: this.userStaff.institution, roleName: x.role.name }));
      return this.groupRolesForTheSameScope(staffRoles);
    },
    ects() {
      if (!this.userStaff.institution.config.early_careers) return [];

      const staffRoles = this.userStaff.staff_roles
        .filter(x => x.student_id && (!x.student.ect || x.student.ect.status == 'active'))
        .map(x => ({ ...x.student, institution: this.userStaff.institution, roleName: x.role.name }));

      return this.groupRolesForTheSameScope(staffRoles);
    },
    schools() {
      const staffRoles = this.userStaff.staff_roles
        .filter(x => x.school_id)
        .map(x => ({ ...x.school, institution: this.userStaff.institution, roleName: x.role.name }));

      return this.groupRolesForTheSameScope(staffRoles);
    },
    cohorts() {
      const staffRoles = this.userStaff.staff_roles
        .filter(x => x.cohort_id)
        .map(x => ({
          ...x.cohort,
          institution: this.userStaff.institution,
          roleName: x.role.name,
          noStudentYetCohort: false,
        }));

      return this.groupRolesForTheSameScope(staffRoles);
    },
    initialCohortFilterStatus() {
      return this.cohorts.every(x => x.status === 'closed') ? 'closed' : 'active';
    },
    showInstitutionCard() {
      return this.selectedInstitution.config.staff_home_page_rubric || this.institutionCardItems.length > 0;
    },
    institutionCardItems() {
      const items = [];

      if (!this.selectedInstitution.config.early_careers && this.staffTrainingStore.hasStaffTraining.value) {
        const myTraining = this.staffTrainingStore.staffTraining.value;

        const themes = myTraining.staffTrainingFrameworks.map(f => f.staffTrainingCompetencyThemes).flat(Infinity);
        const themesNotMet = themes.filter(t => t.status === 'not_met');
        const status = [];

        if (myTraining?.totalDuration) {
          status.push({
            icon: 'mdi-clock-outline',
            text: `${myTraining.totalDuration} of Completed Training`,
          });
        }
        if (themes.length > 0) {
          status.push({
            icon: this.icons.instructorTrainingCompetencyTheme,
            text: `${themes.length - themesNotMet.length}/${themes.length} Competency Themes Met`,
          });
        }
        if (myTraining?.staffTrainingModuleCompletions.length > 0) {
          status.push({
            icon: this.icons.instructorTrainingModule,
            text: `${myTraining.staffTrainingModuleCompletions.length} Completed Modules`,
          });
        }
        if (myTraining?.staffTrainingRecords.length > 0) {
          status.push({
            icon: this.icons.instructorTrainingRecordAudit,
            text: `${myTraining.staffTrainingRecords.length} Manual Records`,
          });
        }
        if (myTraining?.staffTrainingCertificates.length > 0) {
          status.push({
            icon: this.icons.certificate,
            text: `${myTraining.staffTrainingCertificates.length} Certificates`,
          });
        }
        if (myTraining?.staffTrainingEventAttendances.length > 0) {
          status.push({
            icon: this.icons.instructorTrainingEvent,
            text: `${myTraining.staffTrainingEventAttendances.length} Attended Events`,
          });
        }

        items.push({
          icon: icons.instructorTraining,
          title: 'My Training',
          subtitle: '',
          status,
          to: {
            name: 'TutorStaffTrainingPage',
            params: { institutionId: this.selectedInstitution.id },
          },
        });
      }

      if (this.userStaff.has_forums) {
        items.push({
          icon: icons.forum,
          title: 'Forums',
          subtitle: '',
          to: {
            name: 'StaffForumsListPage',
            params: { staff: this.userStaff.id },
          },
        });
      }

      if (this.userStaff.has_any_staff_files || this.canAuthorInstructorCourseFiles) {
        items.push({
          icon: icons.instructorSharedFiles,
          title: 'Shared Files',
          subtitle: '',
          status: [],
          to: {
            name: 'TutorStaffFilesListPage',
            params: { institutionId: this.selectedInstitution.id },
          },
        });
      }
      return items;
    },
    hasHomePageContent() {
      return (
        this.ects.length > 0 ||
        this.trainees.length > 0 ||
        this.cohorts.length > 0 ||
        this.schools.length > 0 ||
        this.unapprovedReviews.length > 0 ||
        this.ectRegistrationsRequiringAttention.length > 0
      );
    },
    hasClosedCohorts() {
      return this.cohorts.some(x => x.status === 'closed');
    },
    unapprovedReviewsWithOrders() {
      return this.unapprovedReviews.map(r => {
        return {
          ...r,
          completedOrder: r.status == 'completed' ? 1 : -1,
          overdue: this.isOverdue(r.dueDate) ? 1 : -1,
          statusOrder: statuses.find(s => s.value === r.status).order,
        };
      });
    },
    mappedIncompleteAssignments() {
      return this.incompleteAssignments.map(a => {
        return {
          ...a,
          overdue: a.dueDate ? (this.isOverdue(a.dueDate) ? 1 : -1) : -2,
        };
      });
    },
    mappedIncompleteReviews() {
      return this.incompleteReviews.map(r => {
        return {
          ...r,
          overdue: this.isOverdue(r.student_due_date) ? 1 : -1,
        };
      });
    },
    activeAnnouncements() {
      return this.announcements.filter(a => !a.read);
    },
    announcementsCard() {
      return {
        title: 'Announcements',
        titleLink: {
          text: `See all (${this.activeAnnouncements.length} unread)`,
          to: { name: 'StaffAnnouncementsPage', params: { id: this.userStaff.id } },
        },
        itemGroups: [
          {
            subtitle: 'Announcements from your course leads',
            emptyText: 'You have no unread announcements',
            icon: icons.announcement,
            items: this.activeAnnouncements.slice(0, 3).map(a => ({
              key: a.id,
              to: { name: 'StaffAnnouncementPage', params: { id: a.id } },
              title: a.title,
              subtitle: `${this.formatDateFromNow(a.announcedAt)} - from ${a.createdBy.name}${
                a.cohortName ? ` - ${a.cohortName}` : ''
              }`,
              status: [],
              chip: createStaffAnnouncementStatusChip(a.read),
            })),
          },
        ],
      };
    },
  },
  async created() {
    this.showClosedCohorts = this.cohorts.every(x => x.status === 'closed');
    // Deliberately not awaited
    this.loadAnnouncements();
    if (this.userStaff.institution.config.early_careers) {
      // Deliberately not awaited
      this.loadEctRegistrationsRequiringAttention();
      try {
        const r = await this.$api.get(
          `/institutions/${this.selectedInstitution.id}/unapproved-and-completed-school-student-reviews`
        );
        this.unapprovedReviews = r.data;
        // Deliberately not awaited
        this.loadAllUnapprovedSchoolStudentReviews();
      } catch (e) {
        console.log(e);
        this.unapprovedReviewsErrorMessage = `Sorry, cannot load ${this.reviewNounCapitalisedAndPluralised} right now`;
      }
    }
    this.loadIncompleteReviews();
    this.loadUpcomingStaffTrainingEvents();
    this.loadIncompleteAssignments();
  },
  methods: {
    async loadAnnouncements() {
      try {
        const r = await this.$api.get(`/staff/${this.userStaff.id}/staff-announcements`);
        this.announcements = r.data.sortBy('announcedAt', 'desc');
      } catch (e) {
        console.log(e);
      }
    },
    async loadAllUnapprovedSchoolStudentReviews() {
      try {
        const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/unapproved-school-student-reviews`);
        this.unapprovedReviews = r.data;
      } catch (e) {
        console.log(e);
        this.unapprovedReviewsErrorMessage = `Sorry, cannot load ${this.reviewNounCapitalisedAndPluralised} right now`;
      }
    },
    async loadEctRegistrationsRequiringAttention() {
      try {
        const r = await this.$api.get(
          `/institutions/${this.selectedInstitution.id}/ects/pending-ects-requiring-attention`
        );
        this.ectRegistrationsRequiringAttention = r.data;
      } catch (e) {
        console.log(e);
        this.ectRegistrationsRequiringAttentionErrorMessage = `Sorry, cannot load ECT registrations requiring attention right now`;
      }
    },
    async loadIncompleteReviews() {
      try {
        const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/incomplete-student-reviews`);
        this.incompleteReviews = r.data;
      } catch (e) {
        console.log(e);
        this.incompleteReviewsErrorMessage = `Sorry, cannot load incomplete ${this.reviewNounPluralised} right now`;
      }
    },
    async loadIncompleteAssignments() {
      try {
        const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/incomplete-student-assignments`);
        this.incompleteAssignments = r.data.filter(a => a.student.cohort.showAssignments);
      } catch (e) {
        console.log(e);
      }
    },
    async loadUpcomingStaffTrainingEvents() {
      try {
        const r = await this.$api.get(`/staff/${this.userStaff.id}/staff-training-events/upcoming`);
        this.upcomingStaffTrainingEvents = r.data;
      } catch (e) {
        console.log(e);
      }
    },
    ectStatusName(status) {
      return this.ectStatusItems.find(s => s.value === status).title;
    },
    unapprovedReviewFilter(searchTerm, sr) {
      return (
        sr.student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sr.student.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sr.ect_school.display_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sr.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    incompleteReviewFilter(searchTerm, sr) {
      return (
        sr.student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sr.student.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sr.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    incompleteAssignmentFilter(searchTerm, sa) {
      return (
        sa.student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sa.student.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sa.assignment.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    schoolFilter(searchTerm, school) {
      return school.display_name.toLowerCase().includes(searchTerm.toLowerCase());
    },
    studentFilter(searchTerm, student) {
      return (
        student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    eventsFilter(searchTerm, event) {
      return event.name.toLowerCase().includes(searchTerm.toLowerCase());
    },
    renderCompletionStatus(status) {
      return this.completionStatuses.find(s => s.value == status).title;
    },
    renderCohortStatus(status) {
      return this.cohortStatuses.find(s => s.value == status).title;
    },
    cohortFilter(searchTerm, cohort) {
      return cohort.name.toLowerCase().includes(searchTerm.toLowerCase());
    },
    groupRolesForTheSameScope(staffRoles) {
      return staffRoles.reduce((dedupedStaffRoles, staffRole) => {
        const matchingScope = dedupedStaffRoles.find(x => x.id == staffRole.id);
        if (matchingScope) {
          matchingScope.roleName += `, ${staffRole.roleName}`;
          return dedupedStaffRoles;
        } else {
          return [...dedupedStaffRoles, staffRole];
        }
      }, []);
    },
    formatDueDate(dueDate) {
      return moment(dueDate).format('DD/MM/YY');
    },
    studentClickTo(student) {
      return findStudentLevelHomePage(this.userStaff, student);
    },
    ectRegistrationClickTo(ect) {
      const params = {
        schoolId: ect.currentSchool?.id || ect.startSchool?.id,
        ectId: ect.id,
      };
      return { name: 'TutorEctReviewRegistrationPage', params };
    },
    schoolClickTo(schoolId) {
      return {
        name: 'TutorSchoolStudentsPage',
        params: { schoolId },
      };
    },
    studentReviewClickTo(studentReviewId, studentId) {
      return {
        name: 'TutorReviewPage',
        params: { id: studentReviewId, studentId: studentId },
      };
    },
    studentAssignmentClickTo(assignment, studentId) {
      return {
        name: 'TutorAssignmentLatestVersionPage',
        params: { id: assignment.id, studentId: studentId },
      };
    },
    cohortClickTo(cohort) {
      if (this.selectedInstitution.config.mentor_training_only) {
        return {
          name: getMentorTrainingPageRedirection(this.userStaff, cohort),
          params: { cohortId: cohort.id },
        };
      } else if (cohort.noStudentYetCohort) {
        return {
          name: 'TutorStaffTrainingPage',
          params: { institutionId: cohort.institution.id },
        };
      } else {
        return {
          name: 'TutorStudentListPage',
          params: { cohortId: cohort.id },
        };
      }
    },
    eventClickTo(event) {
      return {
        name: 'MyStaffTrainingEventPage',
        params: { institutionId: this.selectedInstitution.id, eventId: event.id },
        query: {
          returnUrl: this.$router.resolve({ name: 'TutorHomePage', params: { staffId: this.userStaff.id } }).href,
        },
      };
    },
  },
};
</script>
<style scoped>
.my-training-status {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 8px;
}
</style>
