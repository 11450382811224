<template>
  <div>
    <staff-list
      :staff-click-to="staffClickTo"
      show-reset-password
      :can-reset-password="hasStaffViewEditPermission"
      :url="url"
      :title="`${selectedCohort.name} Cohort Instructors`"
    />
  </div>
</template>
<script setup lang="ts">
import { mapState } from '@/store/map-store';
import StaffList from './StaffList.vue';
import { hasPermissionForSelectedCohort, hasPermissionForSelectedInstitution } from '@/composables/permission';
import { computed } from 'vue';

const { selectedCohort, selectedInstitution } = mapState();
const url = `/cohorts/${selectedCohort.value.id}/staff`;
const hasStaffViewEditPermission = computed(() => hasPermissionForSelectedInstitution('staff.edit').value);

const staffClickTo = (id: number) => {
  if (
    selectedInstitution.value &&
    (hasStaffViewEditPermission.value || hasPermissionForSelectedCohort('staff.view').value)
  ) {
    return {
      name: 'CohortTutorStaffPage',
      params: { id, institutionId: selectedInstitution.value.id, cohortId: selectedCohort.value?.id },
    };
  }
  return;
};
</script>
