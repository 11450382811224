import { defineStore } from 'pinia';
import { useStore } from './common';
import { CacheMap } from './cache-map';
import { computed, ref } from 'vue';
import { useInstitutionStore } from './institution';
import { useApi } from '@/composables/api';

export type ScormPackagesResponse = {
  id: number;
  name: string;
}[];
export type ScormPackage = ScormPackagesResponse[number];

export const useScormPackagesStore = useStore(
  defineStore('scormPackages', () => {
    const api = useApi();

    const { selectedInstitution } = useInstitutionStore();

    const scormPackagesCache = ref(new CacheMap<ScormPackagesResponse>(60 * 24));

    function reset() {
      scormPackagesCache.value.clear();
    }

    async function loadScormPackages(force?: boolean) {
      const institutionId = selectedInstitution.value.id;
      if (force || !scormPackagesCache.value.get(institutionId)) {
        const r = await api.get<ScormPackagesResponse>(`/institutions/${institutionId}/scorm-packages`);
        scormPackagesCache.value.set(institutionId, r.data);
      }
    }

    const institutionScormPackages = computed(() =>
      scormPackagesCache.value.getEvenIfExpired(selectedInstitution.value.id)
    );

    return { reset, scormPackagesCache, loadScormPackages, institutionScormPackages };
  })
);
