<template>
  <div class="flex-grow-1-no-overflow">
    <mosaic-error-snackbar v-model="openResourceError" action="open this Resource" />
    <div class="d-flex flex-grow-1-no-overflow mb-4">
      <div v-if="item.itemType === 'text'" class="flex-grow-1-no-overflow mx-6 my-2">
        <div class="d-flex">
          <mosaic-quill
            :contents="item.value"
            :read-only="!editable"
            :min-height="editable ? '65px' : '0'"
            class="flex-grow-1-no-overflow"
            style="min-width: 0"
            @update:contents="updateItem(item, { value: $event })"
          />
          <div v-if="!item.value">
            <mosaic-error-icon :tooltip="`Text Blocks cannot be empty. Please amend or remove to enable saving.`" />
          </div>
        </div>
      </div>

      <div v-if="item.itemType === 'resource'" class="flex-grow-1-no-overflow mr-6 my-2">
        <mosaic-list-item
          @click.prevent="clickResource(item.resourceId)"
          icon="mdi-file"
          :title="item.title"
          :subtitle="item.description"
        >
          <template #actions v-if="editable">
            <ndt-icon-button icon="pencil" tooltip="Edit resource" @click.prevent="editResource(item)" />
            <ndt-icon-button
              icon="download"
              tooltip="Download resource"
              @click.prevent="downloadResource(item.resourceId)"
            />
          </template>
        </mosaic-list-item>
      </div>

      <div v-if="item.itemType === 'video'" class="flex-grow-1-no-overflow mr-6 my-2">
        <mosaic-list-item icon="mdi-video" :title="item.title" />
      </div>

      <div v-if="item.itemType === 'image'" class="flex-grow-1-no-overflow ml-6 mr-6">
        <mosaic-text-field
          class="mb-4"
          :readonly="!editable"
          name="image-description"
          label="Description"
          :model-value="item.description"
          :no-icon="true"
          hide-details
          @update:model-value="updateItem(item, { description: $event })"
        />
        <div class="d-flex">
          <mosaic-radio-buttons
            :readonly="!editable"
            label="Display"
            :radios="[
              { label: 'Full width', value: 'fullWidth' },
              { label: 'Scaled width', value: 'scaled' },
            ]"
            :model-value="item.displayType"
            inline
            @update:model-value="updateItem(item, { displayType: $event })"
          ></mosaic-radio-buttons>
          <mosaic-color-picker
            name="image-background-color"
            label="Background Colour"
            :model-value="item.backgroundColor"
            :readonly="!editable"
            @update:model-value="updateItem(item, { backgroundColor: $event })"
          />
        </div>
        <div v-if="item.displayType === 'scaled'" class="d-flex align-start">
          <mosaic-text-field
            name="width-percentage"
            :readonly="!editable"
            class="mr-2"
            label="Percentage of width"
            no-icon
            :model-value="item.widthPercentage"
            type="number"
            style="width: 200px"
            @update:model-value="updateItem(item, { widthPercentage: parseInt($event) })"
          />
          <div style="width: 40px" class="mr-4 pt-5">
            <mosaic-error-icon
              v-if="item.widthPercentage < 10 || item.widthPercentage > 100"
              tooltip="Percentage must be between 10 and 100."
            />
          </div>
          <mosaic-radio-buttons
            label="Align"
            :readonly="!editable"
            :radios="[
              { label: 'Left', value: 'left' },
              { label: 'Center', value: 'center' },
              { label: 'Right', value: 'right' },
            ]"
            :model-value="item.alignment"
            inline
            @update:model-value="updateItem(item, { alignment: $event })"
          ></mosaic-radio-buttons>
        </div>
        <image-embed :item="item" :get-resource-url-prefix="getResourceUrlPrefix" />
      </div>

      <div v-if="item.itemType === 'youtube'" class="flex-grow-1-no-overflow ml-6 mr-4">
        <div class="d-flex align-center">
          <div class="flex-grow-1-no-overflow">
            <mosaic-text-field
              name="you-tube-url"
              label="YouTube URL"
              :model-value="item.url"
              :readonly="!editable"
              :no-icon="true"
              hide-details
              class="mb-2"
              @update:model-value="updateItem(item, { url: $event })"
            />
          </div>
          <div v-if="!item.url" style="width: 40px" class="ml-2">
            <mosaic-error-icon tooltip="YouTube links must have a URL." />
          </div>
        </div>
      </div>

      <div v-if="item.itemType === 'link'" class="flex-grow-1-no-overflow ml-6 mr-4">
        <div class="d-flex align-center">
          <div class="flex-grow-1-no-overflow">
            <mosaic-text-field
              name="link-title"
              label="Title"
              :model-value="item.title"
              :readonly="!editable"
              :no-icon="true"
              hide-details
              class="mb-2"
              @update:model-value="updateItem(item, { title: $event })"
            />
          </div>
          <div v-if="!item.url" style="width: 40px" class="ml-2">
            <mosaic-error-icon tooltip="External links must have a title." />
          </div>
        </div>
        <div class="d-flex align-center">
          <div class="flex-grow-1-no-overflow">
            <mosaic-text-field
              name="link-url"
              label="URL"
              :model-value="item.url"
              :readonly="!editable"
              :no-icon="true"
              hide-details
              class="mb-2"
              @update:model-value="updateItem(item, { url: $event })"
            />
          </div>
          <div v-if="!item.url" style="width: 40px" class="ml-2">
            <mosaic-error-icon tooltip="External links must have a URL." />
          </div>
        </div>
        <div class="d-flex align-start">
          <mosaic-radio-buttons
            :readonly="!editable"
            :radios="[
              { label: 'Open in new tab', value: 'newTab' },
              { label: 'Display inline', value: 'inline' },
            ]"
            :model-value="item.openType"
            inline
            @update:model-value="updateItem(item, { openType: $event })"
          ></mosaic-radio-buttons>
          <div v-if="item.openType === 'inline'" class="flex-grow-1-no-overflow ml-2" style="margin-top: 10px">
            (Mosaic recommends double checking the preview for this)
          </div>
        </div>
        <div v-if="editable || item.description">
          <mosaic-text-field
            name="link-description"
            label="Description"
            :model-value="item.description"
            :readonly="!editable"
            :no-icon="true"
            hide-details
            class="mb-2"
            @update:model-value="updateItem(item, { description: $event })"
          />
        </div>
      </div>

      <div v-if="item.itemType === 'curriculumLink'" class="flex-grow-1-no-overflow mr-6 my-2">
        <mosaic-list-item
          lines="two"
          icon="mdi-hexagon"
          :title="`${safelyGetCurriculumStatement(item, curriculumStatements).code} - ${
            safelyGetCurriculumStatement(item, curriculumStatements).statement
          }`"
          @click.prevent="emit('clickCurriculumStatement', safelyGetCurriculumStatement(item, curriculumStatements))"
        >
          <template #information>
            <div
              v-if="
                safelyGetCurriculumStatement(item, curriculumStatements).hidden ||
                !selectedCohort.show_curriculum_for_students
              "
              class="mr-4 ml-2"
            >
              <v-chip color="secondary">Hidden</v-chip>
            </div>
            <div v-if="!!safelyGetCurriculumStatement(item, curriculumStatements).subject_id" class="ml-2">
              <v-chip color="secondary"
                >{{
                  subjectName(safelyGetCurriculumStatement(item, curriculumStatements).subject_id || -1)
                }}
                only</v-chip
              >
            </div>
          </template>
        </mosaic-list-item>
      </div>

      <div v-if="item.itemType === 'fileUpload'" class="flex-grow-1-no-overflow ml-6 mr-4">
        <div class="d-flex align-start">
          <div class="flex-grow-1-no-overflow">
            <mosaic-text-field
              name="file-upload"
              label="Prompt"
              :model-value="item.title"
              :readonly="!editable"
              :no-icon="true"
              @update:model-value="updateItem(item, { title: $event })"
            />
            <div class="d-flex">
              <mosaic-text-field
                name="min-files"
                :readonly="!editable"
                class="mr-4"
                style="width: 100px"
                label="Min # of files"
                no-icon
                :model-value="item.minFiles"
                type="number"
                @update:model-value="updateItem(item, { minFiles: parseInt($event) })"
              />
              <mosaic-text-field
                name="max-files"
                :readonly="!editable"
                style="width: 100px"
                label="Max # of files"
                no-icon
                :model-value="item.maxFiles"
                type="number"
                @update:model-value="updateItem(item, { maxFiles: parseInt($event) })"
              />
            </div>
          </div>
          <div style="width: 40px" class="ml-2">
            <div v-if="!item.title">
              <mosaic-error-icon
                :tooltip="`File upload prompt must be provided. Please amend or remove to enable saving.`"
              />
            </div>
            <div v-if="item.maxFiles < item.minFiles">
              <mosaic-error-icon :tooltip="`Max files must be greater than or equal to min files.`" />
            </div>
            <div v-if="item.minFiles <= 0">
              <mosaic-error-icon :tooltip="`Min files must be greater than 0.`" />
            </div>
            <div v-if="item.maxFiles > 10">
              <mosaic-error-icon :tooltip="`Max files must be less than or equal to 10.`" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="item.itemType === 'comment'" class="flex-grow-1-no-overflow ml-6 mr-4">
        <div class="d-flex align-center">
          <div class="flex-grow-1-no-overflow">
            <mosaic-text-field
              name="comment"
              label="Question"
              :model-value="item.title"
              :readonly="!editable"
              :no-icon="true"
              @update:model-value="updateItem(item, { title: $event })"
            />
          </div>
          <div style="width: 40px" class="ml-2">
            <div v-if="!item.title">
              <mosaic-error-icon
                :tooltip="`Question text must be provided. Please amend or remove to enable saving.`"
              />
            </div>
          </div>
        </div>
        <div v-if="editable || item.placeholder">
          <div class="font-weight-bold mb-1">Placeholder (optional)</div>
          <mosaic-quill
            :contents="item.placeholder"
            :read-only="!editable"
            @update:contents="updateItem(item, { placeholder: $event })"
          />
        </div>
      </div>

      <div v-if="item.itemType === 'taskList'" class="flex-grow-1-no-overflow ml-6 mr-4">
        <div class="d-flex align-center">
          <div class="flex-grow-1-no-overflow">
            <mosaic-text-field
              name="task-list-title"
              label="Title"
              :model-value="item.title"
              :readonly="!editable"
              :no-icon="true"
              hide-details
              class="mb-2"
              @update:model-value="updateItem(item, { title: $event })"
            />
          </div>
          <div style="width: 40px" class="ml-2">
            <div v-if="item.tasks.length == 0" class="ml-2">
              <mosaic-error-icon :tooltip="`Task list must contain tasks. Please amend or remove to enable saving.`" />
            </div>
            <div v-else-if="!item.title && editable">
              <mosaic-warning-icon
                :tooltip="`No title has been provided, which
                      may be confusing for ${traineeNounCapitalisedAndPluralised} and Mentors.`"
              />
            </div>
          </div>
        </div>
        <div v-for="task of item.tasks" :key="task.id" class="d-flex align-center">
          <mosaic-checkbox
            name="hide-details-check"
            class="mt-0 flex-shrink-1 flex-grow-0 mr-1"
            no-icon
            :model-value="false"
          />
          <mosaic-text-area
            name="task-title"
            label="Task"
            :model-value="task.title"
            :readonly="!editable"
            :no-icon="true"
            rows="1"
            class="pt-0 mb-4 flex-grow-1-no-overflow"
            hide-details
            @update:model-value="updateTaskTitle(item, task, $event)"
          />
          <div v-if="editable" class="ml-2">
            <ndt-icon-button icon="delete" :tooltip="`Delete task`" @click.prevent="deleteTask(item, task)" />
          </div>
          <div v-if="!task.title" class="ml-2">
            <mosaic-error-icon :tooltip="`Task cannot be empty. Please amend or remove to enable saving. `" />
          </div>
        </div>
        <v-btn v-if="editable" class="my-2" @click.prevent="addTask(item)">
          <v-icon>mdi-plus</v-icon>
          <span>Task</span>
        </v-btn>
      </div>

      <div
        v-if="item.itemType === 'nasbttMentorModule' || item.itemType === 'nasbttTraineeModule'"
        class="flex-grow-1-no-overflow mr-4 d-flex"
      >
        <div class="flex-grow-1">
          <mosaic-autocomplete
            v-if="!nasbttModulesLoadingError"
            name="nasbtt-modules"
            label="Module"
            :model-value="item.publicationCode || undefined"
            @update:model-value="updateNasbttModulesModule(item, $event)"
            :items="item.itemType === 'nasbttMentorModule' ? nasbttMentorModules : []"
            item-title="full_title"
            item-value="publication_code"
            :readonly="!editable"
          />
          <div v-else class="ml-2">Sorry, cannot load the NASBTT Modules at the moment</div>
        </div>
        <div style="width: 40px" class="ml-2 pt-5">
          <mosaic-error-icon v-if="!item.publicationCode" tooltip="A Module must be selected." />
        </div>
      </div>

      <div v-if="item.itemType === 'scormPackage'" class="flex-grow-1-no-overflow mr-4">
        <div class="d-flex">
          <div class="flex-grow-1">
            <mosaic-autocomplete
              name="nasbtt-modules"
              label="Package name"
              :model-value="item.scormPackageId === -1 ? undefined : item.scormPackageId"
              @update:model-value="updateScormPackageId(item, $event)"
              :items="institutionScormPackages"
              item-title="name"
              item-value="id"
              :no-data-text="
                scormPackagesLoading
                  ? 'Loading...'
                  : scormPackageLoadError
                  ? 'Sorry, cannot load your SCORM packages at the moment'
                  : 'There are no SCORM packages. Add one to the right.'
              "
              :readonly="!editable"
            />
          </div>
          <div style="width: 40px" class="ml-2 pt-5">
            <mosaic-error-icon v-if="item.scormPackageId == -1" tooltip="A SCORM package must be selected." />
          </div>
          <div class="ml-2 pt-2" v-if="editable">
            <mosaic-icon-btn icon="package-variant-plus" tooltip="Add a SCORM package" @click="addScormPackage" />
          </div>
        </div>
        <div
          v-if="
            scormPackageStatus == 'awaitingUpload' ||
            scormPackageStatus == 'uploading' ||
            scormPackageStatus == 'awaitingProcessing'
          "
          class="pl-8"
        >
          <div class="pb-2 text-h7">Add a SCORM Package</div>
          <file-upload
            :files="scormPackageFile ? [scormPackageFile] : []"
            :max-files="1"
            :min-files="1"
            :presign-url="`/presign/institutions/${selectedInstitution.id}/scorm-package`"
            get-file-url-prefix=""
            label="Add a SCORM package"
            name="scorm-upload"
            no-icon
            :allowed-content-types="['application/zip', 'application/x-zip', 'application/x-zip-compressed']"
            @file-added="scormPackagedAdded"
            @file-removed="scormPackageRemoved"
            @uploading="scormPackageUploading"
          />
          <mosaic-text-field class="mt-2" label="Package name" v-model="scormPackageName" prepend-icon="pencil" />
          <div class="d-flex justify-end">
            <mosaic-btn
              :disabled="scormPackageStatus == 'awaitingUpload' || scormPackageStatus == 'uploading'"
              :disabled-tooltip="
                scormPackageStatus == 'uploading' ? 'Waiting for SCORM package' : 'Upload a SCORM package above'
              "
              @click="startScormPackageProcessing"
            >
              Add
            </mosaic-btn>
          </div>
        </div>
        <div v-else-if="scormPackageStatus == 'processing'" class="pl-8">
          <div v-if="createScormPackageError">
            <span>{{
              isString(createScormPackageError)
                ? createScormPackageError
                : 'Sorry, cannot add your SCORM package at the moment'
            }}</span>
            <mosaic-btn class="ml-4" @click="retryScormUpload">Retry</mosaic-btn>
          </div>
          <div v-else>Processing...</div>
        </div>
      </div>

      <div v-if="item.itemType === 'goReactAssignment'" class="flex-grow-1-no-overflow ml-8 mr-4">
        <div class="d-flex">
          <div class="flex-grow-1">
            <mosaic-autocomplete
              name="go-react-templates"
              label="GoReact Template name"
              :model-value="item.goReactTemplateId === -1 ? undefined : item.goReactTemplateId"
              @update:model-value="updateGoReactTemplateId(item, $event)"
              :items="institutionGoReactTemplates"
              item-title="name"
              item-value="id"
              prepend-icon="mosaic-go-react"
              :no-data-text="
                goReactTemplatesLoading
                  ? 'Loading...'
                  : goReactTemplatesLoadError
                  ? 'Sorry, cannot load your GoReact Templates at the moment'
                  : 'There are no GoReact Templates.'
              "
              :readonly="!editable"
            />
          </div>
          <div style="width: 40px" class="ml-2 pt-5">
            <mosaic-error-icon v-if="item.goReactTemplateId == -1" tooltip="A GoReact Template must be selected." />
          </div>
        </div>

        <div class="d-flex">
          <div class="flex-grow-1">
            <mosaic-text-field
              name="assignment-title"
              label="Name"
              :model-value="item.title"
              :readonly="!editable"
              :no-icon="true"
              @update:model-value="updateItem(item, { title: $event })"
            />
          </div>
          <div style="width: 40px" class="ml-2 pt-5">
            <mosaic-error-icon v-if="!item.title" tooltip="GoReact Assignments must have a title." />
          </div>
        </div>
      </div>

      <div v-if="item.itemType === 'columns'" class="flex-grow-1-no-overflow">
        <div class="ml-6">
          <mosaic-radio-buttons
            label="Align"
            :readonly="!editable"
            :radios="[
              { label: 'Top', value: 'top' },
              { label: 'Center', value: 'center' },
              { label: 'Bottom', value: 'bottom' },
            ]"
            :model-value="item.alignment"
            inline
            @update:model-value="updateItem(item, { alignment: $event })"
          ></mosaic-radio-buttons>
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="ml-6">Left Column</div>
            <div v-if="item.left">
              <div class="element-label mb-1 ml-6 mr-4 d-flex align-center">
                <mosaic-icon class="pr-2" :icon="itemIcon(item.left)" />
                <span class="flex-grow-1">{{ itemName(item.left) }}</span>
                <ndt-icon-button
                  icon="delete"
                  :tooltip="`Delete ${itemName(item.left)}`"
                  @click.prevent="updateItem(item, { left: undefined })"
                />
              </div>
              <mosaic-content-layout-builder-item
                :item="item.left"
                :editable="editable"
                :presign-url="presignUrl"
                :get-resource-url-prefix="getResourceUrlPrefix"
                :save-resource-url="saveResourceUrl"
                :save-resource-params="saveResourceParams"
                :supported-item-types="supportedItemTypes"
                @update:item="updateLeftColumn(item, $event)"
                @click-curriculum-statement="emit('clickCurriculumStatement', $event)"
              />
            </div>
            <div v-else-if="editable" class="ml-6 mt-2">
              <mosaic-btn-with-menu text="Element" icon="mdi-plus" :menu-items="addLeftColumnItemMenuItems(item)" />
            </div>
          </div>
          <div
            style="align-self: stretch'; margin-left: -4px; margin-right: -12px"
            class="d-flex flex-column justify-center align-center"
          >
            <div class="flex-grow-1">
              <v-divider vertical />
            </div>
            <mosaic-icon-btn icon="swap-horizontal-circle" tooltip="Swap columns" @click="swapColumns(item)" />

            <div class="flex-grow-1">
              <v-divider vertical />
            </div>
          </div>
          <div style="width: 50%">
            <div class="ml-6">Right Column</div>
            <div v-if="item.right">
              <div class="element-label mb-1 ml-6 mr-4 d-flex align-center">
                <mosaic-icon class="pr-2" :icon="itemIcon(item.right)" />
                <span class="flex-grow-1">{{ itemName(item.right) }}</span>
                <ndt-icon-button
                  icon="delete"
                  :tooltip="`Delete ${itemName(item.right)}`"
                  @click.prevent="updateItem(item, { right: undefined })"
                />
              </div>
              <mosaic-content-layout-builder-item
                :item="item.right"
                :editable="editable"
                :presign-url="presignUrl"
                :get-resource-url-prefix="getResourceUrlPrefix"
                :save-resource-url="saveResourceUrl"
                :save-resource-params="saveResourceParams"
                :supported-item-types="supportedItemTypes"
                @update:item="updateRightColumn(item, $event)"
                @click-curriculum-statement="emit('clickCurriculumStatement', $event)"
              />
            </div>

            <div v-else-if="editable" class="ml-6 mt-2">
              <mosaic-btn-with-menu text="Element" icon="mdi-plus" :menu-items="addRightColumnItemMenuItems(item)" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <edit-resource-dialog
      v-model:active="editResourceDialogRef.active"
      :resource="editResourceDialogRef.item"
      @resource-edited="resourceEdited"
    />

    <upload-dialog
      v-model:active="addVideoDialogRef.active"
      object-type="video"
      :presign-url="presignUrl"
      :save-resource-url="saveResourceUrl"
      :save-resource-params="saveResourceParams"
      @uploaded="videoAdded"
    />

    <upload-dialog
      v-model:active="addImageDialogRef.active"
      object-type="image"
      :presign-url="presignUrl"
      :save-resource-url="saveResourceUrl"
      :save-resource-params="saveResourceParams"
      @uploaded="imageAdded"
    />

    <add-resource-dialog
      v-model:active="addResourceDialogRef.active"
      :presign-url="presignUrl"
      :save-resource-url="saveResourceUrl"
      :save-resource-params="saveResourceParams"
      @resource-added="resourceAdded"
    />

    <nasbtt-modules-unavailable-dialog v-model:active="nasbttModulesUnavailableDialogActive" />
  </div>
</template>

<script setup lang="ts">
import type {
  ContentLayoutColumns,
  ContentLayoutColumnsItem,
  ContentLayoutGoReactAssignment,
  ContentLayoutItem,
  ContentLayoutItemType,
  ContentLayoutNasbttModule,
  ContentLayoutScormPackage,
  ContentLayoutTask,
  ContentLayoutTaskList,
} from '@/utils/content-layout';
import { safelyGetCurriculumStatement } from '@/utils/content-layout';
import { useOpenS3Files } from '@/utils/files';
import ImageEmbed from './ImageEmbed.vue';
import MosaicQuill from '../quill/MosaicQuill.vue';
import EditResourceDialog from './EditResourceDialog.vue';
import { ref } from 'vue';
import { v4 as uuid } from 'uuid';
import type { CurriculumStatement } from '@/store/map-store';
import { mapState } from '@/store/map-store';
import { useContentLayoutBuilder } from './content-layout-builder';
import UploadDialog from './UploadDialog.vue';
import AddResourceDialog from './AddResourceDialog.vue';
import NdtIconButton from '../NdtIconButton.vue';
import { useApi } from '@/composables/api';
import { useNasbttModulesStore } from '@/stores/nasbtt-modules';
import NasbttModulesUnavailableDialog from './NasbttModulesUnavailableDialog.vue';
import FileUpload, { type FileDetails } from '@/components/mosaic-content-layout/FileUpload.vue';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { useScormPackagesStore } from '@/stores/scorm-packages';
import { isString } from 'lodash';
import { useGoReactTemplatesStore } from '@/stores/go-react-templates';

const api = useApi();

const props = defineProps<{
  item: ContentLayoutItem;
  editable: boolean;
  supportedItemTypes: ContentLayoutItemType[];
  presignUrl: string;
  getResourceUrlPrefix: string;
  saveResourceUrl: string;
  saveResourceParams: Record<string, unknown>;
}>();

const emit = defineEmits<{
  (e: 'update:item', item: ContentLayoutItem): void;
  (e: 'clickCurriculumStatement', statement: CurriculumStatement): void;
}>();

const { curriculumStatements, selectedCohort, subjects, selectedInstitution } = mapState();

// Resources
const editResourceDialogRef = ref<{ active: boolean; item: ContentLayoutItem }>({
  active: false,
  item: { id: '', itemType: 'text', value: '' },
});

const {
  open: clickResource,
  download: downloadResource,
  error: openResourceError,
} = useOpenS3Files(props.getResourceUrlPrefix, api);

function editResource(item: ContentLayoutItem) {
  editResourceDialogRef.value = {
    active: true,
    item,
  };
}
function resourceEdited(attributes: { title: string; description: string }) {
  updateItem(editResourceDialogRef.value.item, attributes);
}

// Tasks
function addTask(item: ContentLayoutTaskList) {
  updateItem(item, { tasks: [...item.tasks, { id: uuid(), title: 'Do something...' }] });
}

function updateTaskTitle(item: ContentLayoutTaskList, task: ContentLayoutTask, title: string) {
  updateItem(item, {
    tasks: item.tasks.map(t => {
      if (t.id === task.id) {
        return { ...t, title };
      }
      return t;
    }),
  });
}

function deleteTask(item: ContentLayoutTaskList, task: ContentLayoutTask) {
  updateItem(item, { tasks: item.tasks.filter(t => t.id !== task.id) });
}

// NASBTT module
const {
  actions: { loadNasbttMentorModules },
  nasbttMentorModules,
  loadingError: nasbttModulesLoadingError,
} = useNasbttModulesStore();
if (props.item.itemType === 'nasbttMentorModule') {
  loadNasbttMentorModules();
}

function updateNasbttModulesModule(item: ContentLayoutNasbttModule, publicationCode: string) {
  updateItem(item, { publicationCode });
}

// SCORM packages
const scormPackageName = ref('');
const {
  institutionScormPackages,
  actions: { loadScormPackages },
} = useScormPackagesStore();

const {
  action: loadScormPackagesWithErrorHandling,
  error: scormPackageLoadError,
  processing: scormPackagesLoading,
} = withProcessingAndError(() => loadScormPackages());
if (props.item.itemType === 'scormPackage') {
  loadScormPackagesWithErrorHandling();
}

const scormPackageFile = ref<FileDetails>();

const scormPackageStatus = ref<'notStarted' | 'awaitingUpload' | 'uploading' | 'awaitingProcessing' | 'processing'>(
  'notStarted'
);

function addScormPackage() {
  if (scormPackageStatus.value == 'notStarted') scormPackageStatus.value = 'awaitingUpload';
}

function scormPackageUploading(uploading: boolean) {
  if (uploading) {
    scormPackageStatus.value = 'uploading';
  }
}

function scormPackageRemoved() {
  scormPackageStatus.value = 'awaitingUpload';
  scormPackageFile.value = undefined;
}

function scormPackagedAdded(f: FileDetails) {
  scormPackageStatus.value = 'awaitingProcessing';
  scormPackageFile.value = f;

  if (!scormPackageName.value) {
    scormPackageName.value = f.title.replace('.zip', '');
  }
}

const { action: createScormPackage, error: createScormPackageError } = withProcessingAndError(async () => {
  const r = await api.post<unknown, { id: number }>(`/institutions/${selectedInstitution.value.id}/scorm-packages`, {
    packageId: scormPackageFile.value?.fileId,
    name: scormPackageName.value,
  });

  const pollResponse = await api.pollGet<{ status: 'ready' | 'processing' | 'error'; errorMessage: string }>(
    `/scorm-packages/${r.data.id}`,
    {
      retryOnErrorStatuses: [],
      retryOnSuccess: r => r.data.status == 'processing',
    }
  );

  if (pollResponse.data.status == 'error') {
    return pollResponse.data.errorMessage;
  }

  await loadScormPackages(true);

  updateItem(props.item, { scormPackageId: r.data.id });
  scormPackageStatus.value = 'notStarted';
  scormPackageFile.value = undefined;
  scormPackageName.value = '';
});

async function startScormPackageProcessing() {
  // This is called twice when "Add" is clicked, but I don't know whhy, so we have to defend against that
  if (scormPackageStatus.value == 'processing') return;
  scormPackageStatus.value = 'processing';
  await createScormPackage();
}

function updateScormPackageId(item: ContentLayoutScormPackage, scormPackageId: number) {
  updateItem(item, { scormPackageId });
}

function retryScormUpload() {
  scormPackageStatus.value = 'awaitingProcessing';
}

// GoReact assignments
const {
  institutionGoReactTemplates,
  actions: { loadGoReactTemplates },
} = useGoReactTemplatesStore();

const {
  action: loadGoReactTemplatesWithErrorHandling,
  error: goReactTemplatesLoadError,
  processing: goReactTemplatesLoading,
} = withProcessingAndError(() => loadGoReactTemplates());
if (props.item.itemType === 'goReactAssignment') {
  loadGoReactTemplatesWithErrorHandling();
}

function updateGoReactTemplateId(item: ContentLayoutGoReactAssignment, goReactTemplateId: number) {
  const updates = { goReactTemplateId, title: item.title };
  if (!item.title) {
    const template = institutionGoReactTemplates.value.find(t => t.id == goReactTemplateId);
    updates.title = template?.name || '';
  }
  updateItem(item, updates);
}

// Columns
const {
  addResourceDialogRef,
  resourceAdded,
  addVideoDialogRef,
  videoAdded,
  addImageDialogRef,
  imageAdded,
  nasbttModulesUnavailableDialogActive,
  createAddItemMenuItems,
  itemIcon,
  itemName,
} = useContentLayoutBuilder();

function addLeftColumnItemMenuItems(columns: ContentLayoutColumns) {
  return createAddItemMenuItems(props.supportedItemTypes, false, item =>
    updateLeftColumn(columns, { id: uuid(), ...item })
  );
}

function updateLeftColumn(columns: ContentLayoutColumns, item: ContentLayoutItem) {
  updateItem(columns, { left: item as ContentLayoutColumnsItem });
}

function addRightColumnItemMenuItems(columns: ContentLayoutColumns) {
  return createAddItemMenuItems(props.supportedItemTypes, false, item =>
    updateRightColumn(columns, { id: uuid(), ...item })
  );
}

function updateRightColumn(columns: ContentLayoutColumns, item: ContentLayoutItem) {
  updateItem(columns, { right: item as ContentLayoutColumnsItem });
}

function swapColumns(columns: ContentLayoutColumns) {
  updateItem(columns, { left: columns.right, right: columns.left });
}

// Helpers
function updateItem<T extends ContentLayoutItem>(item: T, updates: DistributivePartial<T>) {
  const i: T = { ...item, ...updates };
  emit('update:item', i);
}

function subjectName(id: number) {
  if (!subjects.value || subjects.value.length === 0) return '';
  return subjects.value.find(x => x.id == id)?.name || '';
}
</script>

<style scoped>
.element-label {
  font-size: 13px;
}
</style>
