<template>
  <mosaic-filter-list-page
    object-type="Forum"
    title="Instructor Forums"
    :load="load"
    :items="filteredForums"
    @add="router.push({ name: `${pageType}ForumCreatePage` })"
  >
    <template #filters>
      <mosaic-checkbox label="Show Forums in closed Cohorts?" v-model="showClosedCohorts" hide-details no-icon />
    </template>

    <template #list-item="{ item: f }">
      <mosaic-list-item
        :key="f.id"
        :title="f.name"
        :subtitle="forumSubtitle(f)"
        :icon="icons.forum"
        :to="{ name: 'StaffForumPage', params: { staffId: userStaff.id, id: f.id } }"
      >
        <template #information>
          <div v-if="f.cohort?.status === 'closed'">
            <v-chip color="secondary">Closed Cohort</v-chip>
          </div>
        </template>

        <template #actions>
          <mosaic-icon-btn
            icon="pencil"
            tooltip="Edit Discussion"
            :to="{ name: `${pageType}ForumEditPage`, params: { id: f.id } }"
          />
        </template>
      </mosaic-list-item>
    </template>
  </mosaic-filter-list-page>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { useInstitutionStore } from '@/stores/institution';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { icons } from '@/utils/icons';
import { useStaffStore } from '@/stores/staff';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { useCohortStore } from '@/stores/cohort';
import { enumerateItems } from '@/utils/text';
import { syncQueryParam } from '@/composables/query';

// #region setup
const api = useApi();
const router = useRouter();
const { selectedInstitution } = useInstitutionStore();
const { selectedCohort } = useCohortStore();
const { userStaff } = useStaffStore();

const route = useRoute();
const pageType = computed(() => (route.name?.toString().includes('Institution') ? 'Institution' : 'Cohort'));
// #endregion

// #region data
interface ForumAdminSlimResponse {
  id: number;
  name: string;
  cohort?: {
    id: number;
    name: string;
    status: string;
  };
  memberCount: number;
}
const forums = ref<ForumAdminSlimResponse[]>([]);
// #endregion

setBreadcrumbs([{ text: 'Instructor Forums' }]);

async function load() {
  const r = await api.get<ForumAdminSlimResponse[]>(
    `${
      pageType.value == 'Institution'
        ? `/institutions/${selectedInstitution.value.id}`
        : `/cohorts/${selectedCohort.value.id}`
    }/admin/forums`
  );
  forums.value = r.data;
}

const showClosedCohorts = ref(false);
syncQueryParam(showClosedCohorts, 'closed', 'boolean');
const filteredForums = computed(() =>
  forums.value.filter(f => showClosedCohorts.value || !f.cohort || f.cohort.status != 'closed')
);

function forumSubtitle(f: ForumAdminSlimResponse) {
  let subtitle = pageType.value == 'Institution' ? (f.cohort ? `Cohort: ${f.cohort.name} - ` : `All Cohorts - `) : '';
  subtitle += `${enumerateItems(f.memberCount, 'Member')}`;
  return subtitle;
}
</script>
