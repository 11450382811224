<template>
  <mosaic-filter-list-page object-type="Forum" :load="load" :items="forums" :hide-add="true">
    <template #list-item="{ item: f }">
      <mosaic-list-item
        :key="f.id"
        :title="f.name"
        :subtitle="`${f.cohort ? `Cohort: ${f.cohort.name}` : `All Cohorts`} - ${enumerateItems(
          f.discussionCount,
          'Discussion'
        )}`"
        :icon="icons.forum"
        :to="{ name: 'StaffForumPage', params: { staffId: userStaff.id, id: f.id } }"
      >
      </mosaic-list-item>
    </template>
  </mosaic-filter-list-page>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { useStaffStore } from '@/stores/staff';
import { ref } from 'vue';
import { icons } from '@/utils/icons';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { enumerateItems } from '@/utils/text';

const api = useApi();
const { userStaff } = useStaffStore();

setBreadcrumbs([{ text: 'Forums' }]);

interface ForumSlimResponse {
  id: number;
  name: string;
  discussionCount: number;
  cohort?: {
    id: number;
    name: string;
  };
}

const forums = ref<ForumSlimResponse[]>([]);

async function load() {
  const r = await api.get<ForumSlimResponse[]>(`/staff/${userStaff.value.id}/forums`);
  forums.value = r.data;
}
</script>
