<template>
  <mosaic-loading-and-error-cards
    object-type="Instructors"
    :object-type-is-plural="true"
    :load="load"
    loading-type="inline-list"
  >
    <mosaic-action-assignment
      :selected-ids="selectedStaffIds"
      @update:selected-ids="emit('update:selectedStaffIds', $event)"
      :assignees="staff"
      :filter="s => filterStaff(s) && filter(s)"
      :initially-selected-ids="initiallySelectedStaffIds"
      :is-editing="isEditing"
      assignee-noun="Instructor"
      assignee-noun-pluralised="Instructors"
      :action-noun="objectType"
      :readonly="readonly"
      :hide-selected-count="hideSelectedCount"
      :hide-title="hideTitle"
      :empty-text="emptyText"
      :pending-assignment-label="pendingAssignmentLabel"
      :pending-assignment-tooltip-action="pendingAssignmentTooltipAction"
      :pending-assignment-tooltip="pendingAssignmentTooltip"
    >
      <template #filters>
        <role-filter :roles="roles" v-model:role-id="filterRoleId" />
        <mosaic-select
          label="Filter by Cohort"
          v-model="filterCohortId"
          :items="cohortItems"
          item-value="id"
          item-title="name"
          no-icon
        />
        <slot name="filters"></slot>
      </template>

      <template v-if="$slots['selected-text']" #selected-text>
        <slot name="selected-text"></slot>
      </template>

      <template #status-badge="{ assignee }">
        <slot name="status-badge" :staff="assignee"></slot>
      </template>

      <template #details-header>
        <slot name="details-header"></slot>
      </template>

      <template #details="{ assignee }">
        <slot name="details" :staff="assignee"></slot>
      </template>
    </mosaic-action-assignment>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { useInstitutionStaffStore, type InstitutionStaff } from '@/stores/institution-staff';
import MosaicActionAssignment from './MosaicActionAssignment.vue';
import { useRoleStore } from '@/stores/role';
import { computed, ref } from 'vue';
import RoleFilter from './RoleFilter.vue';
import { mapActions, mapGetters } from '@/store/map-store';
import { syncQueryParam } from '@/composables/query';

const props = withDefaults(
  defineProps<{
    selectedStaffIds: number[];
    initiallySelectedStaffIds: number[];
    disabledStaff?: { id: number; tooltip: string }[];
    isEditing: boolean;
    objectType: string;
    // staffToHide should never appear in the list or the counts
    staffToHide?: (s: InstitutionStaff) => boolean;
    // filter is used for any filters outside of this component
    filter?: (s: Staff) => boolean;
    scheduledDate?: string;
    actionSetInTheFuture?: boolean;
    actionOriginallySetInTheFuture?: boolean;
    emptyText?: string;
    hideSelectedCount?: boolean;
    hideTitle?: boolean;
    readonly?: boolean;
    pendingAssignmentLabel?: string;
    pendingAssignmentTooltipAction?: string;
    pendingAssignmentTooltip?: string;
  }>(),
  {
    staffToHide: (_: InstitutionStaff) => false,
    filter: (_: Staff) => true,
    disabledStaff: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:selectedStaffIds', ids: number[]): void;
  (e: 'notAllStaffInitiallyVisible'): void;
}>();

type Staff = {
  name: string;
  email: string;
  displayName: string;
} & InstitutionStaff;

const {
  institutionStaff,
  actions: { loadInstitutionStaff },
} = useInstitutionStaffStore();
const {
  roles,
  actions: { loadRoles },
} = useRoleStore();

const staff = computed<Staff[]>(() =>
  institutionStaff.value
    .filter(i => !props.staffToHide(i))
    .map(s => {
      const disabled = props.disabledStaff.find(ds => ds.id == s.id);
      return {
        ...s,
        name: s.user.name,
        email: s.user.email,
        displayName: s.user.name || s.user.email,
        disabledTooltip: disabled?.tooltip,
      };
    })
);

const { activeCohorts } = mapGetters();
const { loadCohorts } = mapActions();

const filterRoleId = ref(-1);
const filterRole = computed(() => roles.value.find(r => r.id == filterRoleId.value));

const filterCohortId = ref(-1);
syncQueryParam(filterCohortId, 'cohortId', 'integer');
const cohortItems = computed(() => [{ id: -1, name: 'All Cohorts' }, ...activeCohorts.value]);
const filterCohort = computed(() => activeCohorts.value.find(c => c.id == filterCohortId.value));

function filterStaff(s: Staff) {
  let result = true;

  if (filterRole.value) {
    result &&= s.staffRoles.some(sr => {
      let result = sr.role.id == filterRole.value!.id;
      if (filterCohort.value) {
        const cohortId = sr.cohort?.id || sr.noStudentYetCohort?.id || sr.student?.cohortId;
        if (!cohortId) return false;
        result &&= cohortId == filterCohort.value.id;
      }
      return result;
    });
  }

  if (filterCohort.value && !filterRole.value) {
    result &&= s.staffRoles.some(
      sr => (sr.cohort?.id || sr.noStudentYetCohort?.id || sr.student?.cohortId) == filterCohort.value?.id
    );
  }

  return result;
}

async function load() {
  await Promise.all([loadInstitutionStaff(), loadRoles(), loadCohorts()]);

  const visibleStaffIds = staff.value.filter(s => props.filter(s)).map(s => s.id);
  if (props.selectedStaffIds.some(id => !visibleStaffIds.includes(id))) {
    emit('notAllStaffInitiallyVisible');
  }
}
</script>
