import { ref } from 'vue';
interface StandardOrSubstandardItem {
  id: number;
  name: string;
  title: string;
  order: number;
  code: string;
  professional: boolean;
  subject: { id: number; name: string } | null;
  disableDelete: boolean;
  hideMove: boolean;
}
interface EditStandardOrSubstandardDialog {
  active: boolean;
  standardOrSubstandard: StandardOrSubstandardItem;
}

export function useEditStandardOrSubstandard() {
  const editStandardOrSubstandardDialog = ref<EditStandardOrSubstandardDialog>({
    active: false,
    standardOrSubstandard: {
      id: 0,
      name: '',
      title: '',
      code: '',
      order: 0,
      disableDelete: false,
      hideMove: false,
      professional: false,
      subject: null,
    },
  });
  const editStandardOrSubstandard = (standardOrSubstandard: StandardOrSubstandardItem) => {
    editStandardOrSubstandardDialog.value = {
      active: true,
      standardOrSubstandard: standardOrSubstandard || {
        id: 0,
        name: '',
        title: '',
        code: '',
        order: 0,
        hideMove: false,
        disableDelete: false,
        professional: false,
        subject: null,
      },
    };
  };

  return { editStandardOrSubstandard, editStandardOrSubstandardDialog };
}
