<template>
  <div>
    <mosaic-loading-and-error-cards object-type="Course Activity" :load="load">
      <mosaic-stepper-card
        :can-continue="() => canContinue"
        :steps="['Details', traineeNounCapitalisedAndPluralised]"
        :return-to="{
          name: 'CohortAssignmentsListPage',
        }"
      >
        <template #title>
          <mosaic-card-title>{{ isEditing ? 'Edit' : 'New' }} Course Activity</mosaic-card-title>
        </template>

        <template #item.1>
          <mosaic-router-link
            v-if="assignmentTemplateItems.length === 0"
            class="mt-2"
            style="text-decoration: none; color: inherit"
            :to="{
              name: 'TutorAdminAssignmentTemplateCreatePage',
              params: { institutionId: selectedInstitution.id },
            }"
          >
            <mosaic-alert type="warning">
              To create a new Course Activity requires a Course Activity Template, however, no templates have been
              created yet. Click here to create one.
            </mosaic-alert>
          </mosaic-router-link>

          <mosaic-text-field v-model="name" prepend-icon="mdi-pencil" name="name" label="Name" />
          <mosaic-date-picker v-model:date="dueDate" name="dueDate" label="Due date (optional)" :exact-width="false" />
          <mosaic-select
            v-model="assignmentTemplateId"
            :readonly="isEditing"
            prepend-icon="mdi-scissors-cutting"
            name="template"
            label="Course Activity Template"
            :items="assignmentTemplateItems"
            item-value="id"
            item-title="name"
          />
          <mosaic-select
            v-model="selectedRoleId"
            v-if="assignmentTemplateId"
            :items="rolesWithStudent"
            :readonly="!isEditing"
            name="role-select"
            item-value="id"
            prepend-icon="mdi-account-multiple-check"
            item-title="pluralisedName"
            label="Who should complete this Course Activity?"
          />
          <template v-if="curriculumEnabled">
            <curriculum-links
              class="mt-4"
              :selected-curriculum-statements="curriculumStatements"
              :can-edit="true"
              artefact-type="Course Activity"
              @update:link-added="addCurriculumLink"
              @update:link-removed="removeCurriculumLink"
            >
            </curriculum-links>
          </template>
        </template>

        <template #item.2>
          <mosaic-info-alert class="mb-2" v-if="selectedRoleId !== 'student'"
            >This Course Activity is to be completed by {{ getRole(selectedRoleId) }}s for the selected
            {{ traineeNounCapitalisedAndPluralised }}</mosaic-info-alert
          >
          <mosaic-student-selector
            v-model:selected-student-ids="selectedStudentIds"
            :students-to-add="studentsToAdd"
            :students-to-remove="studentsToRemove"
            :students="students"
            :filtered-students="filteredStudents"
            action-name="Course Activity"
          >
            <template #filters>
              <!-- This heading should probably be built into the component somehow? -->
              <div class="d-flex align-center">
                <mosaic-card-subheading icon="mdi-filter">Filters</mosaic-card-subheading>

                <div class="px-4 text-right flex-shrink-0 font-italic">
                  <span
                    >({{ filteredStudents.length }} / {{ students.length }}
                    {{ traineeNounCapitalisedAndPluralised }})</span
                  >
                </div>
              </div>
              <div class="px-4 d-flex mb-4">
                <mosaic-name-email-filter v-model="nameEmailFilter" class="mr-4" />
                <mosaic-cohort-monitoring-filters
                  :students="selectedCohortStudents"
                  @update:filtered-student-ids="filteredStudentIds = $event"
                />
              </div>
            </template>

            <template #status-badge="{ student }">
              <mosaic-tooltip-chip :color="studentChipStatus(student).color">
                <template #text>{{ studentChipStatus(student).text }}</template>
                <template #tooltip>{{ studentChipStatus(student).tooltip }}</template>
              </mosaic-tooltip-chip>
            </template>
          </mosaic-student-selector>
        </template>

        <template #error>
          <mosaic-error-alert :error="error" :action="`${isEditing ? 'update' : 'add'} this Course Activity`" />
          <mosaic-success-snackbar v-model="saveSuccess" object-type="Course Activity" />
        </template>

        <template #actions="{ step }">
          <template v-if="step == 2">
            <template v-if="isEditing">
              <mosaic-btn :disabled="!canUpdate || processing" :loading="justSaving" class="mr-2" @click="justSave()"
                >Save</mosaic-btn
              >
              <mosaic-btn
                color="primary"
                :disabled="!canUpdate || processing"
                :loading="savingAndReturning"
                @click="saveAndReturn()"
                >Save and Return</mosaic-btn
              >
            </template>
            <mosaic-btn
              v-else
              :disabled="!canAdd || processing"
              :loading="savingAndReturning"
              color="primary"
              @click="saveAndReturn()"
              >Create and Return</mosaic-btn
            >
          </template>
        </template>
      </mosaic-stepper-card>
    </mosaic-loading-and-error-cards>

    <mosaic-confirm-cohort-action-update-dialog
      v-model:active="confirmDeleteDialog.active"
      action-noun="Course Activity"
      :action-status-items="studentAssignmentStatusItems"
      :student-or-staff-ids-to-be-removed="studentsToRemove"
      :student-or-staff-noun="traineeNoun"
      :student-or-staff-noun-pluralised="traineeNounPluralised"
      :show-delete-check="studentsToRemove.length > 0"
      :get-action-status="getAssignmentStatusForStudent"
      @confirm="confirmed = true"
      @cancel="cancelled = true"
    />

    <mosaic-dialog
      v-model:active="confirmRoleUpdateDialog.active"
      title="Confirm Role Update"
      @close="cancelled = true"
    >
      <div>
        You are changing who should complete this Course Activity from
        {{ getRole(confirmRoleUpdateDialog.oldRoleId) }} to {{ getRole(confirmRoleUpdateDialog.newRoleId) }}.
        {{ getRole(confirmRoleUpdateDialog.oldRoleId) }}s will no longer be able to complete this Course Activity.
      </div>
      <div class="pt-2">Are you sure you want to make this change?</div>
      <template #buttons>
        <mosaic-btn @click="confirmed = true" color="primary" variant="text">Confirm</mosaic-btn>
      </template>
    </mosaic-dialog>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="unsavedChanges"
      object-type="Course Activity"
      :save="isEditing ? saveWithoutProcessingAndError : undefined"
    />
  </div>
</template>

<script setup lang="ts">
import { mapGetters, mapState, mapActions } from '@/store/map-store';
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import MosaicStudentSelector from '@/components/MosaicStudentSelector.vue';
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import { filteredByNameOrEmail } from '@/components/library/filters/filters';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import type { NoPublishReason } from './assignment-templates';
import { mapNoPublishReasonToMessage } from './assignment-templates';
import { parseRouteId } from '@/composables/vue-router';
import { withSaveAndReturn } from '@/composables/save-and-return';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import type { StudentAssignmentStatus } from './student-assignments';
import { studentAssignmentStatuses, studentAssignmentStatusItems } from './student-assignments';
import { mapAssignmentStatusToChip } from '@/components/library/has-status/has-status';
import type { Assignment } from './assignment';
import { until } from '@vueuse/core';
import { useApi } from '@/composables/api';
import { useCurriculumLinks } from '@/frameworks/curriculum/curriculum-links';
import { useCohortStore } from '@/stores/cohort';
import { useStudentStore } from '@/stores/student';

const { traineeNoun, traineeNounPluralised } = useStudentStore();

const api = useApi();

// I think there's a component in here for assigning cohort level items that have Details -> Trainees, but waiting for more examples/to update Course Targets etc.

const route = useRoute();
const isEditing = computed(() => route.name !== 'CohortAssignmentCreatePage');
const assignmentId = parseRouteId('id');

const { selectedInstitution, selectedCohort } = mapState();
const { selectedCohortStudents } = useCohortStore();
const getters = mapGetters();
const traineeNounCapitalised = getters.traineeNounCapitalised;

const curriculumEnabled = getters.curriculumEnabled;

const { loadRoles } = mapActions();
loadRoles();
const rolesWithStudent = getters.rolesWithStudent;
const selectedRoleId = ref<'student' | number>('student');

const name = ref('');
const dueDate = ref<string | null>(null);
const assignmentTemplateId = ref<number | null>(null);
const assignmentTemplates = ref<AssignmentTemplate[]>([]);
const assignmentTemplateItems = computed(() =>
  assignmentTemplates.value.map(at => {
    let name = at.name;
    if (at.noPublishReason) {
      name += ` (${mapNoPublishReasonToMessage(at.noPublishReason)})`;
    }
    return { ...at, name, props: { disabled: !!at.noPublishReason } };
  })
);

const assignment = ref<Assignment>({
  id: -1,
  name: '',
  dueDate: null,
  roleId: null,
  isTraineeContributor: false,
  assignmentTemplate: {
    id: -1,
    name: '',
  },
  studentAssignments: [],
  curriculumStatements: [],
});

setBreadcrumbs(
  computed(() => [
    {
      text: `Course Activities`,
      to: {
        name: 'CohortAssignmentsListPage',
      },
    },
    {
      text: isEditing.value ? assignment.value.name : `New Course Activity`,
    },
  ])
);

const initiallySelectedStudentIds = computed(() => assignment.value.studentAssignments.map(sa => sa.student.id));
const selectedStudentIds = ref<number[]>([]);
const studentsToAdd = computed(() =>
  selectedStudentIds.value.filter(s => !initiallySelectedStudentIds.value.includes(s))
);
const studentsToRemove = computed(() =>
  initiallySelectedStudentIds.value.filter(s => !selectedStudentIds.value.includes(s))
);
interface Student {
  id: number;
  name: string;
  email: string;
  groupIds: number[];
}
const students = computed<Student[]>(() =>
  selectedCohortStudents.value.map(s => ({ ...s, groupIds: s.student_groups.map(sg => sg.group_id) }))
);

function studentChipStatus(student: Student) {
  const studentAssignment = assignment.value.studentAssignments.find(sa => sa.student.id === student.id);
  if (studentAssignment) {
    return mapAssignmentStatusToChip(studentAssignment.status);
  }
  return {
    text: 'Not Assigned',
    tooltip: isEditing.value
      ? `The Course Activity was not assigned to this ${traineeNounCapitalised.value()}.`
      : `The Course Activity hasn't been assigned yet.`,
    color: 'secondary',
  };
}

const nameEmailFilter = ref('');
const filteredStudentIds = ref<number[]>(selectedCohortStudents.value.map(s => s.id));
const filteredStudents = computed(() =>
  filteredByNameOrEmail(students, nameEmailFilter).value.filter(s => filteredStudentIds.value.includes(s.id))
);

async function loadAssignment() {
  if (isEditing.value) {
    const r = await api.get<Assignment>(`/assignments/${assignmentId.value}`);

    assignment.value = r.data;
    name.value = r.data.name;
    dueDate.value = r.data.dueDate;
    assignmentTemplateId.value = r.data.assignmentTemplate.id;
    selectedStudentIds.value = r.data.studentAssignments.map(sa => sa.student.id);
  }
}

watchEffect(() => {
  if (isEditing.value) {
    selectedRoleId.value = assignment.value.roleId || 'student';
  }
});

watchEffect(() => {
  if (!isEditing.value) {
    selectedRoleId.value =
      assignmentTemplates.value.find(at => at.id === assignmentTemplateId.value)?.roleId || 'student';
  }
});
const { curriculumStatements, addCurriculumLink, removeCurriculumLink } = useCurriculumLinks(assignment);

const canContinue = computed(() => !!name.value && !!assignmentTemplateId.value);
const isCreatingAndReturning = ref(false);
const roleDirty = computed(
  () =>
    (assignment.value.isTraineeContributor && selectedRoleId.value !== 'student') ||
    (!assignment.value.isTraineeContributor && selectedRoleId.value !== assignment.value.roleId)
);
const dirty = computed(() => {
  if (isEditing.value) {
    const nameDirty = name.value !== assignment.value.name;
    const dueDateDirty = dueDate.value !== assignment.value.dueDate;
    const selectedStudentsDirty =
      selectedStudentIds.value.length !== assignment.value.studentAssignments.length ||
      assignment.value.studentAssignments.some(sa => !selectedStudentIds.value.includes(sa.student.id));
    const curriculumStatementsDirty =
      curriculumStatements.value.length !== assignment.value.curriculumStatements.length ||
      assignment.value.curriculumStatements.some(cs => !curriculumStatements.value.some(cs2 => cs2.id === cs.id));
    return nameDirty || dueDateDirty || selectedStudentsDirty || curriculumStatementsDirty || roleDirty.value;
  } else {
    if (isCreatingAndReturning.value) return false;
    return !!name.value || !!dueDate.value || !!assignmentTemplateId.value || selectedStudentIds.value.length > 0;
  }
});
const { dialog: unsavedChanges } = useUnsavedChanges(dirty);
const canAdd = computed(() => canContinue.value && selectedStudentIds.value.length > 0);
const canUpdate = computed(() => dirty.value && canAdd.value);

async function load() {
  await Promise.all([loadAssignment(), loadAssignmentTemplates()]);
}

interface AssignmentTemplate {
  id: number;
  name: string;
  roleId: number | null;
  isTraineeContributor: boolean;
  noPublishReason: NoPublishReason;
}
async function loadAssignmentTemplates() {
  const r = await api.get<AssignmentTemplate[]>(
    `/institutions/${selectedInstitution.value.id}/assignment-templates-for-assignment-create`
  );
  assignmentTemplates.value = r.data;
  const queryAssignmentTemplateId = route.query.assignmentTemplateId;
  if (queryAssignmentTemplateId && typeof queryAssignmentTemplateId === 'string') {
    assignmentTemplateId.value = r.data.find(at => at.id == parseInt(queryAssignmentTemplateId))?.id || null;
  }
}

const getAssignmentStatusForStudent = (studentId: number) => {
  const studentAssignment = assignment.value.studentAssignments.find(sa => sa.student.id === studentId);
  return studentAssignmentStatusItems.find(s => s.value === studentAssignment?.status)?.value;
};
const confirmDeleteDialog = ref({
  active: false,
  statusCounts: [] as {
    status: StudentAssignmentStatus;
    count: number;
  }[],
});
const confirmed = ref(false);
const cancelled = ref(false);
const confirmRoleUpdateDialog = ref<{
  active: boolean;
  newRoleId: number | 'student';
  oldRoleId: number | 'student' | null;
}>({
  active: false,
  newRoleId: 'student',
  oldRoleId: null,
});

const getRole = (roleId: number | 'student' | null) => {
  const nullSafeRoleId = roleId ?? 'student';
  return rolesWithStudent.value.find(r => r.id === nullSafeRoleId)?.name;
};

async function saveWithoutProcessingAndError() {
  if (isEditing.value) {
    if (roleDirty.value) {
      confirmed.value = false;
      cancelled.value = false;
      confirmRoleUpdateDialog.value = {
        active: true,
        newRoleId: selectedRoleId.value,
        oldRoleId: assignment.value.roleId,
      };

      await until(computed(() => confirmed.value || cancelled.value)).toBe(true);
      confirmRoleUpdateDialog.value.active = false;
      if (cancelled.value) {
        return 'no-op';
      }
    }

    const studentAssignmentsToBeRemoved = assignment.value.studentAssignments.filter(sa =>
      studentsToRemove.value.includes(sa.student.id)
    );
    if (studentAssignmentsToBeRemoved.some(sa => sa.status !== 'not_started')) {
      confirmed.value = false;
      cancelled.value = false;
      confirmDeleteDialog.value = {
        active: true,
        statusCounts: studentAssignmentStatuses.map(status => ({
          status,
          count: studentAssignmentsToBeRemoved.filter(sa => sa.status === status).length,
        })),
      };
      await until(computed(() => confirmed.value || cancelled.value)).toBe(true);
      if (cancelled.value) {
        return 'no-op';
      }
    }
  }
  const body = {
    name: name.value,
    dueDate: dueDate.value,
    studentIds: selectedStudentIds.value,
    selectedAll: selectedStudentIds.value.length === students.value.length,
    curriculumStatementIds: curriculumStatements.value.map(cs => cs.id),
    roleId: selectedRoleId.value === 'student' ? null : selectedRoleId.value,
    isTraineeContributor: selectedRoleId.value === 'student',
  };
  if (isEditing.value) {
    const r = await api.put<unknown, Assignment>(`/assignments/${assignmentId.value}`, body);
    assignment.value = r.data;
  } else {
    await api.post(`/cohorts/${selectedCohort.value.id}/assignments`, {
      ...body,
      assignmentTemplateId: assignmentTemplateId.value,
    });
    isCreatingAndReturning.value = true;
  }
}
const { action: save, processing, error } = withProcessingAndError(saveWithoutProcessingAndError);

const { saveSuccess, justSaving, savingAndReturning, justSave, saveAndReturn } = withSaveAndReturn(
  save,
  computed(() => ({
    name: 'CohortAssignmentsListPage',
  }))
);
</script>
