<template>
  <mosaic-inline-creation-list-page
    object-type="Substandard"
    :title="standardName"
    list-title="Substandards"
    :load="load"
    :items="substandardItems"
    :icon="icons.evidenceNotLinked"
    :hide-add="false"
    :can-add-item="canAdd"
    :move-item="moveSubstandardItem"
    :add-item="() => addSubstandard()"
    :edit-item-clicked="editSubstandard"
    edit-action="Edit"
    :delete-url-stem="`admin-standards/substandards`"
    v-model:trigger-background-load="triggerBackgroundLoad"
  >
    <template #subtitle>
      <div v-if="institutions.length" class="wrap">
        {{ 'Institutions using this standard set: ' + institutions.map(i => i.name).join(', ') }}
      </div>
      <div v-if="cohort" class="wrap">
        {{ 'Cohort using this standard set: ' + cohort.name }}
      </div>
      <mosaic-alert v-if="cohort || institutions.length" class="mt-2" type="warning">
        CAUTION: Any edits made on this page will be reflected in the UI for users in institutions and cohorts using
        this standard set. This includes ordering.
      </mosaic-alert>
    </template>
    <template #information="{ item }">
      <v-chip v-if="item.professional" class="mr-2">Part 2</v-chip>
      <v-chip v-if="item.hasReview" class="mr-2">Review Only</v-chip>
      <v-chip v-if="item.subject">{{ item.subject.name }}</v-chip></template
    >
    <template #add-item-fields>
      <div class="d-flex align-top flex-grow-1">
        <mosaic-text-field
          v-model="newSubstandard.code"
          name="new-code"
          style="width: 100px"
          prepend-icon="mdi-pencil"
          label="Code"
          class="mr-4"
          hide-details
        ></mosaic-text-field>
        <mosaic-text-field
          v-model="newSubstandard.name"
          name="new-name"
          class="flex-grow-1 mr-2"
          prepend-icon="mdi-pencil"
          label="Name"
          hide-details
        ></mosaic-text-field>
        <mosaic-select
          v-model="newSubstandard.subject.id"
          prepend-icon="mdi-pencil-ruler"
          name="edit-student-subject"
          class="flex-srhink-1"
          :items="subjectsWithNullOptionAndPrimarySeparation"
          placeholder="Subject"
          item-title="name"
          item-value="id"
          ><template #item="{ item, props: slotProps }">
            <v-divider v-if="item.raw.divider" />
            <v-list-item v-else v-bind="slotProps" :title="item.raw.name" /> </template
        ></mosaic-select>
      </div>
    </template>
  </mosaic-inline-creation-list-page>
  <admin-standard-or-substandard-edit-dialog
    v-model:active="editStandardOrSubstandardDialog.active"
    :standard-or-substandard="editStandardOrSubstandardDialog.standardOrSubstandard"
    is-substandard
    @save="triggerBackgroundLoad = true"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { parseRouteId } from '@/composables/vue-router';
import { useApi } from '@/composables/api';
import { useSubjectsStore } from '@/stores/subjects';
import { icons } from '@/utils/icons';
import { useMoveListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { useEditStandardOrSubstandard } from '@/composables/admin-standards';
import AdminStandardOrSubstandardEditDialog from './AdminStandardEditDialog.vue';
const {
  actions: { loadSubjects },
  subjectsWithNullOptionAndPrimarySeparation,
} = useSubjectsStore();
import { setBreadcrumbs } from '@/utils/breadcrumbs';

const api = useApi();
const standardId = parseRouteId('standardId');
const standardName = ref('');

const standardSetName = ref('');
const standardSetId = ref(0);
const institutions = ref<InstitutionResponse[]>([]);
const cohort = ref<CohortResponse | null>(null);
const substandards = ref<SubstandardResponse[]>([]);

interface SubstandardResponse {
  id: number;
  code: string;
  name: string;
  order: number;
  has_review: boolean;
  professional: boolean;
  subject: {
    id: number;
    name: string;
  };
  standard_set_id: number;
}

interface InstitutionResponse {
  id: number;
  name: string;
}
interface CohortResponse {
  id: number;
  name: string;
}

interface StandardResponse {
  id: number;
  name: string;
  standard_set: {
    id: number;
    name: string;
    institutions: InstitutionResponse[];
  };
  substandards: SubstandardResponse[];

  cohort: CohortResponse;
}

const triggerBackgroundLoad = ref(false);
async function getSubstandards() {
  const r = await api.get<StandardResponse>(`/admin-standards/${standardId.value}`);
  substandards.value = r.data.substandards;
  standardSetName.value = r.data.standard_set.name;
  standardName.value = r.data.name;
  standardSetId.value = r.data.standard_set.id;
  institutions.value = r.data.standard_set.institutions;
  cohort.value = r.data.cohort;
}

const load = async () => {
  getSubstandards();
  loadSubjects();
};

const substandardItems = computed(() => {
  return substandards.value.map(s => ({
    ...s,
    title: `${s.code}: ${s.name}`,
    hideMove: false,
    disableDelete: institutions.value.length > 0 || !!cohort.value,
    hasReview: s.has_review,
  }));
});

//#region Add Standard
const newSubstandard = ref({
  code: '',
  name: '',
  subject: { id: null, name: '' },
});

const canAdd = computed(() => !!(newSubstandard.value.code && newSubstandard.value.name));

async function addSubstandard() {
  await api.post(`/admin-standards/${standardId.value}/substandards`, { ...newSubstandard.value });
}

//#endregion

//#region Move
const { moveListItem: moveSubstandardItem } = useMoveListItem('/admin-standards/substandards', api);

const { editStandardOrSubstandard: editSubstandard, editStandardOrSubstandardDialog } = useEditStandardOrSubstandard();

//#endregion

const breadcrumbs = computed(() => {
  return [
    {
      text: 'Standard Sets',
      to: {
        name: 'AdminStandardSetsPage',
      },
    },
    ...(standardSetId.value && standardSetName.value && standardName.value
      ? [
          {
            text: standardSetName.value,
            to: {
              name: 'AdminStandardSetStandardsPage',
              params: {
                standardSetId: standardSetId.value,
              },
            },
          },
          {
            text: standardName.value,
          },
        ]
      : []),
  ];
});
setBreadcrumbs(breadcrumbs);
</script>
