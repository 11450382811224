<template>
  <div>
    <mosaic-card>
      <mosaic-card-title class="mb-2">My Details</mosaic-card-title>
      <div class="d-flex align-center">
        <div style="width: 300px">
          <mosaic-text-field label="Name" v-model="name" prepend-icon="pencil" />
        </div>
        <v-btn class="ml-4" @click.prevent="updateUser()">Update</v-btn>
      </div>
      <div class="d-flex align-center">
        <mosaic-text-field v-model="user.email" :readonly="true" label="Email" prepend-icon="mdi-email" />
        <v-tooltip location="bottom">
          <template #activator="{ props }">
            <v-icon color="primary" dark v-bind="props" class="pl-2">mdi-information</v-icon>
          </template>
          <div class="py-2">If you need your email updated, please contact your course leads</div>
        </v-tooltip>
        <email-status-chip
          v-if="!user.is_demo && isEmailVerificationOnForCurrentUser"
          class="ml-2"
          :email-verified="user.emailVerified"
          :email-bounced="user.emailBounced"
          :opted-out-of-emails="user.optedOutOfEmails"
        />
      </div>
      <mosaic-snackbar v-model="snackbar.status" :color="snackbar.color" :message="snackbar.message" contained />

      <div class="mt-2">
        <mosaic-card-subheading>Profile Picture</mosaic-card-subheading>
        <div v-if="!updatingProfilePicture" class="mt-2 d-flex align-start">
          <mosaic-avatar :user="user" size="profile-page" :hide-profile-picture="false" ignore-institution-config />
          <div class="pl-1">
            <mosaic-icon-btn
              icon="pencil"
              size="small"
              tooltip="Edit profile picture"
              style="margin-top: -8px"
              @click="updatingProfilePicture = true"
            />
            <mosaic-icon-btn
              v-if="user.profilePictureUpdatedAt"
              icon="delete"
              size="small"
              tooltip="Remove profile picture"
              style="margin-top: -8px"
              :loading="removingProfilePicture"
              :disabled="removingProfilePicture"
              @click="removeProfilePicture()"
            />
          </div>
          <div class="pl-8" v-if="institutionsWhereProfilePictureIsHidden.length > 0">
            <div class="font-weight-bold">Institutions that your profile picture doesn't show for</div>
            <ul>
              <li v-for="i of institutionsWhereProfilePictureIsHidden" :key="i.name">
                <span>{{ i.name }}</span>
                <span> - </span>
                <span>{{
                  i.hiddenForInstitution
                    ? 'all profile pictures are hidden in this Institution'
                    : 'a course lead has hidden your profile picture'
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <v-file-input accept="image/*" label="Select an image" class="mt-2" @update:model-value="imageUploaded" />
          <div v-if="profilePictureUpload">
            <div class="d-flex align-center vertical-flex-spacing-small mb-2" :class="{ 'flex-column': smallScreen }">
              <div :style="{ width: smallScreen ? '100%' : '50%' }">
                <cropper
                  ref="cropper"
                  :src="profilePictureUpload"
                  :stencil-component="$options.components.CircleStencil"
                  class="cropper"
                  @change="onCropperChange"
                  :debounce="false"
                  :canvas="{
                    height: 256,
                    width: 256,
                  }"
                />
              </div>
              <div
                class="flex-grow-1 d-flex justify-center align-center"
                :style="{ width: smallScreen ? '100%' : 'default' }"
              >
                <preview
                  :image="cropperResult.image"
                  :coordinates="cropperResult.coordinates"
                  class="cropper-preview"
                />
              </div>
            </div>
          </div>
          <mosaic-save-buttons
            :hide-return="true"
            :save="saveProfilePicture"
            object-type="profile picture"
            :can-save="!!profilePictureUpload"
          >
            <template #beside-buttons>
              <div style="margin-right: -8px">
                <mosaic-btn @click="updatingProfilePicture = false">Cancel</mosaic-btn>
              </div>
            </template>
          </mosaic-save-buttons>
        </div>
        <mosaic-error-snackbar v-model="removeProfilePictureError" action="remove your profile picture" contained />
      </div>
    </mosaic-card>

    <mosaic-card class="mt-4">
      <div
        v-for="(i, index) in institutionsWithLinkedAccounts"
        :key="i.id"
        :class="{ 'mb-4': index < institutionsWithLinkedAccounts.length - 1 }"
      >
        <mosaic-card-title>{{ i.name }}</mosaic-card-title>
        <div class="mt-2">
          <div class="d-flex align-center">
            <span class="font-weight-bold mr-2" style="width: 170px">Cloud storage provider:</span>
            <span class="text-body-1 mr-2">{{ i.storage_type }}</span>
          </div>

          <template v-if="!i.config.on_demand_sharing">
            <v-alert v-if="storageConstraintMessage(i.id) && !i.storage_email" class="mx-6" type="info">
              <div>{{ storageConstraintMessage(i.id) }}</div>
            </v-alert>
            <v-alert v-if="storageEmailErrorMessage && invalidStorageInstitutionId === i.id" class="mx-6" type="error">
              <div>{{ storageEmailErrorMessage }}</div>
              <div class="mt-2">
                Please try again with an allowable One Drive account. You may need to
                <a href="https://login.microsoftonline.com/logout.srf" target="_blank">logout</a> of your One Drive
                account first.
              </div>
            </v-alert>
          </template>

          <div v-if="i.storage_type !== 'Mosaic'" class="d-flex align-center">
            <span class="font-weight-bold mr-2" style="width: 170px">Cloud storage account:</span>
            <template v-if="i.config.on_demand_sharing && !user.student">
              <div class="text-body-1 pr-2">The setup for this Institution does not require a linked account</div>
            </template>
            <template v-else-if="i.storage_email">
              <span class="text-body-1 pr-2">{{ i.storage_email }}</span>
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-icon color="primary" dark v-bind="props">mdi-information</v-icon>
                </template>
                <div class="py-2">
                  Your Mosaic account is currently linked to this
                  {{ i.storage_type }} account
                </div>
              </v-tooltip>
              <v-btn v-if="!user.student" class="ml-4" @click.prevent="resetStorageEmail(i)">Reset Storage Email</v-btn>
            </template>
            <template v-else>
              <div class="link-account-box pa-2 mr-2 d-flex align-center">
                <span class="text-body-1 mr-4 text-accent">No account linked</span>
                <v-btn @click.prevent="navigateToStorageLogin(i.storage_type, i.id)">
                  <v-icon v-if="i.storage_type === 'Google Drive'" class="mr-2">mdi-google-drive</v-icon>
                  <v-icon v-if="i.storage_type === 'One Drive'" class="mr-2">mdi-microsoft-onedrive</v-icon>
                  <span>Sign into {{ i.storage_type }}</span>
                </v-btn>
              </div>
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-icon color="primary" dark v-bind="props">mdi-information</v-icon>
                </template>
                <div class="py-2">
                  You must link a {{ i.storage_type }} account in order to
                  {{ user.student ? 'use the files area' : 'view ' + traineeNoun() + ' files' }}
                </div>
              </v-tooltip>
            </template>
          </div>

          <div class="d-flex align-center pt-4" v-if="!user.student">
            <span class="font-weight-bold mr-2" style="width: 170px"
              >NASBTT {{ user.student ? 'Learn' : 'Mentor Development' }} account:</span
            >
            <div class="text-body-1">
              <span v-if="!i.config.nasbtt_modules_organisation_id">This Institution doesn't use NASBTT Modules</span>
              <template v-else-if="i.nasbtt_modules_user_email">
                <span>{{ i.nasbtt_modules_user_email }}</span>
                <v-btn class="ml-4" @click.prevent="resetNasbttAccount(i)"> Reset NASBTT account </v-btn>
              </template>
              <template v-else>
                <span>Not linked</span>
                <v-btn class="ml-4" @click.prevent="linkNasbttAccount(i)"> Link NASBTT account </v-btn>
              </template>
            </div>
          </div>
        </div>
      </div>
    </mosaic-card>

    <mosaic-card v-if="user.student" class="mt-4">
      <mosaic-card-title class="mb-2">Account Access</mosaic-card-title>
      <div v-if="error || stateError">Sorry, cannot load your account access information right now</div>
      <div v-else>
        <div class="d-flex align-center">
          <div class="text-body-1 pr-2">The following Instructors can view your account:</div>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-icon color="primary" dark v-bind="props">mdi-information</v-icon>
            </template>
            <div class="py-2">Contact your course leads if additional Instructors need access</div>
          </v-tooltip>
        </div>
        <ul>
          <li v-for="staff in studentStaff" :key="staff.id">
            <div class="text-body-1">{{ staff.name }} ({{ listOfRoles(staff) }})</div>
          </li>
        </ul>
      </div>
    </mosaic-card>

    <mosaic-dialog
      v-model:active="resetStorageEmailDialog.active"
      title="Reset Storage email"
      :error-message="resetStorageEmailDialog.errorMessage"
    >
      <div>Are you sure you want to reset your storage email for this Institution?</div>
      <!-- Use of "trainee" instead of traineeNoun() here is fine as AB customers always use S3 -->
      <div>You will not be able to view Trainee files until you link a new storage account.</div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="resetStorageEmailDialog.processing"
          @click.prevent="submitResetStorageEmail()"
          >Confirm</v-btn
        >
      </template>
    </mosaic-dialog>

    <mosaic-save-dialog
      v-model:active="resetNasbttAccountDialog.active"
      title="Reset NASBTT account"
      action="reset"
      object-type="NASBTT account"
      :save="submitResetNasbttAccount"
    >
      <div>Are you sure you want to reset your NASBTT account for this Institution?</div>
      <div>You will not be able to launch NASBTT modules until you link a new account.</div>
    </mosaic-save-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mapStateError, mapStateProcessing } from '@/store/map-store';
import { storageConstraintMessage, storageEmailError } from '../utils/storage-constraints';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import {
  startStaffLinkGoogleDriveEmailFlow,
  startStudentLinkGoogleDriveFlow,
  startStaffLinkOneDriveEmailMicrosoftFlow,
  startStudentLinkOneDriveFlow,
} from '../utils/external-auth';
import { useNasbttModulesStore } from '@/stores/nasbtt-modules';
import { Cropper, CircleStencil, Preview } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: 'UserProfilePage',
  // eslint-disable-next-line vue/no-unused-components
  components: { EmailStatusChip, Cropper, CircleStencil, Preview },
  setup() {
    const {
      actions: { linkNasbttModulesAccount },
    } = useNasbttModulesStore();
    return { linkNasbttModulesAccount };
  },
  data: function () {
    return {
      name: '',
      error: '',
      snackbar: {
        message: '',
        status: false,
        color: '',
      },
      storageConstraints: [],
      resetStorageEmailDialog: {
        active: false,
        staffId: null,
        errorMessage: '',
        institutionId: null,
        processing: false,
      },
      resetNasbttAccountDialog: {
        active: false,
        staffId: null,
      },
      profilePictureUpload: null,
      cropperResult: {
        coordinates: null,
        image: null,
      },
      updatingProfilePicture: false,
      removingProfilePicture: false,
      removeProfilePictureError: false,
    };
  },
  watch: {
    async user() {
      await this.fetchStorageConstraints();
    },
  },
  async created() {
    this.name = this.user.name;
    await this.$store.dispatch('loadStudentStaffRoles');
    await this.fetchStorageConstraints();
  },
  computed: {
    ...mapState(['user', 'selectedInstitution']),
    ...mapGetters(['studentStaff', 'isEmailVerificationOnForCurrentUser']),
    ...mapStateProcessing(['loadStudentStaffRoles']),
    ...mapStateError(['loadStudentStaffRoles']),
    breadcrumbs() {
      return [
        {
          text: 'Profile',
        },
      ];
    },
    institutionsWhereProfilePictureIsHidden() {
      let institutions = [];
      if (this.user.student) {
        institutions = [
          {
            name: this.user.student.institution.name,
            hiddenForInstitution: !this.user.student.institution.config.show_profile_pictures,
            hiddenForStudentOrStaff: this.user.student.hide_profile_picture,
          },
        ];
      } else {
        institutions = this.user.staff.map(s => ({
          name: s.institution.name,
          hiddenForInstitution: !s.institution.config.show_profile_pictures,
          hiddenForStudentOrStaff: s.hide_profile_picture,
        }));
      }
      return institutions.filter(i => i.hiddenForInstitution || i.hiddenForStudentOrStaff);
    },
    institutionsWithLinkedAccounts() {
      if (this.user.student) {
        return [
          {
            ...this.user.student.institution,
            storage_email: this.user.student.storage_email,
            nasbtt_modules_user_email: this.user.student.nasbtt_modules_user_email,
          },
        ];
      }
      return this.user.staff.map(x => ({
        ...x.institution,
        storage_email: x.storage_email,
        staff_id: x.id,
        nasbtt_modules_user_email: x.nasbtt_modules_user_email,
      }));
    },
    invalidStorageInstitutionId() {
      return this.user.student ? this.user.student.institution.id : parseInt(this.$route.query.institution_id);
    },
    storageEmailErrorMessage() {
      const invalidStorageConstraint = this.institutionStorageConstraint(this.invalidStorageInstitutionId);
      if (!invalidStorageConstraint) return false;
      return storageEmailError(this.$route.query, invalidStorageConstraint);
    },
  },
  methods: {
    async updateUser() {
      try {
        await this.$api.put(`/users/${this.user.id}`, { name: this.name });
        await this.$store.dispatch('refreshUser');
        this.snackbar = {
          message: 'Success',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: (e.response && e.response.data && e.response.data.message) || `Sorry, cannot save updates right now`,
          status: true,
          color: 'error',
        };
      }
    },
    async fetchStorageConstraints() {
      let shouldFetchStorageConstraints;
      if (this.user.student) {
        shouldFetchStorageConstraints =
          !this.user.student.storage_email && this.user.student.institution.storage_type === 'One Drive';
      } else {
        shouldFetchStorageConstraints = this.user.staff.some(
          x => x.institution.storage_type === 'One Drive' && !x.storage_email
        );
      }

      if (shouldFetchStorageConstraints) {
        try {
          const r = await this.$api.get(`/storage-constraints/${this.user.id}`);
          this.storageConstraints = r.data;
        } catch (e) {
          console.log(e);
        }
      }
    },
    storageConstraintMessage(institutionId) {
      return storageConstraintMessage(this.institutionStorageConstraint(institutionId));
    },
    institutionStorageConstraint(institution_id) {
      return this.storageConstraints.find(x => x.institution_id === institution_id);
    },
    resetStorageEmail(i) {
      this.resetStorageEmailDialog = {
        active: true,
        staffId: i.staff_id,
        institutionId: i.id,
        errorMessage: '',
        processing: false,
      };
    },
    async submitResetStorageEmail() {
      this.resetStorageEmailDialog.processing = true;
      try {
        await this.$api.post(`/staff/${this.resetStorageEmailDialog.staffId}/reset-storage-data`);
        await this.$store.dispatch('refreshUser');
        this.resetStorageEmailDialog.active = false;
        this.snackbar = {
          message: 'Success',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.resetStorageEmailDialog.errorMessage = 'Sorry, cannot reset your storage email right now';
      }
      this.resetStorageEmailDialog.processing = false;
    },
    linkNasbttAccount() {
      this.linkNasbttModulesAccount(null);
    },
    resetNasbttAccount(i) {
      this.resetNasbttAccountDialog = {
        active: true,
        institutionId: i.id,
      };
    },
    async submitResetNasbttAccount() {
      let url = '';
      if (this.user.student) {
        url = `/students/${this.user.student.id}/reset-nasbtt-account`;
      } else {
        url = `/staff/${
          this.user.staff.find(s => s.institution.id == this.resetNasbttAccountDialog.institutionId).id
        }/reset-nasbtt-account`;
      }
      await this.$api.post(url, {});
      await this.$store.dispatch('refreshUser');
    },
    navigateToStorageLogin(storageType, institutionId) {
      if (storageType === 'One Drive') {
        if (this.user.student) startStudentLinkOneDriveFlow(this.$route.path);
        else startStaffLinkOneDriveEmailMicrosoftFlow(this.$route.path, institutionId);
      } else {
        if (this.user.student) startStudentLinkGoogleDriveFlow(this.$route.path, this.$api);
        else startStaffLinkGoogleDriveEmailFlow(this.$route.path, this.$api);
      }
    },
    listOfRoles(staff) {
      return staff.roles.map(r => r.name).join(', ');
    },
    imageUploaded(file) {
      if (file) {
        this.profilePictureUpload = URL.createObjectURL(file);
      } else {
        this.profilePictureUpload = null;
      }
    },
    onCropperChange({ coordinates, image }) {
      this.cropperResult = {
        coordinates,
        image,
      };
    },
    async saveProfilePicture() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const form = new FormData();
        const file = await new Promise(resolve => canvas.toBlob(resolve));
        form.append('file', file);

        await this.$api.put(`/users/${this.user.id}/profile-picture`, form, 'multipart/form-data');
        await this.$store.dispatch('refreshUser');
        this.updatingProfilePicture = false;
        this.profilePictureUpload = null;
      }
    },
    async removeProfilePicture() {
      this.removingProfilePicture = true;
      this.removeProfilePictureError = false;
      try {
        await this.$api.delete(`/users/${this.user.id}/profile-picture`);
        await this.$store.dispatch('refreshUser');
      } catch (e) {
        console.log(e);
        this.removeProfilePictureError = true;
      }
      this.removingProfilePicture = false;
    },
  },
};
</script>

<style scoped>
.link-account-box {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(var(--v-theme-accent));
}
</style>

<style>
.cropper {
  aspect-ratio: 1/1;
}
.cropper-preview {
  border-radius: 50%;
  width: 70%;
  aspect-ratio: 1/1;
  border: 4px solid black;
}
</style>
