<template>
  <div>
    <cohort-student-action-edit-page
      v-model:selected-student-ids="selectedStudentIds"
      :action-noun="`${traineeNoun} Announcement`"
      :action-set-date="announcement.set_date"
      :action-scheduled-at-date="scheduleInTheFuture ? scheduledDate : undefined"
      :action-schedule-in-the-future="scheduleInTheFuture"
      :has-students-with-updated-status="hasStudentsWithUpdatedStatus"
      :load="load"
      :can-continue="canContinue"
      :save="save"
      :return-to="returnTo"
      :dirty="dirty"
      :details-dirty="detailsDirty"
      :can-delete-student-without-confirmation="() => true"
      :action-status-items="announcementStatusItems"
      :get-action-status="getStudentAnnouncementStatus"
      :is-editing="isEditing"
      :students="selectedCohortStudents"
      :initially-selected-student-ids="announcement.admin_announcement_students.map(s => s.student_id)"
    >
      <template #details-step>
        <mosaic-text-field v-model="title" prepend-icon="mdi-pencil" name="title" label="Title" />
        <mosaic-quill-field v-model="body" name="body" label="Contents" />
        <mosaic-text-field
          v-if="createdByName"
          prepend-icon="mdi-account"
          name="from"
          label="From"
          :model-value="createdByName"
          readonly
        />
        <schedule-in-the-future-picker
          v-if="!isEditing || announcementStatus == 'scheduled'"
          v-model:schedule-in-the-future="scheduleInTheFuture"
          v-model:scheduled-date="scheduledDate"
        />
        <div v-if="isEmailVerificationOnForSelectedInstitution" class="d-flex align-center">
          <mosaic-checkbox
            v-model="sendEmail"
            prepend-icon="mdi-email"
            name="send-email"
            label="Notify by email"
            :disabled="announcementStatus === 'live'"
            density="compact"
          />
          <div class="ml-1">
            <mosaic-help>
              <span v-if="scheduleInTheFuture && announcementStatus != 'live'"
                >Emails will be sent at approximately 8am on the scheduled date</span
              >
              <span v-else>Emails will be sent within 10 minutes of Announcement creation</span>
            </mosaic-help>
          </div>
          <mosaic-count-chip
            v-if="sendEmail && newlySelectedCantReceiveEmailCount > 0"
            class="ml-2"
            style="margin-top: 8px"
            color="accent"
            :count="newlySelectedCantReceiveEmailCount"
            :tooltip="`${enumerateItems(
              newlySelectedCantReceiveEmailCount,
              traineeNoun,
              traineeNounPluralised
            )} won't receive the email`"
          />
        </div>
      </template>

      <template v-if="sendEmail && newlySelectedCantReceiveEmailCount > 0" #action-assignment-selected-text>
        <span> - {{ enumerateItems(newlySelectedCantReceiveEmailCount, traineeNoun) }} won't receive the email</span>
      </template>

      <template #action-assignment-status-badge="{ assignee }">
        <announcement-status-chip :read="hasRead(assignee)" />
      </template>

      <template v-if="sendEmail" #action-assignment-details-header>
        <div class="d-flex justify-center" style="width: 90px">Email Status</div>
      </template>

      <template v-if="sendEmail" #action-assignment-details="{ assignee }">
        <div class="d-flex justify-center" style="width: 90px">
          <email-status-chip v-bind="getUserEmailStatus(assignee)" />
        </div>
      </template>
      <template #reset-student-action-status-prompt>
        <template v-if="isEditing && detailsDirty && hasStudentsWithUpdatedStatus">
          <mosaic-card-subheading>Updated Details</mosaic-card-subheading>
          <div>
            You have edited some of the details of this Announcement and some {{ traineeNounPluralised }} have already
            marked it as read.
          </div>
          <div class="pt-2">Would you like to reset the status of these {{ traineeNoun }} Announcements to unread?</div>
          <mosaic-checkbox
            v-model="resetStudentActionStatus"
            :label="`Reset ${traineeNoun} Announcements' status to unread`"
            no-icon
        /></template>
      </template>
    </cohort-student-action-edit-page>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCohortStore } from '@/stores/cohort';
import { parseRouteId } from '@/composables/vue-router';
import AnnouncementStatusChip from './AnnouncementStatusChip.vue';
import { institutionTimeTomorrowAsDate } from '@/utils/time';
import { mapGetters, mapState } from '@/store/map-store';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import { enumerateItems } from '@/utils/text';
import CohortStudentActionEditPage from '@/components/cohort-actions/CohortStudentActionEditPage.vue';
import ScheduleInTheFuturePicker from '@/components/cohort-actions/ScheduleInTheFuturePicker.vue';
import { createReturnTo } from '@/components/cohort-actions/cohort-actions';
import { useApi } from '@/composables/api';
import { useStudentStore } from '@/stores/student';

const api = useApi();

// Setup
const route = useRoute();
const isEditing = computed(() => route.name !== 'CohortAdminAnnouncementCreatePage');
const announcementId = parseRouteId('id');

const { user } = useUserStore();
const { selectedCohortStudents, selectedCohort } = useCohortStore();
const { selectedInstitution } = mapState();
const { isEmailVerificationOnForSelectedInstitution } = mapGetters();
const { traineeNoun, traineeNounPluralised } = useStudentStore();

type AdminAnnouncement = {
  id: number;
  title: string;
  body?: string;
  set_date: string;
  scheduled_at_date: string;
  send_email: boolean;
  admin_announcement_students: { id: number; student_id: number; announcement?: { has_read: boolean } }[];
  created_by?: { id: number; name: string };
};

const announcement = ref<AdminAnnouncement>({
  id: -1,
  title: '',
  body: undefined,
  set_date: '',
  scheduled_at_date: '',
  send_email: false,
  admin_announcement_students: [],
});
const announcementStatus = computed<'live' | 'scheduled'>(() => (announcement.value.set_date ? 'live' : 'scheduled'));

// Fields
const title = ref('');
const body = ref<string>();

// created_by will be undefined for old announcements
const createdByName = ref();

const scheduleInTheFuture = ref(false);
const scheduledDate = ref<string>(institutionTimeTomorrowAsDate(selectedInstitution.value.config.region));

const sendEmail = ref(false);

const canContinue = computed(
  () => !!title.value && !!body.value && (!scheduleInTheFuture.value || !!scheduledDate.value)
);

const returnTo = createReturnTo('CohortAdminAnnouncementsPage', scheduleInTheFuture, announcementStatus);

setBreadcrumbs(
  computed(() => [
    {
      text: `${traineeNoun.value} Announcements`,
      to: returnTo.value,
    },
    {
      text: isEditing.value ? announcement.value.title : `New ${traineeNoun.value} Announcement`,
    },
  ])
);

// Selected students
const selectedStudentIds = ref<number[]>([]);

function hasRead({ id }: { id: number }) {
  const adminAnnouncementStudent = announcement.value.admin_announcement_students.find(s => s.student_id === id);
  return adminAnnouncementStudent?.announcement?.has_read;
}

function getUserEmailStatus({ id }: { id: number }) {
  const student = selectedCohortStudents.value.find(s => s.id === id);
  if (student) {
    return {
      optedOutOfEmails: student.opted_out_of_emails,
      emailBounced: student.email_bounced,
      emailVerified: student.email_verified,
      isDemo: student.is_demo,
    };
  } else {
    return {
      optedOutOfEmails: false,
      emailBounced: false,
      emailVerified: false,
    };
  }
}

const newlySelectedCantReceiveEmailCount = computed(() => {
  const initiallySelectedStudentIds = announcement.value.admin_announcement_students.map(s => s.student_id);
  const newlySelectedStudentIds = selectedStudentIds.value.filter(id => !initiallySelectedStudentIds.includes(id));
  return selectedCohortStudents.value.filter(s => {
    return (
      newlySelectedStudentIds.includes(s.id) &&
      (s.email_bounced || !s.email_verified || s.opted_out_of_emails || s.is_demo)
    );
  }).length;
});

// Dirty
const isCreatingAndReturning = ref(false);
const dirty = computed(() => {
  const a = announcement.value;
  if (isEditing.value) {
    const scheduledDirty = a.scheduled_at_date
      ? !scheduleInTheFuture.value || a.scheduled_at_date !== scheduledDate.value
      : scheduleInTheFuture.value;
    const selectedStudentsDirty =
      selectedStudentIds.value.length !== a.admin_announcement_students.length ||
      a.admin_announcement_students.map(s => s.student_id).some(id => !selectedStudentIds.value.includes(id));
    return detailsDirty.value || scheduledDirty || sendEmail.value !== a.send_email || selectedStudentsDirty;
  } else {
    if (isCreatingAndReturning.value) return false;
    return detailsDirty.value || scheduleInTheFuture.value || sendEmail.value || selectedStudentIds.value.length > 0;
  }
});

const detailsDirty = computed(() => {
  if (!isEditing.value) return !!title.value || !!body.value;
  else {
    if (isCreatingAndReturning.value) return false;
    return title.value !== announcement.value.title || body.value !== announcement.value.body;
  }
});

// Load
async function load() {
  if (!isEditing.value) {
    createdByName.value = user.value.name;
    return;
  }

  const r = await api.get<AdminAnnouncement>(`/admin-announcements/${announcementId.value}`);
  announcement.value = r.data;
  title.value = r.data.title;
  body.value = r.data.body;
  createdByName.value = r.data.created_by?.name;
  scheduleInTheFuture.value = !!r.data.scheduled_at_date;
  scheduledDate.value = r.data.scheduled_at_date;
  sendEmail.value = r.data.send_email;
  selectedStudentIds.value = r.data.admin_announcement_students.map(s => s.student_id);
}

// Save
const resetStudentActionStatus = ref(false);
async function save() {
  const requestBody = {
    title: title.value,
    body: body.value,
    scheduledAtDate:
      (!isEditing.value || announcementStatus.value == 'scheduled') && scheduleInTheFuture.value
        ? scheduledDate.value
        : null,
    sendEmail: sendEmail.value,
    selectedAll: selectedStudentIds.value.length === selectedCohortStudents.value.length,
    studentIds: selectedStudentIds.value,
    resetStudentActionStatus: resetStudentActionStatus.value,
  };

  if (isEditing.value) {
    const r = await api.put<unknown, AdminAnnouncement>(`/admin-announcements/${announcementId.value}`, requestBody);
    announcement.value = r.data;
  } else {
    await api.post(`/cohorts/${selectedCohort.value.id}/admin-announcements`, requestBody);
    isCreatingAndReturning.value = true;
  }
}

const announcementStatusItems = [
  { title: 'Read', value: 'read' },
  { title: 'Unread', value: 'unread' },
];
const getStudentAnnouncementStatus = (studentId: number) => {
  const aas = announcement.value.admin_announcement_students.find(aas => aas.student_id === studentId);
  return aas?.announcement?.has_read ? 'read' : 'unread';
};

//  Ignore students about to be removed in this check
const hasStudentsWithUpdatedStatus = computed(() =>
  announcement.value.admin_announcement_students.some(
    s => s.announcement?.has_read && selectedStudentIds.value.includes(s.student_id)
  )
);
</script>
