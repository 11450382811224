export interface ForumResponse {
  id: number;
  name: string;
  cohort: {
    id: number;
    name: string;
  };
  canEditMembers: boolean;
  canCreateDiscussions: boolean;
  forumDiscussions: {
    id: number;
    title: string;
    commentAndReplyCount: number;
    lastActivityAt: string;
    createdBy: {
      displayName: string;
    };
    canEdit: {
      permitted: boolean;
      reason: string;
    };
    canDelete: {
      permitted: boolean;
      reason: string;
    };
  }[];
  forumMembers: {
    id: number;
    displayName: string;
    name: string;
    email: string;
    memberType: 'participant' | 'facilitator' | 'moderator';
    staffId: number;
    studentId: number;
    userId: number;
    profilePictureUpdatedAt: string;
    hideProfilePicture: boolean;
    notInCohort: boolean | null;
  }[];
  notifications?: {
    newDiscussion: boolean;
    newCommentInMyDiscussion: boolean;
    newReplyToMyComment: boolean;
  };
}

export function deleteOrEditDiscussionsErrorCodeMap(action: 'edit' | 'delete'): { [errorCode: string]: string } {
  return {
    not_created_by_me: `Cannot ${action} Discussions created by someone else.`,
    has_comments: `Cannot ${action} Discussions that already have comments.`,
  };
}

export const memberTypeItems = [
  { text: 'Participant', value: 'participant' },
  { text: 'Facilitator', value: 'facilitator' },
  { text: 'Moderator', value: 'moderator' },
];
