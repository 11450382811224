<template>
  <mosaic-student-selector
    v-model:selected-student-ids="selectedStudentIdsInternal"
    :students-to-add="studentsToAdd"
    :students-to-remove="studentsToRemove"
    :set-in-the-future="false"
    :is-editing="isEditing"
    :students="sortedStudents"
    :filtered-students="filteredStudents"
    :action-name="reviewNounCapitalised"
  >
    <template #filters>
      <div class="d-flex align-center">
        <mosaic-card-subheading icon="mdi-filter"
          >{{
            reviewType ? `${reviewTypeItems.find(x => x.value === reviewType).title} ` : ''
          }}Filters</mosaic-card-subheading
        >

        <div class="px-4 text-right flex-shrink-0 font-italic">
          <span>({{ filteredStudents.length }} / {{ students.length }} {{ traineeNounPluralised() }})</span>
        </div>
      </div>
      <div class="px-4">
        <div v-if="reviewType" class="pb-4">
          <div class="d-flex align-center">
            <div class="d-flex align-center" style="margin-bottom: -16px">
              <span v-if="reviewType === 'end_of_year_1' || reviewType === 'end_of_year_2'" class="mr-1"
                >Has their End of Year {{ reviewType === 'end_of_year_1' ? 1 : 2 }} date between</span
              >
              <span v-if="reviewType === 'termly'" class="mr-1"
                >Does not have their End of Year 1 or 2 date between</span
              >
              <span v-if="reviewType === 'leavers'" class="mr-1">Is due to leave between</span>
              <mosaic-date-picker v-model:date="startDate" density="compact" /><span class="ml-1 mr-2">and</span
              ><mosaic-date-picker v-model:date="endDate" density="compact" />
              <ndt-icon-button icon="close" tooltip="Clear dates" @click.prevent="clearDateFilters()" />
            </div>
            <v-tooltip v-if="startDate > endDate" location="top">
              <template #activator="{ props }">
                <v-icon color="warning" v-bind="props">mdi-alert-circle-outline</v-icon>
              </template>
              <span>You have selected an end date before the start date</span>
            </v-tooltip>
          </div>
          <div>
            <v-text-field
              v-model="nameOrEmailFilter"
              hide-details
              :label="`Filter by ${traineeNoun()} name or email`"
              prepend-icon="mdi-magnify"
              style="width: 500px"
            >
            </v-text-field>
          </div>
          <div class="d-flex mb-4 mt-2">
            <mosaic-checkbox
              v-if="reviewType !== 'leavers'"
              v-model="hidePaused"
              no-icon
              :label="`Hide ${traineeNounPluralised()} who will be paused on the due date`"
              class="mr-6"
            />
            <mosaic-checkbox
              v-if="reviewType !== 'leavers'"
              v-model="hideLeavers"
              no-icon
              :label="`Hide ${traineeNounPluralised()} who will have left by the due date`"
              class="mr-6"
            />
            <mosaic-checkbox
              v-if="reviewType === 'end_of_year_1'"
              v-model="hideAlreadyHadEoY1"
              no-icon
              :label="`Hide ${traineeNounPluralised()} who have already been assigned their End of Year 1`"
            />
            <mosaic-checkbox
              v-if="reviewType === 'end_of_year_2'"
              v-model="hideAlreadyHadEoY2"
              no-icon
              :label="`Hide ${traineeNounPluralised()} who have already been assigned their End of Year 2`"
            />
          </div>
        </div>
        <template v-else>
          <div class="d-flex align-start">
            <div class="mr-6">
              <v-text-field
                v-model="nameOrEmailFilter"
                :label="`Filter by ${traineeNoun()} name or email`"
                prepend-icon="mdi-magnify"
                style="width: 300px"
              >
              </v-text-field>
            </div>
            <div style="width: 350px">
              <mosaic-cohort-monitoring-filters
                :students="selectedCohortStudents"
                @update:filtered-student-ids="filteredStudentIds = $event"
              />
            </div>
          </div>
        </template>
      </div>
    </template>

    <template v-if="reviewType" #details-header>
      <div class="d-flex mr-4">
        <div style="width: 116px" class="ml-4 text-center">Paused</div>
        <div style="width: 116px" class="ml-4 text-center">Leavers</div>
        <div
          v-if="reviewType === 'end_of_year_1' || reviewType === 'termly'"
          style="width: 116px"
          class="ml-4 text-center"
        >
          End of Year 1
        </div>
        <div
          v-if="reviewType === 'end_of_year_2' || reviewType === 'termly'"
          style="width: 116px"
          class="ml-4 text-center"
        >
          End of Year 2
        </div>
      </div>
    </template>

    <template v-if="reviewType" #details="{ student }">
      <div>
        <mosaic-icon-badge
          v-if="student.ect && willbePausedOnDueDate(student.ect)"
          color="secondary"
          icon="mdi-pause-circle-outline"
          :tooltip="`This ${traineeNoun()} will be paused on the due date`"
        >
          {{ formatDate(student.ect.pause_date || student.ect.future_pause_date) }}
        </mosaic-icon-badge>
        <mosaic-icon-badge
          v-else-if="
            student.ect &&
            student.ect.paused &&
            student.ect.future_resume_date &&
            student.ect.future_resume_date < dueDate
          "
          color="accent"
          icon="mdi-play-circle-outline"
          :tooltip="`This ${traineeNoun()} will be resumed by the due date`"
        >
          {{ formatDate(student.ect.future_resume_date) }}
        </mosaic-icon-badge>
        <div v-else style="width: 110px"></div>
      </div>
      <div class="ml-4">
        <mosaic-icon-badge
          v-if="student.ect && willHaveLeftByDueDate(student)"
          color="secondary"
          icon="mdi-exit-run"
          :tooltip="`This ${traineeNoun()} will have left by the due date`"
          >{{ formatDate(leftDateAtDueDate(student)) }}</mosaic-icon-badge
        >
        <mosaic-icon-badge
          v-else-if="
            student.ect &&
            student.ect.left &&
            student.ect.future_return_date &&
            student.ect.future_return_date < dueDate
          "
          color="accent"
          icon="mdi-play-circle-outline"
          :tooltip="`This ${traineeNoun()} will have returned by the due date`"
        >
          {{ formatDate(student.ect.future_return_date) }}
        </mosaic-icon-badge>
        <div v-else style="width: 110px"></div>
      </div>
      <div v-if="reviewType === 'end_of_year_1' || reviewType === 'termly'" class="ml-4">
        <mosaic-end-of-year-badge
          v-if="
            student.ect &&
            (student.ect.end_of_year_1_review.status === 'approved' || student.ect.current_year === 'year_2')
          "
          year="1"
          date="already-completed"
        />
        <mosaic-end-of-year-badge
          v-else-if="
            student.ect &&
            (student.ect.end_of_year_1_review.status === 'assigned' ||
              student.ect.end_of_year_1_review.status === 'multiple')
          "
          year="1"
          date="already-assigned"
        />
        <mosaic-end-of-year-badge
          v-else-if="student.ect && student.ect.year_1_end"
          year="1"
          :date="student.ect.year_1_end"
        />
        <mosaic-end-of-year-badge
          v-else
          year="1"
          date="none"
          :no-date-reason="student.ect && student.ect.no_end_date_reason"
        />
      </div>
      <div v-if="reviewType === 'end_of_year_2' || reviewType === 'termly'" class="ml-4">
        <mosaic-end-of-year-badge
          v-if="student.ect && student.ect.end_of_year_2_review.status === 'approved'"
          year="2"
          date="already-completed"
        />
        <mosaic-end-of-year-badge
          v-else-if="
            student.ect &&
            (student.ect.end_of_year_2_review.status === 'assigned' ||
              student.ect.end_of_year_2_review.status === 'multiple')
          "
          year="2"
          date="already-assigned"
        />
        <mosaic-end-of-year-badge
          v-else-if="student.ect && student.ect.year_2_end"
          year="2"
          :date="student.ect.year_2_end"
        />
        <mosaic-end-of-year-badge
          v-else
          year="2"
          date="none"
          :no-date-reason="student.ect && student.ect.no_end_date_reason"
        />
      </div>
    </template>

    <template #status-badge="props">
      <v-tooltip v-if="studentBadgeStatus(props.student)" location="top">
        <template #activator="{ props: tooltipProps }">
          <v-chip :color="studentBadgeStatus(props.student).color" v-bind="tooltipProps">{{
            studentBadgeStatus(props.student).label
          }}</v-chip>
        </template>
        <span>{{ studentBadgeStatus(props.student).tooltip }}</span>
      </v-tooltip>
    </template>
  </mosaic-student-selector>
</template>

<script>
import MosaicStudentSelector from './MosaicStudentSelector.vue';
import NdtIconButton from './NdtIconButton.vue';
import MosaicEndOfYearBadge from './MosaicEndOfYearBadge.vue';
import moment from 'moment';
import { mapState } from 'vuex';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import { useCohortStore } from '@/stores/cohort';

export default {
  name: 'ReviewStudentSelect',
  setup() {
    const queryStore = useQueryStore();
    const { selectedCohortStudents } = useCohortStore();
    return { queryStore, selectedCohortStudents };
  },
  mixins: [
    syncQueryParamsMixin({
      hidePaused: { query: 'hidePaused', type: 'boolean' },
      hideLeavers: { query: 'hideLeavers', type: 'boolean' },
      hideAlreadyHadEoY1: { query: 'hideAlreadyHadEoY1', type: 'boolean' },
      hideAlreadyHadEoY2: { query: 'hideAlreadyHadEoY2', type: 'boolean' },
      nameOrEmailFilter: { query: 'name' },
      startDate: { query: 'startDate' },
      endDate: { query: 'endDate' },
    }),
  ],
  components: {
    MosaicStudentSelector,
    NdtIconButton,
    MosaicEndOfYearBadge,
  },
  props: {
    reviewType: {
      default: null,
      type: String,
    },
    dueDate: {
      default: null,
      type: String,
    },
    students: {
      required: true,
      type: Array,
    },
    selectedStudentIds: {
      required: true,
      type: Array,
    },
    studentsToAdd: {
      required: true,
      type: Array,
    },
    studentsToRemove: {
      required: true,
      type: Array,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    review: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:selectedStudentIds'],
  data() {
    return {
      selectedStudentIdsInternal: this.selectedStudentIds,
      nameOrEmailFilter: '',
      hidePaused: true,
      hideLeavers: true,
      startDate: null,
      endDate: null,
      hideAlreadyHadEoY1: true,
      hideAlreadyHadEoY2: true,
      selectedGroupId: null,
      filteredStudentIds: [],
    };
  },
  computed: {
    ...mapState(['selectedCohort', 'reviewTypeItems']),
    leaversItems() {
      return [
        { value: 'exclude', title: `Exclude leavers` },
        { value: 'only', title: `Only leavers` },
        { value: 'all', title: `All ${this.traineeNounPluralised()}` },
      ];
    },
    sortedStudents() {
      return [...this.students].sort((a, b) => {
        if (this.reviewType === 'end_of_year_1') {
          return a.ect?.year_1_end > b.ect?.year_1_end ? 1 : -1;
        }
        if (this.reviewType === 'end_of_year_2') {
          return a.ect?.year_2_end > b.ect?.year_2_end ? 1 : -1;
        }
        return (a.name || a.email) > (b.name || b.email) ? 1 : -1;
      });
    },
    filteredStudents() {
      return this.students.filter(s => {
        const nameOrEmailFilter =
          s.name?.toLowerCase().includes(this.nameOrEmailFilter.toLowerCase()) ||
          s.email.toLowerCase().includes(this.nameOrEmailFilter.toLowerCase());
        const groupAndSubjectFilter = this.filteredStudentIds.includes(s.id);

        const eoY1Filter =
          this.reviewType !== 'end_of_year_1' ||
          ((!this.startDate || s.ect?.year_1_end >= this.startDate) &&
            (!this.endDate || s.ect?.year_1_end <= this.endDate));
        const eoY2Filter =
          this.reviewType !== 'end_of_year_2' ||
          ((!this.startDate || s.ect?.year_2_end >= this.startDate) &&
            (!this.endDate || s.ect?.year_2_end <= this.endDate));
        const eoYExclusiveFilter =
          this.reviewType !== 'termly' ||
          (!this.startDate && !this.endDate) ||
          (!(
            (!this.startDate || s.ect?.year_1_end > this.startDate) &&
            (!this.endDate || s.ect?.year_1_end < this.endDate)
          ) &&
            !(
              (!this.startDate || s.ect?.year_2_end > this.startDate) &&
              (!this.endDate || s.ect?.year_2_end < this.endDate)
            ));
        const leavingDates = this.ectLeavingDates(s.ect);

        const leavingFilter =
          this.reviewType !== 'leavers' ||
          (!this.startDate && !this.endDate) ||
          (!this.startDate && leavingDates.some(d => moment(d).isSameOrBefore(moment(this.endDate)))) ||
          (!this.endDate && leavingDates.some(d => moment(d).isSameOrAfter(moment(this.startDate)))) ||
          leavingDates.some(d => moment(d).isBetween(this.startDate, this.endDate, '[]'));

        const showAlreadyHadEoY1Filter =
          this.reviewType !== 'end_of_year_1' ||
          !this.hideAlreadyHadEoY1 ||
          !s.ect?.has_been_assigned_end_of_year_1_review;

        const showAlreadyHadEoY2Filter =
          this.reviewType !== 'end_of_year_2' ||
          !this.hideAlreadyHadEoY2 ||
          !s.ect?.has_been_assigned_end_of_year_2_review;

        const pausedFilter =
          !this.reviewType ||
          this.reviewType === 'leavers' ||
          !this.hidePaused ||
          !s.ect ||
          !this.willbePausedOnDueDate(s.ect);

        const leaversFilter =
          !this.reviewType ||
          this.reviewType === 'leavers' ||
          !this.hideLeavers ||
          !s.ect ||
          !this.willHaveLeftByDueDate(s);

        return (
          nameOrEmailFilter &&
          groupAndSubjectFilter &&
          eoY1Filter &&
          eoY2Filter &&
          eoYExclusiveFilter &&
          leavingFilter &&
          showAlreadyHadEoY1Filter &&
          showAlreadyHadEoY2Filter &&
          pausedFilter &&
          leaversFilter
        );
      });
    },
  },
  watch: {
    selectedStudentIds(x) {
      this.selectedStudentIdsInternal = x;
    },
    selectedStudentIdsInternal(x) {
      this.$emit('update:selectedStudentIds', x);
    },
    reviewType() {
      if (!this.review) {
        this.reviewTypeChanged();
      }
    },
    dueDate() {
      this.dueDateChanged();
    },
  },
  created() {
    if (this.review) {
      const studentsInReview = this.students.filter(x => x.studentReview);

      // Consider UX of this logic. Currently default checks "hide paused/leavers" if no one is paused/left right now (i.e. not relative to due date)
      this.hidePaused = studentsInReview.every(x => !x.ect?.paused);
      this.hideLeavers = studentsInReview.every(x => !x.ect?.left);

      if (this.reviewType === 'end_of_year_1') {
        this.hideAlreadyHadEoY1 = false;
        const year1Ends = studentsInReview.map(x => x.ect?.year_1_end);
        // If any year1Ends are null then don't set default filter
        if (year1Ends.every(x => x)) {
          this.startDate = year1Ends.sort()[0];
          this.endDate = year1Ends.reverse()[0];
        }
      }

      if (this.reviewType === 'end_of_year_2') {
        this.hideAlreadyHadEoY2 = false;
        const year2Ends = studentsInReview.map(x => x.ect?.year_2_end);
        // If any year2Ends are null then don't set default filter
        if (year2Ends.every(x => x)) {
          this.startDate = year2Ends.sort()[0];
          this.endDate = year2Ends.reverse()[0];
        }
      }

      if (this.reviewType === 'termly') {
        const yearEnds = studentsInReview
          .map(x => x.ect?.year_1_end)
          .concat(studentsInReview.map(x => x.ect?.year_2_end));
        // If any yearEnds are null then don't set default filter
        if (yearEnds.every(x => x)) {
          yearEnds.sort();
          const startCandidates = yearEnds.filter(x => x <= this.review.due_date);
          if (startCandidates.length > 0) {
            this.startDate = startCandidates[startCandidates.length - 1];
          }
          const endCandidates = yearEnds.filter(x => x >= this.review.due_date);
          if (endCandidates.length > 0) {
            this.endDate = endCandidates[0];
          }
        }
      }

      if (this.reviewType === 'leavers') {
        const leavingDates = studentsInReview.map(x => x.ect?.left_date || x.ect?.future_leave_date);
        // If any leavingDates are null then don't set default filter
        if (leavingDates.every(x => x)) {
          this.startDate = leavingDates.sort()[0];
          this.endDate = leavingDates.reverse()[0];
        }
      }
    } else {
      this.reviewTypeChanged();
      this.dueDateChanged();
    }
    this.filteredStudentIds = this.selectedCohortStudents.map(x => x.id);
  },
  methods: {
    reviewTypeChanged() {
      this.hideLeavers = true;
      this.hidePaused = true;
    },
    willHaveLeftByDueDate(student) {
      const ect = student.ect;
      const leaving_events_to_date = ect.leave_and_return_dates.filter(
        e => moment(e.date).isBefore(moment(this.dueDate).add(1, 'days')) && e.type === 'leaving'
      );

      if (leaving_events_to_date.length === 0) {
        return false;
      }
      const return_events = ect.leave_and_return_dates.filter(
        e => moment(e.date).isBefore(moment(this.dueDate).add(1, 'days')) && e.type === 'returned'
      );
      return (
        return_events.length === 0 ||
        moment(leaving_events_to_date.slice(-1)[0].date).isAfter(moment(return_events.slice(-1)[0].date))
      );
    },
    leftDateAtDueDate(student) {
      const ect = student.ect;
      return ect.leave_and_return_dates
        .filter(e => moment(e.date).isBefore(moment(this.dueDate).add(1, 'days')) && e.type === 'leaving')
        .slice(-1)[0].date;
    },
    willbePausedOnDueDate(ect) {
      return (
        (ect.paused && (!ect.future_resume_date || ect.future_resume_date > this.dueDate)) ||
        (ect.future_pause_date &&
          ect.future_pause_date < this.dueDate &&
          (!ect.future_resume_date || ect.future_resume_date > this.dueDate))
      );
    },
    ectLeavingDates(ect) {
      if (!ect) return [];
      return ect.leave_and_return_dates.filter(e => e.type === 'leaving').map(e => e.date);
    },
    dueDateChanged() {
      if (!this.reviewType) return;

      const currentTermIndex = this.selectedCohort.placements.findIndex(
        x => x.start_date <= this.dueDate && x.end_date >= this.dueDate
      );
      if (currentTermIndex !== -1) {
        this.startDate = this.selectedCohort.placements[currentTermIndex].start_date;
        this.endDate = moment(this.selectedCohort.placements[currentTermIndex + 1].start_date)
          .add(2, 'weeks')
          .format();
      } else {
        this.startDate = moment(this.dueDate).subtract(2, 'weeks').format();
        const nextTerm = this.selectedCohort.placements.find(x => x.start_date >= this.dueDate);
        if (nextTerm) {
          this.endDate = moment(nextTerm.start_date).add(2, 'weeks').format();
        } else {
          this.endDate = moment().add(2, 'weeks').format();
        }
      }
    },
    clearDateFilters() {
      this.startDate = null;
      this.endDate = null;
    },
    studentBadgeStatus(student) {
      if (!student.studentReview) {
        return {
          label: 'Not Assigned',
          tooltip: `The ${this.reviewNounCapitalised} was not assigned to this ${this.traineeNoun()}.`,
          color: 'secondary',
        };
      }
      switch (student.studentReview.status) {
        case 'approved':
          return {
            label: 'Approved',
            tooltip: `The ${this.reviewNounCapitalised} has been approved for the ${this.traineeNoun()}.`,
            color: 'secondary',
          };
        case 'completed':
          return {
            label: 'Completed',
            tooltip: `The ${this.reviewNounCapitalised} has been marked as complete by all contributors.`,
            color: 'primary',
          };
        case 'partiallycompleted':
          return {
            label: 'Partially Completed',
            tooltip: `The ${this.reviewNounCapitalised} has been marked as complete by at least one contributor.`,
            color: 'primary',
          };
        case 'started':
          return {
            label: 'Started',
            tooltip: `The ${this.reviewNounCapitalised} has been started.`,
            color: 'accent',
          };
        default:
          return {
            label: 'Not Started',
            tooltip: `The ${this.reviewNounCapitalised} hasn't been started.`,
            color: 'accent',
          };
      }
    },
  },
};
</script>
