import type { Api } from '@/composables/api';
import { isAxiosError } from 'axios';

export const mosaicUnfinishedJobStatuses = ['queued', 'started'] as const;
type MosaicUnfinishedJobStatusTuple = typeof mosaicUnfinishedJobStatuses;
export type MosaicUnfinishedJobStatus = MosaicUnfinishedJobStatusTuple[number];

export const mosaicFinishedJobStatuses = ['finished', 'errored'] as const;
type MosaicFinishedJobStatusTuple = typeof mosaicFinishedJobStatuses;
export type MosaicFinishedJobStatus = MosaicFinishedJobStatusTuple[number];

export type MosaicJobStatus = MosaicUnfinishedJobStatus | MosaicFinishedJobStatus | 'not_found';

export function mosaicJobFinished(status: MosaicJobStatus): boolean {
  return status === 'finished' || status === 'errored';
}

type UnfinishedResponse =
  | {
      status: 'errored';
      errorCode: string;
      errorMessage: string;
    }
  | {
      status: MosaicUnfinishedJobStatus;
    };

export async function executeMosaicJob<T>(
  api: Api,
  action: string,
  url: string,
  body: Record<string, unknown>,
  onSuccess: (pollResult: T) => void,
  onError: (e: { errorCode: string; errorMessage: string; e?: Error }) => void,
  options: {
    maxAttempts?: number;
    waitInMs?: number;
  } = {}
) {
  let data: (T & { status: 'finished' }) | UnfinishedResponse | undefined = undefined;
  try {
    const r = await api.post<unknown, { poll_url: string }>(url, body);
    const pollResult = await api.pollGet<(T & { status: 'finished' }) | UnfinishedResponse>(`${r.data.poll_url}`, {
      retryOnSuccess: r => !mosaicJobFinished(r.data.status),
      maxAttempts: options.maxAttempts,
      waitInMs: options.waitInMs,
    });
    data = pollResult.data;
  } catch (e: unknown) {
    if (!isAxiosError(e)) {
      console.log(e);
    }
    onError({ errorCode: 'network_error', errorMessage: `Sorry, cannot ${action} at the moment`, e: e as Error });
    return;
  }

  if (data.status == 'errored') {
    onError(data);
  }

  if (data.status == 'finished') {
    onSuccess(data);
  }
}
