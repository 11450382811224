import { defineStore } from 'pinia';
import { useStore } from '@/stores/common';
import { CacheMap } from '@/stores/cache-map';
import { useApi } from '@/composables/api';
import { useUserStore } from '@/stores/user';
import { ref, computed } from 'vue';

export interface Exclusion {
  institutionId: number;
  institutionName: string;
}

export interface ProfessionalResource {
  id: number;
  name: string;
  title: string;
  subtitle: string;
  url: string;
  description: string;
  imageFileName: string;
  imageFileUrl: string;
  imageAltText: string;
  showTrainees: boolean;
  showEcts: boolean;
  showStaff: boolean;
  institutionExclusions: Exclusion[];
  region: 'australian' | 'english';
}

export const useProfessionalResourcesStore = useStore(
  defineStore('professionalResources', () => {
    const api = useApi();
    const { user } = useUserStore();

    const professionalResourcesCache = ref(new CacheMap<ProfessionalResource[]>(60 * 24));

    function reset() {
      professionalResourcesCache.value.clear();
    }

    async function loadProfessionalResources() {
      if (!professionalResourcesCache.value.get(user.value.id)) {
        const r = await api.get<ProfessionalResource[]>('my-professional-resources');
        professionalResourcesCache.value.set(user.value.id, r.data);
      }
    }

    const myProfessionalResources = computed(
      () => professionalResourcesCache.value.getEvenIfExpired(user.value?.id) || []
    );

    return { reset, professionalResourcesCache, myProfessionalResources, loadProfessionalResources };
  })
);
