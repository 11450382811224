<template>
  <mosaic-create-edit-card-page
    object-type="Forum"
    :is-creating="isCreating"
    :load="load"
    :dirty="dirty"
    :save="save"
    :can-save="canSave"
    :return-to="getReturnTo(breadcrumbs)"
  >
    <mosaic-text-field label="Name" v-model="name" prepend-icon="pencil" />
    <mosaic-autocomplete
      label="Cohort"
      :items="cohortItems"
      item-title="name"
      item-value="id"
      name="cohort"
      v-model="selectedCohortId"
      :prepend-icon="icons.cohort"
      :readonly="!isCreating || pageType == 'Cohort'"
    />
    <mosaic-info-alert v-if="isCreating && selectedCohortId != -1" class="mb-2">
      You are creating a Forum for a specific Cohort. This means that only Instructors linked to this Cohort will be
      able to be added as Participants.<span v-if="rolesWithCohortForumEditPermission.length > 0">
        Additionally, Instructors who are {{ listItems(rolesWithCohortForumEditPermission, 'or') }} for this Cohort will
        be able to administer this Forum.</span
      >
    </mosaic-info-alert>
  </mosaic-create-edit-card-page>
</template>

<script setup lang="ts">
import { isCreatingRoute, parseRouteId } from '@/composables/vue-router';
import { computed, ref } from 'vue';
import { setBreadcrumbs, getReturnTo } from '@/utils/breadcrumbs';
import { createOrUpdate, useApi } from '@/composables/api';
import { useInstitutionStore } from '@/stores/institution';
import { mapActions, mapGetters } from '@/store/map-store';
import { icons } from '@/utils/icons';
import { useRoute } from 'vue-router';
import { useCohortStore } from '@/stores/cohort';
import { useRoleStore } from '@/stores/role';
import { listItems, pluralise } from '@/utils/text';

// #region setup
const api = useApi();
const isCreating = isCreatingRoute();
const id = parseRouteId('id');
const { selectedInstitution } = useInstitutionStore();
const { selectedCohort } = useCohortStore();
const { activeCohorts } = mapGetters();
const { loadCohorts } = mapActions();
loadCohorts();
const route = useRoute();
const pageType = computed(() => (route.name?.toString().includes('Institution') ? 'Institution' : 'Cohort'));
const {
  roles,
  actions: { loadRoles },
} = useRoleStore();
loadRoles();
// #endregion

// #region data
interface ForumAdminResponse {
  id: number;
  name: string;
  cohortId: number;
}

const forum = ref<ForumAdminResponse>();
const name = ref('');

const selectedCohortId = ref(pageType.value == 'Institution' ? -1 : selectedCohort.value.id);
const cohortItems = computed(() => [{ id: -1, name: 'All Cohorts' }, ...activeCohorts.value]);

const rolesWithCohortForumEditPermission = computed(() =>
  roles.value
    .filter(r => r.cohort_scope && r.permissions.some(p => p.name == 'staff.forums.edit'))
    .map(r => pluralise(r.name))
);

// #endregion

// #region breadcrumbs
const breadcrumbs = computed(() => [
  { text: 'Instructors', to: { name: `${pageType.value}StaffListPage` } },
  {
    text: 'Forums',
    to: { name: `${pageType.value}ForumsListPage` },
  },
  {
    text: isCreating.value ? 'New Forum' : forum.value?.name || '',
  },
]);
setBreadcrumbs(breadcrumbs);
// #endregion

async function load() {
  if (!isCreating.value) {
    const r = await api.get<ForumAdminResponse>(`/admin/forums/${id.value}`);
    forum.value = r.data;
    name.value = r.data.name;
    selectedCohortId.value = r.data.cohortId || -1;
  }
}

const canSave = computed(() => !!name.value);
const { save, isCreatingAndReturning } = createOrUpdate(
  computed(() =>
    selectedCohortId.value == -1
      ? `/institutions/${selectedInstitution.value.id}/admin/forums`
      : `/cohorts/${selectedCohortId.value}/admin/forums`
  ),
  computed(() => `/admin/forums/${id.value}`),
  computed(() => ({
    name: name.value,
  })),
  forum
);

const dirty = computed(() => {
  if (isCreating.value) {
    if (isCreatingAndReturning.value) return false;
    return !!name.value;
  } else {
    return name.value != forum.value?.name;
  }
});
</script>
