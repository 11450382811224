<template>
  <div>
    <mosaic-tab-card-page object-type="Event" :load="load" :headers="tabs">
      <template #title>
        <mosaic-create-edit-title
          object-type="Event"
          :is-creating="isCreating"
          :title-chip="
            status == 'published'
              ? {
                  text: 'Published',
                  color: 'accent',
                  tooltip: 'This Event has been published and is visible to Instructors ',
                }
              : {
                  text: 'Draft',
                  color: 'secondary',
                  tooltip: 'This Event has not been published yet and is not visible to Instructors ',
                }
          "
          :subtitle="event ? { action: 'Created', name: event.creator.name, actionAt: event.createdAt } : undefined"
        >
        </mosaic-create-edit-title>
      </template>

      <template #details-tab-item>
        <div class="pb-2">
          <div>
            <mosaic-text-field label="Name" v-model="name" prepend-icon="pencil" />
            <div class="d-flex">
              <mosaic-disabled-tooltip
                :disabled="hasAttendees"
                tooltip="Date cannot be updated once Attendance has been recorded"
              >
                <template #default="{ disabled }">
                  <div class="mr-6">
                    <mosaic-date-picker v-model:date="date" label="Date" name="date" :readonly="disabled" />
                  </div>
                </template>
              </mosaic-disabled-tooltip>
              <mosaic-disabled-tooltip
                :disabled="hasAttendees"
                tooltip="Time cannot be updated once Attendance has been recorded"
              >
                <template #default="{ disabled }">
                  <div class="mr-6">
                    <mosaic-time-picker v-model="time" label="Time" mode="time" :readonly="disabled" />
                  </div>
                </template>
              </mosaic-disabled-tooltip>
              <mosaic-time-picker v-model="duration" label="Duration" mode="duration" @valid="durationValid = $event" />
            </div>
            <mosaic-quill-field v-model="description" label="Description" name="description" />
            <mosaic-text-field label="Online meeting link" v-model="meetingLink" prepend-icon="laptop-account" />
            <staff-training-competency-multi-select
              v-if="institutionStaffTrainingFrameworksIncludingUnpublished"
              v-model="competencyIds"
              :frameworks="institutionStaffTrainingFrameworksIncludingUnpublished"
              label="Competencies this Event meets"
            />
          </div>
          <mosaic-save-buttons
            object-type="Event Details"
            :object-type-is-plural="true"
            :is-creating="isCreating"
            :save="saveDetails"
            :can-save="canSaveDetails"
            :return-to="getReturnTo(breadcrumbs)"
          />
        </div>
      </template>

      <template #attendance-tab-item>
        <mosaic-info-alert class="my-2" v-if="!attendanceEditable">
          Attendance can be recorded<span v-if="status == 'draft'"> after the Event has been published and</span> after
          the Event has taken place.
        </mosaic-info-alert>
        <institution-staff-assignment
          v-model:selected-staff-ids="selectedStaffIds"
          :initially-selected-staff-ids="initiallySelectedStaffIds"
          :is-editing="!isCreating"
          object-type="Event"
          :staff-to-hide="staffToHide"
          :filter="filterStaff"
          :readonly="!attendanceEditable"
          :hide-selected-count="!attendanceEditable"
          :empty-text="
            isCreating && startsInThePast
              ? `This Event starts in the past so won't be visible to any Instructors. Attendance can be recorded after the Event has been created.`
              : competencyIds.length === 0
              ? `This Event doesn't meet any Competencies so won't be visible to any Instructors`
              : `There are no Instructors who are required to meet the Competencies for this Event`
          "
          pending-assignment-label="Attendance Pending"
          pending-assignment-tooltip-action="marked as attending by"
          @not-all-staff-initially-visible="onlyAssignedToCompetencies = false"
        >
          <template #filters>
            <mosaic-checkbox
              v-if="attendanceEditable"
              v-model="onlyAssignedToCompetencies"
              label="Only Instructors who are required to meet the Competencies?"
              dense
              no-icon
            />
          </template>

          <template #status-badge="{ staff: s }">
            <mosaic-tooltip-chip color="accent" v-if="!attendanceEditable">
              <template #text>Will be visible</template>
              <template #tooltip>
                This Event will appear for this Instructor as they are assigned to the Competencies this Event meets
              </template>
            </mosaic-tooltip-chip>
            <template v-else>
              <mosaic-tooltip-chip
                color="accent"
                v-if="event?.staffTrainingEventAttendances.some(a => a.staffId == s.id)"
              >
                <template #text>Attended</template>
                <template #tooltip> This Instructor attended this event </template>
              </mosaic-tooltip-chip>
              <mosaic-tooltip-chip color="secondary" v-else>
                <template #text>Did Not Attend</template>
                <template #tooltip> This Instructor did not attend this event </template>
              </mosaic-tooltip-chip>
            </template>
          </template>
        </institution-staff-assignment>

        <mosaic-save-buttons
          v-if="attendanceEditable"
          class="mt-4 mb-2"
          object-type="Event Attendance"
          :is-creating="false"
          :save="saveAttendance"
          :can-save="attendanceDirty"
          :return-to="getReturnTo(breadcrumbs)"
        />
      </template>
    </mosaic-tab-card-page>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="Event"
      :save="save"
      :can-save="detailsDirty ? canSaveDetails : true"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { setBreadcrumbs, getReturnTo } from '@/utils/breadcrumbs';
import { DateTime } from 'luxon';
import StaffTrainingCompetencyMultiSelect from '@/pages/staff-training/StaffTrainingCompetencyMultiSelect.vue';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';
import { useInstitutionStore } from '@/stores/institution';
import { useApi } from '@/composables/api';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import type { InstitutionStaff } from '@/stores/institution-staff';
import { isCreatingRoute, parseRouteId } from '@/composables/vue-router';
import { dateTimeIsInThePast, splitIntoLocalDateAndTime } from '@/utils/date';
import { formatTime, splitTimeIntoHoursAndMinutes } from '@/utils/time';
import InstitutionStaffAssignment from '@/components/assignment/InstitutionStaffAssignment.vue';

// #region setup
const isCreating = isCreatingRoute();
const id = parseRouteId('id');

const api = useApi();
const { selectedInstitution } = useInstitutionStore();
const {
  actions: { clearAllStaffTraining },
} = useStaffTrainingStore();
const {
  institutionStaffTrainingFrameworksIncludingUnpublished,
  actions: { loadInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();

const tabs = computed(() => [
  {
    key: 'details',
    text: 'Details',
    dirty: detailsDirty.value,
  },
  {
    key: 'attendance',
    text: 'Attendance',
    dirty: attendanceDirty.value,
  },
]);
// #endregion

// #region data
interface StaffTrainingEventResponse {
  id: number;
  name: string;
  startsAt: string;
  durationHours: number;
  durationMinutes: number;
  description: string;
  meetingLink: string;
  status: 'draft' | 'published';
  staffTrainingEventCompetencies: {
    staffTrainingCompetencyId: number;
  }[];
  createdAt: string;
  creator: {
    id: number;
    name: string;
  };
  staffTrainingEventAttendances: {
    staffId: number;
  }[];
}

const name = ref<string>('');

const defaultDate = DateTime.now().plus({ days: 1 }).toISO();
const date = ref(defaultDate);
const defaultTime = '9:00';
const time = ref(defaultTime);
const defaultDuration = '1:00';
const startsAt = computed(() => {
  const d = DateTime.fromISO(date.value);
  const [hours, minutes] = time.value.split(':');
  return DateTime.local(d.year, d.month, d.day, parseInt(hours), parseInt(minutes)).toUTC().toISO();
});

const duration = ref(defaultDuration);
const description = ref<string>();
const meetingLink = ref<string>('');
const competencyIds = ref<number[]>([]);

const event = ref<StaffTrainingEventResponse>();
const status = computed(() => event.value?.status || 'draft');
const hasAttendees = computed(() => !!event.value && event.value?.staffTrainingEventAttendances.length > 0);
const initiallySelectedStaffIds = computed(() => event.value?.staffTrainingEventAttendances.map(s => s.staffId) || []);
// #endregion

// #region attendance
const startsInThePast = computed(() =>
  event.value ? dateTimeIsInThePast(event.value.startsAt) : dateTimeIsInThePast(startsAt.value)
);
const attendanceEditable = computed(() => !isCreating.value && startsInThePast.value && status.value == 'published');

function staffToHide(s: InstitutionStaff) {
  if (isCreating.value && startsInThePast.value) {
    // Event will be visible to no staff as currently only upcoming events are shown and we don't allow setting attendance
    // while creating
    return true;
  }
  if (!attendanceEditable.value) return !filterAssignedToCompetencies(s);
  return false;
}

const assignedFrameworks = computed(() =>
  (institutionStaffTrainingFrameworksIncludingUnpublished.value || []).filter(f =>
    f.staffTrainingCompetencyThemes.some(t => t.staffTrainingCompetencies.some(c => competencyIds.value.includes(c.id)))
  )
);

const onlyAssignedToCompetencies = ref(true);

function filterStaff(s: InstitutionStaff) {
  if (isCreating.value && startsInThePast.value) return false;

  let result = true;

  if (onlyAssignedToCompetencies.value) {
    result &&= filterAssignedToCompetencies(s);
  }

  return result;
}

function filterAssignedToCompetencies(s: InstitutionStaff) {
  return assignedFrameworks.value.some(f => {
    const cohortIds = f.staffTrainingFrameworkCohorts.map(c => c.publishedToCohortId);
    const roleIds = f.staffTrainingFrameworkRoles.map(r => r.publishedToRoleId);
    return s.staffRoles.some(sr => {
      if (!roleIds.includes(sr.role.id)) return false;
      const cohortId = sr.cohort?.id || sr.noStudentYetCohort?.id || sr.student?.cohortId;
      if (!cohortId) return false;
      return cohortIds.includes(cohortId);
    });
  });
}

const selectedStaffIds = ref<number[]>([]);

// #endregion

// #region dirty checking
const isCreatingAndReturning = ref(false);
const detailsDirty = computed(() => {
  if (isCreating.value) {
    if (isCreatingAndReturning.value) return false;
    return (
      !!name.value ||
      date.value != defaultDate ||
      time.value != defaultTime ||
      duration.value != defaultDuration ||
      !!description.value ||
      !!meetingLink.value ||
      competencyIds.value.length > 0
    );
  } else {
    const e = event.value;
    if (!e) return false;
    return (
      e.name != name.value ||
      e.startsAt != startsAt.value ||
      formatTime(e.durationHours, e.durationMinutes) != duration.value ||
      e.description != description.value ||
      e.meetingLink != meetingLink.value ||
      e.staffTrainingEventCompetencies.length != competencyIds.value.length ||
      e.staffTrainingEventCompetencies.some(c => !competencyIds.value.includes(c.staffTrainingCompetencyId))
    );
  }
});

const attendanceDirty = computed(() => {
  if (!attendanceEditable.value) return false;
  const attendance = event.value?.staffTrainingEventAttendances;
  if (!attendance) return false;
  return (
    attendance.length !== selectedStaffIds.value.length ||
    attendance.some(a => !selectedStaffIds.value.includes(a.staffId))
  );
});

const { dialog } = useUnsavedChanges(computed(() => detailsDirty.value || attendanceDirty.value));
// #endregion

const breadcrumbs = computed(() => [
  { text: 'Instructors', to: { name: 'InstitutionStaffListPage' } },
  { text: 'Training', to: { name: 'InstitutionStaffTrainingPage', query: { tab: 'events' } } },
  {
    text: isCreating.value ? 'New Event' : event.value?.name || '',
  },
]);
setBreadcrumbs(breadcrumbs);

// #region load
async function load() {
  await Promise.all([loadInstitutionStaffTrainingFrameworks(), loadEvent()]);
}

async function loadEvent() {
  if (isCreating.value) return;
  const r = await api.get<StaffTrainingEventResponse>(`/staff-training/events/${id.value}`);
  event.value = r.data;
  name.value = r.data.name;
  const { date: d, time: t } = splitIntoLocalDateAndTime(r.data.startsAt);
  date.value = d;
  time.value = t;
  duration.value = formatTime(r.data.durationHours, r.data.durationMinutes);
  description.value = r.data.description;
  meetingLink.value = r.data.meetingLink;
  competencyIds.value = r.data.staffTrainingEventCompetencies.map(c => c.staffTrainingCompetencyId);
  selectedStaffIds.value = r.data.staffTrainingEventAttendances.map(a => a.staffId);
}
// #endregion

// #region save
const durationValid = ref(false);
const canSaveDetails = computed(
  () => durationValid.value && !!name.value && !!date.value && !!time.value && !!duration.value && detailsDirty.value
);
async function saveDetails() {
  const { hours, minutes } = splitTimeIntoHoursAndMinutes(duration.value);
  const body = {
    name: name.value,
    startsAt: hasAttendees.value ? event.value!.startsAt : startsAt.value,
    durationHours: hours,
    durationMinutes: minutes,
    description: description.value,
    meetingLink: meetingLink.value,
    competencies: competencyIds.value.map(id => ({ staffTrainingCompetencyId: id })),
  };

  if (isCreating.value) {
    await api.post(`/institutions/${selectedInstitution.value.id}/staff-training/events`, body);
    isCreatingAndReturning.value = true;
  } else {
    const r = await api.put<typeof body, StaffTrainingEventResponse>(`/staff-training/events/${event.value!.id}`, body);
    event.value = r.data;
  }

  clearAllStaffTraining();
}

async function saveAttendance() {
  const body = {
    attendances: selectedStaffIds.value.map(id => ({ staffId: id })),
  };
  const r = await api.put<typeof body, StaffTrainingEventResponse>(
    `/staff-training/events/${event.value!.id}/attendance`,
    body
  );
  event.value = r.data;
  const { date: d, time: t } = splitIntoLocalDateAndTime(r.data.startsAt);
  date.value = d;
  time.value = t;

  clearAllStaffTraining();
}

async function save() {
  if (detailsDirty.value) await saveDetails();
  if (attendanceDirty.value) await saveAttendance();
}

// #endregion
</script>
