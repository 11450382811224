import { defineStore } from 'pinia';
import { useStore } from './common';
import { CacheMap } from './cache-map';
import { computed, ref } from 'vue';
import { useInstitutionStore } from './institution';
import { useApi } from '@/composables/api';
import type { GoReactTemplateResponse } from '@/pages/go-react-templates/go-react-template';

export const useGoReactTemplatesStore = useStore(
  defineStore('goReactTemplates', () => {
    const api = useApi();

    const { selectedInstitution } = useInstitutionStore();

    const goReactTemplatesCache = ref(new CacheMap<GoReactTemplateResponse[]>());

    function reset() {
      goReactTemplatesCache.value.clear();
    }

    async function loadGoReactTemplates(force?: boolean) {
      const institutionId = selectedInstitution.value.id;
      if (force || !goReactTemplatesCache.value.get(institutionId)) {
        const r = await api.get<GoReactTemplateResponse[]>(`/institutions/${institutionId}/go-react-templates`);
        goReactTemplatesCache.value.set(institutionId, r.data);
      }
    }

    const institutionGoReactTemplates = computed(
      () => goReactTemplatesCache.value.getEvenIfExpired(selectedInstitution.value.id) || []
    );

    async function clearGoReactTemplates() {
      goReactTemplatesCache.value.remove(selectedInstitution.value.id);
    }

    return { reset, goReactTemplatesCache, loadGoReactTemplates, institutionGoReactTemplates, clearGoReactTemplates };
  })
);
