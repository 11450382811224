<template>
  <div>
    <mosaic-save-card
      :save="save"
      :can-save="canSave"
      object-type="Reflection"
      :is-creating="isCreating"
      :return-to="returnTo"
      :readonly="!editable"
      :preview="preview"
    >
      <mosaic-create-edit-title
        object-type="Reflection"
        :is-creating="isCreating"
        :readonly="!editable"
        :subtitle="reflection ? { action: 'Created', name: createdBy, actionAt: reflection.createdAt } : undefined"
      >
        <template #title-chip v-if="reflection && adminReflectionStudentId">
          <v-chip v-if="!reflection.submitted" color="accent">Active</v-chip>
          <v-chip v-else color="primary">Completed</v-chip>
        </template>
      </mosaic-create-edit-title>
      <mosaic-text-area
        name="reflection-title"
        v-if="!adminReflectionStudentId"
        v-model="entryTitle"
        class="mb-4"
        label="Title"
        hide-details
        autofocus
        auto-grow
        rows="1"
        prepend-icon="pencil"
        :readonly="!editable"
      />
      <mosaic-text-field
        v-else
        name="title"
        label="Title"
        :readonly="true"
        :model-value="entryTitle"
        prepend-icon="pencil"
      />
      <mosaic-text-field
        v-if="prompt"
        name="prompt"
        label="Prompt"
        :readonly="true"
        :model-value="prompt"
        prepend-icon="pencil"
      />

      <mosaic-content-layout
        v-if="contentLayout"
        :content-layout="contentLayout"
        get-resource-url-prefix="/reflection-templates/resources"
        get-file-url-prefix="/reflections/files"
        :upload-file-presign-url="`/presign/students/${studentId}/reflection-files`"
        v-model:comments="comments"
        v-model:tasks="tasks"
        v-model:files="files"
        :preview="preview"
        :readonly="!editable"
      />
      <mosaic-quill-field
        v-else
        name="reflection"
        label="Reflection"
        v-model="content"
        class="mt-5"
        :readonly="!editable"
        :has-min-height="true"
      />

      <curriculum-links
        class="mt-4"
        :selected-curriculum-statements="curriculumStatements"
        :curriculum-statement-id="curriculumStatementId"
        :can-edit="!adminReflectionStudentId && editable"
        artefact-type="Reflection"
        @update:link-added="addCurriculumLink"
        @update:link-removed="removeCurriculumLink"
      />

      <template #beside-buttons>
        <div class="d-flex align-center" v-if="adminReflectionStudentId && editable">
          <div>
            <mosaic-checkbox v-model="submitted" no-icon label="Mark as Complete" class="mt-0 mr-1" />
          </div>
          <mosaic-help>
            <div>This will help your course leads know</div>
            <div>that this reflection is ready to review</div>
          </mosaic-help>
        </div>
      </template>
    </mosaic-save-card>

    <ndt-dialog v-model:active="markCompleteDialog.active" title="Mark as complete?">
      <div>
        It looks like you've added to your Reflection, but have not marked it as complete. Marking your Reflection as
        complete will help your course leads know that this Reflection is ready to review.
      </div>

      <div class="pt-4">Would you like to mark this Reflection as complete?</div>
      <mosaic-checkbox v-model="markCompleteDialog.markAsComplete" no-icon label="Mark as Complete" />

      <template #buttons>
        <v-btn variant="text" ripple color="primary" @click.prevent="markCompleteDialog.active = false">Save</v-btn>
      </template>
    </ndt-dialog>
  </div>
</template>

<script setup lang="ts">
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import {
  createContentLayoutCompletionComments,
  createContentLayoutCompletionCommentsFromTemplate,
  createContentLayoutCompletionFiles,
  createContentLayoutCompletionFilesFromTemplate,
  createContentLayoutCompletionTasks,
  createContentLayoutCompletionTasksFromTemplate,
  type ContentLayout,
} from '@/utils/content-layout';
import { until } from '@vueuse/core';
import { useUserStore } from '@/stores/user';
import { ref, computed, watchEffect } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { useApi } from '@/composables/api';
import { useCurriculumStore } from '@/stores/curriculum';
import { useStudentStore } from '@/stores/student';
import { todaysDate } from '@/mixins/global-mixins';
import type { CurriculumStatement } from '@/store/map-store';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';

interface ReflectionResponse {
  id: number;
  title: string;
  createdAt: string;
  prompt: string;
  submitted: boolean;
  contentLayout: ContentLayout;
  adminReflectionStudentId: number;
  reflectionContentLayoutComments: {
    comment: string;
    reflectionCommentId: string;
  }[];
  reflectionContentLayoutTasks: {
    completed: boolean;
    reflectionTaskId: string;
  }[];
  reflectionContentLayoutFiles: {
    title: string;
    fileId: string;
    reflectionFileUploadId: string;
  }[];
  curriculumStatements: {
    id: number;
    statement: string;
    code: string;
    subjectId: number;
    hidden: boolean;
  }[];
  // Just used for the editing of old style reflections
  content?: string;
  template?: string;
}

const props = withDefaults(
  defineProps<{
    reflection?: ReflectionResponse;
    studentId?: number;
    curriculumStatementId?: string;
    returnTo: RouteLocationNamedRaw;
    editable: boolean;
    preview?: boolean;
    templateContentLayout?: ContentLayout;
  }>(),
  {
    preview: false,
  }
);

if (props.editable && !props.templateContentLayout) throw `Editable ReflectionCard requires a templateContentLayout`;
if (props.editable && !props.preview && !props.studentId)
  throw `Editable (and not preview) ReflectionCard requires a studentId`;

const emit = defineEmits<{
  'update:dirty': [dirty: boolean];
  'update:reflection': [r: ReflectionResponse];
}>();

defineExpose({ save });

const api = useApi();

const { user } = useUserStore();
const {
  actions: { loadCurriculum },
} = useCurriculumStore();
loadCurriculum({ force: false, throwError: false });
const { traineeNoun } = useStudentStore();

const submitted = ref(true);
const content = ref<string | undefined>('');
const defaultTitle = `Reflection - ${todaysDate()}`;
const entryTitle = ref('');
const prompt = ref('');
const curriculumStatements = ref<{ id: number }[]>([]);
const contentLayout = computed(() =>
  props.reflection ? props.reflection.contentLayout : props.templateContentLayout!
);

if (props.reflection) {
  entryTitle.value = props.reflection.title;
  prompt.value = props.reflection.prompt;
  content.value = props.reflection.content || props.reflection.template;
  curriculumStatements.value = props.reflection.curriculumStatements.map(cs => ({ ...cs }));
  submitted.value = props.reflection.submitted;
} else {
  entryTitle.value = defaultTitle;
}

const adminReflectionStudentId = computed(() => props.reflection?.adminReflectionStudentId || null);

const { comments, commentsDirty } = props.reflection
  ? createContentLayoutCompletionComments(
      computed(() => props.reflection!.reflectionContentLayoutComments),
      c => c.reflectionCommentId
    )
  : createContentLayoutCompletionCommentsFromTemplate(contentLayout.value, id => ({
      reflectionCommentId: id,
    }));

const { tasks, tasksDirty } = props.reflection
  ? createContentLayoutCompletionTasks(
      computed(() => props.reflection!.reflectionContentLayoutTasks),
      t => t.reflectionTaskId
    )
  : createContentLayoutCompletionTasksFromTemplate(contentLayout.value, id => ({ reflectionTaskId: id }));

const { files, filesDirty } = props.reflection
  ? createContentLayoutCompletionFiles(
      computed(() => props.reflection!.reflectionContentLayoutFiles),
      f => f.reflectionFileUploadId
    )
  : createContentLayoutCompletionFilesFromTemplate();

const markCompleteDialog = ref({
  active: false,
  markAsComplete: false,
});

const isCreating = computed(() => !props.reflection);

const curriculumStatementsDirty = computed(() => {
  const originalIds = props.reflection ? props.reflection.curriculumStatements.map(s => s.id) : [];
  const currentIds = curriculumStatements.value.map(s => s.id);
  return originalIds.length !== currentIds.length || originalIds.some(id => !currentIds.includes(id));
});

const dirty = computed(() => {
  if (commentsDirty.value || filesDirty.value || tasksDirty.value || curriculumStatementsDirty.value) return true;

  if (!props.reflection) {
    return entryTitle.value !== defaultTitle || curriculumStatementsDirty.value;
  } else {
    const contentDirty = props.reflection.contentLayout
      ? false
      : content.value !== props.reflection.content && content.value !== props.reflection.template;

    return !!(
      contentDirty ||
      entryTitle.value !== props.reflection.title ||
      (props.reflection.adminReflectionStudentId && submitted.value !== props.reflection.submitted)
    );
  }
});
watchEffect(() => emit('update:dirty', dirty.value));

const canSave = computed(() => {
  const hasContentLayoutOrHasContent = contentLayout.value || !!content.value;
  return Boolean(hasContentLayoutOrHasContent && entryTitle.value && dirty.value);
});
const createdBy = computed(() => {
  return user.value.student ? 'Me' : traineeNoun.value;
});

function addCurriculumLink(x: CurriculumStatement) {
  curriculumStatements.value.push(x);
}

function removeCurriculumLink(x: CurriculumStatement) {
  const index = curriculumStatements.value.findIndex(s => s.id === x.id);
  curriculumStatements.value.splice(index, 1);
}

async function save() {
  if (!submitted.value && props.reflection && props.reflection.adminReflectionStudentId) {
    markCompleteDialog.value = {
      active: true,
      markAsComplete: false,
    };
    await until(computed(() => markCompleteDialog.value.active)).toBe(false);
    submitted.value = markCompleteDialog.value.markAsComplete;
  }

  await submitSave();
}

async function submitSave() {
  const reflection = {
    title: entryTitle.value,
    content: content.value,
    curriculumStatementIds: curriculumStatements.value.map(s => s.id),
    submitted: Boolean(props.reflection?.adminReflectionStudentId && submitted.value),
    comments: comments.value.map(c => ({ comment: c.comment, reflectionCommentId: c.templateId })),
    tasks: tasks.value.map(t => ({ completed: t.completed, reflectionTaskId: t.templateId })),
    files: files.value.map(f => ({ file_id: f.fileId, title: f.title, reflectionFileUploadId: f.templateId })),
  };

  if (!props.reflection) {
    await api.post(`/students/${props.studentId}/reflections`, { ...reflection, contentLayout: contentLayout.value });
  } else {
    const r = await api.put<typeof reflection, ReflectionResponse>(`/reflections/${props.reflection.id}`, reflection);
    emit('update:reflection', fromSnakeCaseToCamelCase(r.data));
  }
  emit('update:dirty', false);
}
</script>
