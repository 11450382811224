<template>
  <div>
    <v-card v-if="error">
      <v-card-text>{{ error }}</v-card-text>
    </v-card>
    <template v-else-if="user">
      <v-card class="mb-4">
        <v-card-text>
          <mosaic-card-title
            >Details <template #chip><demo-account-badge v-if="user.is_demo" /></template
          ></mosaic-card-title>
          <table>
            <tr v-for="p of userProperties" :key="p.name">
              <td class="pr-4">{{ p.name }}</td>
              <td>
                <div class="d-flex align-center">
                  <a v-if="p.click" @click.prevent="p.click()">{{ p.value }}</a>
                  <span v-else>{{ p.value }}</span>
                  <template v-if="p.name === 'Email'">
                    <email-status-chip
                      v-if="!user.is_demo"
                      class="ml-2"
                      :email-verified="user.email_verified"
                      :email-bounced="user.email_bounced"
                      :opted-out-of-emails="user.opted_out_of_emails"
                    />
                    <mosaic-icon-btn icon="mdi-pencil" class="ml-2" @click.prevent="editEmail()" />
                  </template>
                </div>
              </td>
            </tr>
          </table>
          <div v-if="user.student">
            <div class="text-h6 mt-4">Trainee/ECT</div>
            <table>
              <tr v-for="p of studentProperties" :key="p.name">
                <td class="pr-2">{{ p.name }}</td>
                <td>
                  <a v-if="p.click" @click.prevent="p.click()" class="text-link">{{ p.value }}</a>
                  <span v-else>{{ p.value }}</span>
                </td>
                <td>
                  <div v-if="p.action" class="pl-2">
                    <mosaic-btn :disabled="p.action.processing" @click.prevent="p.action.click()">
                      <v-icon v-if="p.action.buttonIcon" class="mr-2">{{ p.action.buttonIcon }}</v-icon
                      >{{ p.action.buttonText }}
                    </mosaic-btn>
                    <mosaic-help>
                      {{ p.action.helpText }}
                    </mosaic-help>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div v-if="user.staff">
            <div class="text-h6 mt-4">Instructor</div>
            <table v-for="st of staffProperties" :key="st[0].value" class="mb-2">
              <tr v-for="p of st" :key="p.name">
                <td class="pr-2">{{ p.name }}</td>
                <td>
                  <div class="d-flex align-center">
                    <a v-if="p.click" @click.prevent="p.click()">{{ p.value }}</a>
                    <span v-else>{{ p.value }}</span
                    ><template v-if="p.name === 'Storage Email'">
                      <template v-if="!p.value">
                        <em>not set</em>
                        <mosaic-icon-btn
                          icon="mdi-pencil-plus"
                          class="ml-2"
                          @click.prevent="editStaffStorageEmail(st)"
                          tooltip="Set storage email"
                        />
                      </template>
                      <mosaic-icon-btn
                        v-else
                        icon="mdi-close"
                        class="ml-2"
                        @click.prevent="resetStaffStorageData(st)"
                        tooltip="Reset storage email - This will reset this Instructor's storage email for this Institution and force them to relink Mosaic to it"
                      />
                    </template>
                  </div>
                </td>
                <td>
                  <div v-if="p.action" class="pl-2">
                    <mosaic-btn :disabled="p.action.processing" @click.prevent="p.action.click()">
                      <v-icon v-if="p.action.buttonIcon" class="mr-2">{{ p.action.buttonIcon }}</v-icon
                      >{{ p.action.buttonText }}
                    </mosaic-btn>
                    <mosaic-help>
                      {{ p.action.helpText }}
                    </mosaic-help>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>Actions</v-card-title>
        <mosaic-snackbar v-model="snackbar.status" :color="snackbar.color" :message="snackbar.message" />
        <v-card-text>
          <div class="d-flex">
            <mosaic-btn class="mr-4" :disabled="processingImpersonate" @click.prevent="impersonate()"
              >Impersonate</mosaic-btn
            >
            <mosaic-btn class="mr-4" @click.prevent="resetPassword()">Reset Password</mosaic-btn>
            <mosaic-btn
              v-if="!(user.is_demo || user.email_bounced || user.opted_out_of_emails)"
              class="mr-4"
              :disabled="processingEmailVerification"
              @click.prevent="toggleEmailVerification()"
              >{{ user.email_verified ? 'Unverify email' : 'Verify email' }}</mosaic-btn
            >
            <div v-if="notAdminUser" class="mr-4">
              <mosaic-btn @click.prevent="updateUserStatus()">{{
                user.status == 'archived' ? 'Unarchive' : 'Archive'
              }}</mosaic-btn>
            </div>
            <mosaic-btn
              v-if="user.account_locked"
              class="mr-4"
              :disabled="lockAccountProcessing"
              @click.prevent="unlockAccount()"
              >Unlock Account</mosaic-btn
            >
            <mosaic-btn v-else class="mr-4" :disabled="lockAccountProcessing" @click.prevent="lockAccount()"
              >Lock Account</mosaic-btn
            >
            <mosaic-btn
              v-if="
                user.student &&
                user.student.ect &&
                user.student.ect.qts_checked_at &&
                user.student.ect.status === 'active'
              "
              class="mr-4"
              :disabled="resetTraInformationProcessing"
              @click.prevent="resetTraInformation()"
              >Reset TRA Information</mosaic-btn
            >
          </div>
          <div v-if="user.student" class="mt-4">
            <span class="px-2">On Demand Sharing Override</span>
            <v-btn-toggle
              v-model="onDemandSharing"
              mandatory
              color="orange"
              variant="outlined"
              divided
              @update:model-value="onDemandSharingChanged"
            >
              <mosaic-btn class="px-3" value="notSet">Not set</mosaic-btn>
              <mosaic-btn class="px-3" :value="true">On</mosaic-btn>
              <mosaic-btn class="px-3" :value="false">Off</mosaic-btn>
            </v-btn-toggle>
          </div>
          <div v-if="user.student" class="mt-4">
            <span class="px-2">One Drive Debug Logging</span>
            <v-btn-toggle
              v-model="debugLogging"
              mandatory
              color="orange"
              @update:model-value="debugLoggingChanged"
              variant="outlined"
              divided
            >
              <mosaic-btn class="px-3" :value="true">On</mosaic-btn>
              <mosaic-btn class="px-3" :value="false">Off</mosaic-btn>
            </v-btn-toggle>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <mosaic-dialog
      v-model:active="resetPasswordDialog.active"
      title="Reset Password"
      :error-message="resetPasswordDialog.errorMessage"
    >
      <div class="pt-2">
        <v-text-field v-model="resetPasswordDialog.newPassword" label="Password" type="text" />
        <v-text-field v-model="resetPasswordDialog.newPasswordConfirmation" label="Confirm Password" type="text" />
      </div>
      <template #buttons>
        <mosaic-btn variant="text" ripple :disabled="!canSubmitResetPassword" @click.prevent="submitResetPassword()"
          >Submit</mosaic-btn
        >
      </template>
    </mosaic-dialog>
    <mosaic-dialog
      v-model:active="updateUserStatusDialog.active"
      title="Update User Status"
      :error-message="updateUserStatusDialog.errorMessage"
      :width="500"
    >
      <span class="pb-2">
        Are you sure you want to set {{ updateUserStatusDialog.userName }}'s account status to
        <b>{{ updateUserStatusDialog.newStatus }}</b
        >?
      </span>
      <v-alert v-if="updateUserStatusDialog.newStatus == 'archived'" type="warning"
        >This will mean the user can no longer log in and will be removed from lists in the trainee/instructors
        area</v-alert
      >
      <v-alert v-if="updateUserStatusDialog.newStatus == 'active'" type="info"
        >This will all the user to log in again and be viewable across the trainee/instructors area</v-alert
      >
      <template #buttons>
        <mosaic-btn
          variant="text"
          ripple
          color="error"
          :disabled="updateUserStatusDialog.processing"
          @click.prevent="submitUpdateUserStatus()"
          >Update Status</mosaic-btn
        >
      </template>
    </mosaic-dialog>
    <mosaic-dialog
      v-model:active="editEmailDialog.active"
      title="Edit Email"
      :error-message="editEmailDialog.errorMessage"
    >
      <mosaic-info-alert
        >Updating the User's email address will also reset their email verification status</mosaic-info-alert
      >
      <mosaic-alert type="warning" class="mt-4" v-if="user.staff && user.staff.length > 1"
        >This User has Instructor accounts in multiple Institutions. Please make sure both Institutions are happy that
        this email address is being updated</mosaic-alert
      >
      <div class="pt-2">
        <mosaic-email-field
          v-model="editEmailDialog.newEmail"
          prepend-icon="mdi-email"
          @keyup.enter="submitEditEmail()"
        />
      </div>
      <template #buttons>
        <mosaic-btn
          variant="text"
          ripple
          :disabled="editEmailDialog.processing || !isValidUpdatedEmail"
          @click.prevent="submitEditEmail()"
          >Submit</mosaic-btn
        >
      </template>
    </mosaic-dialog>
    <mosaic-dialog
      v-model:active="editStaffStorageEmailDialog.active"
      title="Edit Storage Email"
      :error-message="editStaffStorageEmailDialog.errorMessage"
    >
      <mosaic-info-alert
        ><div>
          Updating the Instructor's storage email will allow them GDrive/OneDrive Files to be shared withem them.
        </div>
        <div class="pt-2">
          Please only set the storage email if you have verified the email is a valid Google/Microsoft account email (By
          obtaining screenshots from the user that prove this)
        </div></mosaic-info-alert
      >
      <mosaic-alert type="warning" class="mt-4" v-if="user.staff && hasMultipleSameStorageInstitutions(user)"
        >This User has multiple Instructor records that use
        {{ editStaffStorageEmailDialog.staff.institution.storage_type }}. Updating this storage email will only affect
        the Instructor record for {{ editStaffStorageEmailDialog.staff.institution.name }}</mosaic-alert
      >
      <div class="pt-2">
        <mosaic-email-field
          v-model="editStaffStorageEmailDialog.newEmail"
          prepend-icon="mdi-email"
          @keyup.enter="submitEditStaffStorageEmail()"
        />
      </div>
      <template #buttons>
        <mosaic-btn
          variant="text"
          ripple
          :disabled="editStaffStorageEmailDialog.processing || !isValidUpdatedStorageEmail"
          @click.prevent="submitEditStaffStorageEmail()"
          >Submit</mosaic-btn
        >
      </template>
    </mosaic-dialog>
  </div>
</template>

<script>
import DemoAccountBadge from '../../components/DemoAccountBadge.vue';
import { impersonate } from '../../utils/auth';
import { generateStongPassword } from '../../utils/passwords';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import { validateEmail } from '@/utils/email';

export default {
  name: 'AdminUserPage',
  components: { DemoAccountBadge, EmailStatusChip },
  data: () => ({
    user: null,
    error: '',
    userId: null,
    processingResetStorageData: false,
    processingImpersonate: false,
    processingEmailVerification: false,
    onDemandSharing: null,
    debugLogging: null,
    lockAccountProcessing: false,
    resetTraInformationProcessing: false,
    snackbar: {
      color: '',
      status: false,
      message: '',
    },
    resetPasswordDialog: {
      active: false,
      processing: false,
      newPassword: '',
      newPasswordConfirmation: '',
      errorMessage: '',
    },
    updateUserStatusDialog: {
      active: false,
      newStatus: '',
      userName: '',
      userId: null,
      errorMessage: '',
      staffOrStudent: '',
    },
    editEmailDialog: {
      active: false,
      newEmail: '',
      processing: false,
      errorMessage: '',
    },
    editStaffStorageEmailDialog: {
      active: false,
      newEmail: '',
      processing: false,
      errorMessage: '',
    },
  }),
  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Users',
          to: {
            name: 'AdminUsersPage',
          },
        },
        { text: this.user?.name },
      ];
    },
    isValidUpdatedStorageEmail() {
      return validateEmail(this.editStaffStorageEmailDialog.newEmail);
    },
    isValidUpdatedEmail() {
      return validateEmail(this.editEmailDialog.newEmail);
    },
    notAdminUser() {
      return !this.user.isAdmin;
    },
    userProperties() {
      return [
        { name: 'Name', value: this.user.name || 'Not set' },
        { name: 'Email', value: this.user.email },
        { name: 'Status', value: this.user.status },
        { name: 'Account Locked', value: this.user.account_locked },
        { name: 'Logged In', value: this.user.activated },
        { name: 'Force Microsoft SSO', value: this.user.force_microsoft_sso },
      ];
    },
    studentProperties() {
      const student = this.user.student;
      return [
        {
          name: 'Institution',
          value: student.institution.name,
          click: () => {
            this.$router.push({
              name: 'AdminInstitutionPage',
              params: { institutionId: student.institution.id },
            });
          },
        },
        {
          name: 'Cohort',
          value: student.cohort?.name + ` (id: ${student.cohort?.id})`,
          click: () => {
            this.$router.push({
              name: 'AdminInstitutionCohortPage',
              params: { institutionId: student.institution.id, cohortId: student.cohort?.id },
            });
          },
        },
        {
          name: 'ECT?',
          value: student.ect ? 'Yes' : 'No',
        },
        ...(student.ect
          ? [
              {
                name: 'ECT Status',
                value: student.ect.status,
              },
              {
                name: 'ECT School',
                value: student.ect.current_school?.display_name || 'No current school',
              },
              {
                name: 'ECT QTS Check Date',
                value: student.ect.qts_checked_at || 'Not checked',
              },
            ]
          : []),
        {
          name: 'Storage Email',
          value: student.storage_email,
          action: student.storage_email
            ? {
                click: () => this.resetStudentStorageData(student.id),
                processing: this.processingResetStorageData,
                buttonText: 'Reset Storage Data',
                buttonIcon: null,
                helpText: `This will reset this student's cloud storage data for this institution and force them to relink Mosaic to it. It won't reset their files and can be used to force a refresh of the storage credentials.`,
              }
            : null,
        },
        { name: 'Storage Type', value: student.institution.storage_type },
        { name: 'Storage Set Up', value: student.storage_set_up },
        {
          name: 'Storage Folder Id',
          value: student.storage_folder_id,
          action: student.storage_folder_id
            ? {
                click: () => this.resetStorageFolder(student.id),
                processing: this.processingResetStorageData,
                buttonText: 'Reset Storage Data and Folder',
                buttonIcon: 'mdi-alert',
                helpText: `This will reset the user's storage email, authentication credentials and folder. This will force them to relink Mosaic to their cloud storage and a new Mosaic folder will be created for them. This should only be done if the user has not uploaded any files.`,
              }
            : null,
        },
        { name: 'Institution On Demand Sharing', value: student.institution.on_demand_sharing },
        {
          name: 'Student On Demand Sharing',
          value: student.on_demand_sharing ?? 'Not set (takes institution value)',
        },
      ];
    },
    staffProperties() {
      return this.user.staff.map(st => [
        {
          name: 'Staff Id',
          value: st.id,
        },
        {
          name: 'Institution',
          value: st.institution.name,
          click: () => {
            this.$router.push({
              name: 'AdminInstitutionPage',
              params: { institutionId: st.institution.id },
            });
          },
        },
        ...(st.institution.storage_type !== 'S3'
          ? [
              {
                name: 'Storage Email',
                value: st.storage_email,
              },
            ]
          : []),
        { name: 'Storage Type', value: st.institution.storage_type },
        { name: 'Storage Set Up', value: st.storage_set_up },
      ]);
    },
    canSubmitResetPassword() {
      return (
        !this.resetPasswordDialog.processing &&
        !!this.resetPasswordDialog.newPassword &&
        !!this.resetPasswordDialog.newPasswordConfirmation
      );
    },
  },
  async created() {
    this.userId = this.$route.params.userId;
    await this.loadUser();
  },
  methods: {
    async loadUser() {
      try {
        const r = await this.$api.get(`admin/users/${this.userId}`);
        this.user = r.data;
        this.onDemandSharing =
          this.user.student?.on_demand_sharing === null ? 'notSet' : this.user.student?.on_demand_sharing;
        this.debugLogging = this.user.debug_logging;
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot load this user';
      }
    },
    async resetStaffStorageData(propertyStaff) {
      const staff = this.user.staff.find(st => (st.id = propertyStaff.find(x => x.name === 'Staff Id')?.value));
      this.processingResetStorageData = true;
      try {
        await this.$api.post(`/admin/staff/${staff.id}/reset-storage-data`);
        await this.loadUser();
        this.snackbar = {
          message: 'Storage email reset',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: 'Error resetting storage email',
          status: true,
          color: 'error',
        };
      }
      this.processingResetStorageData = false;
    },
    async resetStudentStorageData(studentId) {
      this.processingResetStorageData = true;
      try {
        await this.$api.post(`/admin/students/${studentId}/reset-storage-data`);
        await this.loadUser();
        this.snackbar = {
          message: 'Storage data reset',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: 'Error resetting storage data',
          status: true,
          color: 'error',
        };
      }
      this.processingResetStorageData = false;
    },
    updateUserStatus() {
      this.updateUserStatusDialog = {
        active: true,
        userName: this.user.name || this.email,
        userId: this.user.id,
        errorMessage: '',
        staffOrStudent: this.user.student ? 'Trainee/ECT' : 'Instructor',
        newStatus: this.user.status === 'archived' ? 'active' : 'archived',
        processing: false,
      };
    },
    editEmail() {
      this.editEmailDialog = {
        active: true,
        newEmail: this.user.email,
        processing: false,
        errorMessage: '',
      };
    },
    async submitEditEmail() {
      this.editEmailDialog.processing = true;
      this.editEmailDialog.errorMessage = '';
      try {
        await this.$api.put(`users/${this.user.id}/update-email`, {
          email: this.editEmailDialog.newEmail,
        });
        await this.loadUser();
        this.editEmailDialog.active = false;
      } catch (e) {
        this.editEmailDialog.errorMessage = "Sorry, can't update email right now";
      }
      this.editEmailDialog.processing = false;
    },
    hasMultipleSameStorageInstitutions(user) {
      return (
        user.staff.filter(
          st => st.institution.storage_type === this.editStaffStorageEmailDialog.staff.institution.storage_type
        ).length > 1
      );
    },
    editStaffStorageEmail(propertyStaff) {
      const staff = this.user.staff.find(st => (st.id = propertyStaff.find(x => x.name === 'Staff Id')?.value));
      this.editStaffStorageEmailDialog = {
        active: true,
        newEmail: '',
        processing: false,
        errorMessage: '',
        staff: staff,
      };
    },
    async submitEditStaffStorageEmail() {
      this.editStaffStorageEmailDialog.processing = true;
      this.editStaffStorageEmailDialog.errorMessage = '';

      try {
        await this.$api.put(`staff/${this.editStaffStorageEmailDialog.staff.id}/set-storage-email`, {
          storage_email: this.editStaffStorageEmailDialog.newEmail,
        });
        await this.loadUser();
        this.editStaffStorageEmailDialog.active = false;
      } catch (e) {
        this.editStaffStorageEmailDialog.errorMessage = "Sorry, can't update storage email right now";
      }
      this.editStaffStorageEmailDialog.processing = false;
    },
    async submitUpdateUserStatus() {
      this.updateUserStatusDialog.processing = true;
      try {
        await this.$api.put(`users/${this.updateUserStatusDialog.userId}/update-user-status`, {
          status: this.updateUserStatusDialog.newStatus,
        });
        await this.loadUser();
        this.updateUserStatusDialog.active = false;
      } catch (e) {
        this.updateUserStatusDialog.errorMessage = "Sorry, can't update user status right now";
      }
      this.updateUserStatusDialog.processing = false;
    },
    async resetStorageFolder(studentId) {
      this.processingResetStorageData = true;
      try {
        await this.$api.post(`/admin/students/${studentId}/reset-storage-folder`);
        await this.loadUser();
        this.snackbar = {
          message: 'Storage data and folder reset',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: 'Error resetting storage data and folder',
          status: true,
          color: 'error',
        };
      }
      this.processingResetStorageData = false;
    },
    async toggleEmailVerification() {
      this.processingEmailVerification = true;
      try {
        await this.$api.post(`admin/users/${this.user.id}/set-email-verification-status`, {
          verification_status: !this.user.email_verified,
        });
        await this.loadUser();
      } catch (e) {
        this.snackbar = {
          message: 'Error updating email verification status',
          status: true,
          color: 'error',
        };
      }
      this.processingEmailVerification = false;
    },
    async impersonate() {
      this.processingImpersonate = true;
      try {
        const response = await this.$api.post(`/impersonate`, { target_id: this.user.id });
        impersonate(response.data.auth_token);
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: 'Error impersonating',
          status: true,
          color: 'error',
        };
      }
      this.processingImpersonate = false;
    },
    async resetPassword() {
      const password = await generateStongPassword(this.$api, this.user);
      this.resetPasswordDialog = {
        active: true,
        processing: false,
        newPassword: password,
        newPasswordConfirmation: password,
      };
    },
    async submitResetPassword() {
      this.resetPasswordDialog.processing = true;
      try {
        await this.$api.put(`/users/${this.user.id}/admin-reset-password`, {
          password: this.resetPasswordDialog.newPassword,
          password_confirmation: this.resetPasswordDialog.newPasswordConfirmation,
        });
        this.resetPasswordDialog.processing = false;
        this.resetPasswordDialog.active = false;
      } catch (e) {
        console.log(e);
        this.resetPasswordDialog.errorMessage =
          (e.response && e.response.data && e.response.data.message) || `Sorry, cannot reset password at the moment`;
      }
    },
    async onDemandSharingChanged(x) {
      if (x === 'null') x = null;
      if (x === this.user.student.on_demand_sharing) return;
      try {
        await this.$api.put(`/admin/users/${this.userId}/student`, { on_demand_sharing: x });
        await this.loadUser();
        this.snackbar = {
          message: 'On demand sharing override updated',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: 'Error updating on demand sharing override',
          status: true,
          color: 'error',
        };
        await this.loadUser();
      }
      this.previousOnDemandSharing = x;
    },
    async debugLoggingChanged(x) {
      if (x === this.user.debug_logging) return;
      try {
        await this.$api.put(`/admin/users/${this.userId}`, { debug_logging: x });
        await this.loadUser();
        this.snackbar = {
          message: 'Debug logging updated',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: 'Error updating debug logging',
          status: true,
          color: 'error',
        };
        await this.loadUser();
      }
      this.previousOnDemandSharing = x;
    },
    async unlockAccount() {
      this.lockAccountProcessing = true;
      try {
        await this.$api.post(`/admin/users/${this.userId}/unlock`);
        await this.loadUser();
        this.snackbar = {
          message: 'Account unlocked',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: 'Error unlocking account',
          status: true,
          color: 'error',
        };
      }
      this.lockAccountProcessing = false;
    },
    async lockAccount() {
      this.lockAccountProcessing = true;
      try {
        await this.$api.post(`/admin/users/${this.userId}/lock`);
        await this.loadUser();
        this.snackbar = {
          message: 'Account locked',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: 'Error locking account',
          status: true,
          color: 'error',
        };
      }
      this.lockAccountProcessing = false;
    },
    async resetTraInformation() {
      this.resetTraInformationProcessing = true;
      try {
        await this.$api.post(`/admin/users/${this.userId}/reset-tra-information`);
        await this.loadUser();
        this.snackbar = {
          message: 'ECT TRA information reset. Will be checked on the next crawler run.',
          status: true,
          color: 'success',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: 'Error resetting ECT TRA information',
          status: true,
          color: 'error',
        };
      }
      this.resetTraInformationProcessing = false;
    },
  },
};
</script>

<style scoped>
td {
  vertical-align: middle;
}
</style>
