import { defineStore } from 'pinia';
import { useStore } from './common';
import { ref, watch } from 'vue';
import { useRoute, useRouter, type LocationQuery } from 'vue-router';

// The queryStore allows changes from multiple components to simultaneously sync their state to the query by serialising access to the router
// Without the queryStore the syncQueryParamsMixin is various components would overwrite each other.

// This shouldn't be used directly by components. Options API components should use the syncQueryParamsMixin and
// composition API components should use syncQueryParam() composable.

// The mixin/composable is responsible for transforming the component data into a string for the store.
type QueryParams = {
  [key: string]: string | undefined;
};
export const useQueryStore = useStore(
  defineStore('query', () => {
    const queryParams = ref<QueryParams>({});

    function reset() {
      // queryParams shouldn't be reset on logout
    }

    const route = useRoute();
    const router = useRouter();

    updateQueryParams(route.query);

    watch(queryParams, updateRoute);
    watch(() => route.query, updateQueryParams);

    function updateRoute(params: QueryParams) {
      const query = { ...route.query };
      for (const [key, value] of Object.entries(params)) {
        if (value === undefined) {
          delete query[key];
        } else {
          query[key] = `${value}`;
        }
      }

      const newRoute = { ...route, query };
      router.replace(newRoute);
    }

    function updateQueryParams(q: LocationQuery) {
      const query = { ...queryParams.value };

      for (const key of Object.keys(query)) {
        if (!(key in q)) {
          delete query[key];
        }
      }

      for (const [key, value] of Object.entries(q)) {
        query[key] = `${value}`;
      }

      queryParams.value = query;
    }

    return { reset, queryParams };
  })
);
