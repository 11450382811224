<template>
  <div>
    <v-card v-if="!stateProcessing && stateError">
      <v-card-text> Sorry, cannot load your Cohorts at the moment </v-card-text>
    </v-card>
    <template v-if="!stateProcessing && !stateError">
      <mosaic-list-filters-card title="Cohorts">
        <template #actions>
          <v-btn v-if="!selectedInstitution.config.early_careers" ripple @click.prevent="addCohort()">
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>Cohort</span>
            </div>
          </v-btn>
        </template>

        <template #filters>
          <v-text-field
            v-model="filterTerm"
            hide-details
            style="width: 300px"
            label="Filter Cohorts by name"
            prepend-icon="mdi-magnify"
          />
          <mosaic-checkbox
            v-model="showClosedCohorts"
            class="mt-0"
            density="compact"
            no-icon
            label="Show closed Cohorts"
          />
        </template>
      </mosaic-list-filters-card>
      <v-card>
        <v-card-text>
          <mosaic-list v-if="filteredCohorts.length > 0" :items="filteredCohorts">
            <template #item="{ item: c }">
              <mosaic-list-item
                ripple
                :to="{
                  name: selectedInstitution.config.mentor_training_only
                    ? mentorTrainingRedirectsTo(userStaff, {
                        id: c.id,
                        institution_id: this.selectedInstitution.id,
                      })
                    : 'TutorStudentListPage',
                  params: { cohortId: c.id },
                }"
                icon="mdi-google-classroom"
                :title="c.name"
              >
                <template #information>
                  <div v-if="c.status === 'closed'" class="ml-2">
                    <v-chip color="secondary">Closed</v-chip>
                  </div>
                </template>

                <template #actions>
                  <mosaic-icon-btn
                    v-if="!selectedInstitution.config.early_careers"
                    icon="mdi-content-copy"
                    tooltip="Copy Cohort"
                    @click.prevent="copyCohort(c)"
                  ></mosaic-icon-btn>
                  <ndt-icon-button
                    v-if="c.status === 'active'"
                    icon="archive-lock"
                    tooltip="Close Cohort"
                    @click.prevent="closeCohort(c)"
                  />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
          <div v-else>There are no Cohorts for these filters.</div>
        </v-card-text>
      </v-card>
    </template>
    <ndt-dialog v-model:active="closeCohortDialog.active" :error-message="closeCohortDialog.error" title="Close Cohort">
      <div v-if="closeCohortDialog.cohort">
        <div class="mb-2">Are you sure you want to close "{{ closeCohortDialog.cohort.name }}"?</div>
        <div class="mb-2">
          Closing this Cohort will:
          <ul v-if="selectedInstitution.config.mentor_training_only">
            <li>
              remove all links between Instructors in this Cohort, which means Instructors will no longer be assigned to
              Training Frameworks via this Cohort
            </li>
          </ul>
          <ul v-else>
            <li>
              prevent {{ traineeNounCapitaliseAndPluralised }} in this Cohort from logging in, with immediate effect
            </li>
            <li>
              remove all links between Instructors and {{ traineeNounCapitaliseAndPluralised }} in this Cohort, which
              means only Admins will be able to access these {{ traineeNounCapitaliseAndPluralised }}
            </li>
            <li>remove all Instructors from Forums in this Cohort</li>
            <ul>
              <li>
                this Cohort has
                {{ lowercaseFirstLetter(enumerateItems(closeCohortDialog.cohort.forum_count, 'Forum')) }}
                <mosaic-warning-icon
                  v-if="closeCohortDialog.cohort.forum_count > 0"
                  tooltip="If you do not want Instructors to be removed from these Forums then get in touch with support."
                />
              </li>
            </ul>
          </ul>
        </div>
        <div>Currently re-opening a Cohort can only be done by contacting support.</div>
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="closeCohortDialog.processing"
          @click.prevent="submitCloseCohort()"
          >Close Cohort</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { mapState } from 'vuex';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { mapStateError, mapStateProcessing } from '@/store/map-store';
import { useQueryStore } from '@/stores/query';
import { useInstitutionStaffStore } from '@/stores/institution-staff';
import { getMentorTrainingPageRedirection } from '@/router/guard-methods';
const defaultCloseCohortDialog = {
  active: false,
  processing: false,
  cohort: null,
};

export default {
  name: 'TutorCohortListPage',
  mixins: [
    syncQueryParamsMixin({
      filterTerm: { query: 'name', type: 'string' },
      showClosedCohorts: { query: 'closed', type: 'boolean' },
    }),
  ],
  components: { NdtIconButton, NdtDialog },
  setup() {
    const {
      actions: { clearInstitutionStaff },
    } = useInstitutionStaffStore();
    const queryStore = useQueryStore();

    const mentorTrainingRedirectsTo = (u, p) => getMentorTrainingPageRedirection(u, p);

    return { clearInstitutionStaff, queryStore, mentorTrainingRedirectsTo };
  },
  data: () => ({
    error: null,
    filterTerm: '',
    showClosedCohorts: false,
    closeCohortDialog: {
      ...defaultCloseCohortDialog,
    },
  }),
  async created() {
    this.loadCohorts();
  },
  computed: {
    ...mapState(['selectedInstitution', 'cohorts', 'userStaff']),
    ...mapStateProcessing(['loadCohorts']),
    ...mapStateError(['loadCohorts']),
    title() {
      return `Cohorts`;
    },
    filteredCohorts() {
      return this.cohorts
        .filter(
          x =>
            x.name.toLowerCase().includes(this.filterTerm.toLowerCase()) &&
            (x.status !== 'closed' || this.showClosedCohorts)
        )
        .sortBy(['status', 'name']);
    },

    breadcrumbs() {
      return [
        {
          text: 'Cohorts',
        },
      ];
    },
  },
  methods: {
    async loadCohorts() {
      this.$store.dispatch('loadCohorts', { force: true });
    },
    addCohort() {
      this.$router.push({ name: 'TutorAdminCohortCreatePage' });
    },
    copyCohort(cohort) {
      this.$router.push({
        name: 'TutorAdminCohortCreatePage',
        query: { copying: true, copyCohortId: cohort.id },
      });
    },
    closeCohort(cohort) {
      this.closeCohortDialog.active = true;
      this.closeCohortDialog.cohort = cohort;
      this.closeCohortDialog.error = '';
    },
    async submitCloseCohort() {
      this.closeCohortDialog.processing = true;
      this.closeCohortDialog.error = '';
      try {
        await this.$api.post(`/cohorts/${this.closeCohortDialog.cohort.id}/close`);
        this.closeCohortDialog = {
          ...defaultCloseCohortDialog,
        };
        this.loadCohorts();
        this.clearInstitutionStaff();
      } catch (e) {
        console.log(e);
        this.closeCohortDialog.error = 'Sorry, cannot close this Cohort at the moment';
      }
      this.closeCohortDialog.processing = false;
    },
  },
};
</script>
