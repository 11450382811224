<template>
  <mosaic-multi-section-card
    :title="staffTrainingCompletion.staffTrainingModule.name"
    :sections="moduleSectionsWithCompletion"
    :section-id="sectionId"
    :can-save="canSave"
    :save="save"
    object-type="Training Module"
    :used-with-configure-preview="preview"
    @update:section-id="emit('update:section-id', $event)"
    @click-section="emit('click-section', $event)"
  >
    <template #title-chip>
      <v-chip :color="moduleStatus.color">
        {{ moduleStatus.text }}
      </v-chip>
    </template>
    <template #subtitle v-if="expectedDuration">
      {{ expectedDuration }}
    </template>
    <template #default>
      <mosaic-content-layout
        v-model:comments="comments"
        v-model:tasks="tasks"
        v-model:files="files"
        :content-layout="section.contentLayout"
        get-resource-url-prefix="/staff-training/resources"
        :readonly="props.readonly"
        :student-or-staff="{ type: 'staff', staff }"
        @complete="contentLayoutCompleted = $event"
        :preview="preview"
        :upload-file-presign-url="`/presign/staff/${userStaff.id}/staff-training-module-completion-files`"
        get-file-url-prefix="/staff/training-modules/sections/completion/files"
      />
    </template>

    <template #beside-buttons>
      <mosaic-checkbox
        v-model="completed"
        name="completed"
        label="I confirm that I have read the content and completed any actions required of me"
        :disabled="!contentLayoutCompleted || props.readonly"
        density="compact"
        class="mb-2"
        no-icon
      />
    </template>
  </mosaic-multi-section-card>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import MosaicContentLayout from '@/components/mosaic-content-layout/MosaicContentLayout.vue';
import type {
  StaffTrainingModuleCompletionResponse,
  StaffTrainingModuleCompletionRequest,
} from '@/pages/staff-training/staff-training';
import {
  mapModuleStatus,
  staffTrainingModuleExpectedDuration,
  mapStaffTrainingModuleToSectionCompletions,
} from '@/pages/staff-training/staff-training';
import {
  createContentLayoutCompletionComments,
  createContentLayoutCompletionFiles,
  createContentLayoutCompletionTasks,
} from '@/utils/content-layout';
import MosaicMultiSectionCard from '@/components/library/mosaic-card/MosaicMultiSectionCard.vue';
import { useApi } from '@/composables/api';
import { useStaffTrainingStore, constructCompletionData } from '@/stores/staff-training';
import { useStaffStore, type SelectedStaff } from '@/stores/staff';
import type { UserStaff } from '@/store/map-store';

const props = defineProps<{
  staffTrainingCompletion: StaffTrainingModuleCompletionResponse;
  // sectionId is owned by the page component, to allow easier interacting with unsaved changes and the URL
  // (as we've not got a version of them for script setup), but it is potentially simpler in the long run
  // to have it owned by this component
  sectionId: number;
  readonly?: boolean;
  preview?: boolean;
  staff: UserStaff | SelectedStaff;
}>();

const emit = defineEmits<{
  (e: 'click-section', sectionId: number): void;
  (e: 'update:section-id', sectionId: number): void;
  (e: 'update:dirty', dirty: boolean): void;
  (e: 'update:staff-training-completion', completion: StaffTrainingModuleCompletionResponse): void;
}>();

const api = useApi();
const {
  actions: { loadStaffTraining },
} = useStaffTrainingStore();
const { userStaff } = useStaffStore();

const completion = computed(() => ({
  ...props.staffTrainingCompletion.staffTrainingModuleCompletion,
  ...constructCompletionData(props.staffTrainingCompletion.staffTrainingModuleCompletion),
  ...(props.staffTrainingCompletion.staffTrainingModuleCompletion
    ? {}
    : {
        staffTrainingModuleSectionCompletions: mapStaffTrainingModuleToSectionCompletions(
          props.staffTrainingCompletion.staffTrainingModule
        ),
      }),
}));
const module = computed(() => props.staffTrainingCompletion.staffTrainingModule);
const moduleStatus = computed(() => mapModuleStatus(completion.value.status));
const expectedDuration = computed(() => staffTrainingModuleExpectedDuration(module.value, ''));
const moduleSectionsWithCompletion = computed(() =>
  module.value.staffTrainingModuleSections.map(x => ({
    ...x,
    completed: !!completion.value.staffTrainingModuleSectionCompletions.find(
      s => s.staffTrainingModuleSectionId === x.id
    )?.completed,
    readonly: !!props.readonly,
  }))
);
const section = computed(() => {
  const s = module.value.staffTrainingModuleSections.find(s => s.id === props.sectionId);
  if (!s) {
    throw `Cannot find section with id ${props.sectionId}`;
  }
  return s;
});

const sectionCompletion = computed(() => {
  const s = completion.value.staffTrainingModuleSectionCompletions.find(
    s => s.staffTrainingModuleSectionId === props.sectionId
  );
  if (!s) {
    throw `Cannot find section completion with sectionId ${props.sectionId}`;
  }
  return s;
});

const contentLayoutCompleted = ref(false);
const completed = ref(false);
watchEffect(() => {
  completed.value = contentLayoutCompleted.value && sectionCompletion.value.completed;
});
const { comments, commentsDirty } = createContentLayoutCompletionComments(
  computed(() => sectionCompletion.value.staffTrainingModuleCompletionComments),
  c => c.staffTrainingModuleCommentId
);
const { tasks, tasksDirty } = createContentLayoutCompletionTasks(
  computed(() => sectionCompletion.value.staffTrainingModuleCompletionTasks),
  t => t.staffTrainingModuleTaskId
);
const { files, filesDirty } = createContentLayoutCompletionFiles(
  computed(() => sectionCompletion.value.staffTrainingModuleCompletionFiles),
  t => t.staffTrainingModuleFileUploadId
);

const dirty = computed(() => {
  const completedDirty = completed.value !== sectionCompletion.value.completed;
  return completedDirty || commentsDirty.value || tasksDirty.value || filesDirty.value;
});
watchEffect(() => emit('update:dirty', dirty.value));

const canSave = computed(() => dirty.value);

async function save(): Promise<void> {
  const r = await api.put<StaffTrainingModuleCompletionRequest, StaffTrainingModuleCompletionResponse>(
    `/staff/${props.staffTrainingCompletion.staff.id}/training-modules/sections/${props.sectionId}/completion`,
    {
      comments: comments.value.map(c => ({
        comment: c.comment,
        staffTrainingModuleCommentId: c.templateId,
      })),
      tasks: tasks.value.map(t => ({
        completed: t.completed,
        staffTrainingModuleTaskId: t.templateId,
      })),
      files: files.value.map(f => ({
        fileId: f.fileId,
        title: f.title,
        staffTrainingModuleFileUploadId: f.templateId,
      })),
      completed: completed.value,
    }
  );
  emit('update:staff-training-completion', r.data);
  await loadStaffTraining(props.staffTrainingCompletion.staff.id, true);
}
</script>

<style scoped>
.selected-section {
  background-color: rgb(var(--v-theme-secondary-lighten-1));
}

.border-right {
  border-right: rgb(var(--v-theme-secondary-lighten-1)) 3px solid;
}
.border-bottom {
  border-bottom: rgb(var(--v-theme-secondary-lighten-1)) 3px solid;
}

.mobile-section-link {
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.completed-spot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: rgb(var(--v-theme-primary));
}
.incomplete-spot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: rgb(var(--v-theme-accent));
}
</style>
