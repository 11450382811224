<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <ndt-dialog v-model:active="unsavedChangesDialog.active" :title="title" :on-close="onClose">
    <div v-if="unsavedChangesDialog.navigatingToLogin">
      Sorry, you have been automatically logged out. To save any in progress work, click "Cancel" and copy the contents
      into an external program, e.g. Microsoft Word or Google Docs. Then you should refresh your Mosaic browser tab.
    </div>
    <div v-else>You have unsaved changes. Would you still like to leave?</div>
    <mosaic-error-alert
      :action="`save ${objectTypeIsPlural ? 'these' : 'this'} ${objectType}`"
      :error="saveError"
      :override-error-message="customSaveError"
    />
    <template #buttons>
      <v-btn variant="text" ripple color="error" @click="leave()">Leave</v-btn>
      <v-btn
        v-if="save"
        variant="text"
        ripple
        color="primary"
        :loading="saveProcessing"
        :disabled="saveProcessing || !canSave"
        @click="saveAndLeave()"
        >Save and Leave</v-btn
      >
    </template>
  </ndt-dialog>
</template>

<script>
import NdtDialog from './NdtDialog.vue';
import { handleErrorCodes } from '@/composables/processing-and-errors';

export default {
  name: 'UnsavedChangesDialog',
  components: { NdtDialog },
  props: {
    objectType: {
      type: String,
      required: true,
    },
    objectTypeIsPlural: {
      type: Boolean,
      default: false,
    },
    unsavedChangesDialog: {
      type: Object,
      required: true,
    },
    save: {
      type: Function,
      default: undefined,
    },
    canSave: {
      type: Boolean,
      default: true,
    },
    customLeaveAction: {
      type: Function,
      default: () => null,
    },
    errorCodeMap: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:unsavedChangesDialog'],
  data: () => ({
    saveProcessing: false,
    saveError: false,
    customSaveError: '',
  }),
  computed: {
    title() {
      return `Unsaved ${this.objectType}`;
    },
  },
  methods: {
    leave() {
      this.$emit('update:unsavedChangesDialog', {
        ...this.unsavedChangesDialog,
        leaving: true,
      });
      if (this.unsavedChangesDialog.to) {
        this.$router.push(this.unsavedChangesDialog.to);
      } else {
        this.customLeaveAction();
      }
    },
    async saveAndLeave() {
      this.saveProcessing = true;
      this.saveError = false;
      this.customSaveError = '';

      try {
        const errorOrNoOp = await handleErrorCodes(this.save, this.errorCodeMap);
        if (!errorOrNoOp) {
          this.leave();
        } else if (errorOrNoOp === 'no-op') {
          // This is likely from the confirm delete dialog being cancelled
          // So do nothing and leave this dialog open
        } else {
          this.customSaveError = errorOrNoOp;
          this.saveError = true;
        }
      } catch (e) {
        console.log(e);
        this.saveError = true;
      }
      this.saveProcessing = false;
    },
    onClose() {
      this.saveError = false;
      this.customSaveError = '';
    },
  },
};
</script>
