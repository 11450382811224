import { useUnsavedChanges } from '@/composables/unsaved-changes';
import type { JudgementDescriptor, JudgementSet } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import type { ContentLayout } from '@/utils/content-layout';
import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import type { Api } from '@/composables/api';

export const studentAssignmentStatuses = [
  'not_started',
  'started',
  'completed',
  'reviewed',
  'reviewed_requires_changes',
] as const;
export type StudentAssignmentStatus = (typeof studentAssignmentStatuses)[number];
export interface StudentAssignment {
  id: number;
  status: StudentAssignmentStatus;
  reviewPageCompleted: boolean;
  reviewPageGradeJudgementDescriptor: JudgementDescriptor | null;
  versionNumber: number | null;
  latestVersionNumber: number | null;
  requiredChanges: string | null;
  previousVersionRequiredChanges: string | null;
  nextVersionStatus: StudentAssignmentStatus | null;
  studentId: number;
  budSubmission: {
    id: number;
    submittedAt: string;
    fileIds: string;
    fileName: string;
    budActivityId: string;
  } | null;
  curriculumStatements: {
    id: number;
    code: string;
    statement: string;
    curriculumThemeCode: string;
  }[];
  assignment: {
    id: number;
    name: string;
    dueDate: string;
    roleId: number | null;
    isTraineeContributor: boolean;
    goReactAssignments: {
      id: number;
      contentLayoutId: string;
    }[];
    assignmentTemplate: {
      hasGoReactAssignments: boolean;
      reviewPageGradeJudgementSet: JudgementSet | null;
      reviewPageContentLayout: ContentLayout;
      assignmentTemplateSections: {
        id: number;
        name: string;
        order: number;
        contentLayout: ContentLayout;
      }[];
    };
  };
  studentAssignmentReviewPageComments: { comment: string; assignmentTemplateReviewPageCommentId: string }[];
  studentAssignmentReviewPageTasks: { completed: boolean; assignmentTemplateReviewPageTaskId: string }[];
  studentAssignmentReviewPageFiles: {
    fileId: string;
    title: string;
    assignmentTemplateReviewPageFileUploadId: string;
  }[];
  studentAssignmentSections: {
    id: number;
    assignmentTemplateSectionId: number;
    completed: boolean;
    studentAssignmentSectionComments: { comment: string; assignmentTemplateSectionCommentId: string }[];
    studentAssignmentSectionTasks: { completed: boolean; assignmentTemplateSectionTaskId: string }[];
    studentAssignmentSectionFiles: { fileId: string; title: string; assignmentTemplateSectionFileUploadId: string }[];
  }[];
}

export function setupAssignmentPage(
  api: Api,
  listPageName: string,
  loadUrlStem: string,
  isStudent: boolean,
  versionNumber: ComputedRef<string | (string | null)[] | null>,
  canStaffReview: boolean = false
) {
  const studentAssignment = ref<StudentAssignment>();
  const sectionId = ref(0);

  setBreadcrumbs(
    computed(() => [
      {
        text: 'Course Activities',
        to: {
          name: listPageName,
        },
      },
      {
        text: studentAssignment.value?.assignment.name || '',
      },
    ])
  );

  const loadUrl = computed(() =>
    !versionNumber.value ? loadUrlStem : `${loadUrlStem}/versions/${versionNumber.value}`
  );
  const load = async () => {
    const r = await api.get<StudentAssignment>(loadUrl.value);
    studentAssignment.value = r.data;
    const sortedSections = studentAssignment.value.studentAssignmentSections.sortBy(
      s =>
        studentAssignment.value?.assignment.assignmentTemplate.assignmentTemplateSections.find(
          ats => ats.id === s.assignmentTemplateSectionId
        )?.order || 0
    );
    const firstIncompleteSection = sortedSections.find(s => !s.completed);
    if (isStudent) {
      sectionId.value =
        firstIncompleteSection?.assignmentTemplateSectionId ||
        studentAssignment.value.studentAssignmentSections[0].assignmentTemplateSectionId;
    } else {
      if (studentAssignment.value.status === 'completed' && canStaffReview) {
        sectionId.value = -1;
      } else {
        sectionId.value = sortedSections[0].assignmentTemplateSectionId;
      }
    }
  };

  const dirty = ref(false);
  const { dialog } = useUnsavedChanges(dirty);

  const onClickSectionId = ref(0);
  function onClickSection(id: number) {
    onClickSectionId.value = id;
    if (dirty.value) {
      dialog.value = {
        active: true,
        to: null,
        leaving: false,
        navigatingToLogin: false,
      };
    } else {
      changeSection();
    }
  }

  function changeSection() {
    sectionId.value = onClickSectionId.value;
    onClickSectionId.value = 0;
    dialog.value = {
      active: false,
      to: null,
      leaving: false,
      navigatingToLogin: false,
    };
  }

  return { studentAssignment, sectionId, load, dirty, dialog, onClickSection, changeSection };
}

export const studentAssignmentStatusItems: { title: string; value: StudentAssignmentStatus; order: number }[] = [
  { title: 'Not Started', value: 'not_started', order: 0 },
  { title: 'Started', value: 'started', order: 1 },
  { title: 'Completed', value: 'completed', order: 2 },
  { title: 'Reviewed', value: 'reviewed', order: 3 },
  { title: 'Reviewed (Requires Changes)', value: 'reviewed_requires_changes', order: 4 },
];

export function assignmentStatus(studentAssignments: StudentAssignment[], studentId: number) {
  const studentAssignment = studentAssignments.find(s => s.studentId === studentId);
  if (!studentAssignment) return;
  return studentAssignmentStatusItems.find(s => s.value === studentAssignment.status)?.title;
}
