import type { AxiosResponse, CancelToken } from 'axios';
import { getCurrentInstance, ref, type ComputedRef, type Ref } from 'vue';
import { isCreatingRoute } from './vue-router';

export type Api = {
  get<T>(url: string, cancelToken?: CancelToken): Promise<AxiosResponse<T, unknown>>;
  getAllPages<T extends { id: number }>(url: string, pageSize?: number, cancelToken?: CancelToken): Promise<T[]>;
  getWithoutAuth<T>(url: string, cancelToken?: CancelToken): Promise<AxiosResponse<T, unknown>>;
  pollGet<T>(
    url: string,
    options?: {
      cancelToken?: CancelToken;
      maxAttempts?: number;
      waitInMs?: number;
      retryOnErrorStatuses?: number[];
      retryOnSuccess?: (response: AxiosResponse<T, unknown>) => boolean;
    }
  ): Promise<AxiosResponse<T, unknown>>;
  post<Request, Response>(
    url: string,
    body: Request,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<Response, unknown>>;
  put<Request, Response>(url: string, body: Request): Promise<AxiosResponse<Response, unknown>>;
  putFile<T>(url: string, file: File, contentType: string): Promise<AxiosResponse<T, unknown>>;
  delete<T>(url: string): Promise<AxiosResponse<T, unknown>>;
  downloadAndOpen(url: string): Promise<void>;
};

export function useApi(): Api {
  const instance = getCurrentInstance();
  if (!instance) {
    throw 'You must use this function within the "setup()" method';
  }

  return instance.proxy!.$api;
}

export function createOrUpdate<TRequestBody, TResponseBody>(
  postUrl: ComputedRef<string>,
  putUrl: ComputedRef<string>,
  body: ComputedRef<TRequestBody>,
  responseRef: Ref<TResponseBody>
) {
  const isCreating = isCreatingRoute();
  const api = useApi();
  const isCreatingAndReturning = ref(false);

  async function save() {
    if (isCreating.value) {
      await api.post(postUrl.value, body.value);
      isCreatingAndReturning.value = true;
    } else {
      const r = await api.put<TRequestBody, TResponseBody>(putUrl.value, body.value);
      responseRef.value = r.data;
    }
  }

  return { save, isCreatingAndReturning };
}
