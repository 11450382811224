<template>
  <mosaic-loading-card v-if="stateProcessing" type="content-layout" />
  <div v-else>
    <div
      v-for="(section, i) of contentLayout.sections"
      :key="section.id"
      :class="{ 'mb-4': i < contentLayout.sections.length - 1 }"
    >
      <div v-if="section.heading" class="text-h6 mb-2">
        {{ section.heading }}
      </div>
      <div v-else-if="i === 0" class="pb-2"></div>

      <div v-for="item of section.items" :key="item.id" class="mb-4">
        <mosaic-content-layout-item
          :item="item"
          :section="section"
          :content-layout="contentLayout"
          :readonly="readonly"
          :get-resource-url-prefix="getResourceUrlPrefix"
          :get-file-url-prefix="getFileUrlPrefix"
          :upload-file-presign-url="uploadFilePresignUrl"
          :internal-comments="internalComments"
          :tasks="tasks"
          :files="files"
          :go-react-assignments="goReactAssignments"
          :video-srcs="videoSrcs"
          :get-video-src="() => getVideoSrc(item as ContentLayoutVideo)"
          :student-or-staff="studentOrStaff"
          :preview="preview"
          @comment-updated="commentUpdated($event)"
          @task-completed-updated="taskCompletedUpdated($event)"
          @file-added="fileAdded($event)"
          @file-removed="fileRemoved($event)"
          @file-uploading="filesUploading(item.id, $event)"
          @click-curriculum-statement="emit('clickCurriculumStatement', $event)"
          @reset-video-src="getVideoSrc($event)"
          v-model:refresh-nasbtt-modules-progress="refreshNasbttModulesProgress"
        />
      </div>
      <div v-if="i < contentLayout.sections.length - 1" class="px-4">
        <v-divider />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  ContentLayout,
  ContentLayoutVideo,
  ContentLayoutComment,
  ContentLayoutCompletionComment,
  ContentLayoutCompletionFile,
  ContentLayoutCompletionTask,
  ContentLayoutTask,
} from '@/utils/content-layout';
import { hasComment } from '@/utils/content-layout';
import { computed, ref, watch, toRef, watchEffect } from 'vue';
import type { CurriculumStatement, SelectedStudent, UserStaff, UserStudent } from '@/store/map-store';
import { mapActions } from '@/store/map-store';
import { mapStateProcessingAndError } from '@/store/map-state-error-and-processing';
import MosaicContentLayoutItem from './MosaicContentLayoutItem.vue';
import { useApi } from '@/composables/api';
import { useNasbttModulesStore } from '@/stores/nasbtt-modules';
import type { SelectedStaff } from '@/stores/staff';

const api = useApi();
const props = defineProps<{
  contentLayout: ContentLayout;
  getResourceUrlPrefix: string;
  getFileUrlPrefix?: string;
  uploadFilePresignUrl?: string;
  comments: ContentLayoutCompletionComment[];
  tasks: ContentLayoutCompletionTask[];
  files?: ContentLayoutCompletionFile[];
  goReactAssignments?: { id: number; contentLayoutId: string }[];
  readonly?: boolean;
  // This is currently only needed for NASBTT modules and should be the student or staff who is completing
  // the modules (as opposed to the staff viewing another staff's completion of the module)
  studentOrStaff?:
    | { type: 'student'; student: UserStudent | SelectedStudent }
    | { type: 'staff'; staff: UserStaff | SelectedStaff }
    | { type: 'preview' };
  preview?: boolean;
}>();

if (props.contentLayout.sections.some(s => s.items.some(i => i.itemType === 'fileUpload'))) {
  if (!props.uploadFilePresignUrl)
    throw 'props.uploadFilePresignUrl must be set as the content layout contains fileUploads';
  if (!props.getFileUrlPrefix) throw 'props.geFileUrlPrefix must be set as the content layout contains fileUploads';
  if (!props.files) throw 'props.files must be set as the content layout contains fileUploads';
}

if (
  props.contentLayout.sections.some(s =>
    s.items.some(i => i.itemType == 'nasbttMentorModule' || i.itemType == 'nasbttTraineeModule')
  )
) {
  if (!props.studentOrStaff) throw 'props.studentOrStaff must be set as the content layout contains NASBTT modules';
}

if (props.contentLayout.sections.some(s => s.items.some(i => i.itemType == 'goReactAssignment'))) {
  if (!props.goReactAssignments)
    throw 'props.goReactAssignments must be set as the content layout contains GoReact Assignments';
  if (!props.studentOrStaff)
    throw 'props.studentOrStaff must be set as the content layout contains GoReact Assignments';
}

const emit = defineEmits<{
  (e: 'complete', complete: boolean): void;
  (e: 'update:comments', comments: ContentLayoutCompletionComment[]): void;
  (e: 'update:tasks', tasks: ContentLayoutCompletionTask[]): void;
  (e: 'update:files', tasks: ContentLayoutCompletionFile[]): void;
  (e: 'filesUploading', uploading: boolean): void;
  (e: 'clickCurriculumStatement', cs: CurriculumStatement): void;
}>();

const { stateProcessing } = mapStateProcessingAndError('loadCurriculum');
const { loadCurriculum } = mapActions();
loadCurriculum();

const { nasbttModuleProgressCache } = useNasbttModulesStore();

// File uploads
const uploadingMap = ref<Record<string, boolean>>({});
watchEffect(() => {
  emit(
    'filesUploading',
    Object.values(uploadingMap.value).some(x => x)
  );
});
function filesUploading(id: string, uploading: boolean) {
  uploadingMap.value[id] = uploading;
}

function fileAdded(file: ContentLayoutCompletionFile) {
  emit('update:files', [...(props.files || []), file]);
}

function fileRemoved(file: ContentLayoutCompletionFile) {
  emit(
    'update:files',
    (props.files || []).filter(f => f.fileId !== file.fileId)
  );
}

// Comments
// internalComments is required to allow setting the placeholders on component creation, but not updating the comments prop
// the comments prop is only updated when the user modifies a placeholder (and only that comment should be updated and not any unmodified placeholders)
const internalComments = ref<ContentLayoutCompletionComment[]>([]);
function setInternalComments() {
  internalComments.value = props.comments.map(c => {
    let comment = c.comment;
    if (!comment) {
      let layoutComment = null;
      for (const section of props.contentLayout.sections) {
        layoutComment = section.items.find(i => i.id === c.templateId) as ContentLayoutComment;
        if (layoutComment) {
          comment = layoutComment.placeholder;
          break;
        }
      }
    }
    return {
      ...c,
      comment,
    };
  });
}
setInternalComments();
watch(toRef(props, 'comments'), () => setInternalComments());

const completed = computed(() => {
  const itemsApartFromTasksCompleted = props.contentLayout.sections.every(s =>
    s.items.every(i => {
      if (i.itemType === 'comment') {
        return hasComment(i, props.contentLayout, internalComments.value);
      }

      if (i.itemType === 'fileUpload') {
        const files = (props.files || []).filter(f => f.templateId == i.id);
        return files.length >= i.minFiles;
      }

      if (i.itemType === 'nasbttMentorModule' || i.itemType === 'nasbttTraineeModule') {
        if (!props.studentOrStaff || props.studentOrStaff.type == 'preview') return false;
        const keyPrefix =
          props.studentOrStaff.type == 'staff'
            ? `staff/${props.studentOrStaff.staff.id}`
            : `students/${props.studentOrStaff.student.id}`;
        return nasbttModuleProgressCache.value.getEvenIfExpired(`${keyPrefix}/${i.publicationCode}`)?.completed;
      }

      return true;
    })
  );
  return itemsApartFromTasksCompleted && props.tasks.every(t => t.completed);
});
watchEffect(() => emit('complete', completed.value));

function commentUpdated({ commentId, comment }: { commentId: string; comment: string }) {
  internalComments.value = internalComments.value.map(c => {
    if (c.templateId === commentId) {
      return { ...c, comment };
    }
    return c;
  });

  emit(
    'update:comments',
    props.comments.map(c => {
      if (c.templateId === commentId) {
        return { ...c, comment };
      }
      return c;
    })
  );
}

// Videos
const videoSrcs = ref(defaultVideoSrcs());
function defaultVideoSrcs() {
  const videoSrcs: Record<string, string> = {};
  props.contentLayout.sections.forEach(s =>
    s.items
      .filter(i => i.itemType === 'video')
      .forEach(v => {
        const video = v as ContentLayoutVideo;
        videoSrcs[video.resourceId] = '';
        getVideoSrc(video);
      })
  );
  return videoSrcs;
}

async function getVideoSrc(video: ContentLayoutVideo) {
  try {
    const r = await api.get<{ web_url: string }>(`${props.getResourceUrlPrefix}/${video.resourceId}?download=false`);
    videoSrcs.value[video.resourceId] = r.data.web_url;
    return r.data.web_url;
  } catch (e) {
    console.log(e);
  }
}

// Tasks
function taskCompletedUpdated({ task, completed }: { task: ContentLayoutTask; completed: boolean }) {
  emit(
    'update:tasks',
    props.tasks.map(t => {
      if (t.templateId === task.id) {
        return { ...t, completed };
      }
      return t;
    })
  );
}

// NASBTT Modules
const refreshNasbttModulesProgress = ref(false);
</script>
