<template>
  <div>
    <cohort-student-action-edit-page
      v-model:selected-student-ids="selectedStudentIds"
      action-noun="Course Reflection"
      :action-set-date="reflection.set_date"
      :action-scheduled-at-date="scheduledDate"
      :action-schedule-in-the-future="scheduleInTheFuture"
      :has-students-with-updated-status="hasStudentsWithUpdatedStatus"
      :load="load"
      :can-continue="canContinue"
      :save="save"
      :return-to="returnTo"
      :details-dirty="detailsDirty"
      :dirty="dirty"
      :is-editing="isEditing"
      :students="selectedCohortStudents"
      :initially-selected-student-ids="reflection.admin_reflection_students.map(s => s.student_id)"
      :can-delete-student-without-confirmation="canDeleteStudentWithoutConfirmation"
      :action-status-items="reflectionStatusItems"
      :get-action-status="id => reflectionStatus(reflection.admin_reflection_students, id)"
    >
      <template #details-step>
        <div>
          <v-text-field v-model="title" class="mb-4" label="Title" hide-details autofocus />
          <v-textarea
            v-model="prompt"
            class="mb-4"
            :placeholder="'What should the ' + traineeNounCapitalisedAndPluralised + ' be reflecting on?'"
            autofocus
            auto-grow
            label="Prompt"
            type="text"
            rows="1"
            hide-details
          />

          <div class="text-subtitle-1 mt-4">Template</div>
          <mosaic-quill v-model:contents="template" :read-only="false"></mosaic-quill>
        </div>
        <template v-if="curriculumEnabled">
          <curriculum-links
            class="mt-4"
            :selected-curriculum-statements="curriculumStatements"
            :can-edit="scheduleEditable"
            artefact-type="Course Reflection"
            @update:link-added="addCurriculumLink"
            @update:link-removed="removeCurriculumLink"
          >
          </curriculum-links>
        </template>
        <template v-if="scheduleEditable">
          <mosaic-card-heading class="mt-4">Schedule</mosaic-card-heading>
          <schedule-in-the-future-picker
            v-if="!isEditing || status == 'scheduled'"
            v-model:schedule-in-the-future="scheduleInTheFuture"
            v-model:scheduled-date="scheduledDate"
          />
        </template>
      </template>

      <template #action-assignment-status-badge="{ assignee }">
        <course-reflection-student-status-chip
          :status="reflectionStatus(reflection.admin_reflection_students, assignee.id)"
        />
      </template>
      <template #reset-student-action-status-prompt>
        <template v-if="isEditing && detailsDirty && hasStudentsWithUpdatedStatus">
          <mosaic-card-subheading>Updated Details</mosaic-card-subheading>
          <div>
            You have edited some of the details of this Course Reflection and some {{ traineeNounPluralised }} have
            already completed their reflections.
          </div>
          <div class="pt-2">Would you like to undo the completion of these {{ traineeNoun }} Reflections?</div>
          <mosaic-checkbox
            v-model="resetStudentActionStatus"
            :label="`Reset ${traineeNoun} reflection statuses`"
            no-icon
        /></template>
      </template>
    </cohort-student-action-edit-page>
  </div>
</template>

<script setup lang="ts">
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import CohortStudentActionEditPage from '@/components/cohort-actions/CohortStudentActionEditPage.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { ref } from 'vue';
import { institutionTimeTomorrowAsDate } from '@/utils/time';
import { mapGetters } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { createReturnTo } from '@/components/cohort-actions/cohort-actions';
import { useCohortStore } from '@/stores/cohort';
import ScheduleInTheFuturePicker from '@/components/cohort-actions/ScheduleInTheFuturePicker.vue';
import { parseRouteId } from '@/composables/vue-router';
import CourseReflectionStudentStatusChip from './CourseReflectionStudentStatusChip.vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import type { CourseReflection } from './course-reflections';
import { reflectionStatusItems, reflectionStatus } from './course-reflections';
import { todaysDate } from '@/mixins/global-mixins';
import { useApi } from '@/composables/api';
import { useCurriculumLinks } from '@/frameworks/curriculum/curriculum-links';
import { useInstitutionStore } from '@/stores/institution';
import { useStudentStore } from '@/stores/student';

const api = useApi();
const { traineeNoun, traineeNounPluralised } = useStudentStore();

// Setup
const route = useRoute();
const isEditing = computed(() => route.name !== 'CohortCourseReflectionCreatePage');
const reflectionId = parseRouteId('id');

const reflection = ref<CourseReflection>({
  id: -1,
  admin_reflection_students: [],
  created_at: '',
  curriculum_statements: [],
  title: '',
  prompt: '',
  template: '',
  schedule_created_date: '',
  scheduled_at_date: '',
  selected_all: false,
  set_date: '',
});
const status = computed<'live' | 'scheduled'>(() => (reflection.value.set_date ? 'live' : 'scheduled'));

const { selectedInstitution } = useInstitutionStore();
const { curriculumEnabled } = mapGetters();
const { selectedCohortStudents, selectedCohort } = useCohortStore();

// Fields
const defaultTitle = `Course Reflection - ${todaysDate()}`;
const title = ref(defaultTitle);
const prompt = ref('');
const defaultTemplate = selectedInstitution.value.config.course_reflection_template || '';
const template = ref(defaultTemplate);

const scheduleInTheFuture = ref(false);
const scheduledDate = ref<string>(institutionTimeTomorrowAsDate(selectedInstitution.value.config.region));

const scheduleEditable = computed(() => !isEditing.value || status.value !== 'live');

const canContinue = computed(
  () => !!title.value && !!prompt.value && (!scheduleInTheFuture.value || !!scheduledDate.value)
);

const selectedStudentIds = ref<number[]>([]);

const returnTo = createReturnTo('CohortCourseReflectionsPage', scheduleInTheFuture, status);

setBreadcrumbs(
  computed(() => [
    {
      text: `Course Reflections`,
      to: returnTo.value,
    },
    {
      text: isEditing.value ? reflection.value.title : `New Course Reflection`,
    },
  ])
);

// Load
async function load() {
  if (!isEditing.value) return;

  const r = await api.get<CourseReflection>(`admin-reflections/${reflectionId.value}`);
  reflection.value = r.data;
  title.value = r.data.title;
  prompt.value = r.data.prompt;
  template.value = r.data.template;
  selectedStudentIds.value = r.data.admin_reflection_students.map(s => s.student_id);
  scheduleInTheFuture.value = !!r.data.scheduled_at_date;
  scheduledDate.value = r.data.scheduled_at_date;
}

const { curriculumStatements, addCurriculumLink, removeCurriculumLink } = useCurriculumLinks(reflection);

// Dirty
const isCreatingAndReturning = ref(false);
const dirty = computed(() => {
  const r = reflection.value;
  if (isEditing.value) {
    const scheduledDirty = r.scheduled_at_date
      ? !scheduleInTheFuture.value || r.scheduled_at_date !== scheduledDate.value
      : scheduleInTheFuture.value;
    const selectedStudentsDirty =
      selectedStudentIds.value.length !== r.admin_reflection_students.length ||
      r.admin_reflection_students.map(s => s.student_id).some(id => !selectedStudentIds.value.includes(id));
    return detailsDirty.value || scheduledDirty || selectedStudentsDirty;
  } else {
    if (isCreatingAndReturning.value) return false;
    return detailsDirty.value || scheduleInTheFuture.value || selectedStudentIds.value.length > 0;
  }
});

const detailsDirty = computed(() => {
  const r = reflection.value;
  if (isEditing.value) {
    const curriculumStatementIds = curriculumStatements.value.map(cs => cs.id);
    const curriculumStatementsDirty =
      curriculumStatements.value.length !== r.curriculum_statements.length ||
      r.curriculum_statements.some(cs => !curriculumStatementIds.includes(cs.id));
    return (
      title.value !== r.title || prompt.value !== r.prompt || template.value !== r.template || curriculumStatementsDirty
    );
  } else if (isCreatingAndReturning.value) return false;
  return (
    (!!title.value && title.value != defaultTitle) ||
    !!prompt.value ||
    (!!template.value && template.value != defaultTemplate) ||
    curriculumStatements.value.length > 0
  );
});

// Save
const resetStudentActionStatus = ref(false);
async function save() {
  const requestBody = {
    title: title.value,
    prompt: prompt.value,
    template: template.value,
    studentIds: selectedStudentIds.value,
    curriculumStatementIds: curriculumStatements.value.map(s => s.id),
    scheduledDate: scheduleInTheFuture.value && scheduleEditable.value ? scheduledDate.value : null,
    selectedAll: selectedStudentIds.value.length === selectedCohortStudents.value.length,
    resetStudentActionStatus: resetStudentActionStatus.value,
  };

  if (isEditing.value) {
    const r = await api.put<unknown, CourseReflection>(`/admin-reflections/${reflectionId.value}`, requestBody);
    reflection.value = r.data;
  } else {
    await api.post(`/cohorts/${selectedCohort.value.id}/admin-reflections`, requestBody);
    isCreatingAndReturning.value = true;
  }
}

function canDeleteStudentWithoutConfirmation(id: number) {
  return reflectionStatus(reflection.value.admin_reflection_students, id) === 'not_started';
}
//  Ignore students about to be removed in this check
const hasStudentsWithUpdatedStatus = computed(() =>
  reflection.value.admin_reflection_students.some(s => s.submitted && selectedStudentIds.value.includes(s.student_id))
);
</script>
