<template>
  <div>
    <div class="d-flex justify-center mb-2">
      <v-btn-toggle v-model="previewToggle" class="pr-2" color="orange" mandatory variant="outlined" divided>
        <v-btn class="px-6">
          Configure
          <v-icon class="pl-2" dark>mdi-pencil</v-icon>
        </v-btn>
        <v-btn class="px-6">
          Preview
          <v-icon class="pl-2" dark>mdi-eye</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>

    <div v-if="previewToggle === 0" class="pb-1">
      <div>
        Mentor Meetings within Mosaic can have their structure customised by changing this template. Changes to the
        template will only apply to future Mentor Meetings. Mentor Meetings have a few static parts that cannot
        currently be configured e.g. meeting date.
      </div>
      <div class="text-h6 pt-4">Template</div>
      <mosaic-content-layout-builder
        v-model:content-layout="contentLayout"
        :get-resource-url-prefix="`/mentor-meeting-template/resources`"
        :presign-url="`/presign/cohorts/${selectedCohort.id}/mentor-meeting-template-resources`"
        :save-resource-url="`/cohorts/${selectedCohort.id}/mentor-meeting-template/resources`"
        :save-resource-params="{}"
        :supported-item-types="[
          'text',
          'resource',
          'comment',
          'taskList',
          'link',
          'image',
          'columns',
          'video',
          'youtube',
        ]"
        @valid="contentLayoutValid = $event"
      />
      <div class="text-h6 pt-4">Add a File</div>
      <mosaic-checkbox
        v-model="allowFileUpload"
        density="compact"
        no-icon
        class="pl-4"
        name="allow-file-upload"
        :label="`Allow ${traineeNounCapitalisedAndPluralised} to add files`"
      />
      <v-alert v-if="suggestProForma" density="compact" variant="outlined" class="my-4 mx-4" type="info"
        >Consider adding a pro forma for your Mentor Meetings in the "Template" section</v-alert
      >
      <mosaic-snackbar v-model="saveSnackbar.active" color="success" :message="saveSnackbar.message" />

      <mosaic-error-alert :override-error-message="saveError" />
      <div class="d-flex justify-end mt-2">
        <v-btn :disabled="!canSave" color="primary" @click.prevent="save()">Save</v-btn>
      </div>
    </div>
    <div v-else class="pa-1">
      <mentor-meeting-card
        :mentor-meeting="null"
        :mentor-meeting-template="mentorMeetingTemplate"
        :editable="true"
        targets-url="/"
        mentor-meetings-url="/"
        :target-clicked-to="() => {}"
        :can-add-targets="false"
      />
    </div>
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="unsavedChangesDialog"
      object-type="Mentor Meeting Template"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MentorMeetingCard from '@/pages/mentor-meetings/MentorMeetingCard.vue';
import MosaicContentLayoutBuilder from '../../components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import { fromSnakeCaseToCamelCase } from '../../utils/transforms';
import unsavedChangesMixin from '../../components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';

export default {
  name: 'TutorMentorMeetingTemplatePage',
  components: { MosaicContentLayoutBuilder, MentorMeetingCard, UnsavedChangesDialog },
  mixins: [unsavedChangesMixin],
  ...unsavedChangesMixin,
  data: () => ({
    saveError: null,
    processing: false,
    previewToggle: 0,
    contentLayout: null,
    contentLayoutValid: true,
    allowFileUpload: false,
    saveSnackbar: {
      active: false,
      message: '',
    },
  }),
  computed: {
    ...mapState(['selectedCohort']),
    breadcrumbs() {
      return [
        {
          text: 'Mentor Meeting Template',
        },
      ];
    },
    mentorMeetingTemplate() {
      return {
        contentLayout: this.contentLayout,
        allowFileUpload: this.allowFileUpload,
      };
    },
    canSave() {
      return (
        !this.processing &&
        (this.allowFileUpload || this.contentLayout?.sections.length > 0) &&
        this.dirtyComputed &&
        this.contentLayoutValid
      );
    },
    dirtyComputed() {
      const contentLayoutDirty =
        JSON.stringify(this.contentLayout) !==
        JSON.stringify(fromSnakeCaseToCamelCase(this.selectedCohort.mentor_meeting_template.content_layout));

      const allowFileUploadDirty =
        this.allowFileUpload !== this.selectedCohort.mentor_meeting_template.allow_file_upload;

      return contentLayoutDirty || allowFileUploadDirty;
    },
    suggestProForma() {
      return (
        this.allowFileUpload && this.contentLayout.sections.every(s => s.items.every(i => i.itemType !== 'resource'))
      );
    },
  },
  watch: {
    dirtyComputed(x) {
      this.dirty = x;
    },
  },
  created() {
    this.contentLayout = fromSnakeCaseToCamelCase(this.selectedCohort.mentor_meeting_template.content_layout);
    this.allowFileUpload = this.selectedCohort.mentor_meeting_template.allow_file_upload;
  },
  methods: {
    async save() {
      this.processing = true;
      this.saveError = null;

      try {
        const r = await this.$api.put(`/cohorts/${this.selectedCohort.id}/mentor-meeting-template`, {
          contentLayout: this.contentLayout,
          allowFileUpload: this.allowFileUpload,
        });
        this.$store.commit('updateSelectedCohortMentorMeetingTemplate', r.data);
        this.saveSnackbar = {
          active: true,
          message: 'Mentor Meeting Template saved',
        };
      } catch (e) {
        console.log(e);
        this.saveError = ' Sorry, cannot save your Mentor Meeting Template at the moment';
      }

      this.processing = false;
    },
  },
};
</script>
