<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <div v-if="!error && !busy">
      <v-card class="mb-4">
        <v-card-text>
          <mosaic-card-title
            >Schools
            <template #actions>
              <v-btn ripple @click.prevent="addSchoolDialog.active = true">
                <div class="d-flex align-center">
                  <v-icon>mdi-plus</v-icon>
                  <span>School</span>
                </div>
              </v-btn>
            </template>
          </mosaic-card-title>
          <div class="d-flex align-center">
            <div>
              <v-text-field
                v-model="filterTerm"
                density="compact"
                hide-details
                label="Filter schools by name"
                prepend-icon="mdi-magnify"
                style="width: 300px"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="flex-grow-1">
        <v-card-text>
          <mosaic-list
            :items="filteredSchools"
            :empty-text="`You have no Schools${schools.length > 0 ? ' for these filters' : ''}`"
          >
            <template #item="{ item: s }">
              <mosaic-list-item :to="schoolTo(s.id)" icon="mdi-domain" :title="s.displayName">
                <template #actions v-if="s.canDelete">
                  <ndt-icon-button icon="delete" tooltip="Delete School" @click.prevent="deleteSchool(s)" />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </v-card-text>
      </v-card>
      <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="filteredSchools.length" />
    </div>

    <ndt-dialog v-model:active="addSchoolDialog.active" title="Add School" :error-message="addSchoolDialog.error">
      <v-text-field v-model="addSchoolDialog.name" prepend-icon="mdi-domain" name="name" label="Name" type="text" />
      <v-text-field
        v-model="addSchoolDialog.urn"
        prepend-icon="mdi-pound"
        name="urn"
        label="URN"
        :rules="schoolUrnRules"
        type="text"
      />
      <v-text-field
        v-model="addSchoolDialog.addressFirstLine"
        prepend-icon="mdi-postage-stamp"
        name="address-1"
        label="Address Line 1"
        type="text"
      />
      <v-text-field
        v-model="addSchoolDialog.addressSecondLine"
        class="pl-8"
        prepend-icon
        name="address-2"
        label="Address Line 2"
        type="text"
      />
      <v-text-field
        v-model="addSchoolDialog.addressCity"
        class="pl-8"
        prepend-icon
        name="address-city"
        label="City"
        type="text"
      />
      <v-text-field
        v-model="addSchoolDialog.addressPostcode"
        class="pl-8"
        prepend-icon
        name="address-postcode"
        label="Postcode"
        type="text"
      />
      <v-text-field
        v-model="addSchoolDialog.addressCountry"
        class="pl-8"
        prepend-icon
        name="address-country"
        label="Country"
        type="text"
      />
      <v-text-field
        v-model="addSchoolDialog.phoneNumber"
        prepend-icon="mdi-phone"
        name="phone-number"
        label="Phone Number"
        type="text"
      />
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSubmitAddSchool" @click.prevent="submitAddSchool">Save</v-btn>
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="deleteDialog.active"
      title="Delete School"
      :error-message="deleteDialog.error"
      :width="500"
    >
      <span>Are you sure you want to delete "{{ deleteDialog.title }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteSchool()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import { schoolUrnRules, validationsPass } from '../../utils/validations';

export default {
  name: 'TutorSchoolsListPage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [syncQueryParamsMixin({ filterTerm: { query: 'name' } })],
  components: { NdtIconButton, NdtDialog },
  data: () => ({
    busy: true,
    error: null,
    filterTerm: '',
    addSchoolDialog: {
      active: false,
      processing: false,
      error: '',
      name: '',
      urn: '',
      addressFirstLine: '',
      addressSecondLine: '',
      addressCity: '',
      addressPostcode: '',
      addressCountry: '',
      phoneNumber: '',
    },
    deleteDialog: {
      active: false,
      processing: false,
      id: null,
      title: '',
    },
    schools: [],
    schoolsCount: 0,
    schoolUrnRules,
    currentPage: 1,
    pageSize: 10,
  }),
  computed: {
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [{ text: 'Schools' }];
    },
    filteredSchools() {
      return this.schools.filter(x => x.name.toLowerCase().includes(this.filterTerm.toLowerCase()));
    },
    paginatedSchools() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.filteredSchools.slice(index, index + this.pageSize);
    },
    schoolsTotal() {
      if (this.schools.length === this.filteredSchools.length && this.schools.length < this.schoolsCount)
        return this.schoolsCount;
      return this.filteredSchools.length;
    },
    canSubmitAddSchool() {
      return (
        !this.addSchoolDialog.processing &&
        this.addSchoolDialog.name &&
        this.addSchoolDialog.addressPostcode &&
        validationsPass(this.schoolUrnRules, this.addSchoolDialog.urn)
      );
    },
  },
  watch: {
    filteredSchools() {
      this.currentPage = 1;
    },
  },
  created() {
    this.loadSchools();
  },
  methods: {
    async loadSchools() {
      this.busy = true;
      this.error = false;
      try {
        const [staffResponse, countResponse] = await Promise.all([
          this.$api.get(`/institutions/${this.selectedInstitution.id}/schools?offset=0`),
          this.$api.get(`/institutions/${this.selectedInstitution.id}/schools/count`),
        ]);
        this.schools = staffResponse.data;
        this.schoolsCount = countResponse.data.count;
        this.busy = false;

        let offset = this.schools.length;
        while (true) {
          if (this.schools.length >= this.schoolsCount) break;
          const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/schools?offset=${offset}`);
          this.schools = this.schools.concat(r.data);
          if (r.data.length === 0) break;
          offset += r.data.length;
        }
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot load your Schools at the moment';
        this.busy = false;
      }
    },
    schoolTo(id) {
      return { name: 'TutorSchoolStudentsPage', params: { schoolId: id } };
    },
    async submitAddSchool() {
      this.addSchoolDialog.error = '';
      this.addSchoolDialog.processing = true;
      try {
        await this.$api.post(`/institutions/${this.selectedInstitution.id}/schools`, {
          name: this.addSchoolDialog.name,
          urn: this.addSchoolDialog.urn,
          addressFirstLine: this.addSchoolDialog.addressFirstLine,
          addressSecondLine: this.addSchoolDialog.addressSecondLine,
          addressCity: this.addSchoolDialog.addressCity,
          addressPostcode: this.addSchoolDialog.addressPostcode,
          addressCountry: this.addSchoolDialog.addressCountry,
          phoneNumber: this.addSchoolDialog.phoneNumber,
        });
        this.loadSchools();
        this.$store.commit('clearSchools');
        this.addSchoolDialog = {
          active: false,
          processing: false,
          error: '',
          name: '',
          addressFirstLine: '',
          addressSecondLine: '',
          addressCity: '',
          addressPostcode: '',
          addressCountry: '',
          phoneNumber: '',
        };
      } catch (e) {
        console.log(e);
        this.addSchoolDialog.error =
          e.response?.status === 409
            ? 'School with this URN already exists'
            : 'Sorry, cannot add this school at the moment';
      }

      this.addSchoolDialog.processing = false;
    },
    deleteSchool(school) {
      this.deleteDialog.id = school.id;
      this.deleteDialog.title = school.name;
      this.deleteDialog.active = true;
    },
    async submitDeleteSchool() {
      this.deleteDialog.error = '';
      this.deleteDialog.processing = true;
      try {
        await this.$api.delete(`/schools/${this.deleteDialog.id}`);
        this.loadSchools();
        this.deleteDialog.active = false;
      } catch (e) {
        console.log(e);
        this.deleteDialog.error = 'Sorry, cannot delete this school at the moment';
      }

      this.deleteDialog.processing = false;
    },
  },
};
</script>
