<template>
  <mosaic-create-edit-card-page
    object-type="Discussion"
    :is-creating="isCreating"
    :load="load"
    :dirty="dirty"
    :save="save"
    :can-save="canSave"
    :return-to="getReturnTo(breadcrumbs)"
    :subtitle="
      discussion
        ? { action: 'Created', name: discussion.createdBy.displayName, actionAt: discussion.createdAt }
        : undefined
    "
    :error-code-map="deleteOrEditDiscussionsErrorCodeMap('edit')"
  >
    <template #title-actions v-if="!isCreating">
      <mosaic-card-action-icon-btn
        icon="eye"
        tooltip="View Discussion"
        :to="{ name: 'StaffForumDiscussionViewPage' }"
      />
    </template>
    <mosaic-text-area label="Title" rows="1" v-model="title" name="title" prepend-icon="pencil" />
    <mosaic-quill-field label="Content" v-model="content" name="content" />
  </mosaic-create-edit-card-page>
</template>

<script setup lang="ts">
import { createOrUpdate, useApi } from '@/composables/api';
import { isCreatingRoute, parseRouteId } from '@/composables/vue-router';
import { computed, ref } from 'vue';
import { getReturnTo, setBreadcrumbs } from '@/utils/breadcrumbs';
import { useStaffStore } from '@/stores/staff';
import type { ForumResponse } from './forums';
import { deleteOrEditDiscussionsErrorCodeMap } from './forums';

// #region setup
const api = useApi();
const isCreating = isCreatingRoute();
const forumId = parseRouteId('forumId');
const id = parseRouteId('id');
const { userStaff } = useStaffStore();
// #endregion

// #region data
interface ForumDiscussionEditResponse {
  id: number;
  title: string;
  content: string;
  forum: {
    id: number;
    name: string;
  };
  createdAt: string;
  createdBy: { id: number; displayName: string };
}
const forum = ref<{ name: string }>();
const discussion = ref<ForumDiscussionEditResponse>();
const title = ref('');
const content = ref<string>('');
// #endregion

const breadcrumbs = computed(() => [
  { text: 'Forums', to: { name: 'StaffForumsListPage' } },
  {
    text: forum.value?.name || '',
    to: { name: 'StaffForumPage', params: { staffId: userStaff.value.id, id: forumId.value } },
  },
  {
    text: isCreating.value ? 'New Post' : discussion.value?.title || '',
  },
]);
setBreadcrumbs(breadcrumbs);

async function load() {
  if (!isCreating.value) {
    const r = await api.get<ForumDiscussionEditResponse>(`/staff/${userStaff.value.id}/forum-discussions/${id.value}`);
    discussion.value = r.data;
    forum.value = r.data.forum;
    title.value = r.data.title;
    content.value = r.data.content;
  } else {
    const r = await api.get<ForumResponse>(`/staff/${userStaff.value.id}/forums/${forumId.value}`);
    forum.value = r.data;
  }
}

const canSave = computed(() => !!title.value);
const { save, isCreatingAndReturning } = createOrUpdate(
  computed(() => `/staff/${userStaff.value.id}/forums/${forumId.value}/forum-discussions`),
  computed(() => `/staff/${userStaff.value.id}/forum-discussions/${id.value}`),
  computed(() => ({
    title: title.value,
    content: content.value,
    staffId: userStaff.value.id,
  })),
  discussion
);

const dirty = computed(() => {
  if (isCreatingAndReturning.value) return false;
  if (isCreating.value) {
    return !!title.value || !!content.value;
  } else {
    const p = discussion.value;
    if (!p) return false;
    return title.value != p.title || content.value != p.content;
  }
});
</script>
