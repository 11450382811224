<template>
  <div>
    <mosaic-tab-card-page
      v-model:selected-tab-query-key="selectedTabQueryKey"
      object-type="Training"
      title="My Training"
      :load="() => loadStaffTraining(userStaff.id)"
      :headers="staffTrainingTabHeaders"
      type="list"
    >
      <template #subtitle v-if="staffTraining?.totalDuration">
        <staff-training-subtitle :total-duration="staffTraining.totalDuration" />
      </template>
      <template #info
        ><mosaic-alert
          v-if="staffTrainingHasNasbttModules && !userStaff.nasbtt_modules_user_id"
          type="warning"
          class="pb-4 mt-2"
          ><div class="d-flex" :class="{ 'flex-wrap justify-center': smallScreen }">
            <div>
              Your Training includes NASBTT MDMs but you have not yet linked their NASBTT account to Mosaic. Your NASBTT
              progress will not pull through to Mosaic until you have linked your NASBTT account.
            </div>
            <v-divider v-if="!smallScreen" vertical class="mx-2"></v-divider>
            <div class="d-flex align-center mt-2">
              <mosaic-btn class="ml-4" color="primary" @click.prevent="linkNasbttModulesAccount(null)">
                Link NASBTT account
              </mosaic-btn>
            </div>
          </div>
        </mosaic-alert></template
      >

      <template #training-requirements-tab-item>
        <staff-training-requirements-tab
          :frameworks="assignedStaffTrainingFrameworks"
          :click-theme-to="clickThemeTo"
          is-me
          :load-error="reloadError"
        />
      </template>

      <template #training-modules-tab-item>
        <staff-training-modules-tab
          :module-completions="staffTrainingModuleCompletions"
          :click-module-to="clickModuleTo"
          is-me
          :reload="() => reload(false)"
          :load-error="reloadError"
        />
      </template>

      <template #manual-training-records-tab-item>
        <staff-training-manual-records-tab
          :training-records="staffTrainingRecords"
          :click-record-to="clickRecordTo"
          :create-training-record-to="{
            name: 'MyStaffTrainingRecordCreatePage',
          }"
          is-me
          :reload="() => reload(false)"
          :load-error="reloadError"
          show-add-training-record
          show-delete-training-record
          :can-delete-record="canEditStaffTraining"
          :can-add-record="canEditStaffTraining"
        />
      </template>

      <template #certificates-tab-item>
        <div v-if="reloadError" class="mt-2">
          Sorry, cannot load your Certificates right now. If this problem persists, please contact support.
        </div>
        <staff-training-certificates-tab
          v-else-if="staffTraining"
          :staff-training="staffTraining"
          is-me
          :click-certificate-to="
            c => ({
              name: 'MyStaffTrainingCertificateEditPage',
              params: { certificateId: c.id },
            })
          "
          :create-certificate-to="{
            name: 'MyStaffTrainingCertificateCreatePage',
          }"
          :certificate-deleted="() => reload(true)"
        />
      </template>

      <template #events-tab-item>
        <div v-if="reloadError" class="mt-2">
          Sorry, cannot load your Events right now. If this problem persists, please contact support.
        </div>
        <staff-training-events-tab
          v-else-if="staffTraining"
          :staff-training="staffTraining"
          is-me
          :click-event-to="
            e => ({
              name: 'MyStaffTrainingEventPage',
              params: { eventId: e.id },
            })
          "
        />
      </template>
    </mosaic-tab-card-page>
  </div>
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { ref } from 'vue';
import { mapState } from '../../store/map-store';
import { computed } from 'vue';
import StaffTrainingRequirementsTab from './StaffTrainingRequirementsTab.vue';
import StaffTrainingModulesTab from './StaffTrainingModulesTab.vue';
import StaffTrainingManualRecordsTab from './StaffTrainingManualRecordsTab.vue';
import StaffTrainingCertificatesTab from './StaffTrainingCertificatesTab.vue';
import StaffTrainingEventsTab from '@/pages/staff-training/StaffTrainingEventsTab.vue';
import { useRoute } from 'vue-router';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { useStaffTrainingStore, type StaffTraining, type StaffTrainingCompetencyTheme } from '@/stores/staff-training';
import { staffTrainingTabHeaders } from './staff-training';
import StaffTrainingSubtitle from './StaffTrainingSubtitle.vue';
import { useNasbttModulesStore } from '@/stores/nasbtt-modules';
import { useSmallScreen } from '@/composables/vuetify';

const smallScreen = useSmallScreen();
import { hasPermissionForSelectedInstitution } from '@/composables/permission';

const route = useRoute();
const selectedTabQueryKey = ref(route.query.tab || 'training-requirements');

const {
  actions: { linkNasbttModulesAccount },
} = useNasbttModulesStore();

setBreadcrumbs(
  computed(() => [
    {
      text: 'My Training',
      to: { name: 'TutorStaffTrainingPage' },
    },
  ])
);

const { userStaff, user } = mapState();

// Load Staff Training
const {
  staffTraining,
  staffTrainingModuleCompletions,
  assignedStaffTrainingFrameworks,
  staffTrainingRecords,
  staffTrainingHasNasbttModules,
  actions: { loadStaffTraining },
} = useStaffTrainingStore();

const canEditStaffTraining = computed(() => hasPermissionForSelectedInstitution('staff.training.edit').value);

const { action: reload, error: reloadError } = withProcessingAndError(async (forUser: boolean) => {
  await Promise.all(
    forUser
      ? user.value.staff?.map(staff => loadStaffTraining(staff.id, true)) || []
      : [loadStaffTraining(userStaff.value.id, true)]
  );
});

// Click
function clickThemeTo(theme: StaffTrainingCompetencyTheme) {
  return {
    name: 'MyStaffTrainingCompetencyThemePage',
    params: { themeId: theme.id.toString(), staffId: userStaff.value.id.toString() },
    query: { fromTab: 'training-requirements' },
  };
}

function clickModuleTo(module: StaffTraining['staffTrainingModuleCompletions'][number]['staffTrainingModule']) {
  return {
    name: 'MyStaffTrainingModulePage',
    params: { moduleId: module.id.toString() },
    query: { fromTab: 'training-modules' },
  };
}
function clickRecordTo(record: StaffTraining['staffTrainingRecords'][number]) {
  return {
    name: 'MyStaffTrainingRecordEditPage',
    params: { recordId: record.id.toString() },
  };
}
</script>
