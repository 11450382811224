import { hasPermissionForStudent } from '@/mixins/global-mixins';
import type { SelectedStudent, UserStaff } from '@/store/map-store';

export function findStudentLevelHomePage(staff: UserStaff, student: SelectedStudent) {
  const hasAdminPermission = hasPermissionForStudent(staff, 'Admin', student);
  const isEarlyCareers = staff.institution.config.early_careers;
  const hasReviewPermission = hasPermissionForStudent(staff, 'student.reviews.view', student);
  const hasManageInductionPermission = hasPermissionForStudent(staff, 'ect.manageInduction.view', student);

  const name = isEarlyCareers
    ? hasAdminPermission || hasManageInductionPermission
      ? 'TutorEctProgressPage'
      : hasReviewPermission
      ? 'TutorReviewsListPage'
      : 'TutorTargetsListPage'
    : hasAdminPermission
    ? 'TutorStudentDetailsPage'
    : 'TutorTargetsListPage';

  return {
    name,
    params: { studentId: student.id },
  };
}
