<template>
  <div>
    <div v-if="loadError" class="mt-2">
      <span v-if="isString(loadError)">{{ loadError }}</span>
      <span v-else>
        Sorry, cannot load {{ isMe ? 'your' : "this Instructor's" }} Training Modules right now. If this problem
        persists, please contact support.
      </span>
    </div>
    <mosaic-tab-item-list v-else :items="sortedAndFilteredItems" :empty-text="emptyText" mosaic-key="modules">
      <template #filters>
        <mosaic-text-field
          dense
          no-icon
          hide-details
          name="name-filter"
          label="Filter by name"
          v-model="nameFilter"
          style="width: 300px"
        />
        <mosaic-select
          name="type-filter"
          label="Filter by status"
          :items="statusFilterItems"
          v-model="statusFilter"
          hide-details
          dense
          no-icon
          style="width: 210px"
        />
      </template>

      <template #item="{ item }">
        <mosaic-list-item
          :key="item.key"
          :icon="item.icon"
          :title="item.title"
          :subtitle="item.subtitle"
          :to="item.to"
          :chip="item.chip"
        >
        </mosaic-list-item>
      </template>
    </mosaic-tab-item-list>
  </div>
</template>

<script setup lang="ts">
import { formatDate } from '@/mixins/global-mixins';
import { constructCompletionData, type StaffTraining } from '@/stores/staff-training';
import { icons } from '@/utils/icons';
import { computed, ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { isString } from 'lodash';
import { mapModuleStatus, staffTrainingModuleExpectedDuration } from './staff-training';
import { syncQueryParam } from '@/composables/query';
import { moduleStatuses } from './staff-training';

const props = withDefaults(
  defineProps<{
    moduleCompletions: StaffTraining['staffTrainingModuleCompletions'];
    loadError?: boolean | string;
    clickModuleTo: (
      module: StaffTraining['staffTrainingModuleCompletions'][number]['staffTrainingModule']
    ) => RouteLocationNamedRaw;
    isMe?: boolean;
    reload: () => Promise<unknown>;
  }>(),
  {
    loadError: false,
    isMe: false,
  }
);

// #region items and filters
const items = computed(() =>
  props.moduleCompletions.map(c => {
    const date = c.completed ? c.completedAt : c.createdAt;
    let duration = staffTrainingModuleExpectedDuration(c.staffTrainingModule, '');
    if (duration) duration = ` - ${duration}`;
    const statusValue = constructCompletionData(c).status;
    const chip = mapModuleStatus(statusValue);

    return {
      ...c,
      id: c.id,
      key: `module-${c.id}`,
      type: 'module',
      icon: icons.instructorTrainingModule,
      title: c.staffTrainingModule.name,
      subtitle: `Training Module - ${c.completed ? 'Completed' : 'Started'} on ${formatDate(date)}${duration}`,
      to: props.clickModuleTo(c.staffTrainingModule),
      chip,
      date,
    };
  })
);

const nameFilter = ref('');
syncQueryParam(nameFilter, 'modules-name', 'string');

const statusFilterItems = [{ text: 'All', value: null }, ...moduleStatuses];
const statusFilter = ref<'started' | 'completed' | null>(null);
syncQueryParam(statusFilter, 'modules-status', 'string | null');

const sortedAndFilteredItems = computed(() =>
  items.value
    .filter(
      i =>
        i.title.toLowerCase().includes(nameFilter.value.toLowerCase()) &&
        (statusFilter.value === null ||
          (statusFilter.value == 'started' && !i.completed) ||
          (statusFilter.value == 'completed' && i.completed))
    )
    .sortBy('date', 'desc')
);
// #endregion

const emptyText = computed(() => {
  if (items.value.length == 0) {
    return props.isMe ? 'You have no Training Modules' : `This Instructor has no Training Modules`;
  } else {
    return props.isMe
      ? 'You have no Training Modules for these filters'
      : 'This Instructor has no Training Modules for these filters';
  }
});
</script>
