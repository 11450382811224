<template>
  <div>
    <mosaic-create-edit-card-page
      object-type="Accepted Certificate"
      :is-creating="isCreating"
      :load="load"
      :dirty="dirty"
      :save="save"
      :can-save="canSave"
      :return-to="getReturnTo(breadcrumbs)"
    >
      <div class="d-flex align-center">
        <mosaic-select
          class="flex-grow-1 mr-2"
          name="type"
          label="Certificate Type"
          v-model="certificateTypeId"
          :items="certificateTypesWithInUse"
          item-title="name"
          item-value="id"
          :prepend-icon="icons.certificate"
        />
        <mosaic-help>If you can't find a relevant Certificate type then please get in touch with support</mosaic-help>
      </div>
      <mosaic-quill-field
        label="Description (e.g. evidence you will accept)"
        name="description"
        v-model="description"
      />
      <staff-training-competency-multi-select
        v-if="institutionStaffTrainingFrameworksIncludingUnpublished"
        label="Competencies this Certificate meets"
        v-model="competencyIds"
        :frameworks="institutionStaffTrainingFrameworksIncludingUnpublished"
      />
    </mosaic-create-edit-card-page>
  </div>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { isCreatingRoute, parseRouteId } from '@/composables/vue-router';
import { useStaffTrainingStore, type StaffTrainingAcceptedCertificateSlimResponse } from '@/stores/staff-training';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';
import { computed, ref } from 'vue';
import StaffTrainingCompetencyMultiSelect from '@/pages/staff-training/StaffTrainingCompetencyMultiSelect.vue';
import { useInstitutionStore } from '@/stores/institution';
import { icons } from '@/utils/icons';
import { setBreadcrumbs, getReturnTo } from '@/utils/breadcrumbs';

// #region setup
const isCreating = isCreatingRoute();

const { selectedInstitution } = useInstitutionStore();

const {
  actions: { clearAllStaffTraining },
} = useStaffTrainingStore();

const {
  certificateTypes,
  institutionStaffTrainingFrameworksIncludingUnpublished,
  actions: { loadCertificateTypes, loadInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();

const api = useApi();
const id = parseRouteId('id');
// #endregion

// #region data
interface StaffTrainingAcceptedCertificateResponse {
  id: number;
  description: string;
  certificateType: {
    id: number;
    name: string;
  };
  staffTrainingAcceptedCertificateCompetencies: {
    staffTrainingCompetencyId: number;
  }[];
}

const certificateTypeId = ref<number>();
const description = ref<string>();
const competencyIds = ref<number[]>([]);

const certificate = ref<StaffTrainingAcceptedCertificateResponse>();
const institutionCertificates = ref<StaffTrainingAcceptedCertificateSlimResponse>([]);

const certificateTypesWithInUse = computed(() => {
  return certificateTypes.value.map(ct => {
    const alreadyCreated = institutionCertificates.value.some(
      ic =>
        ic.certificateType.id === ct.id &&
        (isCreating.value || certificate.value?.certificateType.id !== ic.certificateType.id)
    );
    return {
      ...ct,
      name: alreadyCreated ? `${ct.name} (already in use)` : ct.name,
      props: { disabled: alreadyCreated },
    };
  });
});
// #endregion

// #region dirty checking
const isCreatingAndReturning = ref(false);
const dirty = computed(() => {
  if (isCreating.value) {
    if (isCreatingAndReturning.value) return false;
    return !!certificateTypeId.value || !!description.value || competencyIds.value.length > 0;
  } else {
    const c = certificate.value;
    return (
      certificateTypeId.value !== c?.certificateType.id ||
      description.value !== c?.description ||
      competencyIds.value.length !== c?.staffTrainingAcceptedCertificateCompetencies.length ||
      c.staffTrainingAcceptedCertificateCompetencies.some(
        cc => !competencyIds.value.includes(cc.staffTrainingCompetencyId)
      )
    );
  }
});
// #endregion

const breadcrumbs = computed(() => [
  { text: 'Instructors', to: { name: 'InstitutionStaffListPage' } },
  { text: 'Training', to: { name: 'InstitutionStaffTrainingPage', query: { tab: 'certificates' } } },
  {
    text: isCreating.value ? 'New Accepted Certificate' : certificate.value?.certificateType.name || '',
  },
]);
setBreadcrumbs(breadcrumbs);

// #region load
async function load() {
  await Promise.all([
    loadCertificateTypes(),
    loadInstitutionCertificates(),
    loadCertificate(),
    loadInstitutionStaffTrainingFrameworks(),
  ]);
}

async function loadCertificate() {
  if (!isCreating.value) {
    const r = await api.get<StaffTrainingAcceptedCertificateResponse>(
      `/staff-training/accepted-certificates/${id.value}`
    );
    certificate.value = r.data;
    certificateTypeId.value = r.data.certificateType.id;
    description.value = r.data.description;
    competencyIds.value = r.data.staffTrainingAcceptedCertificateCompetencies.map(c => c.staffTrainingCompetencyId);
  }
}

// Deliberately not using the store so it's up to date
async function loadInstitutionCertificates() {
  const r = await api.get<StaffTrainingAcceptedCertificateSlimResponse>(
    `/institutions/${selectedInstitution.value.id}/staff-training/accepted-certificates`
  );
  institutionCertificates.value = r.data;
}
// #endregion

const canSave = computed(() => !!certificateTypeId.value && dirty.value);
async function save() {
  const body = {
    certificateTypeId: certificateTypeId.value,
    description: description.value,
    competencies: competencyIds.value.map(id => ({ staffTrainingCompetencyId: id })),
  };

  if (isCreating.value) {
    await api.post(`/institutions/${selectedInstitution.value.id}/staff-training/accepted-certificates`, body);
    isCreatingAndReturning.value = true;
  } else {
    const r = await api.put<typeof body, StaffTrainingAcceptedCertificateResponse>(
      `/staff-training/accepted-certificates/${certificate.value!.id}`,
      body
    );
    certificate.value = r.data;
  }
  clearAllStaffTraining();
}
</script>
